import React, {useEffect, useState, useCallback, useRef} from 'react';
import Button from '../../components/general/Button';
import {useParams} from 'react-router-dom';
import { Link } from 'react-router-dom';
import useUserSettings from "../../hooks/useUserSettings";
import NotificationMessage from '../../components/general/NotificationMessage';
import SimpleErrorBar from '../../components/general/SimpleErrorBar';
import Select from 'react-select';
import {Helmet} from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';

import NotReadyYet from '../../components/errors/NotReadyYet';
import useDecks from '../../hooks/useDecks';
import {useLanguagesContext} from '../../contexts/LanguagesContext';

const InputTextRow = (props) => {
    const {onChange, value, index} = props;

    const [cursor, setCursor] = useState(null);
    const ref = useRef(null);
 
    useEffect(() => {
       const input = ref.current;
       if (input) input.setSelectionRange(cursor, cursor);
    }, [ref, cursor, value]);
 
    const handleChange = (e, index) => {
       setCursor(e.target.selectionStart);
       onChange && onChange(e, index);
    };

    return (
        <>
            <div>
                <input value={value} ref={ref} onChange={(e)=>handleChange(e, index)} type="text" className="bg-gray-200 appearance-none border-2 border-gray-200 rounded-xl w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white" />
            </div>
        </>
    )
}

const SelectRow = (props) => {
    const {value, onChange, options, isSearchable, index} = props;
    const ref = useRef(null);
    return (
        <>
            <div className="">
                <Select ref={ref} value={value} onChange={(e)=>onChange(e, index)} options={options} isSearchable={isSearchable} autoFocus={false} className="w-full   text-gray-500 text-base text-left"  />
            </div>
        </>
    )
}


const IndividualDeckSettings = (props) => {
    const {id} = useParams();
    const [deckId, setDeckId] = useState(parseInt(id));
    const [error, setError] = useState(false);
    const [deck, setDeck] = useState(null);
    console.log(deck);
    const {allLanguages, sourceLanguageOptions} = useLanguagesContext();

    const [saving, setSaving] = useState(false);
    const [showSavedSuccess, setShowSavedSuccess] = useState(false);
    const [showSavedError, setShowSavedError] = useState(false);

    // settings parameters
    const [variables, setVariables] = useState(null);
    const [deckName, setDeckName] = useState("");
    const [sourceLanguage, setSourceLanguage] = useState(null);
    const [targetLanguage, setTargetLanguage] = useState(null);
    const [privacy, setPrivacy] = useState(null);
    const [privacyOptions, setPrivacyOptions] = useState([{'label': "Public", 'value': 'public'}, {'label': "Private", 'value': 'private'}]);
    const [privacyDict, setPrivacyDict] = useState(privacyOptions.reduce(function(result, item) {
        var key = item.value; 
        result[key] = item;
        return result;
      }, {}))

    const [publicTitle, setPublicTitle] = useState("");
    const [publicDescription, setPublicDescription] = useState("");
    const [publicUrl, setPublicUrl] = useState("");

    const [youtubeVideoId, setYoutubeVideoId] = useState("");
    const [textId, setTextId] = useState("");
    const sourceLanguageScriptOptions =[{'label': 'Simplified characters', 'value': 'simplified'}, {'label': 'Traditional characters', 'value': 'traditional'}, {'label': 'Pinyin', 'value': 'pinyin'}, {'label': 'Zhuyin', 'value': 'zhuyin'}];
    const sourceLanguageScriptOptionsDict = sourceLanguageScriptOptions.reduce(function(result, item) {
        var key = item.value; 
        result[key] = item;
        return result;
      }, {});
    const [sourceLanguageScript, setSourceLanguageScript] = useState(sourceLanguageScriptOptions[0].value);
    const targetLanguageScriptOptions =[{'label': 'Simplified characters', 'value': 'simplified'}, {'label': 'Traditional characters', 'value': 'traditional'}, {'label': 'Pinyin', 'value': 'pinyin'}, {'label': 'Zhuyin', 'value': 'zhuyin'}];
    const targetLanguageScriptOptionsDict = targetLanguageScriptOptions.reduce(function(result, item) {
        var key = item.value;
        result[key] = item;
        return result;
        }, {});
    const [targetLanguageScript, setTargetLanguageScript] = useState(targetLanguageScriptOptions[0].value);

    const [showAddNewCustomField, setShowAddNewCustomField] = useState(false);
    const [customFields, setCustomFields] = useState(null);
    const [originalCustomFields, setOriginalCustomFields] = useState(null);
    const [customFieldsTypeOptions, setCustomFieldsTypeOptions] = useState([
        {
            'label': 'Text', 
            'value': 'text'
        }, 
        {
            'label': 'List', 
            'value': 'list' 
        }
    ]);
    const [customFieldsTypeOptionsDict, setCustomFieldsTypeOptionsDict] = useState(customFieldsTypeOptions.reduce(function(result, item) {
        var key = item.value; 
        result[key] = item;
        return result;
      }, {}));

    const handleEditCustomFieldName = (e, index) => {
        let copy = JSON.parse(JSON.stringify(customFields));
        copy[index]['data']['name'] = e.target.value;
        setCustomFields(copy);
    }



    const handleEditCustomFieldType = (option, index) => {
        let copy = JSON.parse(JSON.stringify(customFields));
        copy[index]['data']['type'] = option.value;
        setCustomFields(copy);
    }

    const handleAddNewCustomField = () => {
        let copy = JSON.parse(JSON.stringify(customFields));
        copy.push({'data': {'name': '', 'type': 'text'}});
        setCustomFields(copy);
    }

    const deleteCustomFieldClick = (e, index) => {
        let copy = JSON.parse(JSON.stringify(customFields));
        let newList = [];
        copy.forEach((item, itemIndex)=>{
            if (index !== itemIndex){
                newList.push(item);
            }
        })
        setCustomFields(newList);
    }

    let {fetchAllItemsInUserDeckRealtime, 
        fetchPaginatedItemsInUserDeck,
        fetchDeckFromCurrentUser, 
        fetchDeckCustomFieldsFromCurrentUser, 
        saveChangesToDeck, 
        saveNewCustomFieldApi, 
        editExistingCustomFieldApi, 
        deleteCustomFieldApi} = useDecks();

    useEffect(()=>{
        const setVariables = (d) => {
            if (d === null || d === undefined){return false}
            if (d.hasOwnProperty("name")){
                setDeckName(d.name);
            };
            if (d.hasOwnProperty("source_ISO_639-1")){
                setSourceLanguage(d['source_ISO_639-1']);
            };
            if (d.hasOwnProperty("target_ISO_639-1")){
                setTargetLanguage(d['target_ISO_639-1']);
            };
            if (d.hasOwnProperty("target_script")){
                setTargetLanguageScript(d['target_script']);
            };
            if (d.hasOwnProperty("source_script")){
                setSourceLanguageScript(d['source_script']);
            };
            if (d.hasOwnProperty("privacy")){
                setPrivacy(d['privacy']);
            };
            if (d.hasOwnProperty("title")){
                setPublicTitle(d['title']);
            };
            if (d.hasOwnProperty("description")){
                setPublicTitle(d['publicDescription']);
            };
            if (d.hasOwnProperty("youtube_id")){
                setYoutubeVideoId(d['youtube_id']);
            };
            if (d.hasOwnProperty("yalango_text_id")){
                setTextId(d['yalango_text_id']);
            };
            setPublicUrl("https://yalango.com/decks/"+deckId);
        }

        const getDeckInDb = async () => {
            let deckMetadata = await fetchDeckFromCurrentUser(deckId);
            let c_f = await fetchDeckCustomFieldsFromCurrentUser(deckMetadata.doc_id);
            setCustomFields(c_f);
            setOriginalCustomFields(c_f);
            setDeck(deckMetadata);
            setVariables(deckMetadata);
        }

        if (deckId !== null){
            getDeckInDb();
        }
    },[deckId]);

    useEffect(()=>{
        const setDataFunction = () => {
            const v = {
                'deckName': {
                    'value': deckName,
                    'onChange': deckNameOnChange,
                   
                }, 
                'sourceLanguageScript': {
                    'value': sourceLanguageScriptOptionsDict[sourceLanguageScript],
                    'onChange': sourceLanguageScriptOnChange,
                    'options': sourceLanguageScriptOptions,
                    'searchable': false
                },
                'targetLanguageScript': {
                    'value': targetLanguageScriptOptionsDict[targetLanguageScript],
                    'onChange': targetLanguageScriptOnChange,
                    'options': sourceLanguageScriptOptions,
                    'searchable': false
                },
                'sourceLanguage': {
                    'value': sourceLanguageOptions[1][sourceLanguage], 
                    'onChange': sourceLanguageOnChange, 
                    'options': sourceLanguageOptions[0],
                }, 
                'targetLanguage': {
                    'value': sourceLanguageOptions[1][targetLanguage], 
                    'onChange': targetLanguageOnChange, 
                    'options': sourceLanguageOptions[0],
                }, 
                'privacy': {
                    'value': privacyDict[privacy], 
                    'onChange': privacyOnChange, 
                    'options': privacyOptions,
                }, 
                'publicTitle': {
                    'value': publicTitle,
                    'onChange': titleOnChange
                }, 
                'publicDescription': {
                    'value': publicDescription,
                    'onChange': descriptionOnChange
                }, 
                'publicUrl': {
                    'label': publicUrl,
                    'value': "/decks/"+deckId,
                }, 
                'youtubeVideoId': {
                    'value': youtubeVideoId,
                    'onChange': youtubeVideoIdOnChange
                }, 
                'textId': {
                    'value': textId,
                    'onChange': textIdOnChange
                }, 
            }
            console.log(v);
            setVariables(v);
        }
        console.log(deckName, sourceLanguage, sourceLanguageOptions);
        if (deckName !== null && sourceLanguage !== null && targetLanguage !== null && sourceLanguageOptions !== null && privacy !== null && privacyDict !== null && privacyOptions !== null && publicTitle !== null && publicDescription !== null && publicUrl !== null && deckId !== null && youtubeVideoId !== null && textId !== null){
            setDataFunction();
        }
    },[deckName, sourceLanguageScript, targetLanguageScript, targetLanguage, deckId, sourceLanguage, sourceLanguageOptions, privacyDict, privacy, privacyOptions, publicTitle, publicDescription, publicUrl, youtubeVideoId, textId])

    console.log("Deck: ", deck);

    const [sections, setSections] = useState([
        {
            'label': 'General', 
            'value': 'general', 
            'description': "General settings for your deck."
        },
        {
            'label': 'Custom fields', 
            'value': 'custom_fields', 
            'description': "Make your deck more useful with custom fields."
        },
        {
            'label': 'Sharing', 
            'value': 'sharing', 
            'description': "Share your deck publicly with the world."
        },
        {
            'label': 'Extra', 
            'value': 'extra', 
            'description': "If this deck is based on a YouTube video or a Yalango text, you can add the IDs below."
        },
    ]);

    const [viewSections, setViewSections] = useState(sections.map((section, sectionIndex)=>section.value));
    console.log(viewSections)

    const toggleViewSection = (sectionValue) => {
        console.log(viewSections)
        setViewSections(currentViewSections => {
            if (currentViewSections.includes(sectionValue)) {
                return currentViewSections.filter(item => item !== sectionValue);
            } else {
                return [...currentViewSections, sectionValue];
            }
        });
        
    };
    

    const [rows, setRows] = useState([
        {
            'parent': 'general', 
            'label': 'Deck name', 
            'variable_name': 'deckName',
            'type': 'input_text'
        }, 
        {
            'parent': 'general', 
            'label': 'Target language', 
            'variable_name': 'targetLanguage',
            'type': 'select',  
            'searchable': true,
        }, 
        {
            'parent': 'general', 
            'label': 'Source language', 
            'variable_name': 'sourceLanguage',
            'type': 'select',  
            'searchable': true,
        }, 
        {
            'parent': 'sharing', 
            'label': 'Privacy', 
            'variable_name': 'privacy',
            'type': 'select', 
            'searchable': false,
        }, 
        {
            'parent': 'sharing', 
            'label': 'Public title', 
            'variable_name': 'publicTitle',
            'type': 'input_text',
            'showIf': 'privacy, public'
        }, 
        {
            'parent': 'sharing', 
            'label': 'Public description', 
            'variable_name': 'publicDescription',
            'type': 'input_text',
            'showIf': 'privacy, public'
        }, 
        {
            'parent': 'sharing', 
            'label': 'Public URL', 
            'variable_name': 'publicUrl',
            'type': 'link',
            'showIf': 'privacy, public'
        }, 
        {
            'parent': 'extra', 
            'label': 'YouTube ID', 
            'variable_name': 'youtubeVideoId',
            'type': 'input_text',
        }, 
        {
            'parent': 'extra', 
            'label': 'Yalango text ID', 
            'variable_name': 'textId',
            'type': 'input_text',
        }, 
    ]);

    const sourceLanguageScriptOnChange = (option) => {
        console.log(option);
        setSourceLanguageScript(option.value);
    }

    const targetLanguageScriptOnChange = (option) => {
        console.log(option);
        setTargetLanguageScript(option.value);
    }
    
    const deckNameOnChange = (e) => {
        setDeckName(e.target.value);
    }

    const titleOnChange = (e) => {
        setPublicTitle(e.target.value);
    }

    const descriptionOnChange = (e) => {
        setPublicDescription(e.target.value);
    }

    const sourceLanguageOnChange = (option) => {
        setSourceLanguage(option.value);
        if (option.value !== "zh"){
            setSourceLanguageScript(null);
        }
    }

    const targetLanguageOnChange = (option) => {
        setTargetLanguage(option.value);
        if (option.value !== "zh"){
            setTargetLanguageScript(null);
        }
    }

    const privacyOnChange = (option) => {
        setPrivacy(option.value);
    }

    const youtubeVideoIdOnChange = (e) => {
        setYoutubeVideoId(e.target.value);
    }

    const textIdOnChange = (e) => {
        setTextId(e.target.value);
    }

    const createCustomFieldClick = () => {
        setShowAddNewCustomField(!showAddNewCustomField);
        handleAddNewCustomField();
    }

    const saveChangesToCustomFields = async () => {
        let copy = JSON.parse(JSON.stringify(customFields));
        for ( const [newCustomFieldIndex, newCustomField] of Object.entries(customFields)){
            let found = false;
            for (const [oldCustomFieldIndex, oldCustomField] of Object.entries(originalCustomFields)){
                console.log(oldCustomField, newCustomField)
                if (newCustomField.hasOwnProperty("docId") && oldCustomField.hasOwnProperty("docId")){
                    if (newCustomField.docId === oldCustomField.docId){
                        // existed already
                        found = true;
                        if (oldCustomField.data.name !== newCustomField.data.name || oldCustomField.data.type !== newCustomField.data.type){
                            try {
                                let results = await editExistingCustomFieldApi(deck, oldCustomField, newCustomField);
                                let updatedField = results.newField.data;
                                copy[newCustomFieldIndex]['data']['name'] = updatedField.name;
                                copy[newCustomFieldIndex]['data']['type'] = updatedField.type;
                            } catch {
                                return false
                            }
                           
                        }
                    }
                }
            }
            if (!found){
                // new custom field      
                if (newCustomField.data.name !== "" && newCustomField.data.type !== null){
                    console.log("New: ", newCustomField);
                    try {
                        let newDbField = await saveNewCustomFieldApi(deck, newCustomField.data.name, newCustomField.data.type);
                        console.log("From db: ", newDbField);
                        copy[newCustomFieldIndex] = newDbField;
                    } catch {
                        return false
                    }
                   
                }
            }
        };
        if (originalCustomFields.length > customFields.length){
            // fields have been deleted
            for (const [oldCustomFieldIndex, oldCustomField] of Object.entries(originalCustomFields)){
                let found = false;
                for ( const [newCustomFieldIndex, newCustomField] of Object.entries(customFields)){
                    if (newCustomField.hasOwnProperty("docId") && oldCustomField.hasOwnProperty("docId")){
                        if (newCustomField.docId === oldCustomField.docId){
                            found = true;
                        }
                    }
                }
                if (!found){
                    console.log("Will delete ", oldCustomField);
                    try {
                        let deleteStatus = await deleteCustomFieldApi({deckDocId: deck.doc_id, fieldDocId: oldCustomField.docId})
                    }
                    catch {
                        return false;
                    }
                }
            }
        }
        // refresh customfields
        setCustomFields(copy);
        setOriginalCustomFields(copy);
        return true;
    }

    const saveChangesClick = async () => {
        setSaving(true);
        setShowSavedError(false);
        setShowSavedSuccess(false);
        let errorIfFalse = await saveChangesToDeck({deckId, deckName, sourceLanguage, privacy, publicTitle, publicDescription, youtubeVideoId, targetLanguage, textId, sourceLanguageScript, targetLanguageScript}).catch(()=>{
            setShowSavedError(true);
        });

        let customFieldsSaved = await saveChangesToCustomFields();
        if (errorIfFalse === false || customFieldsSaved === false){
            console.log("err")
            setShowSavedError(true);
        } else {
            setShowSavedSuccess(true);
        }
        setSaving(false);  
    }

    return (
        <>
            <div className=" ">
                <div className="text-xs">
                    <Link to={"/my-decks/"+deckId}>Back to deck</Link>
                </div>
                
                <div className="flex flex-row justify-center w-5/6 md:w-4/6 m-auto z-0">
                    <div className="flex flex-col gap-12 w-full place-items-center">
                        <h1>Settings</h1>
                        {sections.map((section, sectionIndex)=>(
                            <>
                                <div className={` w-full flex flex-col gap-6`} key={"section_"+sectionIndex}>
                                    <div   className="flex flex-col gap-3">
                                        <div onClick={()=>toggleViewSection(section.value)} className={`h-full  cursor-pointer w-full text-center font-bold text-xl p-3 charcoal-gradient text-white rounded-xl shadow-lg flex flex-row justify-center`}>
                                            <div className="flex-grow">
                                                {section.label}
                                            </div>
                                            <div className="ml-auto">
                                                <FontAwesomeIcon icon={viewSections.includes(section.value) ? faChevronUp : faChevronDown} />
                                            </div>
                                        </div> 
                                        {viewSections.includes(section.value) &&
                                        <div className={`${viewSections.includes(section.value) ? ' block' : '  hidden'}  duration-300 transition-all ease-in-out`}>
                                            <div className="w-full text-center text-sm ">
                                                {section.description}
                                            </div>
                                            {section.value === "custom_fields" && 
                                                <>
                                                    <div className="flex flex-row justify-center">
                                                        <Button text="Create new" onClick={createCustomFieldClick} icon={faPlus} color="green" size="small" />
                                                    </div>
                                                    {customFields !== null && customFields.length > 0 &&
                                                        <div className="">
                                                            <table className="m-auto text-center w-full table-auto">
                                                                <thead>
                                                                    <tr className="bg-white font-bold ">
                                                                        <th className=" w-3/6 border border-solid border-white px-8 py-4">
                                                                            Name
                                                                        </th>
                                                                        <th className=" w-3/6  border  px-8 py-4">
                                                                            Type
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {customFields !== null && customFields.map((customField, customFieldIndex)=>(
                                                                        <>
                                                                            <tr className="w-full" key={"custom_field_"+customFieldIndex}>
                                                                            
                                                                                <td className=" border py-4 px-1">
                                                                                    <InputTextRow index={customFieldIndex} onChange={handleEditCustomFieldName} value={customField.data.name}  />
                                                                                </td>
                                                                                <td className=" border py-4 px-1">
                                                                                    <SelectRow index={customFieldIndex} onChange={handleEditCustomFieldType} value={customFieldsTypeOptionsDict[customField.data.type]} options={customFieldsTypeOptions} isSearchable={false} />                                                                                
                                                                                </td>
                                                                                <td onClick={(e)=>deleteCustomFieldClick(e, customFieldIndex)} className="w-16 px-2 text-burnt-sienna cursor-pointer">
                                                                                    <FontAwesomeIcon icon={faTrash} />
                                                                                </td>
                                                                            </tr>
                                                                            
                                                                        </>
                                                                    ))}
                                                                    
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    }
                                                </>
                                            }
                                    
                                        </div>
                                        }
                                    </div>
                                    <div className={`${viewSections.includes(section.value) ? '  block' : ' hidden'}  duration-300 transition-all ease-in-out`}>
                                        <div className="w-full flex flex-col gap-3">
                                            {variables !== null && rows.map((row, rowIndex)=>(
                                                <>
                                                    {row.parent === section.value &&
                                                        <>
                                                            <div className="w-full flex flex-col gap-2 sm:flex-row place-items-center sm:gap-6 justify-start" key={"row_"+rowIndex}>
                                                                <div className="w-full md:w-1/3">
                                                                    <label className="block text-gray-500 font-bold text-left mb-1 md:mb-0 pr-4" htmlFor="inline-full-name">
                                                                        {row.label}
                                                                    </label>
                                                                </div>
                                                                <div className="w-full md:w-2/3 ">
                                                                    {row.type === "input_text" &&
                                                                        <InputTextRow value={variables[row.variable_name].value} onChange={variables[row.variable_name].onChange} />
                                                                    }
                                                                    {row.type === "select" &&
                                                                        <SelectRow value={variables[row.variable_name].value} onChange={variables[row.variable_name].onChange} options={variables[row.variable_name].options} isSearchable={variables[row.variable_name]['searchable']} />
                                                                    }
                                                                    {row.type === "link" &&
                                                                        <>
                                                                            <Link to={variables[row.variable_name].value} target="_blank">
                                                                                {variables[row.variable_name].label}
                                                                            </Link>
                                                                        </>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </> 
                                            ))} 
                                            {section.value === "general" && (sourceLanguage === "zh" || targetLanguage === "zh") && variables !== null &&
                                                <>
                                                    <div className="mt-4 flex flex-col gap-3">
                                                        <div className="font-semibold text-center muted-text">Do you want to use characters or pinyin/zhuyin on your cards?</div>
                                                        {targetLanguage === "zh" &&
                                                        <div className="w-full flex flex-col gap-2 sm:flex-row place-items-center sm:gap-6 justify-start" >
                                                            <div className="w-full md:w-1/3">
                                                                <label className="block text-gray-500 font-bold text-left mb-1 md:mb-0 pr-4" htmlFor="inline-full-name">
                                                                    Target language script
                                                                </label>
                                                            </div>
                                                            <div className="w-full md:w-2/3 ">
                                                                <SelectRow value={variables['targetLanguageScript'].value} onChange={variables['targetLanguageScript'].onChange} options={variables['targetLanguageScript'].options} isSearchable={variables['targetLanguageScript']['searchable']} />
                                                            </div>
                                                        </div>
}
                                                        {sourceLanguage === "zh" &&
                                                        <div className="w-full flex flex-col gap-2 sm:flex-row place-items-center sm:gap-6 justify-start" >
                                                            <div className="w-full md:w-1/3">
                                                                <label className="block text-gray-500 font-bold text-left mb-1 md:mb-0 pr-4" htmlFor="inline-full-name">
                                                                    Source language script
                                                                </label>
                                                            </div>
                                                            <div className="w-full md:w-2/3 ">
                                                                <SelectRow value={variables['sourceLanguageScript'].value} onChange={variables['sourceLanguageScript'].onChange} options={variables['sourceLanguageScript'].options} isSearchable={variables['sourceLanguageScript']['searchable']} />
                                                            </div>
                                                        </div>
}
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                        </>
                        ))}
                         <div className="w-full flex flex-row justify-center">
                        <Button text={saving ? <FontAwesomeIcon icon={faSpinner} className="fa-pulse" /> : "Save changes"} onClick={saveChangesClick} color="green" />
                    </div>
                    </div>
                   
                </div>
                <NotificationMessage type={showSavedError ? "save-error" : showSavedSuccess ? "save-success" : "save-success"} showMessageBool={showSavedError ? showSavedError : showSavedSuccess} setShowMessageBool={showSavedError ? setShowSavedError : setShowSavedSuccess} />
            </div>
        </>
    )
}

export default IndividualDeckSettings;