import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useCourses from '../../../hooks/useCourses';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons';
import GeneralErrorPage from '../../../components/errors/GeneralErrorPage';
import { DebounceInput } from 'react-debounce-input';
import Skeleton from 'react-loading-skeleton';
import Button from '../../../components/general/Button';
import useDecks from '../../../hooks/useDecks';
import useTables from '../../../hooks/useTables';
import { useMyContentContext } from '../../../contexts/MyContentContext';

const EditMyCourse = () => {

    const {courseId} = useParams();
    const {fetchCourseFromId, fetchCourseLessons, saveChangesToCourse, saveChangesToLesson, getFullThumbnailPath, uploadLessonThumbnail, uploadCourseThumbnail, addNewLessonToCourse, getDefaultThumbnailImage} = useCourses();
    const {fetchAllDecksFromCurrentUserInTargetLanguage} = useDecks();
    const {tables} = useMyContentContext();

    const [course, setCourse] = useState(null);
    const [courseName, setCourseName] = useState(null);
    const [courseSourceLanguage, setCourseSourceLanguage] = useState(null);
    const [courseTargetLanguage, setCourseTargetLanguage] = useState(null);
    const [lessons, setLessons] = useState(null);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [allDecks, setAllDecks] = useState(null);
    const [uploadedLessonThumbnail, setUploadedLessonThumbnail] = useState(null);
    const [lessonTypes, setLessonTypes] = useState(["Deck", "Table"]);

    //Decks
    const [deckGames, setDeckGames] = useState(["Mix", "Quiz", "Flashcards", "Memory", "Input", "Browse", "Context"]);
    const [chineseDeckGames, setChineseDeckGames] = useState(["write-hanzi", "animate-hanzi", "stroke-order-hanzi"]);

    //Tables
    const [tableGames, setTableGames] = useState(["Flashcards", "fill-in"]);
    const [allTables, setAllTables] = useState(null);

    //first step is to fetch course in use effect
    //then fetch lessons
    //then create a button for adding new lessons
    // create cloud function for new lessons (because of ID)

    useEffect(()=>{
        if (tables !== null && tables !== undefined && courseTargetLanguage !== null && courseTargetLanguage !== undefined){
            console.log(tables, courseTargetLanguage)
            let allTables_ = tables.filter((t) => t["target_ISO_639-1"] === courseTargetLanguage);
            setAllTables(allTables_);
        }
    },[tables, courseTargetLanguage]);

    const getAndProcessLessons = async ({lessonIds, courseInFunction, decksInFunction}) => {
        let lessons_ = await fetchCourseLessons({lessonIds: courseInFunction.lesson_ids, course: courseInFunction});
        console.log("Lessons: ", lessons_);
        let nLessons = [];
        if (decksInFunction !== null){
            for (const l of lessons_){
                let deck = decksInFunction.filter((d) => parseInt(d.id) === parseInt(l.content_id));
                let nLesson = {...l};
                if (deck.length === 1){
                    nLesson = {...l, deck: deck[0]};
                }
                if (l.thumbnail_200x200 !== null){
                    let full = getFullThumbnailPath({dbPath:l.thumbnail_200x200});
                    nLesson["full_thumbnail_path_200x200"] = full;
                } else {
                    nLesson["full_thumbnail_path_200x200"] = null;
                }
                nLessons.push(nLesson);
            }
        }
        console.log("Lessons with decks: ", nLessons)
        setLessons(nLessons);
    }

    const getAndProcessCourse = async ({courseIdInFunction}) => {
        let course_ = await fetchCourseFromId({courseId: courseIdInFunction});
        if (course_.thumbnail_200x200 !== null){
            let full = getFullThumbnailPath({dbPath:course_.thumbnail_200x200});
            course_["full_thumbnail_path_200x200"] = full;
        } else {
            course_["full_thumbnail_path_200x200"] = null;
        }
        console.log("Course: ", course_);
        setCourse(course_);
        return course_;
    }

    useEffect(() => {
        const fetchData = async () => {
            let course_ = await getAndProcessCourse({courseIdInFunction: courseId});
            let targetLang = null;
            let sourceLang = null;
            let deckResults = null;
            if (course_ !== null && course_.hasOwnProperty("source_ISO_639-1") && course_["source_ISO_639-1"] !== null){
                setCourseSourceLanguage(course_["source_ISO_639-1"]);
                sourceLang = course_["source_ISO_639-1"];
            }
            if (course_ !== null && course_.hasOwnProperty("target_ISO_639-1") && course_["target_ISO_639-1"] !== null){
                setCourseTargetLanguage(course_["target_ISO_639-1"]);
                targetLang = course_["target_ISO_639-1"];
            }
            if (targetLang !== null){
                deckResults = await fetchAllDecksFromCurrentUserInTargetLanguage(targetLang);
                console.log("Decks: ", deckResults);
                setAllDecks(deckResults);
            }
            if (course_ !== null && course_.hasOwnProperty("name") && course_.name !== null){
                setCourseName(course_.name);
            }

            if (course_ === null || course_ === undefined || !course_.hasOwnProperty("lesson_ids") || course_.lesson_ids === null || course_.lesson_ids.length === 0){
                console.log("No lessons found");
                setLessons([]);    
            }
            else {
                await getAndProcessLessons({lessonIds: course_.lesson_ids, courseInFunction: course_, decksInFunction: deckResults});

            }

            setLoading(false);
        }  
        if (courseId !== null && courseId !== undefined){
            console.log("Fetching course with id: ", courseId);
            fetchData();
        }
    },[courseId]);

    const renameNameOnInput = async (e) => {
        setSaving(true);
        let newName = e.target.value;
        setCourseName(newName);
        await saveChangesToCourse({courseDocId: course.doc_id, name: newName});
        setSaving(false);
    }

    const renameLessonOnInput = async (e, index) => {
        setSaving(true);
        const newLessons = [...lessons];
        newLessons[index].lesson_name = e.target.value;
        setLessons(newLessons);
        await saveChangesToLesson({lessonDocId: lessons[index].doc_id, lessonName: e.target.value, contentId: lessons[index].content_id}); //content id always needs to be included
        setSaving(false);
    };

    const lessonDeckOnChange = async (e, index, deck) => {
        console.log("value: ", e.target.value)
        let value = e.target.value;
        if (value === ""){
            console.log("Setting it to null")
            value = null
        }
        const newLessons = [...lessons];
        if (deck !== undefined){
            newLessons[index].deck = {id: e.target.value};
            newLessons[index].content_id = e.target.value;
            setLessons(newLessons);
        } else {
            newLessons[index].deck = null;
            newLessons[index].content_id = null;
            setLessons(newLessons);
        }
        await saveChangesToLesson({lessonDocId: lessons[index].doc_id, contentId: value});
    }

    const lessonContentTypeOnChange = async (e, index, lesson) => {
        console.log("value: ", e.target.value)
        let value = e.target.value;
        let content_id = lessons[index].content_id;
        if (value === ""){
            console.log("Setting it to null")
            value = null
        }
        const newLessons = [...lessons];
        if (value !== null){
            console.log("Setting content type to ", value.toLowerCase())
            newLessons[index].content_type = value.toLowerCase();  
        }  else {
            newLessons[index].content_type = null;
        }
        console.log(newLessons[index].content_type, lessons[index].content_type)
        if (newLessons[index].content_type !== content_id){
            // if changing to other content type, also reset content_id
            console.log("Resetting content_id because of new content type")
            newLessons[index].content_id = null;
            content_id = null;
        }
        setLessons(newLessons);
        await saveChangesToLesson({lessonDocId: lessons[index].doc_id, contentType: value, contentId: content_id});
    };

    const lessonContentGameOnChange = async (e, index, lesson) => {
        console.log("value: ", e.target.value)
        let value = e.target.value;
        const newLessons = [...lessons];
        if (value === ""){
            console.log("Setting it to null")
            value = null
        }
        if (value !== null){
            newLessons[index].content_game = value.toLowerCase();
            value = value.toLowerCase();
        }
        else {
            newLessons[index].content_game = null;
        }
        setLessons(newLessons);
        await saveChangesToLesson({lessonDocId: lessons[index].doc_id, contentGame: value});
    }

    const uploadLessonThumbnailClick = async (lesson, file) => {
        console.log("Uploading thumbnail for lesson: ", lesson);
        setSaving(true);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = async () => {
        const base64Image = reader.result.split(',')[1];
        const contentType = file.type;
        await uploadLessonThumbnail({base64Image, file, contentType, lesson});
        setSaving(false);
        setUploadedLessonThumbnail(null);
        };
    }

    const uploadCourseThumbnailClick = async (course, file) => {
        console.log("Uploading thumbnail for course: ", course);
        setSaving(true);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = async () => {
        const base64Image = reader.result.split(',')[1];
        const contentType = file.type;
        await uploadCourseThumbnail({base64Image, file, contentType, course});
        setSaving(false);
        setUploadedLessonThumbnail(null);
        };
    };

    const handleLessonThumbnailFileOnChange = async (e, lesson) => {
        console.log("Handling thumbnail file change for lesson: ", lesson);
        setUploadedLessonThumbnail(e.target.files[0]);
        await uploadLessonThumbnailClick(lesson, e.target.files[0]);
    };

    const handleCourseThumbnailFileOnChange = async (e, course) => {
        console.log("Handling thumbnail file change for course: ", course);
        setUploadedLessonThumbnail(e.target.files[0]);
        await uploadCourseThumbnailClick(course, e.target.files[0]);
    };

    const newLessonClick = async () => {
        setSaving(true);
        setLoading(true);
        console.log("Sending: ", course)
        let data = await addNewLessonToCourse({course, lessonName:"Untitled", contentType:"deck", contentId:null});
        //refresh
        let course_ = await getAndProcessCourse({courseIdInFunction: courseId}); //this will include the new lesson ID
        await getAndProcessLessons({lessonIds: course_.lesson_ids, courseInFunction: course_, decksInFunction: allDecks});
        setLoading(false);
        setSaving(false);
    }

    return (
        <div>
            {loading &&
                <>
                    <div className="h-screen w-screen fixed flex flex-col place-items-center justify-center text-4xl">
                        <FontAwesomeIcon icon={faSpinner} spin />
                    </div>
                </>
            }
            {!loading &&
                <>
                    {course === null &&
                        <div className="h-screen">
                            <GeneralErrorPage errorMessage={"Please verify that the course ID is correct and try again."} errorHeadline={"Course not found"} goBackLink={`/my-library`} goBackText={"Go back to library"} />
                        </div>
                    }
                    {course !== null &&
                        <div className="flex flex-col gap-6">
                            <div className="flex flex-row gap-2">
                                <div className="font-bold muted-text text-lg">
                                    {courseName !== null ? 
                                    <DebounceInput
                                        className={" text-left hover:underline bg-transparent font-semibold border-none outline-none"}
                                        minLength={1}
                                        value={courseName}
                                        debounceTimeout={1000}
                                        onChange={event => renameNameOnInput(event)} 
                                    /> : 
                                    <Skeleton count={1} height={30} />}
                                </div>
                                
                                {saving &&
                                    <>
                                        <div className="text-lg">
                                            <FontAwesomeIcon icon={faSpinner} spin />
                                        </div>
                                    </>
                                }
                            </div>
                        <div>
                        <div className="rounded-2xl w-24 shadow-xl overflow-hidden flex flex-col justify-center">
                        {course?.full_thumbnail_path_200x200 !== null ?
                            <>
                                <img src={course.full_thumbnail_path_200x200} alt="thumbnail" />
                            </>
                            :
                            <>
                                No thumbnail
                            </>
                        }
                        </div>
                        <div  className="underline cursor-pointer">
                            <input type="file" onChange={(e)=>handleCourseThumbnailFileOnChange(e, course)} accept=".jpg,.jpeg,.png,.webp" />
                        </div>
                    </div>
                    </div>
                    }
                    {lessons !== null &&
                        <>
                            <div className="flex flex-col place-items-center gap-6 mt-12">
                                <div>
                                    <Button onClick={newLessonClick} text={"Add new lesson"} />
                                </div>
                                <div className="flex w-full flex-col gap-4">
                                    {lessons.map((lesson, lessonIndex) => (
                                        <div key={lessonIndex} className="bg-white w-full rounded-xl p-10 shadow-xl flex flex-col place-items-center gap-4">
                                            <h2>Lesson {lessonIndex+1}</h2> 
                                            <div className="flex flex-col md:flex-row gap-4">
                                                <div className="font-semibold">
                                                    Lesson name: 
                                                </div>
                                                <div>
                                                    <DebounceInput
                                                        className={" text-left hover:underline bg-transparent  border-none outline-none"}
                                                        minLength={1}
                                                        value={lesson.lesson_name}
                                                        debounceTimeout={1000}
                                                        onChange={event => renameLessonOnInput(event, lessonIndex)} 
                                                    />  
                                                </div>
                                            </div>
                                            
                                            <div className="flex flex-col md:flex-row   place-items-center gap-8">
                                                <div className="font-semibold">
                                                    Thumbnail (don't upload too big file):
                                                </div>
                                                <div className="rounded-2xl w-24 shadow-xl overflow-hidden flex flex-col justify-center">
                                                {lesson?.full_thumbnail_path_200x200 !== null ?
                                                    <>
                                                        <img src={lesson.full_thumbnail_path_200x200} alt="thumbnail" />
                                                    </>
                                                    :
                                                    <>
                                                        No thumbnail
                                                    </>
                                                }
                                                </div>
                                                <div  className="underline cursor-pointer">
                                                    <input type="file" onChange={(e)=>handleLessonThumbnailFileOnChange(e, lesson)} accept=".jpg,.jpeg,.png,.webp" />
                                                </div>
                                            </div>
                                            <div className="flex flex-col md:flex-row  gap-4">
                                                <div className="font-semibold">
                                                    Lesson type:
                                                </div>
                                                <div>
                                                    <select onChange={(e)=>lessonContentTypeOnChange(e, lessonIndex, lesson)} value={lesson?.content_type ? lesson.content_type.toLowerCase() : ""}>
                                                        <option key={"null_option"} value="">Select a lesson type</option>
                                                        {lessonTypes.map((lessonType, lessonTypeIndex) => (
                                                            <option key={lessonTypeIndex} value={lessonType.toLowerCase()}>
                                                                {lessonType}
                                                            </option>    
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            {lesson.content_type === "deck" &&
                                                <>
                                                    <div className="flex flex-col md:flex-row  gap-4">
                                                        <div className="font-semibold">
                                                            Chosen deck:
                                                        </div>
                                                        <select onChange={(e)=>lessonDeckOnChange(e, lessonIndex, lesson?.deck)} value={lesson?.deck?.id ? lesson.deck.id : ""}>
                                                            <option key={"null_option"} value="">Select a deck</option>
                                                            {allDecks.map((deck, deckIndex) => (
                                                                <option key={deckIndex} value={deck.id}>
                                                                    {deck.name}
                                                                </option>    
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="flex flex-col md:flex-row  gap-4">
                                                        <div className="font-semibold">
                                                            Deck game:
                                                        </div>
                                                        <select onChange={(e)=>lessonContentGameOnChange(e, lessonIndex, lesson?.deck)} value={lesson?.content_game ? lesson.content_game : ""}>
                                                            <option key={"null_option"} value="">Default option</option>
                                                            {deckGames.map((deckGame, deckGameIndex) => (
                                                                <option key={deckGameIndex} value={deckGame.toLowerCase()}>
                                                                    {deckGame}
                                                                </option>    
                                                            ))}
                                                            {lesson["target_ISO_639-1"] === "zh" &&
                                                                <>
                                                                    {chineseDeckGames.map((deckGame, deckGameIndex) => (
                                                                        <option key={deckGameIndex} value={deckGame.toLowerCase()}>
                                                                            {deckGame}
                                                                        </option>    
                                                                    ))}
                                                                </>
                                                            }
                                                        </select>
                                                    </div>
                                                </>
                                            }
                                            {lesson.content_type === "table" &&
                                                <>
                                                    <div className="flex flex-col md:flex-row  gap-4">
                                                        <div className="font-semibold">
                                                            Chosen table:
                                                        </div>
                                                        <select onChange={(e)=>lessonDeckOnChange(e, lessonIndex, lesson)} value={lesson?.content_id ? lesson.content_id : ""}>
                                                            <option key={"null_option"} value="">Select a table</option>
                                                            {allTables !== null && allTables.map((table, tableIndex) => (
                                                                <option key={tableIndex} value={table.id}>
                                                                    {table.name}
                                                                </option>    
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="flex flex-col md:flex-row  gap-4">
                                                        <div className="font-semibold">
                                                            Table game:
                                                        </div>
                                                        <select onChange={(e)=>lessonContentGameOnChange(e, lessonIndex, lesson)} value={lesson?.content_game ? lesson.content_game : ""}>
                                                            <option key={"null_option"} value="">Default option</option>
                                                            {tableGames.map((tableGame, tableGameIndex) => (
                                                                <option key={tableGameIndex} value={tableGame.toLowerCase()}>
                                                                    {tableGame}
                                                                </option>    
                                                            ))}
                                                        </select>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </>
                    }
                </>
            }
        </div>
    )
}

export default EditMyCourse;