import {projectFirestore, timeStamp} from '../firebase/config.js';
import { useAuth } from "../contexts/AuthContext";
import {useUserInfoContext} from '../contexts/UserInfoContext';
import {
    getFirestore,
    collection,
    query,
    where,
    setDoc,
    getDoc,
    getDocs,
    doc,
    deleteDoc,
    addDoc,
    updateDoc,
    onSnapshot,
    serverTimestamp,
    orderBy,
    limit,
    startAfter,
    endBefore,
    limitToLast, 
    Timestamp
} from "firebase/firestore";
import {  httpsCallable } from "firebase/functions";

export default function useRatings() {
    
    let {currentUser} = useAuth();
    const {userInfo} = useUserInfoContext();

    const fetchAllRatingsFromCurrentUser = async () => {
        const ref = collection(projectFirestore, "user-actions", "ratings", "items");
        const q = query(ref, where("uid", "==", currentUser.uid));
    
        try {
            const results = await getDocs(q);
            if (results.empty) { return [] }
    
            let ratings = [];
            results.forEach((comment) => {
                let o = { ...comment.data() };
                o['doc_id'] = comment.id;
                ratings.push(o);
            });
            return ratings;
        } catch (err) {
            console.log(err);
            return [];
        }
    }
    
    const fetchAllRatingsFromCurrentUserInRealtime = async (setRatings) => {
        const ref = collection(projectFirestore, "user-actions", "ratings", "items");
        const q = query(ref, where("uid", "==", currentUser.uid));
    
        return onSnapshot(q, (results) => {
            if (results.empty) { setRatings([]) }
    
            let ratings = [];
            results.docs.forEach((comment) => {
                let o = { ...comment.data() };
                o['doc_id'] = comment.id;
                ratings.push(o);
            });
            setRatings(ratings);
        });
    }
    
    const fetchSpecificRatingFromCurrentUser = async (source_doc_id) => {
        const ref = collection(projectFirestore, "user-actions", "ratings", "items");
        const q = query(ref, where("uid", "==", currentUser.uid), where("source_doc_id", "==", source_doc_id));
    
        try {
            const results = await getDocs(q);
            if (results.empty) { return null }
    
            let o = results.docs[0].data();
            o['rating_doc_id'] = results.docs[0].id;
            return o;
        } catch (err) {
            console.log(err);
            return null;
        }
    }
    
    const postRating = async (source_doc_id, source_uid, rating_source, rating) => {
        console.log("Adding rating: ", source_doc_id, source_uid, rating_source, rating);
    
        const exists = await fetchSpecificRatingFromCurrentUser(source_doc_id);
        if (exists !== null) {
            // delete existing rating
            await deleteDoc(doc(projectFirestore, "user-actions", "ratings", "items", exists.rating_doc_id)).catch(err => console.log(err));
        }
    
        const ref = collection(projectFirestore, "user-actions", "ratings", "items");
        const obj = {
            'rating_created_timestamp': timeStamp,
            'uid': currentUser.uid,
            'rating': rating,
            'source_doc_id': source_doc_id,
            'source_uid': source_uid,
            'rating_source': rating_source,
        };
    
        return addDoc(ref, obj).catch(err => console.log(err));
    }
    
    const removeRating = async (source_doc_id) => {
        const ref = collection(projectFirestore, "user-actions", "ratings", "items");
        const q = query(ref, where("uid", "==", currentUser.uid), where("source_doc_id", "==", source_doc_id));
    
        try {
            const results = await getDocs(q);
            if (results.empty) { return }
    
            await deleteDoc(results.docs[0].ref).catch(err => console.log(err));
        } catch (err) {
            console.log(err);
        }
    }

    return {
        fetchAllRatingsFromCurrentUser, 
        fetchAllRatingsFromCurrentUserInRealtime,
        postRating, 
        removeRating
    }

}