import { faMoon, faSun } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useThemeContext } from "../../contexts/ThemeContext";

const ThemeSwitch = () => {
  const [mounted, setMounted] = useState(false);
  const { theme, toggleTheme } = useThemeContext();

  // Ensure component is mounted before rendering
  useEffect(() => {
    setMounted(true);
  }, []);

  // Only render if mounted and theme is set
  if (!mounted || !theme) {
    return null;
  }

  const themeSwitchClick = () => {
    toggleTheme();
  };

  return (
    <div>
      <motion.div
        whileHover={{ scale: 1.03 }}
        onClick={themeSwitchClick}
        className={(theme === "dark" ? "text-yellow-500 " : "text-black ") + " cursor-pointer"}
      >
        <FontAwesomeIcon icon={theme === "dark" ? faSun : faMoon} />
      </motion.div>
    </div>
  );
};

export default ThemeSwitch;
