import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowDownWideShort} from '@fortawesome/pro-solid-svg-icons';
import {motion} from 'framer-motion';

const SortByButton = ({textSize, onChange, value, options, hideLabel=false, rightOrLeft="right"}) => {
    const [showMenu, setShowMenu] = useState(false);
    if (textSize === undefined){textSize = "sm"}
    const clickOption = (option) => {
        onChange(option);
        setShowMenu(false);
    }

    const backgroundClick = () => {
        setShowMenu(false);
    }
    /*
<div className={"text-gray-600 text-"+textSize}>
                        Sort by: <span className="font-bold">{value.label}</span>
                    </div>
    */
    return (
        <>
    <div className="">
        {/* Trigger Button */}
        <div
            onClick={() => setShowMenu(!showMenu)}
            className="cursor-pointer flex flex-row place-items-center gap-2"
        >
            <div>
                <FontAwesomeIcon icon={faArrowDownWideShort} />
            </div>
            {value !== undefined && (
                <div
                    className={`${
                        hideLabel ? 'hidden' : 'block'
                    } text-gray-600 dark:text-gray-300 text-${textSize}`}
                >
                    <span className="font-bold whitespace-nowrap">
                        {value.label}
                    </span>
                </div>
            )}
        </div>

        {/* Dropdown Menu */}
        <div className="relative whitespace-nowrap">
            {showMenu && (
                <>
                    <div
                        className={`${
                            rightOrLeft === 'right' ? 'left-0' : 'right-0'
                        } absolute text-sm ${textSize} z-20 bg-white dark:bg-gray-800 shadow-xl rounded-lg text-gray-600 dark:text-gray-300`}
                    >
                        {options !== null &&
                            options.map((option, i) => (
                                option !== undefined && (
                                    <motion.div
                                        whileHover={{ scale: 1.02 }}
                                        onClick={() => clickOption(option)}
                                        key={`option_${i}`}
                                        className={`${
                                            value.value === option.value
                                                ? 'font-bold bg-gray-100 dark:bg-gray-700'
                                                : ''
                                        } p-2 px-4 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors`}
                                    >
                                        {option.label}
                                    </motion.div>
                                )
                            ))}
                    </div>

                    {/* Overlay */}
                    <div
                        onClick={backgroundClick}
                        className="w-screen h-screen left-0 top-0 fixed z-10 bg-transparent"
                    ></div>
                </>
            )}
        </div>
    </div>
</>

    )
}

export default SortByButton;