import {stripe, loadStripe} from '@stripe/stripe-js';
import {projectFirestore, projectFunctions, timeStamp} from '../firebase/config.js';
import {  httpsCallable } from "firebase/functions";
import { doc, collection, addDoc, onSnapshot } from "firebase/firestore";
import { useAuth } from "../contexts/AuthContext";
import { useEffect } from 'react';

export default function useStripe() {
    
    let {currentUser, checkIfPremiumUser} = useAuth();
    let stripePromise = null;

    const initializeStripe = async () => {
        if (!stripePromise){
            stripePromise = await loadStripe("pk_live_51Ltn48KC2e7qSKmE9dWZwSHexvLqlKJyL7VaEo9uUvX9kWxX0U540Gm1iAXJy7lynTmAtZtZru5skTlXVfM1ibpe00kHvMV7xQ");
        }
        return stripePromise;
    }

    const createCheckoutSession = async () => {
        if (currentUser === null){return null}
        const checkoutSessionsRef = collection(
            projectFirestore,
            "stripe-users",
            currentUser.uid,
            "checkout_sessions"
          );
      
          // Add a new checkout session document
          const checkoutSessionRef = await addDoc(checkoutSessionsRef, {
            price: "price_1M0QiHKC2e7qSKmEulpKg4Je",
            success_url: "https://yalango.com/premium/success",
            cancel_url: "https://yalango.com/premium/"
          });

        let unsub = checkoutSessionRef.onSnapshot(async (snap)=>{
            if (snap.exists){
                const { sessionId } = snap.data();
                console.log(snap.data());
                if (sessionId){
                    const stripe = await initializeStripe();
                    await stripe.redirectToCheckout({ sessionId });
                } else {

                }
            }
        });

        return unsub;
    };

    const createStripePortalLink = async () => {
        let func = httpsCallable(projectFunctions,"ext-firestore-stripe-payments-createPortalLink"); //legacy: let func = firebase.app().functions("europe-west3").httpsCallable("ext-firestore-stripe-payments-createPortalLink");
        let {data} = await func({returnUrl: "https://www.yalango.com/premium"});
        window.location.assign(data.url);
    }

    const getUserSubscriptions = async () => {
        if (currentUser === null) return null;
      
        const subscriptionsRef = collection(
          projectFirestore,
          "stripe-users",
          currentUser.uid,
          "subscriptions"
        );
      
        try {
          const snapshot = await getDocs(subscriptionsRef);
          const list = snapshot.docs.map((doc) => doc.data());
          return list;
        } catch (error) {
          console.error("Error fetching user subscriptions:", error);
          throw error;
        }
    };


    return {
        initializeStripe, 
        createCheckoutSession, 
        checkIfPremiumUser, 
        getUserSubscriptions, 
        createStripePortalLink
    }
};