import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, serverTimestamp } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { initializeAppCheck, ReCaptchaV3Provider  } from "firebase/app-check";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyATBFKrkgb3NSFpS9md3zlaT4yHZ0GLUt0",
    authDomain: "soothing-languages.firebaseapp.com",
    projectId: "soothing-languages",
    storageBucket: "soothing-languages.appspot.com",
    messagingSenderId: "799528087069",
    appId: "1:799528087069:web:dc29027d46b8f8b241e0f4",
    measurementId: "G-XSDK7GZZ2N" 
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);


  //const projectAppCheck = firebase.appCheck();
  const projectAuth = getAuth(app);
  const projectFirestore = getFirestore(app);
  const projectStorage = getStorage(app);
  const projectFunctions = getFunctions(app);
  const projectAnalytics = getAnalytics(app);

 if (process.env.NODE_ENV === 'development') {
    connectFunctionsEmulator(projectFunctions, "localhost", 5001);
    self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
  }

  const projectAppCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider("6LduhJwdAAAAAGrP_9X5bVuijCLMpZx1rq7uU4T5"),
    isTokenAutoRefreshEnabled: true
});

  const timeStamp = serverTimestamp();



  //Request debug token for app check: window.FIREBASE_APPCHECK_DEBUG_TOKEN=true
  //projectAppCheck.activate('6LduhJwdAAAAAGrP_9X5bVuijCLMpZx1rq7uU4T5', true);

  export { projectFunctions, projectAppCheck, projectStorage, projectFirestore, projectAuth, projectAnalytics, timeStamp };