import React, {useEffect, useState} from 'react';
import Button from '../../../components/general/Button';
import { CSVLink } from "react-csv";
import {Link} from 'react-router-dom';
import {useParams} from "react-router-dom";
import {faSpinner, faFilePdf} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTableColumns} from '@fortawesome/pro-solid-svg-icons';
import {faKeyboard} from '@fortawesome/pro-solid-svg-icons';
import useDecks from '../../../hooks/useDecks';
import {motion} from 'framer-motion';
import Excel from '../../../assets/svg/logos/excel.svg';

const Card = (props) => {
    const {title, id, description, urlSlug, image, card} = props;
    return (
        <>
            <Link className="no-underline" to={"/my-decks/"+id+"/export/"+urlSlug}>
                <motion.div whileHover={{scale:1.01, y:-2}} className="relative p-6 w-64 h-64  rounded-xl shadow-2xl bg-white dark:dark-mode-background-color flex flex-col m-auto justify-center place-items-center">
                    <div className="absolute bg-persian-green text-white rounded-xl shadow-xl font-bold  text-base left-0 top-0  transform -rotate-8 p-3">
                        <div className="">{title}</div>
                    </div>
                    <div className=" flex flex-col justify-between h-full gap-4 text-center">
                        {card.illustration === "image" &&
                            <div className="w-36 overflow-hidden m-auto">
                                <img src={image} alt="logo" />
                            </div>
                        }
                        {card.illustration === "icon" &&
                            <div className={card.icon_color + " text-7xl  overflow-hidden m-auto"}>
                                <FontAwesomeIcon icon={card.icon} />
                            </div>
                        }
                        <div className="text-sm italic">{description}</div>
                    </div>
                </motion.div>
            </Link>
        </>
    )
}

const ExportMyDeck = () => {
    const [headers, setHeaders] = useState([
        { label: "Source", key: "source" },
        { label: "Target", key: "target" },
        { label: "Source language", key: "source_ISO_639-1"},
        { label: "Target language", key: "target_ISO_639-1"},
        { label: "Created", key: "created" }, 
        { label: "Last updated", key: "last_updated" }
    ]);
    const [data, setData] = useState([]);
    const [deck, setDeck] = useState(null);
    let { id } = useParams();
    id = parseInt(id);
    const [fetchingData, setFetchingData] = useState(false);
    const [dataFetched, setDataFetched] = useState(false);
    const {fetchAllItemsInSingleDeck} = useDecks();

    const fetchDeck = async () => {
        setFetchingData(true);
        let r = await fetchAllItemsInSingleDeck(id);
        let l = [];
        if ('items' in r){
            
            for (const item of r['items']){
                let o = {};
                o['created'] = item['created_timestamp'].toDate();
                o['last_updated'] = item['last_updated_timestamp'].toDate();
                o['source'] = item['source'];
                o['target'] = item['target'];
                o['source_ISO_639-1'] = item['source_ISO_639-1'];
                o['target_ISO_639-1'] = item['target_ISO_639-1'];
                l.push(o);
            }
        }
        if ('deck' in r){
            setDeck(r['deck']);
        }
        setData(l);
        setDataFetched(true);
        setFetchingData(false);
    }

    const downloadDataClick = async () => {
        await fetchDeck();
    }
    let cards = [
        {
            'title': "CSV file",
            'description': 'Export your deck as a CSV file.',
            'url-slug': 'csv',
            'illustration': 'image',
            'image': Excel
        },
        {
            'title': "PDF list",
            'description': 'Export your deck as a glossary list. Perfect for reviewing on other devices or printing.',
            'url-slug': 'pdf',
            'illustration': 'icon',
            'icon': faFilePdf, 
            'icon_color': "text-charcoal"
        },
        {
            'title': "Quiz",
            'description': 'Export your deck as a quiz in PDF format. Perfect for testing your knowledge.',
            'url-slug': 'quiz',
            'illustration': 'icon',
            'icon': faFilePdf, 
            'icon_color': "text-charcoal"
        },
       
    ];

    /*
 {
            'title': "PDF",
            'description': 'Export deck as a PDF file.',
            'url-slug': 'pdf',
            'illustration': 'icon',
            'icon_color': "text-charcoal",
            'icon': faFilePdf
        }
    */

  return (
    <>
    <div className="  min-h-screen h-full">
        <div className="text-xs">
            <Link to={"/my-decks/"+id}>Back to deck</Link>
        </div>
        <h1>Export your deck</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-12">
            {cards !== null && cards.map((card, cardIndex)=>(
                <>
                    <Card key={"card_"+cardIndex} card={card} image={card.image} title={card.title} id={id} description={card.description} urlSlug={card['url-slug']} />
                </>
            ))}
        </div>
       
    </div>
</>
)
}

export default ExportMyDeck