import {projectFirestore, projectFunctions, timeStamp, firebase} from '../firebase/config.js';
import { useAuth } from "../contexts/AuthContext";
import {
    getFirestore,
    collection,
    query,
    where,
    setDoc,
    getDoc,
    getDocs,
    doc,
    deleteDoc,
    addDoc,
    updateDoc,
    onSnapshot,
    serverTimestamp,
    orderBy,
    limit,
    startAfter,
    endBefore,
    limitToLast, 
    Timestamp
} from "firebase/firestore";
import {  httpsCallable } from "firebase/functions";

export default function useStars() {
    const {currentUser} = useAuth();

    const getCurrentUserStars = async () => {
        if (currentUser === null) { return; }
    
        // Creating a reference to the user's stars document
        const userStarsRef = doc(projectFirestore, "users", currentUser.uid, "private-data", "stars");
    
        try {
            const results = await getDoc(userStarsRef);  // Fetching the document
            if (results.exists()) {
                return results.data();  // Return the stars data if it exists
            } else {
                return false;  // Return false if the document doesn't exist
            }
        } catch (err) {
            console.log(err);  // Handle any errors
            return false;  // Return false on error
        }
    }

    return {
        getCurrentUserStars
    }
}