import React, { useEffect, useState } from 'react';
import { useLanguagesContext } from '../../../../contexts/LanguagesContext';
import { useAuth } from '../../../../contexts/AuthContext';

const RenderChineseWords = (props) => {
    const {userLanguages} = useLanguagesContext();
    const {text, language, data, targetOrSource, fontSize, pinyinSize, highlightCharacter, highlightColor="text-persian-green", blurHighlightCharacter=false, blurHighlightPinyin=false} = props;
    const [showPinyin, setShowPinyin] = useState(false); 
    const [hasValidPinyin, setHasValidPinyin] = useState(false);
    const [pinyinArray, setPinyinArray] = useState([]);
    const [showHiddenCharacter, setShowHiddenCharacter] = useState(false);
    const {currentUser} = useAuth();

    useEffect(()=>{
        if (userLanguages !== null && language !== null && targetOrSource === "target" && currentUser !== null && data !== null){
            for (const lang of userLanguages){
                if (lang['target_language'] === language){
                    console.log("lang:", lang, data, lang.hasOwnProperty('display_pinyin'),data.hasOwnProperty("target_pinyin_segment_heteronym"))
                    if (lang.hasOwnProperty('display_pinyin') && data.hasOwnProperty("target_pinyin_segment_heteronym")){
                        console.log("yes")
                        setShowPinyin(lang['display_pinyin']);
                        setHasValidPinyin(true);
                    } 
                    else if (data.hasOwnProperty("target_pinyin_segment_heteronym")){
                        setHasValidPinyin(true);
                    }
                }
            }
        }
        else if (currentUser === null && language !== null && targetOrSource === "target" && data !== null){
            // show pinyin for logged out users
            if (data.hasOwnProperty("target_pinyin_segment_heteronym")){
                setShowPinyin(true);
                setHasValidPinyin(true);
            }
        }
    }
    ,[userLanguages, language, data, targetOrSource, currentUser]);

    useEffect(()=>{
        if (text === null || text === undefined){return}
        if (data === null || data === undefined){return}
        if (hasValidPinyin && data.hasOwnProperty("target_pinyin_segment_heteronym")){
            // valid pinyin
            let newArray = [];
            let textArray = text.split("");
            let dbArray = data["target_pinyin_segment_heteronym"];
            console.log("arrays: ", textArray, dbArray)
            for (let i = 0; i < textArray.length; i++){
                let word = textArray[i];
                let pinyin = "";
                console.log(dbArray[i].length, i, dbArray[i])
                if (dbArray.hasOwnProperty(i)){
                    pinyin = dbArray[i][0];
                } 
                newArray.push({"character": word, "pinyin": pinyin});
            }
            console.log("combined: ", newArray)
            setPinyinArray(newArray);
        } else {
            // add empty pinyin for each character
            let newArray = [];
            let textArray = text.split("");
            for (let i = 0; i < textArray.length; i++){
                let word = textArray[i];
                let pinyin = "";
                newArray.push({"character": word, "pinyin": pinyin});
            }
            setPinyinArray(newArray);
        }

    },[text, hasValidPinyin, data]);

    useEffect(()=>{
        setShowHiddenCharacter(false);
    },[text]);

    console.log("text, hasValidPinyin, data: ", text, hasValidPinyin, data)

    return (
        <>
            <div className="flex flex-col gap-8">
                <div className="flex flex-row gap-2 justify-center">
                    {pinyinArray.map((word, index)=>(
                        <div key={"word_"+index} className="flex flex-col place-items-center gap-1">
                            {showPinyin &&
                                <>
                                    <div className={`${pinyinSize !== undefined ? pinyinSize : "text-base"} ${highlightCharacter === word["character"] ? highlightColor : ''} `}>{word["pinyin"]}</div>
                                </>
                            }
                            <div onClick={()=>setShowHiddenCharacter(true)} className={`${fontSize !== undefined ? fontSize : "text-3xl"} ${highlightCharacter === word["character"] ? highlightColor : ''}  ${(!showHiddenCharacter && highlightCharacter === word["character"] && blurHighlightCharacter) ? " blur-md cursor-pointer" : ""}`}>{word["character"]}</div>
                        </div>
                    ))}
                </div>
                {blurHighlightCharacter && !showHiddenCharacter &&
                <div className="text-xs  muted-text text-center">
                    Click on the <span onClick={()=>setShowHiddenCharacter(true)}  className={`${highlightColor} cursor-pointer`}>hidden character</span> to reveal it, but try your best to remember it without looking!
                </div>
                }
            </div>
        </>
    )
}

export default RenderChineseWords;