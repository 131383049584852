import React, {useState, useEffect} from 'react';
import Icon from '../../general/Icon';
import Button from '../../general/Button'; 
import Modal from '../../general/Modal';
import CountryFlag from '../CountryFlag';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-duotone-svg-icons';
import {faQuestion} from '@fortawesome/pro-solid-svg-icons';
import useLanguages from '../../../hooks/useLanguages';
import SimpleErrorBar from '../../general/SimpleErrorBar';
import Select from 'react-select';
import {useLanguagesContext} from '../../../contexts/LanguagesContext';
import SourceLanguageExplanation from '../../guides/explanationModals/SourceLanguageExplanation';

const ModalContent = ({closeFunction}) => {
    let {addNewLanguages} = useLanguages();
    let {allLanguages, 
        globalSelectedSourceLanguage, 
        setGlobalSelectedSourceLanguage, 
        sourceLanguageOptions
    } = useLanguagesContext();

    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [nbOfSelectedLanguages, setNbOfSelectedLanguages] = useState(0);
    const [selectedSourceLanguage, setSelectedSourceLanguage] = useState(globalSelectedSourceLanguage);
    const [savingLanguagesLoading, setSavingLanguagesLoading] = useState(false);
    const [saveLanguagesError, setSaveLanguagesError] = useState(false);
    const [saveLanguagesErrorMsg, setSaveLanguagesErrorMsg] = useState("");

    // Modal
    const [showSourceLanguageGuideModal, setShowSourceLanguageGuideModal] = useState(false);
    useEffect(()=>{
        setNbOfSelectedLanguages(selectedLanguages.length);
    },[selectedLanguages]);

    const saveLanguagesClick = async () => {
        if (selectedSourceLanguage === null){
            setSaveLanguagesError(true);
            setSaveLanguagesErrorMsg("Please select a source language.");
            return;
        }
        if (selectedLanguages.length === 0){
            setSaveLanguagesError(true);
            setSaveLanguagesErrorMsg("Please select at least one language to study.");
            return;
        }
        setSavingLanguagesLoading(true);
        let db_data = [];
        selectedLanguages.forEach(lang=>{
            db_data.push({
                'target_language': lang,
                'source_language': selectedSourceLanguage,
                'name': allLanguages[0][lang]['name']['en']
            })
        });
        let success = await addNewLanguages(db_data);
        if (success) {
            closeFunction();
            setGlobalSelectedSourceLanguage(selectedSourceLanguage);
        } else {
            setSaveLanguagesError(true);
            setSaveLanguagesErrorMsg("Something went wrong. Please try again.");
        }
        setSavingLanguagesLoading(false);
        
    }

    const resetSelectedLanguages = () => {
        setSelectedLanguages([]);
        setSaveLanguagesError(false);
        setSaveLanguagesErrorMsg("");
    }

    const sourceLanguageSelectChange = (option) => {
        setSelectedSourceLanguage(option.value);
        setSaveLanguagesError(false);
        setSaveLanguagesErrorMsg("");
    }
    
    const selectLanguage = (e) => {
        const new_language = e.currentTarget.getAttribute('data-language-code');
        if (!(selectedLanguages.includes(new_language))){
            setSelectedLanguages([...selectedLanguages, new_language]);
        } else {
            const new_languages = [];
            selectedLanguages.forEach(lang=>{
                if (lang !== new_language){
                    new_languages.push(lang);
                }
            });
            setSelectedLanguages(new_languages);
        }  
        setSaveLanguagesError(false);
        setSaveLanguagesErrorMsg("");
    }

    const showSourceLanguageGuideClick = () => {
        setShowSourceLanguageGuideModal(true);
    }

    const closeSourceLanguageGuideModal = () => {
        setShowSourceLanguageGuideModal(false);
    }

    return (
        <>
  <div className="flex flex-col gap-6 text-center place-content-center ">
    {/* Source Language Selector */}
    <div className="mt-4">
      <div className="flex flex-col sm:flex-row gap-4 justify-center items-center">
        <div className="font-bold text-gray-700 dark:text-gray-200">Source language:</div>
        <div>
          <Select
            value={sourceLanguageOptions[1][selectedSourceLanguage]}
            onChange={sourceLanguageSelectChange}
            options={sourceLanguageOptions[0]}
            autoFocus={false}
            className="w-48 text-gray-500 dark:text-gray-300 text-base"
          />
        </div>
        <div>
          <div
            onClick={showSourceLanguageGuideClick}
            className="bg-white dark:bg-gray-700 cursor-pointer shadow-lg border border-dashed border-gray-200 dark:border-gray-600 px-4 py-2 rounded-full"
          >
            <Icon hover="none" color="black" icon={<FontAwesomeIcon icon={faQuestion} />} />
          </div>
        </div>
      </div>
      {showSourceLanguageGuideModal && (
        <SourceLanguageExplanation closeFunction={closeSourceLanguageGuideModal} />
      )}
    </div>

    {/* Languages Selection Grid */}
    <div className="text-xl m-auto p-2 mt-4 rounded-xl shadow-md">
      <div className="h-72 m-auto overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-thumb-charcoal scrollbar-track-gray-100 dark:scrollbar-track-gray-600 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
          {allLanguages !== null &&
            allLanguages[1].map((lang) => (
              <div
                key={`lang_${lang}`}
                onClick={selectLanguage}
                data-language-code={lang}
                className={`${
                  selectedLanguages.includes(lang)
                    ? "bg-persian-green text-white"
                    : "bg-gray-100 dark:darker-dark-mode-background-color text-gray-700 dark:text-gray-300"
                } rounded-xl p-3 flex flex-col place-items-center cursor-pointer shadow-md hover:shadow-lg transition-shadow duration-300`}
              >
                <div>
                  <CountryFlag
                    size="4em"
                    countryCode={allLanguages[0][lang]["country_flag"]}
                    flagName={allLanguages[0][lang]["name"]["en"]}
                  />
                </div>
                <div className="text-center text-base font-bold">
                  {allLanguages[0][lang]["name"]["en"]}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>

    {/* Selected Languages Info */}
    <div className="my-4">
      <div className="flex flex-row justify-center gap-2">
        <div>
          <span className="px-2 py-1 font-bold rounded-full bg-persian-green text-white">
            {nbOfSelectedLanguages}{" "}
            {nbOfSelectedLanguages === 1 ? "language" : "languages"} selected
          </span>
        </div>
        {nbOfSelectedLanguages > 0 && (
          <div
            onClick={resetSelectedLanguages}
            className="underline cursor-pointer text-persian-green hover:text-persian-green-dark"
          >
            Reset
          </div>
        )}
      </div>
    </div>

    {/* Error Bar */}
    {saveLanguagesError && (
      <div className="sm:w-1/2 m-auto">
        <SimpleErrorBar message={saveLanguagesErrorMsg} />
      </div>
    )}

    {/* Save Button */}
    <div className="text-2xl flex flex-row justify-center">
      <Button
        disabled={saveLanguagesError}
        disabledDesign={saveLanguagesError}
        onClick={saveLanguagesClick}
        text={
          savingLanguagesLoading ? (
            <div className="font-bold">
              <FontAwesomeIcon icon={faSpinner} className="fa-pulse" />
            </div>
          ) : (
            <div className="font-bold">Add languages</div>
          )
        }
        color="green"
      />
    </div>
  </div>
</>

    )
}

const AddLanguagesModal = ({closeFunction}) => {
    return (
        <>
            <Modal  size="3xl" content={<ModalContent closeFunction={closeFunction} />} closeFunction={closeFunction} />
        </>
    )
}

export default AddLanguagesModal;
