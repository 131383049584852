import React, {useEffect, useState} from 'react';
import Modal from '../../general/Modal';
import Button from '../../general/Button';
import useTables from '../../../hooks/useTables';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {AddDeckModalContent} from '../../vocabulary/modals/AddDeckModal';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {Link} from 'react-router-dom';
import {motion} from 'framer-motion';
import {useMyContentContext} from '../../../contexts/MyContentContext';

export const DeckSelector = ({saveChangesFunction, setSelectedDeck, targetLanguage, sourceLanguage, closeFunction}) => {
  const {getTablesFromCurrentUser} = useTables();
  const [loading, setLoading] = useState(false);
  const [tempSelectedDeck, setTempSelectedDeck] = useState(null);

  let {
    decks
} = useMyContentContext();

  const isSelectedDeck = (id) => {
    if (tempSelectedDeck !== null && tempSelectedDeck.id === id){
      return true; 
    } else {
      return false;
    }
  }

  const selectFinalDeckClick = async () => {
    if (tempSelectedDeck !== null){
      setSelectedDeck !== undefined && setSelectedDeck(tempSelectedDeck);
      closeFunction();
      await saveChangesFunction(tempSelectedDeck);
    }
  }

  const [page, setPage] = useState("deck-selector");
  const goToDeckSelector = () => {
    setPage("deck-selector");
  }

  console.log(decks);
  
  return (
<>
  <div className="">

    {/* Loading State */}
    {loading && (
      <div className="h-full p-4 flex flex-col justify-center overflow-hidden text-center text-3xl">
        <FontAwesomeIcon icon={faSpinner} className="fa-pulse text-gray-600 dark:text-gray-300" />
      </div>
    )}

    {/* Deck Selector */}
    {!loading && decks !== null && (
      <>
        {page === "deck-selector" && (
          <>
            <h1 className="text-lg font-bold text-gray-700 dark:text-gray-200">Select a deck</h1>
            <div className="p-4 flex flex-col gap-4">
              <div
                onClick={() => setPage("create-deck")}
                className="underline cursor-pointer text-sm text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-300"
              >
                Create new deck
              </div>
              <div className="">
                {decks.map((deck, index) => (
                  <>
                    {deck["target_ISO_639-1"] === targetLanguage &&
                      deck["source_ISO_639-1"] === sourceLanguage && (
                        <motion.div
                          onClick={() =>
                            setTempSelectedDeck(isSelectedDeck(deck.id) ? null : deck)
                          }
                          whileHover={{ scale: 1.01 }}
                          className={`${
                            isSelectedDeck(deck.id)
                              ? "bg-charcoal dark:bg-persian-green text-white"
                              : "bg-white dark:bg-gray-800 text-gray-600 dark:text-gray-300"
                          } mb-4 cursor-pointer p-3 rounded-lg shadow-xl transition-all duration-300`}
                          key={`table_${index}`}
                        >
                          <span className="font-bold">{deck.name}</span> ({deck.number_of_items} cards)
                        </motion.div>
                      )}
                  </>
                ))}
              </div>
              <div className="flex flex-row justify-center mt-6">
                <Button
                  text="Add selected words to deck"
                  onClick={() => selectFinalDeckClick()}
                  disabledDesign={tempSelectedDeck === null}
                  disabled={tempSelectedDeck === null}
                  color={tempSelectedDeck !== null ? "green" : "bg-gray-500"}
                />
              </div>
            </div>
          </>
        )}

        {/* Create Deck Page */}
        {page === "create-deck" && (
          <>
            <AddDeckModalContent
              setTempSelectedDeck={setTempSelectedDeck}
              closeFunction={goToDeckSelector}
              setAddNewDeckLoading={setLoading}
              hideNavigation={true}
            />
          </>
        )}
      </>
    )}
  </div>
</>

  )
}

const DeckSelectorModal = ({saveChangesFunction, setSelectedDeck, targetLanguage, sourceLanguage, closeFunction}) => {

  return (
    <>
      <Modal size="2xl" closeFunction={closeFunction} content={<DeckSelector sourceLanguage={sourceLanguage} saveChangesFunction={saveChangesFunction} closeFunction={closeFunction} targetLanguage={targetLanguage} setSelectedDeck={setSelectedDeck} />} />
    </>
  )
}

export default DeckSelectorModal;