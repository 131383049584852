import React from 'react';
import {projectFirestore, timeStamp, projectFunctions} from '../firebase/config.js';
import { useAuth } from "../contexts/AuthContext";
import {
    getFirestore,
    collection,
    query,
    where,
    setDoc,
    getDoc,
    getDocs,
    doc,
    deleteDoc,
    addDoc,
    updateDoc,
    onSnapshot,
    serverTimestamp,
    orderBy,
    limit,
    startAfter,
    endBefore,
    limitToLast, 
    Timestamp
} from "firebase/firestore";
import useDecks from './useDecks';
import useLanguages from './useLanguages';
import {  httpsCallable } from "firebase/functions";

export default function useVocabulary() {

    let { currentUser } = useAuth();

    const fetchTargetWordsDoc = async (targetLang) => {
        console.log("roiegjerg", targetLang)
        const docRef = doc(projectFirestore, "users", currentUser.uid, "private-data", "vocabulary", "languages", targetLang, "metadata", "target_words");
        const docSnap = await getDoc(docRef);
    
        let targetWords = [];
        if (docSnap.exists()) {
            if (docSnap.data().hasOwnProperty("target_words")) {
                targetWords.push(...docSnap.data()['target_words']);
            }
        }
    
        let lowerCase = targetWords.map(word => word.toLowerCase());
        return lowerCase;
    }

    const fetchTargetWordFromVocabulary = async (targetWord, targetLang) => {
        const colRef = collection(projectFirestore, "users", currentUser.uid, "private-data", "vocabulary", "languages", targetLang, "items");
        const q = query(colRef, where("target", "==", targetWord));
        const querySnapshot = await getDocs(q);
    
        let doc = false;
        if (!querySnapshot.empty) {
            doc = querySnapshot.docs[0].data();
        }
        return doc;
    }

    const fetchItemFromVocabularyInLanguage = async ({id, targetLang}) => {
        id = parseInt(id);
        const colRef = collection(projectFirestore, "users", currentUser.uid, "private-data", "vocabulary", "languages", targetLang, "items");
        const q = query(colRef, where("id", "==", id));
        const querySnapshot = await getDocs(q);
    
        let doc = false;
        if (!querySnapshot.empty) {
            doc = querySnapshot.docs[0].data();
        }
        return doc;
    }

    const fetchKnownVocabulary = async (targetLang) => {
        const docRef = doc(projectFirestore, "users", currentUser.uid, "private-data", "vocabulary", "languages", targetLang, "metadata", "known_target_words");
        const docSnap = await getDoc(docRef);
    
        let targetWords = [];
        if (docSnap.exists()) {
            if (docSnap.data().hasOwnProperty("known_target_words")) {
                targetWords.push(...docSnap.data()['known_target_words']);
            }
        }
    
        console.log("Known: ", targetWords);
        return targetWords;
    }

    const addTargetWordsToKnownVocabulary = async (words, targetLang) => {
        const docRef = doc(projectFirestore, "users", currentUser.uid, "private-data", "vocabulary", "languages", targetLang, "metadata", "known_target_words");
        
        const obj = {
            'known_target_words': arrayUnion(...words),
            'last_updated_timestamp': timeStamp
        };
        
        await setDoc(docRef, obj, { merge: true }).catch(err => { console.log(err) });
        return true;
    }

    const removeIgnoreListFromWord = (word, ignoreList) => {
        ignoreList.forEach(ignore=>{
            word = word.replace(ignore, "");
        }) 
        return word;
    }

    const calculatePercentageOfVocabulary = (textBody, splitString, vocabulary) => {
        let listOfUnknownWords = [];
        let ignoreList = [".", ",", "?", "!", "。", "，", ":", ";", "`", "¿", "？", "！", "-", "(", ")"];
        let allWords = [];
        
        textBody.forEach((paragraph)=>{
            paragraph.children.forEach((child)=>{
                child.text.split("\n").forEach((sentence)=>{
                    sentence.split(splitString).forEach((word)=>{
                        word = removeIgnoreListFromWord(word.trim().toLowerCase(), ignoreList);
                        if (word !== "" && word !== " " && !ignoreList.includes(word.trim())){
                            allWords.push(word);
                        }
                        if (!ignoreList.includes(word) && !vocabulary.includes(word)){
                            if (word.trim() !== ""){
                                listOfUnknownWords.push(word);
                            }
                        }
                    })
                })
            })
        });
        listOfUnknownWords = [...new Set(listOfUnknownWords)]; //unique
        allWords = [...new Set(allWords)];
        console.log(listOfUnknownWords.length, allWords.length);
        let percentage = Math.round(100*((allWords.length - listOfUnknownWords.length)/allWords.length));
        return percentage;
    }

    const fetchUnfinishedOverdueSpacedVocabulary = async (uid, targetLang) => {
        let docs = [];
        let addedVocabIds = [];
    
        let tonightMidnight = new Date();
        tonightMidnight.setDate(tonightMidnight.getDate() + 1);
        tonightMidnight.setHours(0, 0, 0, 0);
        let tonightTimestamp = firebase.firestore.Timestamp.fromDate(tonightMidnight); //tonight
    
        let lastNightMidnight = new Date(); //last night
        lastNightMidnight.setHours(0, 0, 0, 0);
        let lastNightMidnightTimestamp = firebase.firestore.Timestamp.fromDate(lastNightMidnight);
    
        const queryToday = query(
            collection(projectFirestore, "users", uid, "private-data", "vocabulary", "languages", targetLang, "items"),
            where("all_sources_deleted", "==", false),
            where("spaced_repetition_finished", "==", false),
            where("spaced_repetition_next_review", "<", lastNightMidnightTimestamp)
        );
    
        const rToday = await getDocs(queryToday);
        if (!rToday.empty) {
            rToday.docs.forEach((doc) => {
                let d = doc.data();
                d['doc_id'] = doc.id;
                if (!addedVocabIds.includes(d.id)) {
                    let addBool = true;
                    if (d.hasOwnProperty("spaced_repetition_last_correct_timestamp")) {
                        let lastCorrectDate = d['spaced_repetition_last_correct_timestamp'].toDate();
                        if (lastCorrectDate < lastNightMidnight) {
                            // check if it's been tested today
                            addedVocabIds.push(d.id);
                            docs.push(d);
                        }
                    }
                }
            });
        }
    
        return docs;
    }

    const fetchUnfinishedSpacedVocabularyDueToday = async (uid, targetLang) => {
        let docs = [];
        let addedVocabIds = [];
    
        let tonightMidnight = new Date();
        tonightMidnight.setDate(tonightMidnight.getDate() + 1);
        tonightMidnight.setHours(0, 0, 0, 0);
        let tonightTimestamp = firebase.firestore.Timestamp.fromDate(tonightMidnight); // tonight
    
        let lastNightMidnight = new Date(); // last night
        lastNightMidnight.setHours(0, 0, 0, 0);
        let lastNightMidnightTimestamp = firebase.firestore.Timestamp.fromDate(lastNightMidnight);
    
        const queryToday = query(
            collection(projectFirestore, "users", uid, "private-data", "vocabulary", "languages", targetLang, "items"),
            where("all_sources_deleted", "==", false),
            where("spaced_repetition_finished", "==", false),
            where("spaced_repetition_next_review", "<", tonightTimestamp),
            where("spaced_repetition_next_review", ">", lastNightMidnightTimestamp)
        );
    
        const rToday = await getDocs(queryToday);
        if (!rToday.empty) {
            rToday.docs.forEach((doc) => {
                let d = doc.data();
                d['doc_id'] = doc.id;
                if (!addedVocabIds.includes(d.id)) {
                    let addBool = true;
                    if (d.hasOwnProperty("spaced_repetition_last_correct_timestamp")) {
                        let lastCorrectDate = d['spaced_repetition_last_correct_timestamp'].toDate();
                        if (lastCorrectDate < lastNightMidnight) {
                            // check if it's been tested today
                            addedVocabIds.push(d.id);
                            docs.push(d);
                        }
                    }
                }
            });
        }
    
        return docs;
    }

    const fetchUnstartedSpacedVocabulary = async (uid, targetLang, limit) => {
        let queryRef = query(
            collection(projectFirestore, "users", uid, "private-data", "vocabulary", "languages", targetLang, "items"),
            where("all_sources_deleted", "==", false),
            where("spaced_repetition_finished", "==", false),
            where("spaced_repetition_next_review", "==", null),
            orderBy("last_updated", "asc"),
            limit(limit)
        );
    
        let rNull = await getDocs(queryRef);
    
        let docs = [];
        let addedVocabIds = [];
    
        if (!rNull.empty) {
            rNull.docs.forEach((doc) => {
                let d = doc.data();
                d['doc_id'] = doc.id;
                if (!addedVocabIds.includes(d.id)) {
                    addedVocabIds.push(d.id);
                    docs.push(d);
                }
            });
        }
        return docs;
    };

    const fetchStartedSpacedVocabularyDueInFuture = async (uid, targetLang, limit) => {
        let docs = [];
        let addedVocabIds = [];
    
        let tonightMidnight = new Date();
        tonightMidnight.setDate(tonightMidnight.getDate() + 1);
        tonightMidnight.setHours(0, 0, 0, 0);
        let tonightTimestamp = Timestamp.fromDate(tonightMidnight); // tonight
    
        // Do limit * 5 in order to sort afterwards on client by number_of_answers (show least played)
        let queryFuture = query(
            collection(projectFirestore, "users", uid, "private-data", "vocabulary", "languages", targetLang, "items"),
            where("all_sources_deleted", "==", false),
            where("spaced_repetition_finished", "==", false),
            where("spaced_repetition_next_review", ">", tonightTimestamp),
            orderBy("spaced_repetition_next_review", "desc"),
            limit(limit * 5)
        );
    
        let rFuture = await getDocs(queryFuture);
        if (!rFuture.empty) {
            let counter = 0;
            rFuture.docs.forEach(doc => {
                let d = doc.data();
                d['doc_id'] = doc.id;
                if (!addedVocabIds.includes(d.id)) {
                    addedVocabIds.push(d.id);
                    docs.push(d);
                }
            });
        }
    
        // Now we will sort the documents
        let acceptedDocs = [];
        console.log("Future: ", docs);
        for (const doc of docs) {
            if (doc.hasOwnProperty("total_statistics") && doc['total_statistics'].hasOwnProperty('number_of_answers')) {
                acceptedDocs.push(doc);
            }
        }
    
        acceptedDocs = acceptedDocs.sort((a, b) => a.total_statistics.number_of_answers - b.total_statistics.number_of_answers);
    
        let filteredDocs = [];
        let numberOfCardsAdded = 0;
        for (const doc of acceptedDocs) {
            if (numberOfCardsAdded < limit) {
                filteredDocs.push(doc);
                numberOfCardsAdded++;
            }
        }
    
        return filteredDocs;
    };

    const addVocabItemsToListIfNotAlreadyAdded = (docs, addedVocabIds, newDocs) => {
        for (const doc of newDocs){
            if (!addedVocabIds.includes(doc.id)){
                docs.push(doc);
                addedVocabIds.push(doc.id);
            }
        }
    }

    const fetchMostUrgentNextReviewItemsFromSpacedRepetitionVocabularyApi = async ({targetLang, howManyItems}) => {
        let func = httpsCallable(projectFunctions, 'fetchMostUrgentNextReviewItemsFromSpacedRepetitionVocabulary');
        let results = await func({lang: targetLang, howManyItems: howManyItems});
        console.log("Spaced:", results);
        return results.data.cards;
    }

    const fetchMostUrgentNextReviewItemsFromSpacedRepetitionVocabulary = async (targetLang, howManyItems) => {
        /*
        LEGACY!!! Use API instead to ensure updated algorithm.
        */
        //let i = [0, 1, 2, 4, 8, 16, 32, 64, 128]
        //let perLevel = Math.ceil(limit/i.length);
        let docs = [];
        let addedVocabIds = [];
        let uid = currentUser.uid;

        const exitFunction = (limit, docs) => {
            let missingNumberOfCards = limit - docs.length;
            let exit = false;
            if (missingNumberOfCards <= 0){
                exit = true;
            }
            return {exit, missingNumberOfCards};
        }

        // OVERDUE ITEMS
        let overDueDocs = await fetchUnfinishedOverdueSpacedVocabulary(uid, targetLang);
        addVocabItemsToListIfNotAlreadyAdded(docs, addedVocabIds, overDueDocs);

        let {exit, missingNumberOfCards} = exitFunction(howManyItems, docs);
        if (exit){return docs.slice(0, howManyItems)}
        
        // DUE TODAY
        let dueTodayDocs = await fetchUnfinishedSpacedVocabularyDueToday(uid, targetLang);
        addVocabItemsToListIfNotAlreadyAdded(docs, addedVocabIds, dueTodayDocs);

        ({exit, missingNumberOfCards} = exitFunction(howManyItems, docs));
        if (exit){return docs.slice(0, howManyItems)}


        // UNSTARTED ITEMS
        console.log("Missing ", missingNumberOfCards, " cards after due today");
        let unstartedDocs = await fetchUnstartedSpacedVocabulary(uid, targetLang, missingNumberOfCards);
        addVocabItemsToListIfNotAlreadyAdded(docs, addedVocabIds, unstartedDocs);
       
        ({exit, missingNumberOfCards} = exitFunction(howManyItems, docs));
        if (exit){return docs.slice(0, howManyItems)}

        // FUTURE ITEMS
        console.log("Missing ", missingNumberOfCards, " cards after introducing unstarted vocab");
        let futureDocs = await fetchStartedSpacedVocabularyDueInFuture(uid, targetLang, missingNumberOfCards);
        addVocabItemsToListIfNotAlreadyAdded(docs, addedVocabIds, futureDocs);
        console.log("Future docs: ", futureDocs);

        return docs;
    }

    const fetchItemsBasedOnCurrentIndexFromSpacedRepetitionVocabulary = async (targetLang, howManyItems, index) => {
        let uid = currentUser.uid;
        let docs = [];
        let addedVocabIds = [];
    
        // Building the query using modular Firestore
        let itemsCollection = collection(projectFirestore, "users", uid, "private-data", "vocabulary", "languages", targetLang, "items");
        
        let q = query(
            itemsCollection,
            where("all_sources_deleted", "==", false),
            where("spaced_repetition_finished", "==", false),
            where("spaced_repetition_current_index", "==", index),
            orderBy("spaced_repetition_next_review", "asc"),
            limit(howManyItems)
        );
    
        // Fetching the documents using the query
        let r = await getDocs(q);
        
        if (!r.empty) {
            r.docs.forEach(doc => {
                let d = doc.data();
                d['doc_id'] = doc.id;
                if (!addedVocabIds.includes(d.id)) {
                    docs.push(d);
                    addedVocabIds.push(d.id);
                }
            });
        }
    
        return docs;
    };

    const fetchHistoricalStatisticsItemsFromList = async ({docIds, game, language}) => {
        if (docIds === undefined || docIds === null || language === null || language === undefined){return false}
        let listOfGames = returnPossibleGames();
        if (!listOfGames.includes(game)){return false}
        if (docIds.length === 0){return false}

        let dataArray = await Promise.all(
            docIds.map(async (docId) => {
                let data = await fetchHistoricalStatisticsItem({docId: docId, game: game, language:language});
                return data;
            }
        ));
        console.log(dataArray)
        return dataArray;
    }

    const returnPossibleGames = () => {
        let listOfGames = ["flashcards", "quiz", "input","table-flashcards"];
        return listOfGames;
    }

    const fetchHistoricalStatisticsItem = async ({ docId, game, language }) => {
        if (docId === undefined || docId === null || language === null || language === undefined) {
            return false;
        }
    
        let listOfGames = returnPossibleGames();
        if (!listOfGames.includes(game)) {
            return false;
        }
    
        // Constructing the reference path to the document
        const itemRef = doc(projectFirestore, "users", currentUser.uid, "private-data", "statistics", "historic_data", "languages", language, game, "items", docId);
    
        // Fetching the document
        const r = await getDoc(itemRef);
        
        if (r.exists()) {
            return r.data();
        } else {
            return false;
        }
    };

    return {
        fetchTargetWordsDoc, 
        addTargetWordsToKnownVocabulary, 
        fetchKnownVocabulary,  
        fetchTargetWordFromVocabulary, 
        calculatePercentageOfVocabulary, 
        fetchMostUrgentNextReviewItemsFromSpacedRepetitionVocabulary, 
        fetchMostUrgentNextReviewItemsFromSpacedRepetitionVocabularyApi,
        fetchItemsBasedOnCurrentIndexFromSpacedRepetitionVocabulary, 
        fetchItemFromVocabularyInLanguage, 
        fetchHistoricalStatisticsItem, 
        fetchHistoricalStatisticsItemsFromList
    }
}