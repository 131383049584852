import { faChevronRight, faHome } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const ViewFolderPath = ({ folderDocId, menuItems }) => {
    const [pathItems, setPathItems] = useState([]);

    useEffect(() => {
        const initialItems = [{ text: "Home", link: "/my-library" }];
        const recursiveSearch = (items, path = initialItems) => {
            for (const item of items) {
                const newPath = [...path, { ...item, link: `/my-library/folder/${item.doc_id}` }];

                if (item.doc_id === folderDocId) {
                    setPathItems(newPath);
                    return true;
                }

                if (item.children) {
                    const found = recursiveSearch(item.children, newPath);
                    if (found) return true;
                }
            }
            return false;
        };

        folderDocId ? recursiveSearch(menuItems) : setPathItems(initialItems);
    }, [folderDocId, menuItems]);

    return (
        <div className="flex items-center gap-4 p-4 bg-gray-100 dark:dark-mode-background-color rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
        {pathItems.map((item, index) => (
            <React.Fragment key={index}>
            <PathItem {...item} isFirst={index === 0} />
            {index < pathItems.length - 1 && (
                <FontAwesomeIcon
                icon={faChevronRight}
                className="text-darker-charcoal dark:text-white text-sm"
                />
            )}
            </React.Fragment>
        ))}
        </div>

    );
};

const PathItem = ({ text, link, isFirst }) => (
    <Link
        to={link}
        className="flex items-center gap-2 text-charcoal dark:text-white hover:text-persian-green transition-colors duration-300"
    >
        {isFirst && (
            <div className="p-2 bg-persian-green text-white rounded-full shadow-md">
                <FontAwesomeIcon icon={faHome} className="text-lg" />
            </div>
        )}
        <span className="font-medium">{text}</span>
    </Link>
);

export default ViewFolderPath;
