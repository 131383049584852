import React, {useEffect, useState} from 'react';
import PublicDeckCard from '../../components/vocabulary/decks/PublicDeckCard';
import useLanguages from '../../hooks/useLanguages';
import useDecks from '../../hooks/useDecks';
import useUserSettings from '../../hooks/useUserSettings';
import {useParams} from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import {Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';

const UserProfileDecks = () => {
    let { username } = useParams();
    let {fetchPublicUserInfoFromUsername} = useUserSettings();
    let {getAllLanguages} = useLanguages();
    let {fetchAllPublicDecksDataFromOneUser} = useDecks();
    let {currentUser} = useAuth();

    const [publicProfileData, setPublicProfileData] = useState(null);
    const [allLanguages, setAllLanguages] = useState(null);
    const [decks, setDecks] = useState(null);
    const [loading, setLoading] = useState(true);

    const decksLimit = 20; //after that, infinite scrolling

    useEffect(()=>{
        const fetchData = async () => {
            let temp_uid;
            if (currentUser !== null){
                temp_uid = currentUser.uid;
            } else {
                temp_uid = null;
            }

            let [public_data, own_profile_bool] = await fetchPublicUserInfoFromUsername(username,temp_uid);
            setPublicProfileData(public_data);
            let all_languages = await getAllLanguages();
            setAllLanguages(all_languages);
            let all_decks = await fetchAllPublicDecksDataFromOneUser(public_data.uid, decksLimit);
            setDecks(all_decks);
        }

        fetchData();
    },[]);

    useEffect(()=>{
        if (decks !== null && allLanguages !== null && publicProfileData !== null){
            //console.log(decks);
            setLoading(false);
        }
    },[decks, allLanguages, publicProfileData])

    return (
        <>
<div className="bg-white dark:bg-gray-800 min-h-screen">
  <div className="p-6 relative">
    {!loading && (
      <>
        {/* Helmet Metadata */}
        <Helmet>
          <title>
            {(publicProfileData.displayname !== ""
              ? publicProfileData.displayname
              : publicProfileData.username) + "'s public decks - Yalango"}
          </title>
          <meta
            name="description"
            content={
              "Browse and play with the public decks of " +
              (publicProfileData.displayname !== ""
                ? publicProfileData.displayname
                : publicProfileData.username) +
              "."
            }
          />
        </Helmet>

        {/* Header */}
        <h1 className="text-gray-700 dark:text-gray-200 text-2xl font-bold mb-6">
          {(publicProfileData.displayname !== ""
            ? publicProfileData.displayname
            : publicProfileData.username) + "'s public decks"}
        </h1>

        {/* Decks Grid */}
        <div className="grid place-items-center grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {decks.map((deck, index) => (
            <div key={`deck_${index}`} className="min-w-64 w-64">
              <PublicDeckCard languages={allLanguages} deck={deck} />
            </div>
          ))}
        </div>

        {/* No Decks Message */}
        {decks.length === 0 && (
          <div className="text-center italic text-gray-600 dark:text-gray-400 mt-4">
            {publicProfileData.displayname !== ""
              ? publicProfileData.displayname
              : publicProfileData.username}{" "}
            has no public decks.
          </div>
        )}
      </>
    )}
  </div>
</div>

        </>
    )
}

export default UserProfileDecks;
