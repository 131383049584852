import React , {useState} from 'react';
import { useAuth } from "../../contexts/AuthContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-duotone-svg-icons';
import {faPaperPlaneTop} from '@fortawesome/pro-solid-svg-icons';
import {faLanguage} from '@fortawesome/pro-solid-svg-icons';
import {faMessageText} from '@fortawesome/pro-solid-svg-icons';
import useComments from "../../hooks/useComments";
import Select from 'react-select';
import {useLanguagesContext} from '../../contexts/LanguagesContext';


const WriteComment = (props) => {
    const {commentSource, alreadyChosenLanguage, showPostOptions, postType, inputDesign, commentParentMetadata, commentSourceMetadata, sourceId, onSubmitFinished} = {...props};
    const {allLanguages, sourceLanguageOptions} = useLanguagesContext();
    const {postComment} = useComments();
    let {currentUser} = useAuth();
    const [commentBody, setCommentBody] = useState("");
    const [numberOfCharacters, setNumberOfCharacters] = useState(0);
    const [maxLength, setMaxLength] = useState(2000);
    const [minLength, setMinLength] = useState(1);
    const [commentPosting, setCommentPosting] = useState(false);
    const [error, setError] = useState(false);

    const [newPostDesign, setNewPostDesign] = useState("standard");

    let topicOptions = [
        {label: 'Not specified', value: 'not_specified'}, 
        {label: 'Introduce yourself', value: 'introduction'}, 
        {label: 'Ask for corrections', value: 'corrections_request'}
    ];
    
    const [chosenTopic, setChosenTopic] = useState(topicOptions[0]);
    const [languageOptions, setLanguageOptions] = useState(sourceLanguageOptions !== null ? [{label: 'Not specified', value: 'not_specified'},{label: 'General discussions', value: 'general'},...sourceLanguageOptions[0]] : [{label: 'Not specified', value: 'not_specified'}, {label: 'General discussions', value: 'general'}]);
    const [chosenLanguage, setChosenLanguage] = useState(alreadyChosenLanguage !== null ? alreadyChosenLanguage : languageOptions[1]);

    const handleTopicChange = (option) => {
        console.log(option);
        if (option.value === "corrections_request"){
            setNewPostDesign("corrections_request");
        } else {
            setNewPostDesign("standard");
        }
        if (option.value === "not_specified"){
            setShowLanguageOptions(false);
        } else {
            setShowLanguageOptions(true);
        }
        
        setChosenTopic(option);
    }

    const handleLanguageChange = (option) => {
        setChosenLanguage(option);
    }

    //corrections
    const [newPostTextToBeCorrected, setNewPostTextToBeCorrected] = useState("");
    const [newPostAdditionalTextInCorrectionRequest, setNewPostAdditionalTextInCorrectionRequest] = useState("");

    const newPostTextToBeCorrectedOnChange = (e) => {
        setNewPostTextToBeCorrected(e.target.value);
    }

    const newPostAdditionalTextInCorrectionRequestOnChange = (e) => {
        setNewPostAdditionalTextInCorrectionRequest(e.target.value);
    }

    const [commentCorrectionOriginal, setCommentCorrectionOriginal] = useState((commentParentMetadata !== undefined ? (commentParentMetadata.hasOwnProperty("comment_text_to_be_corrected_request") ? commentParentMetadata.comment_text_to_be_corrected_request :  commentParentMetadata.hasOwnProperty("comment") ? commentParentMetadata.comment : "") : ""));
    const [yourCorrection, setYourCorrection] = useState((commentParentMetadata !== undefined ? (commentParentMetadata.hasOwnProperty("comment_text_to_be_corrected_request") ? commentParentMetadata.comment_text_to_be_corrected_request :  commentParentMetadata.hasOwnProperty("comment") ? commentParentMetadata.comment : "") : ""));
    const [yourCorrectionExplanation, setYourCorrectionExplanation] = useState("");

    const [showTopicOptions, setShowTopicOptions] = useState(false);
    const [showLanguageOptions, setShowLanguageOptions] = useState(false);

    const topicOnClick = () => {
        setShowTopicOptions(!showTopicOptions);
    }

    const languageOnClick = () => {
        setShowLanguageOptions(!showLanguageOptions);
    }

    const originalCommentCorrectionEdit = (e) => {
        setCommentCorrectionOriginal(e.target.value);
    }

    const yourCorrectionOnChange = (e) => {
        setYourCorrection(e.target.value);
    }

    const yourCorrectionExplanationOnChange = (e) => {
        setYourCorrectionExplanation(e.target.value);
    }

    const placeholders = {
        'reply': 'Write your reply here...',
        'comment': 'Write your comment here...', 
        'post': 'Write a language related post or question here...'
    };

    const commentChange = (e) => {
        setCommentBody(e.target.value);
        console.log(e.target.value);
        setNumberOfCharacters(e.target.value.length);
        if (e.target.value.length > maxLength){
            setError("The comment cannot be longer than " + maxLength + " characters.");
        } else if (e.target.value.length < minLength){
            setError("The comment must be longer than " + (minLength-1) + " characters.");
        }
        else {
            setError(false);
        }
    }

    const submitComment = async () => {
        if (currentUser === null){return null}
        if (error !== false){return null}
        setCommentPosting(true);
        console.log(commentSource, commentSourceMetadata, commentBody, sourceId, postType)
        let success = await postComment({commentSource, newPostTextToBeCorrected, newPostAdditionalTextInCorrectionRequest, chosenTopic, chosenLanguage, commentSourceMetadata, commentBody, sourceId, postType, commentParentMetadata, commentCorrectionOriginal, yourCorrection, yourCorrectionExplanation, inputDesign});
        setCommentPosting(false);
        if (onSubmitFinished !== undefined){
            await onSubmitFinished();
        }
        if (success === true){
            setCommentBody("");
            setCommentCorrectionOriginal("");
            setYourCorrection("");
            setYourCorrectionExplanation("");
            setNewPostTextToBeCorrected("");
            setNewPostAdditionalTextInCorrectionRequest("");
        }
    };

    console.log(sourceLanguageOptions)

    return (
        <>
            <div className=" p-2 w-full h-full relative">
                   
                    <div className={(currentUser === null || commentPosting ? "opacity-30 " : "opacity-100") + " flex flex-col gap-1"}>
                        {inputDesign === "correction" ? 
                            <>
                                <div className="flex flex-col gap-2">
                                    <div className="">
                                        <div className="font-bold text-sm">
                                        Original text
                                        </div>
                                        <textarea value={commentCorrectionOriginal} onChange={originalCommentCorrectionEdit} className="w-full rounded-lg p-2 text-gray-700 border-2 border-gray-300 focus:outline-none focus:border-persian-green" placeholder={"The part you want to correct from the original post."}></textarea>
                                    </div>
                                    <div className="">
                                        <div className="font-bold text-sm">
                                        Your correction or suggestion
                                        </div>
                                        <textarea value={yourCorrection} onChange={yourCorrectionOnChange} className="w-full rounded-lg p-2 text-gray-700 border-2 border-gray-300 focus:outline-none focus:border-persian-green" placeholder={"Write your suggested correction or improvement here."}></textarea>
                                    </div>
                                    <div className="">
                                        <div className="font-bold text-sm">
                                        Explanation (optional)
                                        </div>
                                        <textarea value={yourCorrectionExplanation} onChange={yourCorrectionExplanationOnChange} className="w-full rounded-lg p-2 text-gray-700 border-2 border-gray-300 focus:outline-none focus:border-persian-green" placeholder={"Help the original poster by providing a reason for your suggested edit."}></textarea>
                                    </div>
                                </div>
                            </>
                        :
                            <>
                                {newPostDesign === "standard" &&
                                    <>
                                        <div className="relative">
                                            <textarea value={commentBody} onChange={commentChange} className="w-full rounded-lg p-3 text-gray-700 border-2 border-gray-300 focus:outline-none focus:border-persian-green" placeholder={placeholders[postType]}>
                                            </textarea>
                                            <div className="absolute right-2 top-2 text-gray-600 text-right w-full text-xs" id="counter">
                                                {numberOfCharacters}/{maxLength}
                                            </div>
                                        </div>
                                        <div className="flex flex-row gap-6 justify-between">
                                            {error !== false &&
                                            <div className="text-red-500 w-full text-xs">
                                                {error}
                                            </div>
                                            }  
                                            
                                        </div>
                                    </>
                                }
                                {newPostDesign === "corrections_request" &&
                                    <>
                                        <div className="font-bold">
                                            Text you want corrected
                                            <textarea value={newPostTextToBeCorrected} onChange={newPostTextToBeCorrectedOnChange} className="w-full rounded-lg p-2 text-gray-700 border-2 border-gray-300 focus:outline-none focus:border-persian-green" placeholder={"The text you want other users to correct."}></textarea>
                                        </div>
                                        <div className="font-bold">
                                            Additional message or context (optional)
                                            <textarea value={newPostAdditionalTextInCorrectionRequest} onChange={newPostAdditionalTextInCorrectionRequestOnChange} className="w-full rounded-lg p-2 text-gray-700 border-2 border-gray-300 focus:outline-none focus:border-persian-green" placeholder={"Additional message or context. This will not be corrected."}></textarea>
                                        </div>
                                        
                                    </>
                                }

                                {showPostOptions && false && 
                                    <>
                                        <div className="flex flex-col my-1 gap-3 md:flex-row justify-between">
                                        {showTopicOptions &&
                                        <div className= "max-w-sm w-full">
                                            <div className="font-bold text-gray-600">
                                                Post topic (optional)
                                            </div>
                                            <div>
                                                <Select onChange={handleTopicChange} value={chosenTopic} isSearchable={false} options={topicOptions} autoFocus={false} placeholder="Post topic" className=" text-gray-500 text-base" />
                                            </div>
                                        </div>
                                        }
                                        {showLanguageOptions &&
                                            <div className= "max-w-sm w-full">
                                                <div className="font-bold text-gray-600">
                                                    Language feed (optional)
                                                </div>
                                                <div>
                                                    <Select onChange={handleLanguageChange} value={chosenLanguage} isSearchable={false} options={languageOptions !== null ? languageOptions : []} autoFocus={false} placeholder="Language" className=" text-gray-500 text-base" />
                                                </div>
                                            </div>
                                        }
                                        </div>
                                    </>
                                }
                            </>
                        }
                        <div className={(showPostOptions ? "justify-between " : "justify-end ") + " flex flex-row gap-3 items-center "}>
                            {showPostOptions && 
                                    <>
                            <div className="flex flex-row gap-2 text-gray-600">
                                    <div onClick={topicOnClick} className="cursor-pointer hover:bg-charcoal font-bold hover:text-white p-2 border border-text-gray-600 border-solid rounded-lg shadow-lg flex flex-row gap-2">
                                        <div>
                                            <FontAwesomeIcon icon={faMessageText} />
                                        </div>
                                        
                                    </div>
                                    <div onClick={languageOnClick} className="cursor-pointer hover:bg-charcoal font-bold hover:text-white p-2 border border-text-gray-600 border-solid rounded-lg shadow-lg flex flex-row gap-2">
                                        <div>
                                            <FontAwesomeIcon icon={faLanguage} />
                                        </div>
                                    </div>
                                </div>
                                </>
                            }
                            <button onClick={submitComment} className="flex flex-row gap-2 bg-persian-green text-white py-2 px-4 rounded-lg hover:bg-green-600">
                                <div>
                                    <FontAwesomeIcon icon={faPaperPlaneTop} />
                                </div>
                                <div>
                                    Publish
                                </div>
                            </button>
                        </div>
 
                    </div>  
                    {currentUser === null &&
                        <>
                            <div className="left-0 w-full text-center h-full top-0 flex flex-col justify-center  absolute text-xl">
                                <div className=" text-gray-600  rounded-lg p-2">
                                    Please sign in to write a post.
                                </div>
                            </div>
                            <div className="h-full absolute left-0 top-0 w-full z-10 ">
                            </div> 
                        </>
                    }    
                    {commentPosting === true &&
                    <>
                        <div className="left-0 w-full text-center h-full top-0 flex flex-col justify-center  absolute text-xl">
                                <div className=" text-gray-600  rounded-lg p-2">
                                    <FontAwesomeIcon icon={faSpinner} className="fa-pulse" />
                                </div>
                        </div>
                        <div className="h-full absolute left-0 top-0 w-full z-10 ">
                        </div>
                     </>
                    }
            </div>
        </>
    )
}

export default WriteComment