import React, {useState, useEffect, useRef} from 'react';
import {useUserStatisticsContext} from '../../contexts/UserStatisticsContext'; 
import {faHeadSideBrain} from '@fortawesome/pro-solid-svg-icons';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Skeleton from 'react-loading-skeleton';
import CustomSkeleton from '../general/CustomSkeleton';

const VocabularyCard = (props) => {
    const {language, onClick} = props;
    const { 
        refreshVocabularyMetadataInAllActiveLanguages,
        vocabularyMetadata,
    } = useUserStatisticsContext();
    const [loading, setLoading] = useState(true);
    const fetchedData = useRef(false);

    useEffect(()=>{
        const fetchData = async () => {
            setLoading(true);
            fetchedData.current = true;
            await refreshVocabularyMetadataInAllActiveLanguages();
        }
        if (vocabularyMetadata === null || !vocabularyMetadata.hasOwnProperty(language)){
            if (fetchedData.current === false){
                fetchData();
            }
        } else {
            setLoading(false);
        }
    },[vocabularyMetadata, language]);
   
  return (
    <>
        {(vocabularyMetadata !== null && vocabularyMetadata.hasOwnProperty(language)) &&
            <>
                <Link onClick={onClick} className="no-underline" to="/my-vocabulary">
                    <div className="flex flex-row gap-1 md:gap-2 text-gray-600 font-bold text-sm place-items-center md:text-base">
                        <div className=" text-burnt-sienna">
                            <FontAwesomeIcon icon={faHeadSideBrain} />
                        </div>
                        <div className=" text-gray-600 ">
                            {vocabularyMetadata[language].hasOwnProperty('active_items_doc_ids') ? vocabularyMetadata[language]['active_items_doc_ids'].length  : vocabularyMetadata[language].hasOwnProperty('number_of_items') ? vocabularyMetadata[language]['number_of_items'] : 0}
                        </div>
                    </div>
                </Link>
            </>
        }
        {loading &&
            <>
                <CustomSkeleton height={50} count={1} />
            </>
        }
    </>
  )
}

export default VocabularyCard;