import React, { createContext, useContext, useEffect, useState } from 'react';

// Create the ThemeContext
const ThemeContext = createContext();

// ThemeContext Provider Component
export const ThemeProvider = ({ children }) => {
    // Initialize theme state with localStorage or fallback to 'light'

    function getThemeCookie() {
        const cookies = document.cookie.split("; ");
        const themeCookie = cookies.find(row => row.startsWith("yalangoTheme="));
        return themeCookie ? themeCookie.split("=")[1] : null;
      }

    const [theme, setTheme] = useState(() => getThemeCookie() || 'light');


    // Update localStorage and apply theme class to <html> when theme changes
    useEffect(() => {
        if (typeof window !== 'undefined') {
            localStorage.setItem('theme', theme);
            document.documentElement.className = ''; // Clear any existing theme classes
            document.documentElement.classList.add(theme);

            function setThemeCookie(value) {
                const domain = window.location.hostname.includes("localhost") ? "" : "domain=.yalango.com;";
                document.cookie = `yalangoTheme=${value}; path=/; ${domain} SameSite=Lax`;
            }
            setThemeCookie(theme);
        }
    }, [theme]);

    // Toggle between light and dark mode
    const toggleTheme = () => {
        setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
    };

    return (
        <ThemeContext.Provider value={{ theme, setTheme, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

// Custom Hook to Use the ThemeContext
export const useThemeContext = () => {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error('useThemeContext must be used within a ThemeProvider');
    }
    return context;
};
