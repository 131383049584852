import React from 'react';
import {projectFirestore, projectFunctions, timeStamp, firebase} from '../firebase/config.js';
import { useAuth } from "../contexts/AuthContext";

import {
    getFirestore,
    collection,
    query,
    where,
    setDoc,
    getDoc,
    getDocs,
    doc,
    deleteDoc,
    addDoc,
    updateDoc,
    onSnapshot,
    serverTimestamp,
    orderBy,
    limit,
    startAfter,
    endBefore,
    limitToLast, 
    Timestamp
} from "firebase/firestore";
import {  httpsCallable } from "firebase/functions";

export default function useTables() {

    let {currentUser} = useAuth();

    //projectFunctions.useEmulator("localhost", 5001); // local testing

    // Realtime Tables Fetch (targetLanguage)
const getTablesFromCurrentUserInRealtime = async (targetLanguage, setStateFunc) => {
    const tablesRef = collection(projectFirestore, 'tables');
    const q = query(tablesRef, where('uid', '==', currentUser.uid), where('target_ISO_639-1', '==', targetLanguage));
    onSnapshot(q, querySnapshot => {
        let tables = [];
        querySnapshot.forEach((doc) => {
            tables.push(doc.data());
        });
        setStateFunc(tables);
    });
};

// Save Changes to Table
const saveChangesToTable = async ({ tableId, tableName, sourceLanguage, privacy, publicTitle, description }) => {
    if (!tableId || !currentUser) return false;

    const tablesRef = collection(projectFirestore, 'tables');
    const q = query(tablesRef, where('uid', '==', currentUser.uid), where('id', '==', tableId));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.size === 1) {
        const docRef = doc(projectFirestore, 'tables', querySnapshot.docs[0].id);
        let obj = { 'last_updated_timestamp': timeStamp };

        if (sourceLanguage) obj['source_ISO_639-1'] = sourceLanguage;
        if (tableName) obj['name'] = tableName;
        if (privacy) obj['privacy'] = privacy;
        if (publicTitle) obj['title'] = publicTitle.trim();
        if (description) obj['description'] = description.trim();

        await updateDoc(docRef, obj).catch(err => console.log(err));
        return true;
    }

    return false;
};

// Get All Tables from Current User (Realtime)
const getAllTablesFromCurrentUserInRealtime = async (setStateFunc) => {
    const tablesRef = collection(projectFirestore, 'tables');
    const q = query(tablesRef, where('uid', '==', currentUser.uid));
    onSnapshot(q, querySnapshot => {
        let tables = [];
        querySnapshot.forEach((doc) => {
            let tableData = doc.data();
            tableData['doc_id'] = doc.id;
            tables.push(tableData);
        });
        setStateFunc(tables);
    });
};

// Get Tables from Current User (No Realtime)
const getTablesFromCurrentUser = async (targetLanguage) => {
    const tablesRef = collection(projectFirestore, 'tables');
    const q = query(tablesRef, where('uid', '==', currentUser.uid), where('target_ISO_639-1', '==', targetLanguage));
    const querySnapshot = await getDocs(q);

    let tables = [];
    querySnapshot.forEach((doc) => {
        let tableData = doc.data();
        tableData['table_doc_id'] = doc.id;
        tables.push(tableData);
    });
    return tables;
};

// Get Table from Current User (Realtime)
const getTableFromCurrentUserInRealtime = async (tableId, setTable) => {
    const tablesRef = collection(projectFirestore, 'tables');
    const q = query(tablesRef, where('uid', '==', currentUser.uid), where('id', '==', tableId));
    onSnapshot(q, querySnapshot => {
        if (querySnapshot.docs.length === 1) {
            let tableData = querySnapshot.docs[0].data();
            tableData['doc_id'] = querySnapshot.docs[0].id;
            console.log("table: ", tableData);
            setTable(tableData);
        } else if (querySnapshot.docs.length === 0) {
            return false;
        }
    });
};

// Get Table from Current User (No Realtime)
const getTableFromCurrentUser = async (tableId) => {
    const tablesRef = collection(projectFirestore, 'tables');
    const q = query(tablesRef, where('uid', '==', currentUser.uid), where('id', '==', tableId));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.docs.length === 1) {
        let tableData = querySnapshot.docs[0].data();
        tableData['doc_id'] = querySnapshot.docs[0].id;
        return tableData;
    } else if (querySnapshot.docs.length === 0) {
        return false;
    }
};

// Get Public Table
const getPublicTable = async ({ tableId }) => {
    const tablesRef = collection(projectFirestore, "tables");
    const q = query(tablesRef, where("privacy", "==", "public"), where("id", "==", tableId));
    const snapshot = await getDocs(q);

    if (snapshot.empty) return null;

    let tableData = snapshot.docs[0].data();
    tableData['doc_id'] = snapshot.docs[0].id;
    return tableData;
};

// Get Table Columns from Current User (Realtime)
const getTableColumnsFromCurrentUserInRealtime = async (table, setTableColumns) => {
    const columnsRef = collection(projectFirestore, 'tables', table['doc_id'], "columns");
    const querySnapshot = onSnapshot(columnsRef, querySnapshot => {
        if (querySnapshot.empty) {
            setTableColumns([]);
            return;
        }

        let docs = Array(querySnapshot.docs.length);
        let order = table['column_order'];
        querySnapshot.docs.forEach(doc => {
            let columnData = doc.data();
            columnData['doc_id'] = doc.id;
            let pos = order.indexOf(columnData.id);
            if (pos > -1) {
                docs[pos] = columnData;
            }
        });
        setTableColumns(docs);
    });
};

const getTableColumnsFromCurrentUser = async (table) => {
    const columnsRef = collection(projectFirestore, 'tables', table['doc_id'], 'columns');
    const querySnapshot = await getDocs(columnsRef);

    if (querySnapshot.empty) {
        return [];
    }

    let docs = Array(querySnapshot.docs.length);
    let order = table['column_order'];
    let not_added = [];
    querySnapshot.docs.forEach(doc => {
        let d = doc.data();
        d['doc_id'] = doc.id;
        let pos = order.indexOf(d.id);
        if (pos > -1) {
            docs[pos] = d;
        } else {
            not_added.push(d);
        }
    });

    docs = docs.filter(n => n);
    not_added.forEach(el => docs.push(el));
    return docs;
};

// Get Table Columns from Public Table
const getTableColumnsFromPublicTable = async ({ table }) => {
    const columnsRef = collection(projectFirestore, 'tables', table['doc_id'], 'columns');
    const querySnapshot = await getDocs(columnsRef);

    if (querySnapshot.empty) {
        return [];
    }

    let docs = Array(querySnapshot.docs.length);
    let order = table['column_order'];
    let not_added = [];
    querySnapshot.docs.forEach(doc => {
        let d = doc.data();
        d['doc_id'] = doc.id;
        let pos = order.indexOf(d.id);
        if (pos > -1) {
            docs[pos] = d;
        } else {
            not_added.push(d);
        }
    });

    docs = docs.filter(n => n);
    not_added.forEach(el => docs.push(el));
    return docs;
};

// Get Table Rows from Current User in Realtime
const getTableRowsFromCurrentUserInRealtime = async (tableDoc, rowOrder, setTableItems) => {
    const rowsRef = collection(projectFirestore, 'tables', tableDoc, 'rows');
    onSnapshot(rowsRef, querySnapshot => {
        if (querySnapshot.docs.length > 0) {
            let items = [];
            querySnapshot.docs.forEach(doc => {
                items.push(doc.data());
            });

            if (rowOrder) {
                let ordered_list = [];
                rowOrder.forEach((row) => {
                    items.forEach((item) => {
                        if (row === item.id) {
                            ordered_list.push(item);
                        }
                    });
                });

                items.forEach((item) => {
                    if (!ordered_list.includes(item)) {
                        ordered_list.push(item);
                    }
                });

                setTableItems(ordered_list);
            } else {
                setTableItems(items);
            }
        } else {
            setTableItems([]);
        }
    });
};

// Get Table Rows from Current User
const getTableRowsFromCurrentUser = async (table) => {
    const rowsRef = collection(projectFirestore, 'tables', table['doc_id'], 'rows');
    const querySnapshot = await getDocs(rowsRef);

    if (querySnapshot.docs.length > 0) {
        let items = [];
        querySnapshot.docs.forEach(doc => {
            items.push({ 'doc_id': doc.ref.id, 'table_id': table.id, ...doc.data() });
        });

        if (table['row_order']) {
            let ordered_list = [];
            table['row_order'].forEach((row) => {
                items.forEach((item) => {
                    if (row === item.id) {
                        ordered_list.push(item);
                    }
                });
            });

            items.forEach((item) => {
                if (!ordered_list.includes(item)) {
                    ordered_list.push(item);
                }
            });

            return ordered_list;
        } else {
            return items;
        }
    } else {
        return [];
    }
};

const getTableRowsFromPublicTable = async ({ table }) => {
    const rowsRef = collection(projectFirestore, 'tables', table['doc_id'], 'rows');
    const querySnapshot = await getDocs(rowsRef);

    if (querySnapshot.docs.length > 0) {
        let items = [];
        querySnapshot.docs.forEach((doc) => {
            items.push({ 'doc_id': doc.id, 'table_id': table.id, ...doc.data() });
        });

        if (table['row_order'] !== null && table['row_order'] !== undefined) {
            let ordered_list = [];
            table['row_order'].forEach((row) => {
                items.forEach((item) => {
                    if (row === item.id) {
                        ordered_list.push(item);
                    }
                });
            });

            items.forEach((item) => {
                if (!ordered_list.includes(item)) {
                    ordered_list.push(item);
                }
            });

            return ordered_list;
        } else {
            return items;
        }
    } else {
        return [];
    }
};
    const getSpecificTableRow = async (tableDocId, tableId, tableRowDocId) => {
        const rowRef = doc(projectFirestore, 'tables', tableDocId, 'rows', tableRowDocId);
        const querySnapshot = await getDoc(rowRef);

        if (querySnapshot.exists()) {
            let row = { 'doc_id': querySnapshot.id, 'table_id': tableId, ...querySnapshot.data() };
            return row;
        } else {
            return null;
        }
    };

    const getXRandomTableRowsFromCurrentUser = async (table, howManyItems) => {
        const f =  httpsCallable(projectFunctions, 'getXRowsFromTable');
        const results = await f({'tableId': table.id, 'howManyItems': howManyItems});
        let rows = results['data']['rows'];
        return rows;
    }

    const getXRandomTableRowsFromPublicTable = async ({table, howManyItems}) => {
        const f =  httpsCallable(projectFunctions, 'getXRowsFromPublicTable');
        const results = await f({'tableId': table.id, 'howManyItems': howManyItems});
        let rows = results['data']['rows'];
        return rows;
    }

    const generateUniqueTableId = async () => {
        const generateTableId =  httpsCallable(projectFunctions, 'generateTableId');
        const results = await generateTableId();
        let id = results['data'];
        return id;
    }

    const saveNewTable = async (targetLanguage, sourceLanguage, name, selectedTemplateValue, type, parentFolderDocId) => {
        if (currentUser === null){return false};
        if (name === "" || name === null || name === undefined){return false};
        
        const saveNewTableFunction =  httpsCallable(projectFunctions, 'saveNewTable');
        let data = await saveNewTableFunction({
            'targetLanguage': targetLanguage,
            'sourceLanguage': sourceLanguage, 
            'name': name, 
            'startTemplate': selectedTemplateValue, 
            'type': type, 
            'parentFolderDocId': "top_level", 
            'destinationFolder': parentFolderDocId
        }).catch(err=>{return false});
        if (data === false){return false};

        return data;
    };

    const addNewTableColumn = async (tableDoc, columnName) => {
        if (columnName === null || columnName === undefined || columnName === ""){
            columnName = "Untitled";
        }
        const saveNewColumn = httpsCallable(projectFunctions, "saveNewTableColumn");
        return saveNewColumn({
            'tableDoc': tableDoc,
            'columnName': columnName
        })
    }

    const renameTableColumn = async (tableDoc, columnDoc, name) => {
        const rename = httpsCallable(projectFunctions, "renameTableColumn");
        return rename({
            'tableDoc': tableDoc,
            'columnDoc': columnDoc,
            'name': name
        });
    }

    const renameTable = async (tableDoc, name) => {
        const tableRef = doc(projectFirestore, "tables", tableDoc);
        return setDoc(
            tableRef, 
            {
                'name': name, 
                'last_updated_timestamp': timeStamp
            }, 
            { merge: true }
        );
    };

    const changeTableColumnsOrder = async (table, order) => {
        const changeOrder = httpsCallable(projectFunctions, "changeTableColumnsOrder");
        return changeOrder({
            'colOrder': order,
            'tableDoc': table['doc_id']
        });
    }

    const saveChangesToTableRows = async (table, originalRows, newRows) => {
        const save = httpsCallable(projectFunctions, "saveChangesToTableRows");
        return save({
            'originalRows': originalRows,
            'newRows': newRows, 
            'tableDoc': table['doc_id']
        })
    }

    const getTableAndContentFromCurrentUser = async (tableId) => {
        let table = await getTableFromCurrentUser(tableId);
        let rows = await getTableRowsFromCurrentUser(table);
        let columns = await getTableColumnsFromCurrentUser(table);

        return {'table': table, 'rows': rows, 'columns': columns};
    }

    const deleteTableColumns = async (tableId, colIds, rows) => {
        const tableQuery = query(
            collection(projectFirestore, "tables"),
            where('uid', '==', currentUser.uid),
            where("id", "==", tableId)
        );
        const snapshot = await getDocs(tableQuery);
        if (!snapshot.empty && snapshot.docs.length === 1) {
            const tableDocId = snapshot.docs[0].id;
    
            // Delete columns
            for (const colId of colIds) {
                const colQuery = query(
                    collection(projectFirestore, "tables", tableDocId, "columns"),
                    where("id", "==", colId)
                );
                const colSnap = await getDocs(colQuery);
                if (!colSnap.empty && colSnap.docs.length === 1) {
                    const colDocId = colSnap.docs[0].id;
                    await deleteDoc(doc(projectFirestore, "tables", tableDocId, "columns", colDocId));
                }
            }
    
            // Delete values from rows
            for (const row of rows) {
                if ('values' in row) {
                    const newValuesObj = {};
                    Object.entries(row['values']).forEach(([col, row_value]) => {
                        if (!colIds.includes(parseInt(col))) {
                            newValuesObj[parseInt(col)] = row_value;
                        }
                    });
    
                    const rowQuery = query(
                        collection(projectFirestore, "tables", tableDocId, "rows"),
                        where("id", "==", row['id'])
                    );
                    const rowSnap = await getDocs(rowQuery);
                    if (!rowSnap.empty && rowSnap.docs.length === 1) {
                        const doc_id = rowSnap.docs[0].id;
                        await setDoc(doc(projectFirestore, "tables", tableDocId, "rows", doc_id), { 'values': newValuesObj }, { merge: true });
                    }
                }
            }
        } else {
            return false;
        }
    };

    const deleteTable = async (tableId) => {
        const tableQuery = query(
            collection(projectFirestore, "tables"),
            where('uid', '==', currentUser.uid),
            where("id", "==", tableId)
        );
        const snapshot = await getDocs(tableQuery);
        if (!snapshot.empty && snapshot.docs.length === 1) {
            const tableDocId = snapshot.docs[0].id;
    
            // Delete rows
            const rowsSnapshot = await getDocs(collection(projectFirestore, "tables", tableDocId, "rows"));
            if (!rowsSnapshot.empty) {
                for (const doc of rowsSnapshot.docs) {
                    await deleteDoc(doc(projectFirestore, "tables", tableDocId, "rows", doc.id));
                }
            }
    
            // Delete columns
            const columnsSnapshot = await getDocs(collection(projectFirestore, "tables", tableDocId, "columns"));
            if (!columnsSnapshot.empty) {
                for (const doc of columnsSnapshot.docs) {
                    await deleteDoc(doc(projectFirestore, "tables", tableDocId, "columns", doc.id));
                }
            }
    
            // Delete table document
            await deleteDoc(doc(projectFirestore, "tables", tableDocId));
        } else {
            return false;
        }
    };

    const deleteTableFromDocId = async (docId) => {
        const tableRef = doc(projectFirestore, "tables", docId);
        await deleteDoc(tableRef);
    };


return {
    getTablesFromCurrentUserInRealtime, 
    saveNewTable, 
    getTableFromCurrentUserInRealtime, 
    getTableRowsFromCurrentUserInRealtime, 
    getTableColumnsFromCurrentUserInRealtime, 
    addNewTableColumn, 
    renameTableColumn, 
    changeTableColumnsOrder, 
    saveChangesToTableRows, 
    saveChangesToTable,
    getTableFromCurrentUser, 
    getTableColumnsFromCurrentUser, 
    getTableColumnsFromPublicTable,
    getTableRowsFromCurrentUser, 
    getXRandomTableRowsFromCurrentUser,
    getXRandomTableRowsFromPublicTable,
    getTablesFromCurrentUser, 
    getTableAndContentFromCurrentUser,
    getAllTablesFromCurrentUserInRealtime, 
    deleteTable,
    deleteTableColumns, 
    renameTable, 
    deleteTableFromDocId, 
    getSpecificTableRow, 
    getPublicTable, 
    getTableRowsFromPublicTable
  }
}