import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import { useAuth } from "../../contexts/AuthContext";
import useDecks from '../../hooks/useDecks';
import useSearch from '../../hooks/useSearch';
import useFavorites from '../../hooks/useFavorites';
import useTextToSpeech from '../../hooks/useTextToSpeech';
import useQueryStrings from '../../hooks/useQueryStrings';
import useLanguages from '../../hooks/useLanguages';
import {useLanguagesContext} from '../../contexts/LanguagesContext';
import DuplicateDeckModal from '../../components/vocabulary/modals/DuplicateDeckModal.js'
import DeckItemCard from './DeckItemCard';
import Button from '../../components/general/Button';
import {useParams} from "react-router-dom";
import {faList} from '@fortawesome/pro-duotone-svg-icons';
import UserComment from '../../components/comments/UserComment';
import WriteComment from '../../components/comments/WriteComment';
import {faFloppyDisk} from '@fortawesome/pro-duotone-svg-icons';
import {faVideo} from '@fortawesome/pro-duotone-svg-icons';
import {faX} from '@fortawesome/pro-duotone-svg-icons';
import {faPlus} from '@fortawesome/pro-duotone-svg-icons';
import {Helmet} from 'react-helmet';
import {faPlay} from '@fortawesome/pro-duotone-svg-icons';
import {faHeart} from '@fortawesome/pro-thin-svg-icons';
import {faHeart as faHeartSolid} from '@fortawesome/pro-solid-svg-icons';
import {faGrid} from '@fortawesome/pro-duotone-svg-icons';
import ItemRating from '../../components/rating/ItemRating';
import {faAngleRight} from '@fortawesome/pro-solid-svg-icons';
import {faAngleLeft} from '@fortawesome/pro-solid-svg-icons';
import {faVolumeUp} from '@fortawesome/pro-duotone-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import CountryFlag from '../../components/languages/CountryFlag';
import ReactTooltip from 'react-tooltip';
import {motion} from 'framer-motion';
import {useUserInfoContext} from '../../contexts/UserInfoContext';
import DeckNotFound from '../../components/errors/DeckNotFound';
import Skeleton from 'react-loading-skeleton';
import Icon from '../../components/general/Icon';
import CommentSection from '../../components/comments/CommentSection';
import CustomSkeleton from '../../components/general/CustomSkeleton.js';

const PublicDeck = () => {
    let { id } = useParams();
    let deck_id = parseInt(id);

    let { currentUser } = useAuth();
    let { favorites } = useUserInfoContext();
    const {userLanguages} = useLanguagesContext();
    let {getQueryStrings, setQueryStrings} = useQueryStrings();
    let { fetchAllItemsAndMetadataInPublicDeck, 
        fetchAllItemsInPublicDeck,
        duplicateDeckToMyLibraryApi 
    } = useDecks();
    let {addOrRemoveDeckFavorite} = useFavorites();
    let {getAllLanguages} = useLanguages();
    let {allLanguages} = useLanguagesContext();

    const [languages, setLanguages] = useState(null);
    const {textToSpeechWithGoogle} = useTextToSpeech();
    const [deckItems, setDeckItems] = useState(null);
    const [deckItemsLoading, setDeckItemsLoading] = useState(false);
    const [lastVisibleItem, setLastVisibleItem] = useState(null);
    const [firstVisibleItem, setFirstVisibleItem] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [deckMetadata, setDeckMetadata] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showDescription, setShowDescription] = useState(true);

    const [deckAccessible, setDeckAccessible] = useState(true);

    const [showDuplicateDeckModal, setShowDuplicateDeckModal] = useState(false);
    const closeDuplicateDeckModal = () => {
        setShowDuplicateDeckModal(false);
    }

    const [currentAudioContent, setCurrentAudioContent] = useState(null);

    const [viewMode, setViewMode] = useState(null);
    const [showVideo, setShowVideo] = useState(false);

    const [currentFavorite, setCurrentFavorite] = useState(null);

    // individual loading 
    const [addToLibraryLoading, setAddToLibraryLoading] = useState(false);
    const [addToLibraryError, setAddToLibraryError] = useState(false);
    const [addToLibrarySuccess, setAddToLibrarySuccess] = useState(false);
    const [addToLibraryNewMetadata, setAddToLibraryNewMetadata] = useState(null);

    const [cardsLoading, setCardsLoading] = useState(true);
    const [formatMode, setFormatMode] = useState("typesense");
    const [queryStringsRead, setQueryStringsRead] = useState(false);

    const {searchCurrentUserDeckCards, 
        getScopedDeckCardsApiKey} = useSearch();

    let {fetchAllItemsInUserDeckRealtime, 
            fetchPaginatedItemsInUserDeck,
            fetchDeckFromCurrentUserRealtime, 
            fetchDeckCustomFieldsFromCurrentUserRealtime} = useDecks();

    useEffect(()=>{
        const fetchData = async () => {
            let [results, metadata, firstVisible, lastVisible] = await fetchAllItemsAndMetadataInPublicDeck(deck_id);
            if (results !== null){
                setLastVisibleItem(lastVisible);
                setFirstVisibleItem(firstVisible);
                setDeckItems(results);
                metadata = addMissingFields(metadata);
                setDeckMetadata(metadata);
                if (metadata.hasOwnProperty("youtube_id") && metadata["youtube_id"] !== "" && metadata["youtube_id"] !== null){
                    setShowVideo(true);
                }
                setLoading(false);
            } else {
                setDeckAccessible(false);
            }
        }

        const addMissingFields = (metadata) => {
            if (!('title' in metadata)){
                metadata['title'] = '';
            }
            if (!('description' in metadata)){
                metadata['description'] = '';
            }
            if (!('author' in metadata)){
                metadata['author'] = {};
                metadata['author']['username'] = '';
                metadata['author']['displayname'] = '';
            }

            return metadata;
        }

        const fetchAllLanguages = async () => {
            let langs = await getAllLanguages();
            setLanguages(langs);
        }

        const readQueryStrings = async () => {
            let qs = await getQueryStrings();
            //console.log("qs: ", qs);
            if ('view' in qs){
                if (qs['view'] === "grid" || qs['view'] === 'table'){
                    setViewMode(qs['view']);
                } else {
                    setViewMode("table");
                }
            } else {
                setViewMode("table");
            }
        }

        const fetchEverything = async () => {
            await readQueryStrings();
            await fetchData();
            await fetchAllLanguages();
        }

        if (loading && deck_id !== null) {
            fetchEverything();
        } else {
            ReactTooltip.rebuild();
        }
    },[loading, deck_id]);

    useEffect(()=>{
        if (favorites !== null && 'decks' in favorites[0] && deck_id in favorites[0]['decks']){
            setCurrentFavorite(true);
        } else {
            setCurrentFavorite(false);
        }
    },[favorites, deck_id])

    const changeViewModeClick = () => {
        if (viewMode === "grid"){
            setViewMode("table");
            setQueryStrings('table', 'view');
        } else {
            setViewMode("grid");
            setQueryStrings('grid', 'view');
        }  
    }

    const showDescriptionClick = () =>  {
        setShowDescription(!showDescription);
    }

    const toggleShowVideo =  () => {
        setShowVideo(!showVideo);
    }

    const addToMyLibraryClick = async (name) => {
        setAddToLibraryLoading(true);
        let r = await duplicateDeckToMyLibraryApi(deck_id, name);
        let er = false;
        if (r.hasOwnProperty(['data'])){
            if (r['data'].hasOwnProperty("success")){
                setAddToLibraryLoading(false);
                setAddToLibrarySuccess(true);
                setAddToLibraryNewMetadata(r['data']['newDeckData']);
            } else {
                er = true;
            }
        } else {
            er = true;
        }
        if (er){
            setAddToLibraryError(true);
        }
    }

    const nextPageClick = async () => {
        if (deckMetadata !== null){
            console.log("next")
            await fetchItems("next");
        }
    }

    const previousPageClick = async () => {
        if (deckMetadata !== null){
            await fetchItems("last");
        }
    }

    const fetchItems = async (type) => {
        setDeckItemsLoading(true);
        console.log("meta: ", deckMetadata.doc_id);
        let docStartingPoint = null;
        if (type === "next"){
            docStartingPoint = lastVisibleItem;
        } else {
            docStartingPoint =  firstVisibleItem;
        }
        let [results, firstVisible, lastVisible] = await fetchAllItemsInPublicDeck(deckMetadata['doc_id'], docStartingPoint, type);
        
        console.log("Results: ", results, firstVisible, lastVisible);
        if (results.length > 0 && firstVisible !== null && lastVisible !== null){
            setDeckItems(results);
            setFirstVisibleItem(firstVisible);
            setLastVisibleItem(lastVisible);
            window.scrollTo(0, 0);
            if (type === "next"){
                setCurrentPage(currentPage + 1);
            } else {
                if (currentPage > 0 ){
                    setCurrentPage(currentPage - 1);
                }
            }
        }
        setDeckItemsLoading(false);
    }

    const textToSpeechClick = async (text, langType) => {
/*         let audioContent = await textToSpeechWithGoogle();
        let objJsonB64 = Buffer.from(JSON.stringify(audioContent)).toString("base64");
        console.log(audioContent);
        console.log(objJsonB64);
        let audio = new Audio("data:audio/mp3;base64," + objJsonB64);
        console.log(audio);
        //audio.play(); */
    }

    const openDuplicationModal = () => {
        setShowDuplicateDeckModal(true);
    }
    
    const addToFavoritesClick = async () => {
        await addOrRemoveDeckFavorite(deckMetadata);
    }

    console.log(deckMetadata);
    
    return (
        <>
            <ReactTooltip id="public-deck-tooltip" place="bottom" effect="solid" />
            {deckMetadata !== null &&
                <Helmet>
                    <title>{(deckMetadata.title !== "" && deckMetadata.title !== null) ? deckMetadata.title : deckMetadata.name}</title>
                    <meta name="description" content={deckMetadata.description !== "" && deckMetadata.description} />
                </Helmet>
            }
            <div className=" ">
                <div className="text-left dark:dark-mode-muted-text text-xs text-gray-600 ">
                    <Link to="/decks">
                        Back to explore
                    </Link>
                </div>
            <div className="py-5 min-h-screen relative flex flex-col gap-4">
                <div className="flex flex-row gap-4 place-items-center justify-center">
                    <div className="font-bold text-xl text-center text-gray-600 dark:text-white">
                        {loading ? <CustomSkeleton className="w-72" height={30} /> : (deckMetadata.title !== "" && deckMetadata.title !== null) ? deckMetadata.title : deckMetadata.name}
                    </div>
                    <div>
                        {loading ?
                            <>
                                <CustomSkeleton height={30} />
                            </>
                        :
                            <>
                                {(currentUser !== null && currentUser.uid !== null && currentUser.uid !== deckMetadata.uid && currentFavorite !== null) &&
                                    <div className="text-2xl" onClick={addToFavoritesClick}>
                                        <Icon data-for="public-deck-tooltip" data-tip="Add to favorites" color="red" icon={<FontAwesomeIcon icon={currentFavorite ? faHeartSolid : faHeart} />} />
                                    </div>
                                }
                            </>
                        }
                    </div>
                </div>
                {!loading  &&
                    <div className="flex flex-col place-items-center gap-2">
                        {deckMetadata.description !== "" && deckMetadata.description !== null &&
                            <div className="italic text-center text-sm">
                                "{deckMetadata.description}"
                            </div>
                        }
                        <div className="text-xs">
                            Created by {(deckMetadata['author']['username'] !== null && deckMetadata['author']['username'] !== "" && deckMetadata['author']['displayname'] !== null && deckMetadata['author']['displayname'] !== "") ? <><Link to={"/u/"+deckMetadata['author']['username']}>{deckMetadata['author']['displayname']}</Link></> : 'Unknown user'}  
                        </div>
                    </div>
                }  
                
                {(!loading && deckMetadata.number_of_items === 0) &&
                        <>
                            <div className="text-center italic my-4">
                                This deck is empty.
                            </div>
                            <div className="text-center">
                                <Link to="/decks?target=all&source=all">
                                    <Button text="Browse all public decks" />
                                </Link>
                            </div>
                        </>
                }
                <div className="w-5/6 m-auto flex flex-row justify-center place-items-center">
                    <div className=" flex flex-row gap-10 sm:gap-12 justify-center mt-4 text-2xl sm:text-3xl">
                        {loading ? 
                            <>
                                {[...new Array(3)].map((item, itemIndex)=>(
                                    <>
                                        <div key={"button_"+itemIndex} className="w-32" >
                                            <CustomSkeleton height={50} />
                                        </div>
                                    </>
                                ))}
                            </>
                        :
                            <>
                                <div className="text-sm font-normal">
                                    <ItemRating itemType={"deck"} item={deckMetadata} />
                                </div>
                                {(currentUser !== null && currentUser.uid !== null && currentUser.uid !== deckMetadata.uid && userLanguages !== null && userLanguages !== undefined && userLanguages.find(lang=>lang["target_language"] === deckMetadata["target_ISO_639-1"])) &&
                                    <div onClick={openDuplicationModal}>
                                        <Icon data-for="public-deck-tooltip" data-tip="Save to my decks" color="charcoal" icon={<FontAwesomeIcon icon={faFloppyDisk} />} />
                                    </div>
                                }
                                {deckMetadata.hasOwnProperty("youtube_id") && deckMetadata.youtube_id !== null && deckMetadata.youtube_id !== "" &&
                                    <>
                                        <Icon data-for="public-deck-tooltip" data-tip="Toggle video" color="blue" icon={<FontAwesomeIcon icon={showVideo ? faVideo : faVideo} />} onClick={toggleShowVideo} />
                                    </>
                                }
                                <div>
                                    <Icon data-for="public-deck-tooltip" data-tip="Toggle view" color="red" icon={<FontAwesomeIcon icon={viewMode==='grid' ? faList : faGrid} />} onClick={changeViewModeClick} />
                                </div>
                                <div>
                                    <Link data-for="public-deck-tooltip" data-tip="Play with this deck" to={"https://yalango.com/decks/"+deck_id+"/play?game=choose&returnUrl=publicDashboardDeck&n=20"}><Icon color="green" icon={<FontAwesomeIcon icon={faPlay} />} /></Link>
                                </div>
                            </>
                        }
                    </div>
                </div>     
                {showVideo &&
                    <div className="flex flex-row justify-center my-4 h-48 sm:h-96 md:h-112 lg:h-136">
                        <div className="rounded-2xl overflow-hidden shadow-2xl w-full sm:w-5/6 m-auto h-full">
                            <iframe className="aspect-video w-full h-full" src={"https://www.youtube-nocookie.com/embed/"+deckMetadata.youtube_id} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>  
                }
                {viewMode === "grid" && (
  <>
    <div className={`${loading ? 'opacity-100' : ''} p-6 rounded-xl`}>
      {/* Header */}
      <div className="flex flex-row justify-between place-items-center text-gray-700 dark:text-gray-300 font-bold text-sm mb-4">
        {/* Total Cards */}
        <div>
          Total cards:{' '}
          {deckMetadata === null ? (
            <Skeleton height={10} baseColor="#e0e0e0" highlightColor="#f5f5f5" />
          ) : (
            deckMetadata.number_of_items
          )}
        </div>

        {/* Current Page */}
        <div>
          Page{' '}
          {deckMetadata === null ? (
            <Skeleton height={10} baseColor="#e0e0e0" highlightColor="#f5f5f5" />
          ) : (
            <>
              {currentPage}/{Math.ceil(deckMetadata.number_of_items / 24)}
            </>
          )}
        </div>
      </div>

      {/* Grid of Deck Items */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 text-center gap-4">
        {!loading &&
          deckItems.map((item, index) => (
            <DeckItemCard key={`deck_${index}`} item={item} />
          ))}

        {loading &&
          [...new Array(25)].map((_, index) => (
            <CustomSkeleton
              key={`skeleton_${index}`}
              height={250}
              baseColor="#e0e0e0"
              highlightColor="#f5f5f5"
              className="dark:baseColor:bg-gray-700 dark:highlightColor:bg-gray-600 rounded-lg"
            />
          ))}
      </div>
    </div>
  </>
)}

{viewMode === "table" && (
  <>
    <div className="py-6">
      <table className="m-auto text-center w-full sm:w-5/6 table-auto border-collapse">
        <thead>
          <tr className="text-center font-bold">
            {!loading && allLanguages !== null && (
              <>
                {/* Target Language Header */}
                <th className="bg-persian-green dark:dark-mode-background-color text-white border px-8 py-4">
                  <div className="flex flex-row place-items-center gap-2 justify-center">
                    <CountryFlag
                      flagName={allLanguages[0][deckMetadata['target_ISO_639-1']]['name']['en']}
                      countryCode={allLanguages[0][deckMetadata['target_ISO_639-1']]['country_flag']}
                      size="2em"
                    />
                    <div>
                      {allLanguages[0][deckMetadata['target_ISO_639-1']]['name']['en']}{' '}
                      {deckMetadata.target_script && (
                        <>({deckMetadata['target_script']})</>
                      )}
                    </div>
                  </div>
                </th>

                {/* Source Language Header */}
                <th className="bg-persian-green dark:dark-mode-background-color text-white border px-8 py-4">
                  <div className="flex flex-row place-items-center gap-2 justify-center">
                    <CountryFlag
                      flagName={allLanguages[0][deckMetadata['source_ISO_639-1']]['name']['en']}
                      countryCode={allLanguages[0][deckMetadata['source_ISO_639-1']]['country_flag']}
                      size="2em"
                    />
                    <div>
                      {allLanguages[0][deckMetadata['source_ISO_639-1']]['name']['en']}{' '}
                      {deckMetadata.source_script && (
                        <>({deckMetadata['source_script']})</>
                      )}
                    </div>
                  </div>
                </th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {!loading &&
            deckItems.map((item, index) => (
              <tr key={`item_${index}`} className="">
                {/* Target Cell */}
                <td className="border py-4 sm:px-8 sm:py-4">
                  <div className="bg-white dark:bg-gray-800 rounded-xl py-4">
                    <div className="flex gap-3 flex-row justify-center text-gray-700 dark:text-gray-300">
                      <div>{item.target}</div>
                    </div>
                  </div>
                </td>

                {/* Source Cell */}
                <td className="border py-4 sm:px-8 sm:py-4">
                  <div className="bg-white dark:bg-gray-800 rounded-xl py-4">
                    <div className="flex gap-3 flex-row justify-center text-gray-700 dark:text-gray-300">
                      <div>{item.source}</div>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  </>
)}

                <div className="flex w-5/6 m-auto flex-row justify-between gap-4 mb-3 text-3xl">
                    <div onClick={previousPageClick} className="cursor-pointer">
                        {currentPage !== 1 &&
                            <>
                                <div className="flex flex-row place-items-center gap-2">
                                    <FontAwesomeIcon icon={faAngleLeft} /> 
                                    <div className="text-lg">
                                        Previous
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    
                    <div onClick={nextPageClick} className="cursor-pointer">
                        {!loading &&
                            <>
                                {(currentPage !== Math.ceil(deckMetadata.number_of_items/24)) &&
                                    <>
                                        <div className="flex flex-row place-items-center gap-2">
                                            <div className="text-lg">
                                                Next
                                            </div>
                                            <FontAwesomeIcon icon={faAngleRight} /> 
                                        </div>
                                    </>
                                }
                            </>
                        }
                    </div>
                </div>

            {!deckAccessible &&
                <DeckNotFound />
            }
            </div>
            </div>
            {showDuplicateDeckModal &&
                <>
                    <DuplicateDeckModal deck={deckMetadata} error={addToLibraryError} newDeck={addToLibraryNewMetadata} success={addToLibrarySuccess} saving={addToLibraryLoading} saveFunction={addToMyLibraryClick} closeFunction={closeDuplicateDeckModal} />
                </>
            }
            
        </>
    )
}

export default PublicDeck;
