import React, {useEffect} from 'react';
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from 'react-router-dom';


const LogOutComponent = () => {
    const { logout } = useAuth();
    const navigate = useNavigate();

    useEffect(()=>{
        logout();
        console.log('Logging out...');
        navigate('/login');
    }, [logout, navigate])
    
    return null;
};

export default LogOutComponent;
