import React, {useEffect, useState, useCallback} from 'react';
import {useParams, useNavigate} from "react-router-dom";
import useTables from '../../../hooks/useTables';
import useDebounce from '../../../hooks/useDebounce';
import Button from '../../../components/general/Button';
import isHotkey from 'is-hotkey';
import {motion} from 'framer-motion';
import AddTableColumnModal from '../../../components/tables/modals/AddTableColumnModal';
import TableColumnOrderModal from '../../../components/tables/modals/TableColumnOrderModal';
import DeleteTableModal from '../../../components/tables/modals/DeleteTableModal';
import DeleteTableColumnsModal from '../../../components/tables/modals/DeleteTableColumnsModal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLoader} from '@fortawesome/pro-solid-svg-icons';
import {faEllipsisVertical} from '@fortawesome/pro-solid-svg-icons';
import {faCheck} from '@fortawesome/pro-solid-svg-icons';
import {faPlay} from '@fortawesome/pro-solid-svg-icons';
import {faPlus} from '@fortawesome/pro-solid-svg-icons';
import {faTrash} from '@fortawesome/pro-solid-svg-icons';
import {faFloppyDisk} from '@fortawesome/pro-light-svg-icons';
import {faSquareX} from '@fortawesome/pro-duotone-svg-icons';
import {faTableRows} from '@fortawesome/pro-light-svg-icons';
import {faTableColumns} from '@fortawesome/pro-light-svg-icons';
import {DebounceInput} from 'react-debounce-input';
import {faSort} from '@fortawesome/pro-solid-svg-icons';
import {Link} from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { faLock } from '@fortawesome/pro-duotone-svg-icons';
import { faGlobe } from '@fortawesome/pro-duotone-svg-icons';
import { faGrid2Plus } from '@fortawesome/pro-solid-svg-icons';
import Icon from '../../../components/general/Icon';
import Skeleton from 'react-loading-skeleton';

const MyTable = () => {
    let { id } = useParams();
    const navigate = useNavigate();
    id = parseInt(id);

    const [table, setTable] = useState(null);
    const [tableRows, setTableRows] = useState(null);
    const [tableColumns, setTableColumns] = useState(null);
    const [tableLoading, setTableLoading] = useState(true);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [renameTableLoading, setRenameTableLoading] = useState(false);

    const [showTableMenu, setShowTableMenu] = useState(false);
    const [showDeleteTableModal, setShowDeleteTableModal] = useState(false);
    const [showDeleteTableColumnModal, setShowDeleteTableColumnModal] = useState(false);
    const [showPrivacyBox, setShowPrivacyBox] = useState(false);

    const [columnsToDelete, setColumnsToDelete] = useState([]);
    const [showRenameTableNameInput, setShowRenameTableNameInput] = useState(true);
    const [renameTableNameInputValue, setRenameTableNameInputValue] = useState("");

    const [currentlyFocusedCell, setCurrentlyFocusedCell] = useState([null, null]); //[0, 0], [0, 1] etc.

    // new column 
    const [newColumnSaving, setNewColumnSaving] = useState(false);
    const [showNewColumnModal, setShowNewColumnModal] = useState(false);
    const [newColumnName, setNewColumnName] = useState("");

    // rename column
    const [editColumn, setEditColumn] = useState(null);
    const [editColumnName, setEditColumnName] = useState("");
    const [editColumnNameSaving, setEditColumnNameSaving] = useState(false);
    const [editColumnNameError, setEditColumnNameError] = useState(false);
    const [editColumnNameErrorMsg, setEditColumnNameErrorMsg] = useState("");

    // column order
    const [showColumnOrderModal, setShowColumnOrderModal] = useState(false);
    const [columnOrderSaving, setColumnOrderSaving] = useState(false);
    const [newColumnOrder, setNewColumnOrder] = useState(null);

    // rows
    const [originalTableRows, setOriginalTableRows] = useState(null);
    const [savedOriginalTableRows, setSavedOriginalTableRows] = useState(false);
    const [rowsSaving, setRowsSaving] = useState(false);
    const [rowsChanged, setRowsChanged] = useState(false);

    const saveAllChanges = async () => {
        let rowsCopy = JSON.parse(JSON.stringify(tableRows));
        saveRowChangesClick(rowsCopy);
        if ('name' in table){
            saveRenameTableChanges(table.name, table);
        }
    }

    const HOTKEYS = {
        'mod+s': {'type': 'function', 'function': saveAllChanges}
      }

    let {getTableFromCurrentUserInRealtime, 
        getTableFromCurrentUser,
        getTableRowsFromCurrentUserInRealtime, 
    getTableRowsFromCurrentUser,
    getTableColumnsFromCurrentUserInRealtime, 
    getTableColumnsFromCurrentUser,
    addNewTableColumn, 
    renameTableColumn, 
    changeTableColumnsOrder, 
    saveChangesToTableRows, 
    saveChangesToTable,
    deleteTable, 
    deleteTableColumns, 
    renameTable} =  useTables();

    const {debounce} = useDebounce();

    useEffect(()=>{
        let unsub = null;
        const fetchData = async () => {
            unsub = await getTableFromCurrentUserInRealtime(id, setTable);
        }
        fetchData();

        return () => {
            //(unsub !== null && unsub !== false) && unsub();
        }
    },[id]);

    useEffect(()=> {
        const fetchData = async () => {
            let r_col = await getTableColumnsFromCurrentUser(table);
            setTableColumns(r_col);
            let r = await getTableRowsFromCurrentUser(table);
            setTableRows(r);
        }
        if (table !== null && 'doc_id' in table){
            fetchData();
            //getTableRowsFromCurrentUserInRealtime(table['doc_id'], table['row_order'], setTableRows);
            //getTableColumnsFromCurrentUserInRealtime(table, setTableColumns);
            setRenameTableNameInputValue(table.name);
        }
    },[table]);

    useEffect(()=> {
        if (table !== null && tableColumns !== null && tableRows !== null){
            setTableLoading(false);
        }
    },[table, tableColumns, tableRows]);

    useEffect(()=>{
        if (tableRows !== null && savedOriginalTableRows === false){
            let clone = JSON.parse(JSON.stringify(tableRows));
            setOriginalTableRows(clone);
            setSavedOriginalTableRows(true);
        }
    },[tableRows, savedOriginalTableRows]);

    useEffect(()=>{
        if (tableColumns !== null){
            setNewColumnOrder(tableColumns);
        }
    },[tableColumns])

    const hideNewTableColumnModal = () => {
        setShowNewColumnModal(false);
        setNewColumnName("");
    }

    const saveNewTableColumn = async () => {
        setNewColumnSaving(true);
        await addNewTableColumn(table['doc_id'], newColumnName);
        await refreshTableColumns();
        setNewColumnSaving(false);
        hideNewTableColumnModal();
    }

    const columnHeaderClick = async (col) => {
        if (editColumn !== null){
            await saveNewColumnName();
        }
        setEditColumn(col);
    }

    useEffect(()=>{
        if (editColumn !== null && tableColumns){
            tableColumns.forEach((col)=>{
                if (col.id === editColumn.id){
                    editColumnName !== col.name && setEditColumnName(col.name);
                }
            })
            
        }
    },[editColumn, tableColumns]);

    const saveNewColumnName = async (name, column) => {
        let n = name.toString().trim();
        tableColumns.forEach((col)=>{
            if (col.id === column.id){
                if (n === col.name){
                    return;  // no changes made
                }
            }
        });
        if (n !== ""){
            setEditColumnNameSaving(true);
            let r = await renameTableColumn(table['doc_id'], column['doc_id'], n);
            await refreshTableColumns();
            setEditColumnNameSaving(false);
        } else {
            setEditColumnNameError(true);
            setEditColumnNameErrorMsg("Name cannot be blank.");
        }
    }

    const editColumnNameChange = (e, col) => {
        setEditColumnName(e.target.value, col);
        if (e.target.value === ""){
            if (!editColumnNameError){
                setEditColumnNameError(true);
                setEditColumnNameErrorMsg("Name cannot be blank.");
            }
        } else {
            if (editColumnNameError){
                setEditColumnNameError(false);
                setEditColumnNameErrorMsg("");
            } else {
                saveNewColumnName(e.target.value, col);
            }
        }
    }

    const hideChangeOrderModal = () => {
        setShowColumnOrderModal(false);
        setNewColumnOrder(tableColumns);
    }

    const changeColOrderClick = () => {
        setShowColumnOrderModal(true);
    }

    const columnOrderOnDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(newColumnOrder);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setNewColumnOrder(items);
    }

    const saveNewColumnOrderClick = async (data) => {
        setColumnOrderSaving(true);
        let l = [];
        if (data !== undefined && 'order' in data){
            if (data['order'] !== undefined){
                data['order'].forEach((col, index)=>{
                    if ('id' in col){
                        l.push(col.id);
                    }
                });
            }
        }
        else {
            newColumnOrder.forEach((col, index)=>{
                l.push(col.id);
            });
        }
        await changeTableColumnsOrder(table, l);
        await refreshTable();
        hideChangeOrderModal();
        setColumnOrderSaving(false);
    }

    const addRowClick = () => {
        let row_id = Math.floor(Math.random()*1000000);
        let unique = false;
        if (tableRows !== null){
            while (!unique){
                let found = false;
                row_id = Math.floor(Math.random()*1000000);
                for (const row of tableRows){
                    if (row.id === row_id){
                        found = true;
                    }
                }
                if (found){
                    unique = false;
                } else {
                    unique = true;
                }
            }
        }
        let row = {'values': {}, 'id': row_id};
        tableColumns.forEach((column)=>{
            row.values[column.id] = '';
        });
        setTableRows([...tableRows, row]);
        saveRowChangesClick([...tableRows, row]);
    }

    const rowInputOnChange = async (text, colId, rowIndex) => {
        let copy = JSON.parse(JSON.stringify(tableRows));

        copy[rowIndex]['values'][colId] = text;
        setTableRows(copy);
        saveRowChangesClick(copy);
    }

    const privacyOptionChanged = async (e) => {
        console.log(e.target.value);
        let newPrivacyOption = e.target.value;
        await saveChangesToTable({privacy: newPrivacyOption, tableId: id})
    }



    useEffect(()=>{
        if (tableRows !== null && originalTableRows !== null){
            let identical = checkIfTableRowsAreIdentical();
            //console.log("Identical: ", identical, tableRows, originalTableRows);
            if (!identical){
                !rowsChanged && setRowsChanged(true);
            } else {
                rowsChanged && setRowsChanged(false);
            }
        }
    },[tableRows, originalTableRows, rowsChanged])

    const checkIfTableRowsAreIdentical = (copy)=> {
        let identical = true;
        // check if changes
        if (copy !== undefined){
            if (copy.length !== originalTableRows.length){
                identical = false;
            }
        } else {
            if (tableRows.length !== originalTableRows.length){
                identical = false;
            }
        }
        if (identical){
            if (copy !== undefined){
                copy.forEach((row, index)=>{
                    if (!('id' in row)){
                        identical = false;
                    }
                    else if (row.id !== originalTableRows[index].id){
                        identical = false;
                    }
                    else {
                        for (const [key, value] of Object.entries(row['values'])){
                            if (!(key in originalTableRows[index]['values'])){
                                identical = false;
                            } else {
                                if (originalTableRows[index]['values'][key] !== value){
                                    identical = false;
                                }
                            }
                        } 
                    }
                });
            }
            else {
                tableRows.forEach((row, index)=>{
                    if (!('id' in row)){
                        identical = false;
                    }
                    else if (row.id !== originalTableRows[index].id){
                        identical = false;
                    }
                    else {
                        for (const [key, value] of Object.entries(row['values'])){
                            if (!(key in originalTableRows[index]['values'])){
                                identical = false;
                            } else {
                                if (originalTableRows[index]['values'][key] !== value){
                                    identical = false;
                                }
                            }
                        } 
                    }
                });
            }
        }
        return identical;
    }

    const saveRowChangesClick = async (copy) => {
        setRowsSaving(true);
        let identical = await checkIfTableRowsAreIdentical(copy);
        if (!identical){
            let success = null;
            let updatedRows = null;
            if (copy !== undefined){
                updatedRows = await saveChangesToTableRows(table, originalTableRows, copy);
            } else {
                updatedRows = await saveChangesToTableRows(table, originalTableRows, tableRows);
            }
            if (updatedRows !== null){
                setSavedOriginalTableRows(false);
/*                 if ('data' in updatedRows){
                    setTableRows(updatedRows['data']);
                } */
            }      
        }
        setRowsSaving(false);
    }

    const deleteTableRow = (index) => {
        let copy = JSON.parse(JSON.stringify(tableRows));
        copy.splice(index, 1);
        setTableRows(copy);
        saveRowChangesClick(copy);
    }

    const onRowDragEnd = (result) => {
        if (!result.destination) return;
        if (!result.source) return;
        const {destination, source} = result;
        if (destination.droppableId === source.droppableId){
            if (destination.droppableId === "table"){
                const copy = JSON.parse(JSON.stringify(tableRows));
                let element = copy[source.index];
                let start_index = source.index;
                let end_index = destination.index;
                copy.splice(start_index, 1);
                copy.splice(end_index, 0, element);
                setTableRows(copy);
                saveRowChangesClick(copy);

            } else if (destination.droppableId === "table_columns"){
                const copy = JSON.parse(JSON.stringify(tableColumns));
                let element = copy[source.index];
                let start_index = source.index;
                let end_index = destination.index;
                copy.splice(start_index, 1);
                copy.splice(end_index, 0, element);
                saveNewColumnOrderClick({'order': copy});
                setNewColumnOrder(copy);
                setTableColumns(copy);  
            }
        }
    }

    const addNewColumnMenuClick = () => {
        setShowNewColumnModal(true);
        setShowTableMenu(false);
    }

    const addNewRowMenuClick = () => {
        addRowClick();
        setShowTableMenu(false);
    }

    const changeColumnOrderClick = () => {
        setShowColumnOrderModal(true);
        setShowTableMenu(false);
    }

    const deleteTableMenuClick = () => {
        setShowDeleteTableModal(true);
        setShowTableMenu(false);
    } 

    const deleteTableColumnMenuClick = () => {
        setShowDeleteTableColumnModal(true);
        setShowTableMenu(false);
    }

    const closeDeleteTableColumnMenu = () => {
        setShowDeleteTableColumnModal(false);
        setColumnsToDelete([]);
    }

    const deleteTableClick = async () => {
        setDeleteLoading(true);
        await deleteTable(table['id']);
        navigate("/my-tables");
        setDeleteLoading(false);
        setShowDeleteTableModal(false);
    }

    const closeDeleteTableModal = () => {
        setShowDeleteTableModal(false);
    }

    const generalBackgroundClick = (e) => {
        setShowTableMenu(false);
    }

    const tableMenuClick = (e) => {
        e.stopPropagation();
        setShowTableMenu(!showTableMenu);
    }

    const deleteColumns = async () => {
        setDeleteLoading(true);
        await deleteTableColumns(table.id, columnsToDelete, tableRows);
        await refreshTableColumns();
        setDeleteLoading(false);
        closeDeleteTableColumnMenu();
    }
    
    const refreshTableColumns = async () => {
        let r_col = await getTableColumnsFromCurrentUser(table);
        setTableColumns(r_col);
    }

    const refreshTable = async () => {
        let r_table = await getTableFromCurrentUser(id);
        setTable(r_table);
    }

    const renameTableNameClick = () => {
        setShowRenameTableNameInput(true);
    }

    const renameTableNameOnInput = (e) => {
        setRenameTableNameInputValue(e.target.value);
        saveRenameTableChanges(e.target.value, table);
    }

    const saveRenameTableChanges = async (name, table) => {
        if (table !== null && name !== null && name !== ""){
            setRenameTableLoading(true);
            await renameTable(table.doc_id, name);
            //await saveChangesToTable({tableName: name, tableId: id});
            setRenameTableLoading(false);
        }
    }

    
    const togglePrivacyBox = () => {
        setShowPrivacyBox(!showPrivacyBox);
    }

    const tableDescriptionOnInput = async (e) => {
        await saveChangesToTable({description: e.target.value.trim(), tableId: id})
    }

    const onKeyDown = (event) => {
        for (const hotkey in HOTKEYS) {
            if (isHotkey(hotkey, event)) {
              event.preventDefault();
              const command = HOTKEYS[hotkey];
              if (command.type === "function"){
                command.function();
              }
            }
        }
        checkOtherKeys(event);
    }

    const checkOtherKeys = (e) => {
        // SPACE 
        if(e.keyCode === 32){
              
        }
        // DOWN ARROW 
        else if (e.keyCode === 40){
            console.log("down arrow")
        }
        // LEFT ARROW
        else if (e.keyCode === 37){
            
        }
        // RIGHT ARROW - CORRECT
        else if (e.keyCode === 39){
            
        }
    }

    return (
        <>
            <ReactTooltip place="bottom" id="table-menu-tooltip" effect="solid" />
            <div onKeyDown={onKeyDown} onClick={generalBackgroundClick}  className="  overflow-x-auto min-h-screen h-full">
            {table !== null &&
                    <>
                    <div className="relative flex flex-row justify-end">
                            <div className="">
                                <div className="cursor-pointer text-lg p-0" onClick={tableMenuClick} >
                                    <FontAwesomeIcon icon={faEllipsisVertical} />
                                </div>
                                {showTableMenu &&
                                <>
                                <div className="absolute z-10 whitespace-nowrap top-6 right-2 p-4 bg-white rounded-lg shadow-xl flex cursor-pointer flex-col gap-3">
                                
                                    <motion.div onClick={addNewRowMenuClick} whileHover={{scale:1.02}} className="flex flex-row gap-3 px-2">
                                        <div className="text-charcoal">
                                            <FontAwesomeIcon icon={faTableRows} />
                                        </div> 
                                        <div>
                                            Add new row
                                        </div>
                                    </motion.div>
                                    <motion.div onClick={addNewColumnMenuClick} whileHover={{scale:1.02}} className="flex flex-row gap-3 px-2">
                                        <div className="text-charcoal">
                                            <FontAwesomeIcon icon={faTableColumns} />
                                        </div> 
                                        <div>
                                            Add new column
                                        </div>
                                    </motion.div>
                                    <motion.div onClick={changeColumnOrderClick} whileHover={{scale:1.02}} className="flex flex-row gap-3 px-2">
                                        <div className="text-charcoal">
                                            <FontAwesomeIcon icon={faTableColumns} />
                                        </div> 
                                        <div>
                                            Change column order
                                        </div>
                                    </motion.div>
                                    <motion.div onClick={deleteTableColumnMenuClick} whileHover={{scale:1.02}} className="flex flex-row gap-3 px-2">
                                        <div className="text-burnt-sienna">
                                            <FontAwesomeIcon icon={faTableColumns} />
                                        </div> 
                                        <div>
                                            Delete columns
                                        </div>
                                    </motion.div>
                                    <motion.div onClick={deleteTableMenuClick} whileHover={{scale:1.02}} className="flex flex-row gap-3 px-2">
                                        <div className="text-burnt-sienna">
                                            <FontAwesomeIcon icon={faTrash} />
                                        </div> 
                                        <div>
                                            Delete table
                                        </div>
                                    </motion.div>
                                </div>
                                </>
                                }
                                </div>
                            </div>
                    
                    </>
                }
                <div className="flex flex-col gap-4">
                
                <div className="pl-2 flex w-full flex-row justify-start place-items-center">
                    <div className="flex flex-row gap-4 justify-start place-items-start">
                        <div className="text-xl relative">
                            {table !== null &&
                                <>
                                    <div onClick={togglePrivacyBox}>
                                        {(table.privacy === "private") && 
                                            <Icon data-place="left" data-for="card-menu-tooltip"  icon={<FontAwesomeIcon icon={faLock} />} color="charcoal" hover="none" cursor="pointer" /> 
                                        }
                                        {(table.privacy === "public") && 
                                            <Icon data-place="left" data-for="card-menu-tooltip"  icon={<FontAwesomeIcon icon={faGlobe} />} color="charcoal" hover="none" cursor="pointer" /> 
                                        }
                                    </div>
                                    {showPrivacyBox &&
                                        <>
                                        <div className="absolute left-0 top-8 bg-white rounded-xl w-48 shadow-xl p-5 z-20">
                                            <fieldset>
                                                <div className="flex flex-col gap-3">
                                                    <div>
                                                        <div className="flex flex-row gap-2 font-bold text-base">
                                                            <input onChange={privacyOptionChanged} type="radio" id="private" value="private" checked={table.privacy === "private" ? true : false} />
                                                            <label htmlFor="private">Private</label>
                                                        </div>
                                                        <div className="text-xs muted-text">
                                                            Only you can see this table.
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="flex flex-row gap-2 font-bold text-base">
                                                            <input onChange={privacyOptionChanged} type="radio" id="public" value="public" checked={table.privacy === "public" ? true : false} />
                                                            <label htmlFor="public">Public</label>
                                                        </div>
                                                        <div className="text-xs muted-text">
                                                                Everyone can find and study your table.
                                                            </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <div onClick={togglePrivacyBox} className="z-10 w-screen h-screen fixed left-0 top-0 ">

                                        </div>
                                        </>
                                    }
                                </>
                            }
                            {table === null &&
                                <>
                                    <Skeleton count={1} height={30} />
                                </>
                            }
                        </div>
                        <div className="">
                            <div className="font-bold muted-text text-lg">
                            {table !== null ? 
                            <DebounceInput
                                className={" text-left hover:underline bg-transparent font-semibold border-none outline-none"}
                                minLength={1}
                                value={table.name}
                                debounceTimeout={1000}
                                onChange={event => renameTableNameOnInput(event)} 
                            /> : 
                            <Skeleton count={1} height={30} />}
                            </div>
                            
                        </div>
                    </div>
                    
                </div>
                <div className="pl-2 muted-text text-sm w-full">
                    {table !== null ? 
                    <DebounceInput
                        className={" text-left w-full hover:underline bg-transparent italic border-none outline-none"}
                        minLength={1}
                        value={table.hasOwnProperty("description") ? table.description : ""}
                        placeholder={"No description added yet"}
                        debounceTimeout={1000}
                        onChange={event => tableDescriptionOnInput(event)} 
                    /> : 
                    <Skeleton count={1} height={20} />}
                </div>
                </div>
                
                <div className="">
                    {table !== null &&
                    <div className="flex flex-row gap-3 md:gap-6 justify-center">
                        <div className="flex flex-row justify-center">
                            <Button text="Add column" onClick={addNewColumnMenuClick} size="normal" icon={faPlus} color="charcoal" />
                        </div>
                        <Link to={"/my-tables/"+id+"/play"} className="no-underline">
                            <Button text="Play" size="normal" icon={faPlay} color="green" />
                        </Link>
                    </div>
                    }
                    {!tableLoading &&
                        <>
                            <div className="">
                            
                            <div className="flex w-full flex-row gap-2 p-4 justify-center place-items-center">
                                <div className="w-full overflow-x-auto m-auto">
                                    <div className="  p-4">
                                    <DragDropContext onDragEnd={onRowDragEnd}>
                                        
                                        <table className={(columnOrderSaving || rowsSaving ? '' : '')+ "w-full  border-collapse "}>
                                            <Droppable type="column" droppableId="table_columns" direction="horizontal">
                                                {(provided, snapshot) => (
                                                    <>
                                                        <thead {...provided.droppableProps} ref={provided.innerRef}  className=" text-white font-bold ">
                                                            <tr>
                                                                {tableColumns.map((col, index)=>(
                                                                    <>
                                                                        <Draggable
                                                                        draggableId={col.id.toString()}
                                                                        index={index}
                                                                        key={col.id.toString()}
                                                                        >
                                                                            {(provided, snapshot) => (
                                                                                <th ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}  key={"head_"+index} tabIndex="-1" className="p-3 min-w-36 py-4 ">
                                                                                    <div  className="relative w-full">
                                                                                        
                                                                                            <div className="flex w-full p-2 rounded-lg shadow-xl bg-persian-green flex-row justify-center place-items-center ">
                                                                                                <div>
                                                                                                    <DebounceInput
                                                                                                        className={(editColumnNameError === true ? 'border-red-500 border-solid border-2 ' : '' ) +"text-white bg-transparent w-full p-2  outline-none font-bold hover:underline decoration-dashed whitespace-nowrap text-center"}
                                                                                                        minLength={0}
                                                                                                        value={col.name}
                                                                                                        
                                                                                                        debounceTimeout={3000}
                                                                                                        onChange={(e)=>editColumnNameChange(e, col)} 
                                                                                                    />
                                                                                                    {editColumnNameError === true &&
                                                                                                        <>
                                                                                                            <div className="italic text-xs text-red-400">
                                                                                                                {editColumnNameErrorMsg}
                                                                                                            </div>
                                                                                                        </>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                    
                                                                                    </div> 
                                                                                </th>
                                                                            )}
                                                                        </Draggable>
                                                                    </>
                                                                ))}
                                                                {provided.placeholder}
                                                            </tr>
                                                          
                                                        </thead>
                                                    </>
                                                )}
                                            </Droppable>
                                            <Droppable type="row" droppableId="table">
                                                {(provided, snapshot) => (
                                                    <>
                                                        <tbody {...provided.droppableProps} ref={provided.innerRef} className=" ">
                                                            {tableRows.map((row, rowIndex)=>(
                                                                <>
                                                                    <Draggable
                                                                    draggableId={row.id.toString()}
                                                                    index={rowIndex}
                                                                    key={row.id.toString()}
                                                                    
                                                                    >
                                                                    {(provided, snapshot) => (
                                                                        <tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} tabIndex="-1" className="w-full ">
                                                                            {tableColumns.map((col, colIndex)=>(
                                                                                <>
                                                                                    <td className="p-5 relative whitespace-nowrap ">
                                                                                        {colIndex === 0 &&
                                                                                            <div onClick={()=>deleteTableRow(rowIndex)} className="absolute top-0 left-0  p-1 rounded-lg text-red-500 text-lg cursor-pointer">
                                                                                                <FontAwesomeIcon icon={faSquareX} />
                                                                                            </div>
                                                                                        }
                                                                                        
                                                                                        <DebounceInput
                                                                                            className="text-center w-full m-auto p-2 rounded-lg shadow-lg  bg-white border-none outline-none"
                                                                                            minLength={0}
                                                                                            value={row['values'][col.id] !== undefined ? row['values'][col.id] : ''}
                                                                                            debounceTimeout={1000}
                                                                                            onChange={(e)=>rowInputOnChange(e.target.value, col.id, rowIndex)} 
                                                                                        />
                                                                                    </td>
                                                                                </>
                                                                            ))}
                                                                            
                                                                        </tr>
                                                                    )}
                                                                    </Draggable>
                                                                </>
                                                            ))}
                                                            {provided.placeholder}
                                                        </tbody>
                                                        
                                                    </>
                                                )}
                                            </Droppable>
                                        </table>
                                        
                                    </DragDropContext>
                                    </div>
                                    
                                </div>
                                
                                    <div  className="h-16 flex flex-row justify-start">
                                        <motion.button data-for="table-menu-tooltip" data-tip="Add column" onClick={addNewColumnMenuClick} whileHover={{scale:1.02}} className={' text-lg text-black font-bold '}>
                                            <FontAwesomeIcon icon={faPlus} /> 
                                        </motion.button>
                                    </div>
                                
                            </div>
                            <div className="m-auto flex flex-row justify-center">
                                {(tableColumns !== null && tableColumns.length > 0) &&
                                    <motion.button data-for="table-menu-tooltip" data-tip="Add row" onClick={addRowClick} whileHover={{scale:1.02}} className={'text-lg px-6 text-black font-bold '}>
                                        <FontAwesomeIcon icon={faPlus} /> 
                                    </motion.button>
                                }
                            </div>
                            
                            <div className="flex my-4 flex-row justify-center place-items-center gap-4">
                                
                                
                            </div>
                            </div>
                        </>
                    }
                </div>
                
            </div>
            {showNewColumnModal &&
                <>
                    <AddTableColumnModal saveNewColumn={saveNewTableColumn} setNewColumnName={setNewColumnName} saveNewTableColumn={saveNewTableColumn} newColumnName={newColumnName} newColumnSaving={newColumnSaving} closeFunction={hideNewTableColumnModal} />
                </>
            }
            {showColumnOrderModal &&
                <>
                    <TableColumnOrderModal saveNewOrder={saveNewColumnOrderClick} onDragEnd={columnOrderOnDragEnd} saving={columnOrderSaving} closeFunction={hideChangeOrderModal} table={table} columns={newColumnOrder} />
                </>
            }
            {showDeleteTableModal &&
                <>
                    <DeleteTableModal loading={deleteLoading} deleteTableClick={deleteTableClick} closeFunction={closeDeleteTableModal} table={table} />
                </>
            }  
            {showDeleteTableColumnModal &&
                <>
                    <DeleteTableColumnsModal loading={deleteLoading} deleteColumns={deleteColumns} closeFunction={closeDeleteTableColumnMenu} setColumnsToDelete={setColumnsToDelete} columnsToDelete={columnsToDelete} table={table} columns={newColumnOrder} />
                </>
            }

        </>
    )
}

export default MyTable;