import React, {useState, useEffect} from 'react';
import {useMyContentContext} from '../../../../contexts/MyContentContext';
import {useLanguagesContext} from '../../../../contexts/LanguagesContext';
import useFolders from '../../../../hooks/useFolders';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSchool, faSpinner} from '@fortawesome/pro-duotone-svg-icons';
import useDecks from '../../../../hooks/useDecks';
import useTables from '../../../../hooks/useTables';
import useNotes from '../../../../hooks/useNotes';
import useTexts from '../../../../hooks/useTexts';
import GeneralSidebarMenu from '../GeneralSidebarMenu';
import {faHeart} from '@fortawesome/pro-solid-svg-icons';
import {faPlus} from '@fortawesome/pro-solid-svg-icons';
import {faGlobeEurope} from '@fortawesome/pro-solid-svg-icons';
import {faNote, faUpload, faFileImport,  faFontCase} from '@fortawesome/pro-solid-svg-icons';
import {faFolderPlus} from '@fortawesome/pro-solid-svg-icons';
import {faCardsBlank} from '@fortawesome/pro-solid-svg-icons';
import {faTableColumns} from '@fortawesome/pro-solid-svg-icons';
import { motion , AnimatePresence } from 'framer-motion';
import {useNavigate, useParams} from 'react-router-dom';
import {Link} from 'react-router-dom';
import Icon from '../../../../components/general/Icon';
import CreateFolderModal from '../../../folders/modals/CreateFolderModal';
import ChangeItemFolderModal from '../../../folders/modals/ChangeItemFolderModal';
import CreateItemModal from '../../../general-modals/CreateItemModal';
import RenameItemModal from '../../../general-modals/RenameItemModal';
import DeleteItemModal from '../../../general-modals/DeleteItemModal';
import CreateOrImportContentButton from '../../../my-library/CreateOrImportContentButton';
import MyLibraryGrid from '../../../my-library/MyLibraryGrid';
import ChangeViewComponent from '../../../my-library/ChangeViewComponent';
import useCourses from '../../../../hooks/useCourses';

const MyContentSidebarMenu = ({outputDesign, filterContentList, view, setView, libraryPage}) => {
    const [showMenu, setShowMenu] = useState(false);
    const navigate = useNavigate();
    let {decks, 
        tables,
        notes, 
        texts,
        folders, 
        courses,
        globalFoldersOrder,
        updateGlobalFolderOrderInTargetLanguage
    } = useMyContentContext();
    const {globalSelectedTargetLanguage, globalSelectedSourceLanguage, setGlobalSelectedTargetLanguage} = useLanguagesContext();
    const {createFolder,
        moveItemToFolder,
        updateFolderOrder, 
        renameFolder, 
        deleteFolderFromDocId
    } = useFolders();

    const {renameDeck, 
        deleteDeckFromDocId, 
        handleAddDeckForCurrentUserWithoutCategory} = useDecks();

    const {renameTable, 
        deleteTableFromDocId, 
        saveNewTable} = useTables();

    const {renameNote, 
        deleteNoteFromDocId} = useNotes();

    const {renameText, 
        saveNewText, 
        deleteTextFromDocId} = useTexts();

    const {renameCourse, 
        deleteCourseFromDocId
    } = useCourses();


    const [saving, setSaving] = useState(false);
    const [menuItems, setMenuItems] = useState(null);
    const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
    const [showCreateItemModal, setShowCreateItemModal] = useState(false);
    const [showCreateItemMenu, setShowCreateItemMenu] = useState(false);
    const [createFolderMode, setCreateFolderMode] = useState("main");
    const [createItemMode, setCreateItemMode] = useState("main");
    const [newFolderName, setNewFolderName] = useState("");
    const [newFolderLoading, setNewFolderLoading] = useState(false);
    const [newFolderParentFolderDocId, setNewFolderParentFolderDocId] = useState(null);

    const [newItemParentFolderDocId, setNewItemParentFolderDocId] = useState(null);
    const [newItemName, setNewItemName] = useState("");
    const [newItemLoading, setNewItemLoading] = useState(false);
    const [newItemType, setNewItemType] = useState(null); //deck, table, note
    const [addNewTextLoading, setAddNewTextLoading] = useState(false);
    const [showMoveItemModal, setShowMoveItemModal] = useState(false);
    const [showRenameItemModal, setShowRenameItemModal] = useState(false);
    const [showDeleteItemModal, setShowDeleteItemModal] = useState(false);
    
    const [editItemModalItem, setEditItemModalItem] = useState(null);
    const [moveItemCurrentParent, setMoveItemCurrentParent] = useState(null);
    const [moveItemLoading, setMoveItemLoading] = useState(false);

    const {folderDocId} = useParams();
    const [filteredItems, setFilteredItems] = useState([]);


    const openCreateFolderModal = (e, item) => {
        setCreateFolderMode("main");
        setShowCreateFolderModal(true);
    }
    
    const openCreateSubFolderModal = (e, item) => {
        console.log("subfolder")
        setCreateFolderMode("subfolder");
        setNewFolderParentFolderDocId(item['doc_id']);
        setShowCreateFolderModal(true);
    }

    const quicklyCreateDeckClick = async (e, child, redirectLocation) => {
        setSaving(true);
        let id = await handleAddDeckForCurrentUserWithoutCategory("Untitled", globalSelectedSourceLanguage, globalSelectedTargetLanguage, "private");
        if (id !== undefined){
            if (redirectLocation === "deck"){
                navigate("/my-decks/"+id);
            }
            else if (redirectLocation === "create"){
                navigate("/my-decks/"+id+"/import");
            } else {
                navigate("/my-decks/"+id);
            }
        }
        setSaving(false);
    }

    const quicklyCreateTableClick = async () => {
        setSaving(true);
        let data = await saveNewTable(globalSelectedTargetLanguage, globalSelectedSourceLanguage, "Untitled", "default");
        if (data !== false){
            let id = data['data']['tableId'];
            if (typeof id === 'number') {
                navigate("/my-tables/"+id);
            }
        }
        setSaving(false);
    }

    const quicklyCreateTextClick = async () => {
        setSaving(true);
        let data = await saveNewText({targetLanguage: globalSelectedTargetLanguage, title: "Untitled"});
        if (data !== false){
            let id = data['data']['textId'];
            if (typeof id === 'number') {
                navigate("/my-texts/" + id);
            }
        }
        setSaving(false);
    }

    const closeCreateFolderModal = () => {
        setShowCreateFolderModal(false);
        setNewFolderName("");
        setNewFolderParentFolderDocId(null);
    }

    const openCreateItemModal = (e, item) => {
        setNewItemLoading(false);
        setCreateItemMode("main");
        setShowCreateItemModal(true);
    }

    const openCreateSubItemModal = (e, item) => {
        setNewItemLoading(false);
        setCreateItemMode("subitem");
        setNewItemParentFolderDocId(item['doc_id']);
        setShowCreateItemModal(true);
    }

    const closeCreateItemModal = () => {
        setShowCreateItemModal(false);
        setNewItemLoading(false);
        setNewItemParentFolderDocId(null);
    }

    const newFolderNameOnChange = (e) => {
        setNewFolderName(e.target.value);
    }

    const createANewFolder = async (event) => {
        setNewFolderLoading(true);
        await createFolder(newFolderName, globalSelectedTargetLanguage);
        setNewFolderLoading(false);
        closeCreateFolderModal();
    }

    const createANewSubfolder = async (event) => {
        setNewFolderLoading(true);
        await createFolder(newFolderName, globalSelectedTargetLanguage, newFolderParentFolderDocId);
        setNewFolderLoading(false);
        closeCreateFolderModal();
    }


    useEffect(()=> {
        //change globalSelectedTargetLanguage if folder is in another language
        if (folderDocId === undefined || folderDocId === null){return}
        if (folders === null || folders === undefined){return}
        if (globalSelectedSourceLanguage === null || globalSelectedSourceLanguage === undefined){return}

        for (const folder of folders){
            if (folder.doc_id === folderDocId){
                if (folder['target_ISO_639-1'] !== globalSelectedTargetLanguage){
                    // change globalSelectedTargetLanguage
                    console.log("Changing language to: ", folder['target_ISO_639-1']);
                    setGlobalSelectedTargetLanguage(folder['target_ISO_639-1']);
                }
            }
        }

    },[folders, folderDocId, globalSelectedSourceLanguage]);

    useEffect(()=>{

        const createDeckObject = (deck) => {
            let o = {};
            o['text'] = deck.name;
            o['showIcon'] = true;
            o['icon'] = faCardsBlank;
            o['color'] = 'text-primary';
            o['link'] = '/my-decks/'+deck.id+"/";
            o['id'] = deck.id;
            o['type'] = 'link';
            o['content_type'] = 'deck';
            o['doc_id'] = deck.doc_id;
            o['temp_adding_items'] = deck['temp_adding_items'];
            o['temp_number_of_items_to_add'] = deck['temp_number_of_items_to_add'];
            o['number_of_items'] = deck['number_of_items'];
            o["original_object"] = deck;
            return o;
        }

        const createTableObject = (table) => {
            
            let o = {};
            o['text'] = table.name;
            o['showIcon'] = true;
            o['icon'] = faTableColumns;
            o['color'] = 'text-primary';
            o['link'] = '/my-tables/'+table.id;
            o['id'] = table.id;
            o['type'] = 'link';
            o['content_type'] = 'table';
            o['number_of_items'] = table.hasOwnProperty('row_order') ? table.row_order.length : null;
            o['doc_id'] = table.doc_id;
            o["original_object"] = table;
            return o;
        }

        const createNoteObject = (note) => {
            let o = {};
            o['text'] = note.name;
            o['showIcon'] = true;
            o['icon'] = faNote;
            o['color'] = 'text-primary';
            o['link'] = '/my-notes/'+note.note_id;
            o['id'] = note.note_id;
            o['type'] = 'link';
            o['content_type'] = 'note';
            o['doc_id'] = note.doc_id;
            o["original_object"] = note;
            return o;
        }

        const createTextObject = (text) => {
            let o = {};
            o['text'] = text?.title;
            o['showIcon'] = true;
            o['icon'] = faFontCase;
            o['color'] = 'text-primary';
            o['link'] = '/my-texts/'+text.text_id.toString();
            o['id'] = text.text_id;
            o['type'] = 'link';
            o['content_type'] = 'text';
            o['doc_id'] = text.doc_id;
            o["original_object"] = text;
            return o;
        }

        const createCourseObject = (course) => {
            let o = {};
            o['text'] = course.name;
            o['showIcon'] = true;
            o['icon'] = faSchool;
            o['color'] = 'text-charcoal';
            o['link'] = '/my-courses/'+course.course_id.toString();
            o['id'] = course.course_id;
            o['type'] = 'link';
            o['content_type'] = 'course';
            o['doc_id'] = course.doc_id;
            o["original_object"] = course;
            return o;
        }
        
        const createFolderObject = (folder, children_list) => {
            let filtered_children_list = children_list.filter(item => item !== undefined);
            filtered_children_list = filtered_children_list.filter(item => Object.keys(item).length !== 0);
            let o = {};
            o['text'] = folder.name;
            o['showIcon'] = false;
            o['color'] = 'text-sandy-brown';
            o['type'] = 'folder';
            o['content_type'] = 'folder';
            o['children'] = filtered_children_list;
            o['doc_id'] = folder.doc_id;
            o["original_object"] = folder;
            return o;
        }

        const createDecksList = () => {
            let l = [];
            for (const deck of decks){
                if (deck['target_ISO_639-1'] === globalSelectedTargetLanguage){
                    let o = createDeckObject(deck);
                    l.push(o);
                }
            }
            return l;
        }

        const createTablesList = () => {
            let l = [];
            for (const table of tables){
                if (table['target_ISO_639-1'] === globalSelectedTargetLanguage){
                    let o = createTableObject(table);
                    l.push(o);
                }
            }
            return l;
        }

        const createNotesList = () => {
            let l = [];
            for (const note of notes){
                if (note['target_ISO_639-1'] === globalSelectedTargetLanguage){
                    let o = createNoteObject(note);
                    l.push(o);
                }
            }
            return l;
        }

        const createTextsList = () => {
            let l = [];
            for (const text of texts){
                if (text['target_ISO_639-1'] === globalSelectedTargetLanguage){
                    let o = createTextObject(text);
                    l.push(o);
                }
            }
            return l;
        }

        const createCoursesList = () => {
            let l = [];
            for (const course of courses){
                if (course['target_ISO_639-1'] === globalSelectedTargetLanguage){
                    let o = createCourseObject(course);
                    l.push(o);
                }
            }
            return l;
        }

        const createFoldersList = (d_list, t_list, n_list, texts_list, docIdsSorted, courses_list) => {
            let l = [];
/*             let folder_doc_ids = folders.map((folder)=>folder.doc_id);
            let notes_doc_ids = n_list.map((e)=>e.doc_id.trim());
            let deck_doc_ids = d_list.map((e)=>e.doc_id.trim());
            let table_doc_ids = t_list.map((e)=>e.doc_id.trim()); */
            
            let folders_doc_ids_added = [];
            //!folders_doc_ids_added.includes(folder.doc_id)
            for (const folder of folders){
                // check first if present in other folders - if yes, skip item
                let found = false;
                for (const foldercheck of folders){
                    if (foldercheck.children_doc_ids.includes(folder.doc_id)){
                        found = true;
                        docIdsSorted.push(folder.doc_id);
                        //console.log("List: ", docIdsSorted);
                    }
                }
                if (!found){
                    if (folder['target_ISO_639-1'] === globalSelectedTargetLanguage){
                        let children_doc_ids = folder.children_doc_ids;
                        let r_children = createFolderChildrenObjects(d_list, t_list, n_list, texts_list, children_doc_ids, folder.doc_id, docIdsSorted, courses_list);
                        let children_list = r_children[0];
                        //docIdsSorted = r_children[1];
                        let o = createFolderObject(folder, children_list);
                        l.push(o);
                        folders_doc_ids_added.push(o.doc_id);
                    }
                }
            }
            return [l, docIdsSorted];
        }

        const createFolderChildrenObjects = (d_list, t_list, n_list, texts_list, sub_children, parentDocId, docIdsSorted, courses_list) => {
            let folder_doc_ids = folders.map((folder)=>folder.doc_id);
            let notes_doc_ids = n_list.map((e)=>e.doc_id.trim());
            let deck_doc_ids = d_list.map((e)=>e.doc_id.trim());
            let table_doc_ids = t_list.map((e)=>e.doc_id.trim());
            let text_doc_ids = texts_list.map((e)=>e.doc_id.trim());
            let courses_doc_ids = courses_list.map((e)=>e.doc_id.trim());

            //console.log(d_list);

            let children_list = [];
            //addCreateFolderButtonAtBeginning(children_list, "subfolder", parentDocId); 
            for (const doc_id of sub_children){
                
                let o = {};
                let deck_index = deck_doc_ids.indexOf(doc_id.trim());
                let table_index = table_doc_ids.indexOf(doc_id.trim());
                let note_index = notes_doc_ids.indexOf(doc_id.trim());
                let text_index = text_doc_ids.indexOf(doc_id.trim());
                let course_index = courses_doc_ids.indexOf(doc_id.trim());
                let folder_index = folder_doc_ids.indexOf(doc_id.trim());
                let found = true;
                if (deck_index > -1){
                    o = d_list[deck_index];
                    //d_list.splice(deck_index, 1);
                    found = true;
                } else if (table_index > -1){
                    o = t_list[table_index];
                    //t_list.splice(table_index, 1);
                    found = true;
                } else if (note_index > -1){
                    o = n_list[note_index];
                    //n_list.splice(note_index, 1);
                    found = true;
                } else if (text_index > -1){
                    o = texts_list[text_index];
                    //n_list.splice(note_index, 1);
                    found = true;
                }  
                else if (course_index > -1){
                    o = courses_list[course_index];
                    found = true;
                }
                else if (folder_index > -1){
                    // loop over all folders to find the subfolder
                    for (const subfolder of folders){
                        if (subfolder.doc_id === doc_id){
                            let child_sub_children = subfolder.children_doc_ids;
                            let r_children = createFolderChildrenObjects(d_list, t_list, n_list, texts_list, child_sub_children, doc_id, docIdsSorted, courses_list);
                            let objList = r_children[0];
                            //docIdsSorted = r_children[1];
                            o = createFolderObject(subfolder, objList);
                            found = true;
                        }
                    }
                }
                if (found){
                    docIdsSorted.push(doc_id);
                    children_list.push(o);
                } else {
                    console.log("Did not find this item: ", doc_id);
                }
            }
            return [children_list, docIdsSorted];
        }

        const sortListAccordingToDatabase = (all_items_list, docIdsSorted) => {
            if (globalFoldersOrder !== null && globalSelectedTargetLanguage in globalFoldersOrder && 'doc_ids_order' in globalFoldersOrder[globalSelectedTargetLanguage]) {
                let db_order = globalFoldersOrder[globalSelectedTargetLanguage]['doc_ids_order'];
                let sorted_ids = [];
                let not_sorted_items = [];
                let new_list = new Array(db_order.length);
                for (const item of all_items_list){
                    let position = db_order.indexOf(item.doc_id);
                    if (position > -1){
                        if (item !== undefined && item !== null){
                            new_list[position] = item;
                            sorted_ids.push(item.doc_id);
                        }
                    } else if (position === -1){
                        if (item !== undefined && item !== "undefined" && item !== null && !docIdsSorted.includes(item.doc_id)){
                            not_sorted_items.push(item);
                        }
                    } else {
                        console.log("The error is here:  ", item);
                    }
                }
                new_list = [...new_list, ...not_sorted_items];
                new_list = new_list.filter(item=>item !== undefined);
                return new_list;
            } else {
                return all_items_list;
            }
        }

        const addButtonsToSortedList = (sorted_list, showFolders, filterContentList) => {
           
           
            //addCreateTextButtonAtBeginning(sorted_list, "main", filterContentList);  //quick versions
            //addCreateTableButtonAtBeginning(sorted_list, "main", filterContentList); 
            //addCreateDeckButtonAtBeginning(sorted_list, "main", filterContentList); 
           
            if (showFolders){
                addCreateFolderButtonAtBeginning(sorted_list, "main"); 
            }
            addCreateItemButtonAtBeginning(sorted_list, "main", filterContentList);
        }

        const addCreateFolderButtonAtBeginning = (list, type, parentFolderDocId) => {
            let button = {
                'text': 'Folder',
                'icon': faFolderPlus, 
                'text_decoration': 'font-bold',
                //'color': 'text-sandy-brown', 
                'size': 'text-sm',
                'type': 'function', 
                'save_to_db': false,
                'content_type': 'button',
                'function': type === "main" ? openCreateFolderModal : openCreateSubFolderModal,
                'parent_folder_doc_id': type === "main" ? null : parentFolderDocId
            };
            list.unshift(button); 
        }

        const addCreateItemButtonAtBeginning = (list, type, filterContentList, parentFolderDocId) => {
            let itemString = "item";
            if (filterContentList !== undefined && filterContentList.length === 1){
                if (filterContentList[0] === "decks"){
                    itemString = "deck";
                } else if (filterContentList[0] === "tables"){
                    itemString = "table";
                } else if (filterContentList[0] === "notes"){
                    itemString = "note";
                } 
            }
            let button = {
                'text': itemString,
                'icon': faPlus, 
                'size': 'text-sm',
                'text_decoration': 'font-bold',
                'type': 'function', 
                'save_to_db': false,
                'content_type': 'button',
                'function': type === "main" ? openCreateItemModal : openCreateSubItemModal,
                'parent_folder_doc_id': type === "main" ? null : parentFolderDocId
            };
            list.unshift(button); 
        }

        const addCreateTextButtonAtBeginning = (list, type, filterContentList, parentFolderDocId) => {
            let itemString = "item";
            if (filterContentList !== undefined && filterContentList.length === 1){
                if (filterContentList[0] === "decks"){
                    itemString = "deck";
                } else if (filterContentList[0] === "tables"){
                    itemString = "table";
                } else if (filterContentList[0] === "notes"){
                    itemString = "note";
                } else if (filterContentList[0] === "texts"){
                    itemString = "text";
                } 
            }
            let button = {
                'text': 'Text',
                'icon': faFontCase, 
                'color': 'text-primary', 
                'size': 'text-sm',
                'text_decoration': 'font-bold',
                'type': 'function', 
                'save_to_db': false,
                'content_type': 'button',
                'function': type === "main" ? quicklyCreateTextClick : openCreateSubItemModal,
                'parent_folder_doc_id': type === "main" ? null : parentFolderDocId
            };
            list.unshift(button); 
        }

        const addCreateTableButtonAtBeginning = (list, type, filterContentList, parentFolderDocId) => {
            let itemString = "item";
            if (filterContentList !== undefined && filterContentList.length === 1){
                if (filterContentList[0] === "decks"){
                    itemString = "deck";
                } else if (filterContentList[0] === "tables"){
                    itemString = "table";
                } else if (filterContentList[0] === "notes"){
                    itemString = "note";
                } 
            }
            let button = {
                'text': 'Table',
                'icon': faTableColumns, 
                'color': 'text-primary',  //beautiful-blue
                'size': 'text-sm',
                'text_decoration': 'font-bold',
                'type': 'function', 
                'save_to_db': false,
                'content_type': 'button',
                'function': type === "main" ? quicklyCreateTableClick : openCreateSubItemModal,
                'parent_folder_doc_id': type === "main" ? null : parentFolderDocId
            };
            list.unshift(button); 
        }

        const addCreateDeckButtonAtBeginning = (list, type, filterContentList, parentFolderDocId) => {
            let itemString = "item";
            if (filterContentList !== undefined && filterContentList.length === 1){
                if (filterContentList[0] === "decks"){
                    itemString = "deck";
                } else if (filterContentList[0] === "tables"){
                    itemString = "table";
                } else if (filterContentList[0] === "notes"){
                    itemString = "note";
                } 
            }
            let button = {
                'text': 'Deck',
                'icon': faCardsBlank, 
                'color': 'text-primary',  //burnt-sienna
                'text_decoration': 'font-bold',
                'size': 'text-sm',
                'type': 'function', 
                'save_to_db': false,
                'content_type': 'button',
                'function': type === "main" ? quicklyCreateDeckClick : openCreateSubItemModal,
                'parent_folder_doc_id': type === "main" ? null : parentFolderDocId
            };
            list.unshift(button); 
        }
        console.log("courses: ", courses)
        if (decks !== null && globalSelectedTargetLanguage !== null && tables !== null && notes !== null && folders !== null && texts !== null && courses !== null){
            let docIdsSorted = [];
            let d_list = [];
            let t_list = [];
            let n_list = [];
            let texts_list = [];
            let courses_list = [];
            let f_list = [];
            let showFolders = true;
            if (filterContentList !== undefined){
                showFolders = false;
                for (const filter of filterContentList){
                    if (filter === "decks"){
                        d_list = createDecksList();
                    } else if (filter === "tables"){
                        t_list = createTablesList();
                    } else if (filter === "notes"){
                        n_list = createNotesList();
                    } else if (filter === "folders"){
                        showFolders = true;
                    } else if (filter === "texts"){
                        texts_list = createTextsList();
                    } else if (filter === "courses"){
                        courses_list = createCoursesList();
                    }
                }
            } 
            else {
                d_list  = createDecksList();
                t_list = createTablesList();
                n_list = createNotesList();
                texts_list = createTextsList();
                courses_list = createCoursesList();
            }
          
            let r_folder_list = createFoldersList(d_list, t_list, n_list, texts_list, docIdsSorted, courses_list);
            
            //docIdsSorted = r_folder_list[1];
            //now we need to filter out the items included in folders
            let filtered_items = [];
            
            if (showFolders){
                for (const item of d_list){
                    if (!docIdsSorted.includes(item.doc_id)){
                        filtered_items.push(item);
                    }
                }
                for (const item of t_list){
                    if (!docIdsSorted.includes(item.doc_id)){
                        filtered_items.push(item);
                    }
                }
                for (const item of n_list){
                    if (!docIdsSorted.includes(item.doc_id)){
                        filtered_items.push(item);
                    }
                }
                for (const item of texts_list){
                    if (!docIdsSorted.includes(item.doc_id)){
                        filtered_items.push(item);
                    }
                }
                for (const item of courses_list){
                    if (!docIdsSorted.includes(item.doc_id)){
                        filtered_items.push(item);
                    }
                }
                f_list = r_folder_list[0];
            } else {
                filtered_items = [...d_list, ...t_list, ...n_list, ...texts_list, ...courses_list];
            }
            let all_items_list = [...f_list, ...filtered_items];
            let sorted_list = [];
            if (showFolders){
                sorted_list = sortListAccordingToDatabase(all_items_list, docIdsSorted);
            } else {
                sorted_list = all_items_list;
            }
            addButtonsToSortedList(sorted_list, showFolders, filterContentList);
            setMenuItems(sorted_list);
        } else {
            setMenuItems(null);
        }
    },[decks, tables, globalSelectedTargetLanguage, texts, courses, folders, notes, globalFoldersOrder, filterContentList])

    /* const [menuItems, setMenuItems] = useState([
    {
        'text': 'Tables', 
        'icon': faTableColumns, 
        'color': 'text-beautiful-blue', 
        'type': 'folder', 
        'children': [{
            'text': 'Table 1', 
            'showIcon': false, 
            'type': 'link',
            'link': "/my-decks",
            'color': 'text-beautiful-blue'
        }]
    },
    {
        'text': 'Notes', 
        'icon': faNote, 
        'color': 'text-sandy-brown', 
        'type': 'folder', 
        'children': [{
            'text': 'My decks', 
            'icon': faCardsBlank, 
            'link': "/my-decks",
            'type': 'link',
            'color': 'text-sandy-brown'
        }]
    }
    ]); */
    
    const onDragEndFunction = (order_list, parent_folder) => {
        console.log("New order: ", order_list, "Parent: ", parent_folder);
        let doc_ids = order_list.map((elem)=>elem.doc_id);
        if (parent_folder === null || parent_folder === undefined){
            // change on first level
            updateGlobalFolderOrderInTargetLanguage(doc_ids, globalSelectedTargetLanguage);
        } else {
            console.log("Deeper change: ", doc_ids, parent_folder);
            updateFolderOrder(parent_folder, order_list);
        }
    }

    const changeItemFolderClick = (e, item) => {
        e.stopPropagation();
        setEditItemModalItem(item);
        setShowMoveItemModal(true);
        console.log("Showing modal for item: ", item);
    }

    const closeChangeItemFolderModal = () => {
        setShowMoveItemModal(false);
        setMoveItemLoading(false);
        setEditItemModalItem(null);
    }

    const closeRenameItemModal = () => {
        setShowRenameItemModal(false);
        setMoveItemLoading(false);
        setEditItemModalItem(null);
    }

    const closeDeleteItemModal = () => {
        setShowDeleteItemModal(false);
        setMoveItemLoading(false);
        setEditItemModalItem(null);
    }
    
    const moveItemToFolderSaveChanges = async (destinationFolder) => {
        setMoveItemLoading(true);
        console.log("Moving ", editItemModalItem, " to ", destinationFolder, " from ", moveItemCurrentParent);
        let r = await moveItemToFolder(editItemModalItem, moveItemCurrentParent, destinationFolder);
        console.log("Finished moving!", r);
        if (r === true){
            setMoveItemLoading(false);
        } else {
            // TO DO: error handling!
            setMoveItemLoading(false);
        }
        setShowMoveItemModal(false);
        setEditItemModalItem(null);
        setMoveItemCurrentParent(null);
    }

    const renameItemClick = (e, item) => {
        console.log(e, item);
        e.stopPropagation();
        setEditItemModalItem(item);
        setShowRenameItemModal(true);
    }

    const deleteItemClick = (e, item) => {
        e.stopPropagation();
        setEditItemModalItem(item);
        setShowDeleteItemModal(true);
    }


    useEffect(() => {

        const findAllChildElements = () => {  
            if (folderDocId !== undefined && folderDocId !== null){

                const recursiveSearch = (items) => {
                    for (const item of items){
                        if (item.doc_id === folderDocId){
                            setFilteredItems(item.children);
                        }
                        else if (item.children !== undefined && item.children !== null){
                            recursiveSearch(item.children);
                        }
                    }
                };
                recursiveSearch(menuItems);
            }
        }

        if (menuItems === undefined || menuItems === null){return}

        if (libraryPage === "library"){
            setFilteredItems(menuItems);
        }
        else if (libraryPage === "folder"){
            findAllChildElements();
        }

    }, [libraryPage, folderDocId, menuItems]);

    const closeMenu = () => {
        setShowMenu(false);
    }

    const parentFolder = {"text": "Top level", "doc_id": "top_level", "children": menuItems};

    const libraryProps = {
        renameItemClick: renameItemClick,
        deleteItemClick: deleteItemClick,
        outputDesign: outputDesign,
        filterContentList: filterContentList,
        closeCreateItemModal: closeCreateItemModal,
        openCreateSubItemModal: openCreateSubItemModal,
        openCreateFolderModal: openCreateFolderModal,
        openCreateSubFolderModal: openCreateSubFolderModal,
        setMoveItemCurrentParent: setMoveItemCurrentParent,
        changeItemFolderClick: changeItemFolderClick,
        moveItemModalItem: editItemModalItem, // Assuming editItemModalItem is correct here based on your mapping
        setMoveItemModalItem: setEditItemModalItem, // Assuming setEditItemModalItem is correct here based on your mapping
        showMoveItemModal: showMoveItemModal,
        setShowMoveItemModal: setShowMoveItemModal,
        title: "My library",
        isDraggable: true,
        onDragEndFunction: onDragEndFunction,
        showMenu: showMenu,
        setShowMenu: setShowMenu,
        menuItems: menuItems,
        setMenuItems: setMenuItems, 
        libraryPage: libraryPage, 
        filteredItems: filteredItems, 
        folderDocId: folderDocId, 
        showCreateItemMenu: showCreateItemMenu,
        setShowCreateItemMenu: setShowCreateItemMenu, 
        closeMenu: closeMenu, 
        parentFolder: parentFolder,
    }

    const libraryItemMenuProps = {
        renameItemClick: renameItemClick,
        deleteItemClick: deleteItemClick,
        closeMenu: closeMenu,
        closeCreateItemModal: closeCreateItemModal,
        openCreateSubItemModal: openCreateSubItemModal,
        openCreateSubFolderModal: openCreateSubFolderModal,
        openCreateFolderModal: openCreateFolderModal,
        parentFolder: parentFolder,
        setMoveItemCurrentParent: setMoveItemCurrentParent,
        changeItemFolderClick: changeItemFolderClick,
    }

    console.log("Menu items: ", menuItems);

/*
                    <div className="flex flex-row justify-end px-4">
                        <ChangeViewComponent view={view} setView={setView} />
                    </div>
*/
    return (
        <>
            <div className="overflow-visible h-full flex flex-col gap-6">
                {!saving &&
                <>
                {menuItems !== null && 
                    <>
                        <div className="flex flex-col md:flex-row gap-6 place-items-center justify-between">
                            <CreateOrImportContentButton {...libraryProps} />
                           
                        </div>
                    </>
                }
                <div className="w-full 2xl:w-4/6 mx-auto">

                {menuItems !== null && view === "tree" &&
                    <>
                        <GeneralSidebarMenu {...libraryProps} libraryItemMenuProps={libraryItemMenuProps} />
                    </>
                }
                 {menuItems !== null && view === "grid" &&
                    <>
                        <MyLibraryGrid {...libraryProps} libraryItemMenuProps={libraryItemMenuProps} />
                    </>
                }
                </div>
                {showCreateFolderModal &&
                    <>
                        <CreateFolderModal newFolderLoading={newFolderLoading} createANewFolder={createANewFolder} createANewSubfolder={createANewSubfolder} newFolderNameOnChange={newFolderNameOnChange} name={newFolderName} closeFunction={closeCreateFolderModal} createFolderMode={createFolderMode} />
                    </>
                }
                {showCreateItemModal &&
                    <>
                        <CreateItemModal filterContentList={filterContentList} newItemParentFolderDocId={newItemParentFolderDocId} createItemMode={createItemMode} setAddNewTextLoading={setAddNewTextLoading} setNewItemType={setNewItemType} newItemType={newItemType} setNewItemLoading={setNewItemLoading} newItemLoading={newItemLoading} closeFunction={closeCreateItemModal} createItemMode={createItemMode} />
                    </>
                }
                {showMoveItemModal &&
                    <>
                        <ChangeItemFolderModal moveItemCurrentParent={moveItemCurrentParent} moveItemToFolderSaveChanges={moveItemToFolderSaveChanges} loading={moveItemLoading} menuItems={menuItems} item={editItemModalItem} folders={folders} closeFunction={closeChangeItemFolderModal} />
                    </>
                }
                {showRenameItemModal &&
                    <>
                        <RenameItemModal renameNote={renameNote} renameCourse={renameCourse} renameText={renameText} renameTable={renameTable} renameDeck={renameDeck} renameFolder={renameFolder} setShowRenameItemModal={setShowRenameItemModal} item={editItemModalItem} closeFunction={closeRenameItemModal} />
                    </>
                }
                {showDeleteItemModal &&
                    <>
                        <DeleteItemModal deleteFolder={deleteFolderFromDocId} deleteCourse={deleteCourseFromDocId} deleteNote={deleteNoteFromDocId} deleteDeck={deleteDeckFromDocId} deleteTable={deleteTableFromDocId} deleteText={deleteTextFromDocId} item={editItemModalItem} closeFunction={closeDeleteItemModal} />
                    </>
                }
                </>
                }
                {saving &&
                    <>
                        <div className="h-screen overflow-hidden flex flex-col justify-center text-center text-3xl">
                            <FontAwesomeIcon icon={faSpinner} className="fa-pulse" />
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default MyContentSidebarMenu;