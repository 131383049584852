import React, {useState, useEffect} from 'react';
import usePersonalizedMessages from '../../hooks/usePersonalizedMessages';
import {useLanguagesContext} from '../../contexts/LanguagesContext';
import {useUserInfoContext} from '../../contexts/UserInfoContext';

const GreetingMessage = () => {

    const {getPersonalizedGreetingInLanguage} = usePersonalizedMessages();
    const [greeting, setGreeting] = useState(null);
    const {userInfo} = useUserInfoContext();
    let { globalSelectedTargetLanguage, activeUserLanguages, allLanguages } = useLanguagesContext();

    useEffect(()=> {
        const getGreeting = () => {
            let msg = getPersonalizedGreetingInLanguage(globalSelectedTargetLanguage, userInfo);
            setGreeting(msg);
        }        

        if (globalSelectedTargetLanguage !== null){
            getGreeting();    
        }
    },[globalSelectedTargetLanguage, userInfo]);

    return (
        <div className="font-bold text-gray-600 dark:text-white text-center text-lg xl:text-2xl">
            {greeting !== null ? <>{greeting}</> : <>Hello!</>}
        </div>
    );
};

export default GreetingMessage;