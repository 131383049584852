import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faRightFromBracket, faHome, faRightToBracket } from "@fortawesome/pro-solid-svg-icons";

const AccountMenu = () => {
  const { currentUser, logout } = useAuth();
  const [menuOpen, setMenuOpen] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => setMenuOpen((prev) => !prev);

  const handleLoginClick = () => {
    setShowLoginModal(true);
    setMenuOpen(false);
  };

  const handleCloseModal = () => {
    setShowLoginModal(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="relative text-gray-800" ref={menuRef}>
      <div
        className="flex items-center justify-center w-16 h-16 rounded-full bg-white shadow-lg cursor-pointer"
        onClick={toggleMenu}
      >
        <span className="font-bold">
          <FontAwesomeIcon icon={faUser} />
        </span>
      </div>
      {menuOpen && (
        <div className="absolute right-0 mt-2 w-52 bg-white border border-gray-200 rounded-lg shadow-lg p-4">
          <div className="flex flex-col place-items-start gap-4">
            {currentUser && (
              <>
                <span className="italic text-sm">
                  Hello{currentUser.displayName ? " " + currentUser.displayName.split(" ")[0] : ""}!
                </span>
                <a href="https://yalango.com" className="no-underline">
                <button
                  className="flex items-center gap-3"
                >
                <div className="muted-text dark:text-gray-500">
                    <FontAwesomeIcon icon={faHome} />
                  </div>
                  Yalango
                </button>
                </a>
                <a href="https://yalango.com/logout" className="no-underline">
                <button
                  onClick={logout}
                  className=" flex items-center gap-3"
                >
                     <div className="muted-text dark:text-gray-500">
                  <FontAwesomeIcon icon={faRightFromBracket} />
                  </div>
                  Sign out
                </button>
                </a>
              </>
            )}
          </div>
        </div>
      )}
      {showLoginModal && <LoginModal onClose={handleCloseModal} />}
    </div>
  );
};

export default AccountMenu;
