import React, {useEffect, useState} from 'react';
import {motion} from 'framer-motion';

const DeckItemCard = ({item}) => {

    const textSizes = ["text-xs", "text-sm", "text-base", "text-lg", "text-xl", "text-2xl", "text-3xl"];
    const [targetSize, setTargetSize] = useState(null);
    const [sourceSize, setSourceSize] = useState(null);

    useEffect(()=>{
        const calculate = (type, text) => {
            let chosenSize = null;
            if (text.length < 10){
                if (type === "target"){
                    chosenSize = textSizes[6];
                } else {
                    chosenSize = textSizes[3];
                }
            } else if (text.length < 40){
                if (type === "target"){
                    chosenSize = textSizes[4];
                } else {
                    chosenSize = textSizes[2];
                }
            }
            else if (text.length < 70){
                if (type === "target"){
                    chosenSize = textSizes[3];
                } else {
                    chosenSize = textSizes[1];
                }
            }
            else {
                if (type === "target"){
                    chosenSize = textSizes[2];
                } else {
                    chosenSize = textSizes[0];
                }
            }
            if (type === "target"){
                setTargetSize(chosenSize);
            } else {
                setSourceSize(chosenSize);
            }
        }
        if (item !== null){
            console.log(item.target.length);
            console.log(item.source.length);
            calculate("target", item.target);
            calculate("source", item.source);
        }
    },[item]);

    return (
        
<motion.div
  whileHover={{ y: -3 }}
  className="relative rounded-lg p-4 h-64 bg-white dark:bg-gray-800 shadow-2xl flex flex-col place-items-center overflow-auto justify-evenly transition-colors duration-300"
>
  {/* Content */}
  <div className="m-auto p-4 text-center text-gray-700 dark:text-gray-300">
    <div className="max-h-xs">
      {targetSize !== null && <div className={targetSize}>{item.target}</div>}
      {sourceSize !== null && <div className={sourceSize}>{item.source}</div>}
    </div>
  </div>
</motion.div>

    )
}

export default DeckItemCard;
