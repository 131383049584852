import React, {useState, useEffect} from 'react';
import {useLanguagesContext} from '../../../contexts/LanguagesContext';
import useDecks from '../../../hooks/useDecks';
import {Link} from 'react-router-dom';
import Button from '../../../components/general/Button';

const VocabularyHighlightCard = (props) => {
    const {filter, howManyItems} = props;
    const [items, setItems] = useState(null);
    const [loading, setLoading] = useState(false);
    const [startedFetching, setStartedFetching] = useState(false);
    let { globalSelectedTargetLanguage, activeUserLanguages, allLanguages } = useLanguagesContext();
    const {fetchVocabularyInTargetLanguage, 
        fetchVocabularyMetadataInTargetLanguage} = useDecks();

    useEffect(()=>{
        const fetchItems = async () => {
            let r = await fetchVocabularyInTargetLanguage(globalSelectedTargetLanguage, filter, 5);
            console.log(r);
            if (r !== null && r !== undefined && r.hasOwnProperty("vocabulary")){
                setItems(r['vocabulary']);
                setLoading(false);
            }
        }

        if (globalSelectedTargetLanguage !== null && filter !== null){
            fetchItems();
        }
    },[globalSelectedTargetLanguage, filter])

    return (
        <>
<div className="flex flex-col items-center gap-6 w-full max-w-3xl mx-auto">
    {/* Title */}
    <div className="font-semibold text-lg lg:text-lg text-center text-gray-700 dark:text-gray-200 capitalize">
        {filter}{" "}
        {(allLanguages !== null && globalSelectedTargetLanguage !== null) &&
            allLanguages[0][globalSelectedTargetLanguage]["name"]["en"]}{" "} words
    </div>

    {/* Word List */}
    <div className="flex flex-col gap-4 w-full">
        {items !== null && !loading && items.map((item, itemIndex) => (
            <div
                className="flex flex-row items-center justify-between p-4 bg-gray-100 dark:bg-gray-700 rounded-lg shadow-sm transform transition-transform hover:scale-105 hover:shadow-lg"
                key={`item_${itemIndex}`}
            >
                <div className="flex flex-row items-center gap-4">
                    {/* Percentage */}
                    <div className="text-lg font-semibold text-persian-green">
                        {item?.total_statistics?.percentage?.correct}%
                    </div>

                    {/* Word Pair */}
                    <div>
                        <Link
                            to={`/my-vocabulary/${globalSelectedTargetLanguage}/item/${item.id}`}
                            className="text-gray-700 dark:text-gray-300 no-underline hover:text-persian-green hover:underline"
                        >
                            {item.target.charAt(0).toUpperCase() + item.target.slice(1)} -{" "}
                            {item.source.charAt(0).toUpperCase() + item.source.slice(1)}
                        </Link>
                    </div>
                </div>
            </div>
        ))}
    </div>

    {/* See More Button */}
    {items !== null && !loading && (
        <div className="w-full flex justify-center mt-4">
            <Link className="no-underline" to="/my-vocabulary">
                <Button
                    text="See more"
                    size="normal"
                    color="green"
                    className="transform transition-transform hover:scale-105"
                />
            </Link>
        </div>
    )}

    {/* Loading Skeleton */}
    {loading && (
        <div className="w-full">
            {[...Array(5)].map((_, index) => (
                <CustomSkeleton key={index} height={50} />
            ))}
        </div>
    )}

    {/* No Items Placeholder */}
    {!loading && items?.length === 0 && (
        <div className="text-center text-gray-500 dark:text-gray-400 italic">
            No words found. Start adding some vocabulary!
        </div>
    )}
</div>

    </>
    
    )
}

export default VocabularyHighlightCard