import React from "react";
import { motion , AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faNote, faUpload, faPlus, faFileImport,  faFontCase} from '@fortawesome/pro-solid-svg-icons';
import {Link} from 'react-router-dom';
import Icon from '../../components/general/Icon';

const CreateOrImportContentButton = (props) => {

    const {menuItems, setShowCreateItemMenu, showCreateItemMenu, folderDocId, openCreateSubItemModal, openCreateSubFolderModal} = props; //folderDocId is not undefined -> we are inside a folder


    const createItemButtonClick =  () => {
        setShowCreateItemMenu(!showCreateItemMenu);
    }

    const runCreateItemButtonFunction = async (e, child) => {
        console.log(child)
        setShowCreateItemMenu(false);
        if (child.text.toLowerCase() === "item"){
            //openCreateSubItemModal if we are inside a folder
            if (folderDocId !== undefined){
                openCreateSubItemModal(e, {doc_id: folderDocId});
            } else {
                await child.function(e, child);
            }
        } 
        else if (child.text.toLowerCase() === "folder"){
            //openCreateSubFolderModal if we are inside a folder
            if (folderDocId !== undefined){
                openCreateSubFolderModal(e, {doc_id: folderDocId});
            } else {
                await child.function(e, child);
            }
        } 
        
        else {
            await child.function(e, child);
        }

    }


    return (
<>
    <div className="flex flex-col w-full">
        <div className="relative">
            {/* Main Button */}
            <button
                onClick={createItemButtonClick}
                className="flex items-center justify-center gap-3 w-full sm:w-96 p-6 rounded-lg bg-charcoal dark:dark-mode-background-color dark:hover:bg-black text-white font-semibold text-lg shadow-md hover:shadow-lg hover:bg-darker-charcoal transition-all duration-300"
            >
                <FontAwesomeIcon icon={faPlus} className="text-xl" />
                <span>Create or import content</span>
            </button>

            <AnimatePresence>
                {showCreateItemMenu && (
                    <>
                        {/* Dropdown Menu */}
                        <motion.div
                            variants={{
                                initial: { opacity: 0, scale: 0.95 },
                                animate: { opacity: 1, scale: 1, transition: { duration: 0.2 } },
                                exit: { opacity: 0, scale: 0.95, transition: { duration: 0.2 } },
                            }}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                            className="absolute mt-2 w-full sm:w-96 bg-white dark:bg-gray-800 rounded-lg shadow-xl z-20"
                        >
                            {/* Section: Create */}
                            <div className="border-b dark:border-gray-600 px-4 py-2 text-sm font-medium text-gray-500 dark:text-gray-300 bg-gray-50 dark:bg-gray-700">
                                Create New
                            </div>
                            {menuItems.map((child, index) => (
                                child.type === "function" && (
                                    <div
                                        key={`menu_item_${index}`}
                                        onClick={(e) => runCreateItemButtonFunction(e, child)}
                                        className="flex items-center gap-3 px-4 py-3 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-600 transition-colors cursor-pointer"
                                    >
                                        <Icon icon={<FontAwesomeIcon icon={child.icon} />} className="text-persian-green" />
                                        <span className="capitalize">{child.text}</span>
                                    </div>
                                )
                            ))}

                            {/* Section: Import */}
                            <div className="border-t dark:border-gray-600 px-4 py-2 text-sm font-medium text-gray-500 dark:text-gray-300 bg-gray-50 dark:bg-gray-700">
                                Import or Upload
                            </div>
                            <Link
                                to="/import/decks/csv"
                                className="flex items-center gap-3 px-4 py-3 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-600 transition-colors no-underline"
                            >
                                <Icon icon={<FontAwesomeIcon icon={faUpload} />} className="text-persian-green" />
                                <span>Upload CSV</span>
                            </Link>
                            <Link
                                to="/import/decks/quizlet"
                                className="flex items-center gap-3 px-4 py-3 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-600 transition-colors no-underline"
                            >
                                <Icon icon={<FontAwesomeIcon icon={faFileImport} />} className="text-persian-green" />
                                <span>Import Quizlet</span>
                            </Link>
                        </motion.div>

                        {/* Overlay */}
                        <div
                            onClick={() => setShowCreateItemMenu(false)}
                            className="fixed inset-0 bg-black bg-opacity-30 z-10"
                        />
                    </>
                )}
            </AnimatePresence>
        </div>
    </div>
</>


    )

}

export default CreateOrImportContentButton;