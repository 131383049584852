import React, {useEffect, useState, useCallback} from 'react';
import useLanguages from '../../hooks/useLanguages';
import CountryFlag from '../../components/languages/CountryFlag';
import Select from 'react-select';
import Button from '../../components/general/Button';
import {faSpinner} from '@fortawesome/pro-duotone-svg-icons';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NotificationMessage from '../../components/general/NotificationMessage';
import {Link} from 'react-router-dom';
import AddLanguagesModal from '../../components/languages/modals/AddLanguagesModal';
import {useLanguagesContext} from '../../contexts/LanguagesContext';
import {Helmet} from 'react-helmet';
import {motion} from 'framer-motion';
import Skeleton from 'react-loading-skeleton';
import GeneralHamburgerMenu from '../../components/general/GeneralHamburgerMenu';
import CustomSkeleton from '../../components/general/CustomSkeleton';

const MyLanguages = () => {
    let {getRealtimeUserLanguagesFromCurrentUser, getAllLanguages, updateLanguages} = useLanguages();
    const {sourceLanguageOptions} = useLanguagesContext();
    const [userDbLanguagesData, setUserDbLanguagesData] = useState(null);
    const [languages, setLanguages] = useState(null);
    const [oldLanguages, setOldLanguages] = useState(null);

    const [userLanguagesUpdates, setUserLanguagesUpdates] = useState([]);
    const [languagesToBeDeleted, setLanguagesToBeDeleted] = useState([]);

    const [loading, setLoading] = useState(true);
    const [savingChanges, setSavingChanges] = useState(false);
    const [saveButtonEnabled, setSaveButtonEnabled] = useState(true);

    const [showChangesSavedConfirmation, setShowChangesSavedConfirmation] = useState(false);

    useEffect(()=>{
        const fetchData = async () => {
            getRealtimeUserLanguagesFromCurrentUser(setUserDbLanguagesData);
        }
    
        if (loading === true){
            fetchData();
        } 
    },[]);

    useEffect(()=>{
        const fetchData = async () => {
            let all_languages = await getAllLanguages();
            setLanguages({'user': userDbLanguagesData.map(a => ({...a})), 'all': {...all_languages}});
            setOldLanguages({'user': userDbLanguagesData.map(a => ({...a})), 'all': {...all_languages}});
            setLoading(false);
        }
        if (userDbLanguagesData !== null){
            fetchData();
        }
    },[userDbLanguagesData])

    const equalObjects = useCallback((new_array, old_array) => {
        // checks if state has updated
        let equal = true;
        let newState = [];
        let foundOldLangs = [];
        new_array.forEach((lang, l_index)=>{
            old_array.forEach((old_lang, old_l_index)=>{
                if (lang.target_language === old_lang.target_language){
                    foundOldLangs.push(old_lang.target_language);
                    //console.log("Matching elements: ", lang, old_lang);
                    if ((lang['level'] !== old_lang['level']) || (lang['currently_studying'] !== old_lang['currently_studying']) || (lang['source_language'] !== old_lang['source_language']) || (lang['source_script'] !== old_lang['source_script']) || (lang['target_script'] !== old_lang['target_script']) || (lang['user_type'] !== old_lang['user_type']) || (lang['display_pinyin'] !== old_lang['display_pinyin'])){
                        //console.log("Not equal.", lang, lang['level'], old_lang['level']);
                        equal = false;
                        let newAdded = false;
                        if (userLanguagesUpdates.length > 0){
                            for (let already_added of userLanguagesUpdates){
                                //console.log("Already added:", already_added);
                                if (already_added.target_language  === lang.target_language){
                                    newState.push({...lang}); //replaces
                                    newAdded = true;
                                }
                                else {
                                    newState.push({...already_added});
                                }
                            }
                            if (!newAdded){
                                newState.push({...lang});
                            }  
                        }
                        else {
                            newState.push({...lang});
                        }
                        //console.log("Endring i språket: ", newState);
                        setUserLanguagesUpdates(newState);
                    }
                    else {
                        // remove from updates - no change for this language after all
                        //console.log("Ingen oppdatering!", userLanguagesUpdates);
                        
                        if (userLanguagesUpdates.length > 0){
                            for (let already_added of userLanguagesUpdates){
                                if (already_added.target_language  !== lang.target_language){
                                    // adding all except for same language
                                    newState.push({...already_added});
                                }
                            }
                            //console.log("Updating from and to ", userLanguagesUpdates, newState);    
                            setUserLanguagesUpdates(newState);
                        }
                        

                    }
                }
            })
        });

        let delItems = [];
        if (new_array.length !== old_array.length){
            equal = false;
            // need to delete
            old_array.forEach((old_lang, old_l_index)=>{
                if (!foundOldLangs.includes(old_lang.target_language)){
                    delItems.push(old_lang.target_language);
                };
            });
            setLanguagesToBeDeleted(delItems);
        }
        return {'equal': equal, 'updates': newState, 'delete': delItems};

      },[]);

/*     useEffect(()=>{
        
        if (languages!== null && oldLanguages !== null && languages.user !== null && oldLanguages.user !== null){
            setLoading(false);
            //console.log("Should never change: ", oldLanguages.user);
            if (!equalObjects(languages.user, oldLanguages.user)){
                // a change has been made
                //console.log("Updating old state");
                setSaveButtonEnabled(true);
            }
            else {
                //console.log("No new state");
                setSaveButtonEnabled(false);

            }
        }
    },[languages, oldLanguages, equalObjects]); */

    useEffect(()=>{
        //console.log("Updates ready: ", userLanguagesUpdates);
    },[userLanguagesUpdates])

    const saveChangesClick = async () => {
        console.log("Updates: ", userLanguagesUpdates);
        let results = equalObjects(languages.user, oldLanguages.user);
        console.log(results);
        if (!results.equal){
            setSavingChanges(true);
            let success = await updateLanguages(results.updates, results.delete);
            setSavingChanges(false);
            if (success) {
                setShowChangesSavedConfirmation(true);
                setOldLanguages({'user': languages.user.map(a => ({...a})), 'all': {...languages.all}});
                setUserLanguagesUpdates([]);
            }
        }
    }

    //{JSON.stringify(userLanguages)}

    let languageLevelOptions = [{value: 'zero', label:'Wishlist'}, {value: 'native', label:'Native'}, {value: 'A1', label:'A1'}, {value:'A2', label:'A2'}, {value:'B1', label:'B1'}, {value:'B2', label:'B2'}, {value:'C1', label:'C1'}, {value:'C2', label:'C2'}];
    let languageLevelLabels = {zero: 'Wishlist', native: 'Native', A1: 'A1', A2: 'A2', B1: 'B1', B2: 'B2', C1: 'C1', C2:'C2'};
    let chineseScriptOptions = [{value: 'simplified', label: 'Simplified characters'}, {value: 'traditional', label: 'Traditional characters'},  {'label': 'Pinyin', 'value': 'pinyin'}, {'label': 'Zhuyin', 'value': 'zhuyin'}];
    let chineseScriptLabels = {simplified: 'Simplified characters', traditional: 'Traditional characters', pinyin: 'Pinyin', zhuyin: 'Zhuyin'};
    const userTypeOptions = [[{value: 'student', label: 'Student'}, {value: 'teacher', label: 'Teacher'}, {value: 'native', label: "It's my native language but I'm not teaching it"}], {'student': {value: 'student', label: 'Student'}, 'teacher': {value: 'teacher', label: 'Teacher'}, 'native': {value: 'native', label: "It's my native language but I'm not teaching it"}}];

    //console.log(languages);

    const [showAddLanguagesModal, setShowAddLanguagesModal] = useState(false);
    const closeAddLanguagesModal = () => {
        setShowAddLanguagesModal(false);
    }
    const addLanguagesClick = () => {
        setShowAddLanguagesModal(true);
    }

    /*
   <table className="w-full p-4 m-auto  shadow-lg bg-white my-4">   
                                    <thead>  
                                        <tr className="text-center font-bold">
                                            <th className="bg-persian-green w-2/6 text-white border px-8 py-4">
                                                Language
                                            </th>
                                            <th className="bg-persian-green w-3/6 text-white border  px-8 py-4">
                                                Source
                                            </th>
                                            <th className="bg-persian-green w-3/6 text-white border  px-8 py-4">
                                                Level
                                            </th>
                                            <th className="bg-persian-green w-1/6 text-white border px-8 py-4">
                                                Active?
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {languages.user.map((value)=>(
                                        <TableRow sourceLanguageOptions={sourceLanguageOptions} key={"lang_row_"+value.target_language} setLanguages={setLanguages} languages={languages} value={value} languageLevelLabels={languageLevelLabels} languageLevelOptions={languageLevelOptions} />
                                    ))}
                                    </tbody>
                                </table>
    */
    return (
        <>
            <div className=" ">
                
                <h1>My languages</h1>
                <div className="w-full mx-auto grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3">
                    {loading && 
                        <> 
                            {[1,2,3,4,5,6].map((value, index)=>(
                                <CustomSkeleton count={1} height={300} key={"skeleton_"+index} />
                            ))}
                        </>
                    }
                    {!loading && !savingChanges &&
                        <>
                            {languages.user.map((value, langIndex)=>(
                                <>
                                    <div key={"lang_"+langIndex}>
                                        <LanguageCard sourceLanguageOptions={sourceLanguageOptions} userTypeOptions={userTypeOptions} key={"lang_row_"+value.target_language} setLanguages={setLanguages} languages={languages} value={value} languageLevelLabels={languageLevelLabels} languageLevelOptions={languageLevelOptions} chineseScriptOptions={chineseScriptOptions} chineseScriptLabels={chineseScriptLabels} />
                                    </div>
                                </>
                            ))}
                        </>
                    } 
                </div>

                {(!loading && !savingChanges) ?
                    <> 

                            <div className="flex flex-row gap-3 justify-center">
                                <Button color="charcoal" text="Add languages" onClick={addLanguagesClick} />
                                <Button disabledDesign={!saveButtonEnabled ? true : false} color={saveButtonEnabled ? 'green' : 'bg-gray-500'}  text="Save changes" onClick={saveChangesClick} disabled={!saveButtonEnabled && true} />    
                            </div>
                        {showAddLanguagesModal && 
                            <>
                                <AddLanguagesModal closeFunction={closeAddLanguagesModal} />
                            </>
                        }
                        <NotificationMessage type="save-success" showMessageBool={showChangesSavedConfirmation} setShowMessageBool={setShowChangesSavedConfirmation} />
                    </>
                :
                    <>
                       <div>
                            <div className="h-screen overflow-hidden flex flex-col justify-center text-center text-3xl">
                                <FontAwesomeIcon icon={faSpinner} className="fa-pulse" />
                            </div>
                       </div> 
                    </>
                }
            </div>
        </>
    )
}

const LanguageCard = ({languages, sourceLanguageOptions, setLanguages, userTypeOptions, value, languageLevelLabels, languageLevelOptions, chineseScriptOptions, chineseScriptLabels}) => {
    console.log(languages)
    const handleLanguageLevelChange = (option) => {
        let newState = {...languages}; 
        languages.user.forEach((elem, index) => {
            if (elem.target_language === value.target_language){
                newState['user'][index]['level'] = option.value;
            }
        })
        setLanguages(newState);
    }

    useEffect(()=>{
        let newState = {...languages}; 
        languages.user.forEach((elem, index) => {
            if (elem.target_language === value.target_language){
                if (!newState['user'][index].hasOwnProperty('user_type')){
                    newState['user'][index]['user_type'] = 'student';
                    setLanguages(newState);
                }
                if (value.target_languge === "zh"){
                    if (!newState['user'][index].hasOwnProperty('display_pinyin')){
                        newState['user'][index]['display_pinyin'] = true;
                        setLanguages(newState);
                    }
                }
            }
        });
    },[languages]);

    const handleSourceLanguageOnChange = (option) => {
        let newState = {...languages}; 
        languages.user.forEach((elem, index) => {
            if (elem.target_language === value.target_language){
                newState['user'][index]['source_language'] = option.value;
            }
        })
        setLanguages(newState);
    }

    const handleDisplayPinyinChange = (displayPinyin) => {
        let newState = {...languages};
        languages.user.forEach((elem, index) => {
            if (elem.target_language === value.target_language){
                newState['user'][index]['display_pinyin'] = displayPinyin;
            }
        })
        setLanguages(newState);
    }

    const handleActiveCheckboxChange = (currentlyStudying) =>  {
        //console.log("CHECKBOX");
        let newState = {...languages}; 
        languages.user.forEach((elem, index) => {
            if (elem.target_language === value.target_language){
                newState['user'][index]['currently_studying'] = currentlyStudying;
            }
        })
        setLanguages(newState);
    }

    const onDeleteClick = () => {
        let newState = {...languages}; 
        let newList = [];
        languages.user.forEach((elem, index) => {
            if (elem.target_language !== value.target_language){
                newList.push(elem);
            }
        })
        newState['user'] = newList;
        setLanguages(newState);
    }

    const handleTargetLanguageScriptOnChange = (option) => {
        console.log(option)
        let newState = {...languages}; 
        languages.user.forEach((elem, index) => {
            if (elem.target_language === value.target_language){
                newState['user'][index]['target_script'] = option.value;
            }
        })
        setLanguages(newState);
    }

    const handleSourceLanguageScriptOnChange = (option) => {
        let newState = {...languages}; 
        languages.user.forEach((elem, index) => {
            if (elem.target_language === value.target_language){
                newState['user'][index]['source_script'] = option.value;
            }
        })
        setLanguages(newState);
    }

    const handleUserTypeChange = (option) => {
        let newState = {...languages};
        languages.user.forEach((elem, index) => {
            if (elem.target_language === value.target_language){
                newState['user'][index]['user_type'] = option.value;
            }
        })
        setLanguages(newState);
    };
    
    console.log({value: value.target_script ? value.target_script : null, label: value.target_script ? chineseScriptLabels[value.target_script] : null})
    return (
        <>
               <div className="relative p-8 rounded-xl shadow-xl bg-white dark:bg-gray-800 flex flex-col gap-6 place-items-center">
  {/* Hamburger Menu */}
  <div className="absolute right-0 top-0 p-3">
    <GeneralHamburgerMenu
      onChange={onDeleteClick}
      options={[{ label: "Delete language", icon: faTrash }]}
    />
  </div>

  {/* Language Title */}
  <h2 className="text-charcoal dark:text-gray-200">
    {languages['all'][0][value.target_language]['name']['en']}
  </h2>

  {/* Country Flag */}
  <div className="rounded-xl shadow-lg overflow-hidden">
    <CountryFlag
      animation={false}
      showToolTip={false}
      countryCode={languages['all'][0][value.target_language]['country_flag']}
      size="8em"
    />
  </div>

  {/* Current Language Level */}
  <div className="flex w-full flex-col gap-2">
    <div className="muted-text dark-mode-muted-text font-semibold">
      My current {languages['all'][0][value.target_language]['name']['en']} level
    </div>
    <div>
      <Select
        selectPros={{ id: value.target_language }}
        value={{
          value: value.level,
          label: languageLevelLabels[value.level],
        }}
        isSearchable={false}
        options={languageLevelOptions}
        onChange={handleLanguageLevelChange}
                className="text-black"
      />
    </div>
  </div>

  {/* Chinese Script Settings */}
  {value.target_language === 'zh' && (
    <>
      <div className="flex w-full flex-col gap-2">
        <div className="muted-text dark-mode-muted-text font-semibold">
          Default {languages['all'][0][value.target_language]['name']['en']} script (target)
        </div>
        <div>
          <Select
            selectPros={{ id: "target_script" }}
            value={{
              value: value.target_script ? value.target_script : null,
              label: value.target_script
                ? chineseScriptLabels[value.target_script]
                : null,
            }}
            isSearchable={false}
            options={chineseScriptOptions}
            onChange={handleTargetLanguageScriptOnChange}
                    className="text-black"
          />
        </div>
      </div>
      {(value.target_script === 'simplified' || value.target_script === 'traditional') && (
        <div className="flex w-full flex-col gap-2">
          <div className="muted-text dark-mode-muted-text font-semibold">
            Display automatic pinyin above the characters?
          </div>
          <div>
            <label className="flex flex-row gap-3 cursor-pointer">
              <input
                type="radio"
                name={`show_pinyin_${value.target_language}`}
                checked={value.display_pinyin}
                onChange={() => handleDisplayPinyinChange(true)}
              />
              <div className="text-sm dark-mode-muted-text">
                Yes, show pinyin above the characters.
              </div>
            </label>
          </div>
          <div>
            <label className="flex flex-row gap-3 cursor-pointer">
              <input
                type="radio"
                name={`show_pinyin_${value.target_language}`}
                checked={!value.display_pinyin}
                onChange={() => handleDisplayPinyinChange(false)}
              />
              <div className="text-sm dark-mode-muted-text">
                No, don't show pinyin by default.
              </div>
            </label>
          </div>
        </div>
      )}
    </>
  )}

  {/* Source Language */}
  <div className="flex w-full flex-col gap-2">
    <div className="muted-text dark-mode-muted-text font-semibold">
      The language I'm translating into (source)
    </div>
    <div>
      <Select
        placeholder="Source language"
        value={sourceLanguageOptions[1][value.source_language]}
        isSearchable={true}
        options={sourceLanguageOptions[0]}
        onChange={handleSourceLanguageOnChange}
                className="text-black"
      />
    </div>
  </div>

  {/* Source Script for Chinese */}
  {value.source_language === 'zh' && (
    <div className="flex w-full flex-col gap-2">
      <div className="muted-text dark-mode-muted-text font-semibold">
        Default {languages['all'][0][value.source_language]['name']['en']} script (source)
      </div>
      <div>
        <Select
          selectPros={{ id: "source_script" }}
          value={{
            value: value.source_script ? value.source_script : null,
            label: value.source_script
              ? chineseScriptLabels[value.source_script]
              : null,
          }}
          isSearchable={false}
          options={chineseScriptOptions}
          onChange={handleSourceLanguageScriptOnChange}
                  className="text-black"
        />
      </div>
    </div>
  )}

  {/* User Type */}
  <div className="flex w-full flex-col gap-2">
    <div className="muted-text dark-mode-muted-text font-semibold">
      Are you a student or teacher in this language?
    </div>
    <div>
      <Select
        selectPros={{ id: "student_select" }}
        value={{
          value: value.user_type ? value.user_type : null,
          label: value.user_type
            ? userTypeOptions[1][value.user_type]["label"]
            : null,
        }}
        isSearchable={false}
        options={userTypeOptions[0]}
        onChange={handleUserTypeChange}
        className="text-black"
      />
    </div>
  </div>

  {/* Currently Studying Status */}
  <div className="flex w-full flex-col gap-2">
    <div className="muted-text dark-mode-muted-text font-semibold">
      {value?.user_type === "native"
        ? "Do you still want it to show up in your dashboard?"
        : `Are you currently ${
            value?.user_type === "teacher" ? "teaching" : "learning"
          } this language?`}
    </div>
    <div>
      <label className="flex flex-row gap-3 cursor-pointer">
        <input
          type="radio"
          name={`currently_studying_${value.target_language}`}
          checked={value.currently_studying}
          onChange={() => handleActiveCheckboxChange(true)}
        />
        <div className="text-sm dark-mode-muted-text">
          Yes, this language should show up in my dashboard.
        </div>
      </label>
    </div>
    <div className="flex flex-row gap-3">
      <label className="flex flex-row gap-3 cursor-pointer">
        <input
          type="radio"
          name={`currently_studying_${value.target_language}`}
          checked={!value.currently_studying}
          onChange={() => handleActiveCheckboxChange(false)}
        />
        <div className="text-sm dark-mode-muted-text">
          {value?.user_type === "native"
            ? "No, I don't want this language to show up on my dashboard."
            : `No, I'm not currently ${
                value?.user_type === "teacher" ? "teaching" : "studying"
              } this language and don't want it to show up in my dashboard.`}
        </div>
      </label>
    </div>
  </div>
</div>


                
        </>
    )
}

const TableRow = ({languages, sourceLanguageOptions, setLanguages, value, languageLevelLabels, languageLevelOptions}) => {

    const handleLanguageLevelChange = (option) => {
        let newState = {...languages}; 
        languages.user.forEach((elem, index) => {
            if (elem.target_language === value.target_language){
                newState['user'][index]['level'] = option.value;
            }
        })
        setLanguages(newState);
    }

    const handleSourceLanguageOnChange = (option) => {
        let newState = {...languages}; 
        languages.user.forEach((elem, index) => {
            if (elem.target_language === value.target_language){
                newState['user'][index]['source_language'] = option.value;
            }
        })
        setLanguages(newState);
    }

    const handleActiveCheckboxChange = (e) =>  {
        //console.log("CHECKBOX");
        let newState = {...languages}; 
        languages.user.forEach((elem, index) => {
            if (elem.target_language === value.target_language){
                newState['user'][index]['currently_studying'] = e.target.checked;
            }
        })
        setLanguages(newState);
    }

    const onDeleteClick = () => {
        let newState = {...languages}; 
        let newList = [];
        languages.user.forEach((elem, index) => {
            if (elem.target_language !== value.target_language){
                newList.push(elem);
            }
        })
        newState['user'] = newList;
        setLanguages(newState);
    }
    
    return (
        <>
            <Helmet>
                <title>My languages - Yalango</title>
                <meta name="description" content={"The languages you're studying on Yalango."} />
            </Helmet>
            <tr className="bg-gray-50 relative">
                <td className="border py-4 flex flex-col gap-2 place-items-center text-sm sm:text-base sm:px-8 sm:py-4">
                    <Link to={'/learn/'+languages['all'][0][value.target_language]['name']['en'].replace(' ', '-')}><CountryFlag size="3em" countryCode={languages['all'][0][value.target_language]['country_flag']} flagName={languages['all'][0][value.target_language]['name']['en']} /></Link>
                    <div><Link className="no-underline italic text-sm text-gray-600" to={'/learn/'+languages['all'][0][value.target_language]['name']['en'].replace(' ', '-')}>{languages['all'][0][value.target_language]['name']['en']}</Link></div>
                </td>
                <td>
                    <Select placeholder="Source language" value={sourceLanguageOptions[1][value.source_language]} isSearchable={ false } options={sourceLanguageOptions[0]} onChange={handleSourceLanguageOnChange} />
                </td>
                <td className="border py-4 sm:px-8 sm:py-4">
                    <Select placeholder="Choose a level" selectPros={{id: value.target_language}} value={{value: value.level, label: languageLevelLabels[value.level]}} isSearchable={ false } options={languageLevelOptions} onChange={handleLanguageLevelChange} />
                </td>
                <td className="border py-4 sm:px-8 sm:py-4">
                    <input type="checkbox" checked={value.currently_studying} onChange={handleActiveCheckboxChange} />
                </td>
                <motion.div whileHover={{scale:1.03}} onClick={onDeleteClick} className="absolute cursor-pointer left-0 top-0 p-4 text-burnt-sienna">
                    <FontAwesomeIcon icon={faTrash} />            
                </motion.div>
            </tr>
        </>
    )
}

export default MyLanguages;
