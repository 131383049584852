import React, {useState, useEffect} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import QuizGame from '../../../components/vocabulary/games/quiz/QuizGame';
import FlashcardsGame from '../../../components/vocabulary/games/flashcards/FlashcardsGame';
import ScoreScreen from '../../../components/vocabulary/games/common-assets/ScoreScreen';
import InputGame from '../../../components/vocabulary/games/input/InputGame';
import MemoryGame from '../../../components/vocabulary/games/memory/MemoryGame';
import BrowseGame from '../../../components/vocabulary/games/browse/BrowseGame';
import ContextGame from '../../../components/vocabulary/games/context/ContextGame';
import WriteHanziGame from '../../../components/vocabulary/games/hanzi/write-hanzi/WriteHanziGame';
import HanziGames from '../../../components/vocabulary/games/hanzi/HanziGames';

import MixGame from '../../../components/vocabulary/games/mix/MixGame';
import LoadingGame from '../../../components/vocabulary/games/common-assets/LoadingGame';
import HelpMenu from '../../../components/vocabulary/games/common-assets/HelpMenu';
import useDecks from '../../../hooks/useDecks';
import useTextToSpeech from '../../../hooks/useTextToSpeech';
import GeneralErrorPage from '../../../components/errors/GeneralErrorPage';
import GeneralSuccessPage from '../../../components/success/GeneralSuccessPage';
import FlashcardHelpModal from '../../../components/vocabulary/games/flashcards/modals/FlashcardHelpModal';
import QuizHelpModal from '../../../components/vocabulary/games/quiz/modals/QuizHelpModal';
import InputHelpModal from '../../../components/vocabulary/games/input/modals/InputHelpModal';
import MemoryHelpModal from '../../../components/vocabulary/games/memory/modals/MemoryHelpModal';
import BrowseHelpModal from '../../../components/vocabulary/games/browse/modals/BrowseHelpModal';
import MixHelpModal from '../../../components/vocabulary/games/mix/modals/MixHelpModal';
import ScoreBar from '../../../components/vocabulary/games/common-assets/ScoreBar';

import { useAuth } from "../../../contexts/AuthContext";
import { useUserInfoContext } from '../../../contexts/UserInfoContext';

function DeckGame({isEmbed=false, type, isPublic, game, deckCardsFromParams=null, deckFromParams=null, avoidParamsContentId=false, avoidParamsTargetLanguage=false, manualParamNumberOfItems=null, nextLessonId=null}) {
    //
    let id = null; 
    let lang = null;
    let params = useParams();
    let courseId = null;
    let lessonId = null;
    if (!avoidParamsContentId && !avoidParamsTargetLanguage){
        params = useParams();
        id = params.id;
        lang = params.lang;
        if (type === "deck"){
            id = parseInt(id);
        }
    } else {
        id = avoidParamsContentId;
        lang = avoidParamsTargetLanguage;
        let params = useParams();
        courseId = params.courseId;
        lessonId = params.lessonId;
    }
    console.log("Deck game: ", id, lang, type, game)
    const {loadAllGameData, 
        refreshAllGameData} = useDecks();

    const {getStandardForvoPronunciation, 
        textToSpeechWithGoogle} = useTextToSpeech();
    let {isPremium} = useAuth();
    const [howManyItems, setHowManyItems] = useState(manualParamNumberOfItems);
    const [targetLanguage, setTargetLanguage] = useState(lang);
    const [playMode, setPlayMode] = useState(null);
    const [playModeArray, setPlayModeArray] = useState(null);
    const [level, setLevel] = useState(null);
    const [backToPlayPageLink, setBackToPlayPageLink] = useState(null);
    const [deck, setDeck] = useState(null);
    const [decks, setDecks] = useState(null);
    const [paramsLoading, setParamsLoading] = useState(true);
    const [loadingItems, setLoadingItems] = useState(true);
    const [showLoadingScreen, setShowLoadingScreen] = useState(true);
    const [apiFetchStarted, setApiFetchStarted] = useState(false);
    const [error, setError] = useState(false);
    const [errorObject, setErrorObject] = useState("");
    const [cards, setCards] = useState(null);
    const [mistakes, setMistakes] = useState([]);
    const [laterMistakes, setLaterMistakes] = useState([]);
    const navigate = useNavigate();

    const refreshData = async () => {
        await refreshAllGameData(setTargetLanguage,courseId, nextLessonId, setCards, game, setShowLoadingScreen, id, howManyItems, type, setLoadingItems, setApiFetchStarted, setDeck, setDecks, lang, level, playMode, setPlayMode, setPlayModeArray).catch((error)=>{
            setError(true);
            setErrorObject(error);
        });;
    }

    const loadAllDataFunction = async () => {
        console.log("Manual call to load all data function: ", courseId, nextLessonId, setHowManyItems, game, type, setBackToPlayPageLink, id, setParamsLoading, setApiFetchStarted, setDeck, setDecks, setCards, setLoadingItems, isPublic, lang, setLevel, setPlayMode, setPlayModeArray)
        await loadAllGameData(setTargetLanguage, courseId, nextLessonId, setHowManyItems, game, type, setBackToPlayPageLink, id, setParamsLoading, setApiFetchStarted, setDeck, setDecks, setCards, setLoadingItems, isPublic, lang, setLevel, setPlayMode, setPlayModeArray).catch((error)=>{
            setError(true);
            setErrorObject(error);
        });
    };

    const resetAllVariables = () => {
        setPlayMode(null);
        setCurrentCardIndex(0);
        setScore(0);
        setNbOfAnswers(0);
        setCardsLeft(0);
        setPlayModeArray(null);
        setBackToPlayPageLink(null);
        setCards(null);
        setDeck(null);
        setDecks(null);
        setParamsLoading(true);
        setLoadingItems(true);
        setShowLoadingScreen(true);
        setApiFetchStarted(false);
        setError(false);
        setErrorObject("");
        setMistakes([]);
        setLaterMistakes([]);
        //setGameScreen("game");
    }

    useEffect(()=>{
        const fetchData = async () => {
            await loadAllGameData(setTargetLanguage, courseId, nextLessonId, setHowManyItems, game, type, setBackToPlayPageLink, id, setParamsLoading, setApiFetchStarted, setDeck, setDecks, setCards, setLoadingItems, isPublic, lang, setLevel, setPlayMode, setPlayModeArray).catch((error)=>{
                setError(true);
                setErrorObject(error);
            });
        }
        console.log("fetching ", Math.random())   
        fetchData();
    },[]);

    const [audioLoading, setAudioLoading] = useState(false);
    const [audioUrl, setAudioUrl] = useState(null);
    const [audioDocId, setAudioDocId] = useState(null);
    const [showHelpModal, setShowHelpModal] = useState(false);
    const [helpModalGameName, setHelpModalGameName] = useState(null);

    const {userSettings} = useUserInfoContext();

    const [autoPlayPronunciations, setAutoPlayPronunciations] = useState(false);

    useEffect(()=>{
        if (userSettings !== null && userSettings !== undefined && userSettings.hasOwnProperty("autoplayGameAudio")){
            setAutoPlayPronunciations(userSettings.autoplayGameAudio);
        }
    },[userSettings]);

    // game
    const [gameScreen, setGameScreen] = useState(type === "review-mistakes" ? "review-mistakes" : "game"); //options: game, endscreen, review-mistakes, review-mistakes-end-screen
    const [score, setScore] = useState(0);
    const [currentCardIndex, setCurrentCardIndex] = useState(0);
    const [nbOfAnswers, setNbOfAnswers] = useState(0);
    const [cardsLeft, setCardsLeft] = useState(0);
    const [reviewFinished, setReviewFinished] = useState(false);

    const closeHelpModal = () => {
        setShowHelpModal(false);
    }

    const helpClick = () => {
        setShowHelpModal(true);
        setHelpModalGameName(game);
    }

    const autoPlayPronunciationsClick = () => {
        setAutoPlayPronunciations(!autoPlayPronunciations);
    }

    useEffect(()=>{
        if (cards !== null){
            setCardsLeft(cards.length);
        }
    },[cards]);

    const playAgain = async () =>{
        setGameScreen("game");
        await refreshData();
/*         if (cards !== null){
            setCardsLeft(cards.length);
        } else {
            setCardsLeft(0);
        } */
        setScore(0);
        setCurrentCardIndex(0);
        setReviewFinished(false);
        setNbOfAnswers(0);
        setMistakes([]);
        setLaterMistakes([]);
    }

    const reviewMistakes = () => {
        if (mistakes === null || mistakes === undefined){return false}
        setCards(mistakes);
        setScore(0);
        setNbOfAnswers(0);
        setCardsLeft(mistakes.length);
        setCurrentCardIndex(0);
        setGameScreen("review-mistakes");
        setMistakes([]);
    }

    const nextCourseLessonClick = async () => {
        // next lesson
        //nextLessonId
        if (nextLessonId !== null){
            navigate(`/course/${courseId}/lesson/${nextLessonId}`);
            console.log("Navigating to next lesson: ", nextLessonId)
            //setShowLoadingScreen(true);
            //await loadAllDataFunction();
    /*         if (cards !== null){
                setCardsLeft(cards.length);
            } else {
                setCardsLeft(0);
            } */
/*             setScore(0);
            setCurrentCardIndex(0);
            setReviewFinished(false);
            setNbOfAnswers(0);
            setMistakes([]);
            setLaterMistakes([]);
            setGameScreen("game"); */
        } else {
            navigate(`/course/${courseId}`);
        }
    }

    const helpModals = {
        'flashcards': <FlashcardHelpModal closeFunction={closeHelpModal} />, 
        'quiz': <QuizHelpModal closeFunction={closeHelpModal} />, 
        'input': <InputHelpModal closeFunction={closeHelpModal} />, 
        'memory': <MemoryHelpModal closeFunction={closeHelpModal} />, 
        'browse':  <BrowseHelpModal closeFunction={closeHelpModal} />, 
        'mix':  <MixHelpModal closeFunction={closeHelpModal} />
    }

    const googleTextToSpeechClick = async (card) => {
        console.log(card);
        try {
        if (card !== undefined && card !== undefined && !audioLoading){
            if (card.hasOwnProperty('google_tts')){
                setAudioLoading(true);
                let audio = new Audio(card['google_tts']);
                try {
                    await audio.play();
                    audio.onended = () => {
                        setAudioLoading(false);
                    }
                } catch (error){
                    console.log(error);
                    setAudioLoading(false);
                }
            } else if (card.doc_id === audioDocId && audioUrl !== null){
                setAudioLoading(true);
                let audio = new Audio(audioUrl);
                try {
                await audio.play();
                audio.onended = () => {
                    setAudioLoading(false);
                }
                } catch (error){
                    console.log(error);
                    setAudioLoading(false);
                }
            }
            /*
            else {
                setAudioLoading(true);
                let r =  await textToSpeechWithGoogle(card, type);
                if (r !== null){
                    setAudioUrl(r);
                    setAudioDocId(card.doc_id);
                    let audio = new Audio(r);
                    audio.play();
                    audio.onended = () => {
                        setAudioLoading(false);
                    }
    /*                 if (game !== "quiz"){
                        let copyCards = cards.map(item => {
                            if (item.doc_id === card.doc_id){
                                item['google_tts'] = r;
                                return item;
                            } else {
                                return item;
                            }
                        });
                        setCards(copyCards);
                    } 
                } else {
                    setAudioLoading(false);
                }
            }
            */
            
        }
        } catch (error){
            console.log(error)
        }
    }
    return (
        <>
            <div className="py-8 px-4 sm:px-8 min-h-screen h-full overflow-y-auto flex flex-col gap-4">
                {!error ?
                    <>
                        {showHelpModal && 
                            <>
                                {helpModals[helpModalGameName]}
                            </>
                        }
                        {!loadingItems && gameScreen !== "endscreen" &&
                            <>
                                <div className="flex flex-row place-items-center justify-center">
                                    <div className="flex-1">

                                    </div>
                                    <div className="flex flex-row justify-center">
                                        <ScoreBar score={score} nbOfAnswers={nbOfAnswers} cardsLeft={cardsLeft} />
                                    </div>
                                    <div className="flex-1">
                                        <HelpMenu isEmbed={isEmbed} targetLanguage={targetLanguage} isPremium={isPremium} playMode={playMode} autoPlayPronunciations={autoPlayPronunciations} setAutoPlayPronunciations={setAutoPlayPronunciations} autoPlayPronunciationsClick={autoPlayPronunciationsClick} backToPlayPageLink={backToPlayPageLink} helpClick={helpClick} />
                                    </div>
                                </div>
                            </>
                        }
                        <div className="h-full flex justify-between place-items-center flex-col w-full">
                            {gameScreen === "game" &&
                                <>
                                    {game === "flashcards" &&
                                        <>
                                            <FlashcardsGame reviewFinished={reviewFinished} setReviewFinished={setReviewFinished} currentCardIndex={currentCardIndex} setCurrentCardIndex={setCurrentCardIndex} nbOfAnswers={nbOfAnswers} setNbOfAnswers={setNbOfAnswers} cardsLeft={cardsLeft} setCardsLeft={setCardsLeft} score={score} setScore={setScore} gameScreen={gameScreen} setGameScreen={setGameScreen} laterMistakes={laterMistakes} setLaterMistakes={setLaterMistakes} isPremium={isPremium} autoPlayPronunciations={autoPlayPronunciations} audioLoading={audioLoading} googleTextToSpeechClick={googleTextToSpeechClick} setPlayMode={setPlayMode} playMode={playMode} mistakes={mistakes} setMistakes={setMistakes} setCards={setCards} showLoadingScreen={showLoadingScreen} setShowLoadingScreen={setShowLoadingScreen} deck={deck} decks={decks} loadingItems={loadingItems} refreshData={refreshData} cards={cards} howManyItems={howManyItems} playModeArray={playModeArray} backToPlayPageLink={backToPlayPageLink} id={id} type={type} />
                                            <LoadingGame headline="Creating flashcards..." subtext="Tim is doing his best to create meaningful exercises for you."  showLoading={showLoadingScreen} setShowLoading={setShowLoadingScreen} loading={loadingItems} />
                                        </>
                                    }
                                    {game === "quiz" &&
                                        <>
                                            <QuizGame reviewFinished={reviewFinished} setReviewFinished={setReviewFinished} currentCardIndex={currentCardIndex} setCurrentCardIndex={setCurrentCardIndex} nbOfAnswers={nbOfAnswers} setNbOfAnswers={setNbOfAnswers} cardsLeft={cardsLeft} setCardsLeft={setCardsLeft} score={score} setScore={setScore} gameScreen={gameScreen} setGameScreen={setGameScreen} laterMistakes={laterMistakes} setLaterMistakes={setLaterMistakes} isPremium={isPremium} autoPlayPronunciations={autoPlayPronunciations} audioLoading={audioLoading} googleTextToSpeechClick={googleTextToSpeechClick} setPlayMode={setPlayMode} playMode={playMode} showLoadingScreen={showLoadingScreen} setShowLoadingScreen={setShowLoadingScreen} deck={deck} decks={decks} loadingItems={loadingItems} refreshData={refreshData} cards={cards} howManyItems={howManyItems} backToPlayPageLink={backToPlayPageLink} id={id} type={type} mistakes={mistakes} playModeArray={playModeArray} setMistakes={setMistakes} />
                                            <LoadingGame headline="Creating quiz..." subtext="Tim is creating an exciting quiz for you!" showLoading={showLoadingScreen} setShowLoading={setShowLoadingScreen} loading={loadingItems} />
                                        </>
                                    }
                                    {game === "input" &&
                                        <>
                                            <InputGame reviewFinished={reviewFinished} setReviewFinished={setReviewFinished} currentCardIndex={currentCardIndex} setCurrentCardIndex={setCurrentCardIndex} nbOfAnswers={nbOfAnswers} setNbOfAnswers={setNbOfAnswers} cardsLeft={cardsLeft} setCardsLeft={setCardsLeft} score={score} setScore={setScore} gameScreen={gameScreen} setGameScreen={setGameScreen} laterMistakes={laterMistakes} setLaterMistakes={setLaterMistakes} isPremium={isPremium} autoPlayPronunciations={autoPlayPronunciations} audioLoading={audioLoading} googleTextToSpeechClick={googleTextToSpeechClick} setPlayMode={setPlayMode} playMode={playMode} showLoadingScreen={showLoadingScreen} setShowLoadingScreen={setShowLoadingScreen} deck={deck} decks={decks} loadingItems={loadingItems} refreshData={refreshData} cards={cards} howManyItems={howManyItems} backToPlayPageLink={backToPlayPageLink} id={id} type={type} mistakes={mistakes} playModeArray={playModeArray} setMistakes={setMistakes} />
                                            <LoadingGame headline="Creating exercises..." subtext="Tim is doing his best to create meaningful exercises for you." showLoading={showLoadingScreen} setShowLoading={setShowLoadingScreen} loading={loadingItems} />
                                        </>
                                    }
                                    {game === "memory" &&
                                        <>
                                            <MemoryGame reviewFinished={reviewFinished} setReviewFinished={setReviewFinished} currentCardIndex={currentCardIndex} setCurrentCardIndex={setCurrentCardIndex} nbOfAnswers={nbOfAnswers} setNbOfAnswers={setNbOfAnswers} cardsLeft={cardsLeft} setCardsLeft={setCardsLeft} score={score} setScore={setScore} gameScreen={gameScreen} setGameScreen={setGameScreen} laterMistakes={laterMistakes} setLaterMistakes={setLaterMistakes} isPremium={isPremium} autoPlayPronunciations={autoPlayPronunciations} setPlayMode={setPlayMode} playMode={playMode} showLoadingScreen={showLoadingScreen} setShowLoadingScreen={setShowLoadingScreen} deck={deck} decks={decks} loadingItems={loadingItems} refreshData={refreshData} cards={cards} howManyItems={howManyItems} backToPlayPageLink={backToPlayPageLink} id={id} type={type} mistakes={mistakes} playModeArray={playModeArray} setMistakes={setMistakes} />
                                            <LoadingGame headline="Creating memory grid..." subtext="Tim is currently choosing which words to include this round." showLoading={showLoadingScreen} setShowLoading={setShowLoadingScreen} loading={loadingItems} />
                                        </>
                                    }
                                    {game === "browse" &&
                                        <>
                                            <BrowseGame reviewFinished={reviewFinished} setReviewFinished={setReviewFinished} currentCardIndex={currentCardIndex} setCurrentCardIndex={setCurrentCardIndex} nbOfAnswers={nbOfAnswers} setNbOfAnswers={setNbOfAnswers} cardsLeft={cardsLeft} setCardsLeft={setCardsLeft} score={score} setScore={setScore} gameScreen={gameScreen} setGameScreen={setGameScreen} laterMistakes={laterMistakes} setLaterMistakes={setLaterMistakes} isPremium={isPremium} autoPlayPronunciations={autoPlayPronunciations} audioLoading={audioLoading} setAudioLoading={setAudioLoading} googleTextToSpeechClick={googleTextToSpeechClick} getStandardForvoPronunciation={getStandardForvoPronunciation} setPlayMode={setPlayMode} playMode={playMode} showLoadingScreen={showLoadingScreen} setShowLoadingScreen={setShowLoadingScreen} deck={deck} decks={decks} loadingItems={loadingItems} refreshData={refreshData} cards={cards} howManyItems={howManyItems} playModeArray={playModeArray} backToPlayPageLink={backToPlayPageLink} id={id} type={type} mistakes={mistakes} setMistakes={setMistakes} />
                                            <LoadingGame headline="Loading items..." subtext={"Tim is currently fetching cards for you to study."} showLoading={showLoadingScreen} setShowLoading={setShowLoadingScreen} loading={loadingItems} />
                                        </>
                                    }
                                     {game === "context" &&
                                        <>
                                            <ContextGame reviewFinished={reviewFinished} setReviewFinished={setReviewFinished} currentCardIndex={currentCardIndex} setCurrentCardIndex={setCurrentCardIndex} nbOfAnswers={nbOfAnswers} setNbOfAnswers={setNbOfAnswers} cardsLeft={cardsLeft} setCardsLeft={setCardsLeft} score={score} setScore={setScore} gameScreen={gameScreen} setGameScreen={setGameScreen} laterMistakes={laterMistakes} setLaterMistakes={setLaterMistakes} isPremium={isPremium} autoPlayPronunciations={autoPlayPronunciations} audioLoading={audioLoading} setAudioLoading={setAudioLoading} googleTextToSpeechClick={googleTextToSpeechClick} getStandardForvoPronunciation={getStandardForvoPronunciation} setPlayMode={setPlayMode} playMode={playMode} showLoadingScreen={showLoadingScreen} setShowLoadingScreen={setShowLoadingScreen} deck={deck} decks={decks} loadingItems={loadingItems} refreshData={refreshData} cards={cards} howManyItems={howManyItems} playModeArray={playModeArray} backToPlayPageLink={backToPlayPageLink} id={id} type={type} mistakes={mistakes} setMistakes={setMistakes} />
                                            <LoadingGame headline="Loading items..." subtext={"Tim is currently finding sentences for you."} showLoading={showLoadingScreen} setShowLoading={setShowLoadingScreen} loading={loadingItems} />
                                        </>
                                    }
                                     {(game === "write-hanzi" || game === "animate-hanzi" || game === "stroke-order-hanzi") &&
                                        <>
                                            <HanziGames hanziGame={game} reviewFinished={reviewFinished} setReviewFinished={setReviewFinished} currentCardIndex={currentCardIndex} setCurrentCardIndex={setCurrentCardIndex} nbOfAnswers={nbOfAnswers} setNbOfAnswers={setNbOfAnswers} cardsLeft={cardsLeft} setCardsLeft={setCardsLeft} score={score} setScore={setScore} gameScreen={gameScreen} setGameScreen={setGameScreen} laterMistakes={laterMistakes} setLaterMistakes={setLaterMistakes} isPremium={isPremium} autoPlayPronunciations={autoPlayPronunciations} audioLoading={audioLoading} setAudioLoading={setAudioLoading} googleTextToSpeechClick={googleTextToSpeechClick} getStandardForvoPronunciation={getStandardForvoPronunciation} setPlayMode={setPlayMode} playMode={playMode} showLoadingScreen={showLoadingScreen} setShowLoadingScreen={setShowLoadingScreen} deck={deck} decks={decks} loadingItems={loadingItems} refreshData={refreshData} cards={cards} howManyItems={howManyItems} playModeArray={playModeArray} backToPlayPageLink={backToPlayPageLink} id={id} type={type} mistakes={mistakes} setMistakes={setMistakes} />
                                            <LoadingGame headline="Loading items..." subtext={"Tim is currently choosing the characters for you."} showLoading={showLoadingScreen} setShowLoading={setShowLoadingScreen} loading={loadingItems} />
                                        </>
                                    }
                                    {game === "mix" &&
                                        <>
                                            <MixGame reviewFinished={reviewFinished} setReviewFinished={setReviewFinished} currentCardIndex={currentCardIndex} setCurrentCardIndex={setCurrentCardIndex} nbOfAnswers={nbOfAnswers} setNbOfAnswers={setNbOfAnswers} cardsLeft={cardsLeft} setCardsLeft={setCardsLeft} score={score} setScore={setScore} gameScreen={gameScreen} setGameScreen={setGameScreen} laterMistakes={laterMistakes} setLaterMistakes={setLaterMistakes} isPremium={isPremium} autoPlayPronunciations={autoPlayPronunciations} ScoreScreen={ScoreScreen} FlashcardsGame={FlashcardsGame} QuizGame={QuizGame} InputGame={InputGame} audioLoading={audioLoading} googleTextToSpeechClick={googleTextToSpeechClick} setPlayMode={setPlayMode} playMode={playMode} mistakes={mistakes} setMistakes={setMistakes} setCards={setCards} showLoadingScreen={showLoadingScreen} setShowLoadingScreen={setShowLoadingScreen} deck={deck} decks={decks} loadingItems={loadingItems} playModeArray={playModeArray} setPlayModeArray={setPlayModeArray} refreshData={refreshData} cards={cards} howManyItems={howManyItems} backToPlayPageLink={backToPlayPageLink} id={id} type={type}  />
                                            <LoadingGame headline={type === "course" ? "Loading lesson..." : "Creating mix..."} subtext={"Tim is doing his best to create meaningful exercises for you."}  showLoading={showLoadingScreen} setShowLoading={setShowLoadingScreen} loading={loadingItems} />
                                        </>
                                    }
                                </>
                            }
                            {gameScreen === "endscreen" &&
                                <>
                                    <ScoreScreen isEmbed={isEmbed} cards={cards} deck={deck} contentType={"deck"} reviewFinished={reviewFinished} nextLessonId={nextLessonId} type={type} setReviewFinished={setReviewFinished} score={score} mistakes={mistakes} nextCourseLessonClick={nextCourseLessonClick} reviewMistakesClick={reviewMistakes} nbOfAnswers={nbOfAnswers} backToPlayPageLink={backToPlayPageLink} playAgainClick={playAgain} courseId={courseId} />
                                </>
                            }
                            {gameScreen === "review-mistakes" &&
                                <>
                                    <LoadingGame headline="Fetching mistakes..." subtext="Tim is finding unreviewed mistakes for you to review."  showLoading={showLoadingScreen} setShowLoading={setShowLoadingScreen} loading={loadingItems} />
                                    <FlashcardsGame reviewFinished={reviewFinished} setReviewFinished={setReviewFinished} currentCardIndex={currentCardIndex} setCurrentCardIndex={setCurrentCardIndex} nbOfAnswers={nbOfAnswers} setNbOfAnswers={setNbOfAnswers} cardsLeft={cardsLeft} setCardsLeft={setCardsLeft} score={score} setScore={setScore} gameScreen={gameScreen} setGameScreen={setGameScreen} laterMistakes={laterMistakes} setLaterMistakes={setLaterMistakes} isPremium={isPremium} autoPlayPronunciations={autoPlayPronunciations} audioLoading={audioLoading} googleTextToSpeechClick={googleTextToSpeechClick} setPlayMode={setPlayMode} playMode={playMode} mistakes={mistakes} setMistakes={setMistakes} setCards={setCards} showLoadingScreen={showLoadingScreen} setShowLoadingScreen={setShowLoadingScreen} deck={deck} decks={decks} loadingItems={loadingItems} refreshData={refreshData} cards={cards} howManyItems={howManyItems} playModeArray={playModeArray} backToPlayPageLink={backToPlayPageLink} id={id} type={type} />

                                    {(cards !== null && cards.length === 0 && !showLoadingScreen) &&
                                        <>
                                            <GeneralSuccessPage goBackText={"Go back"} goBackLink={backToPlayPageLink} headline={"No mistakes found!"} message={"Great job, you did 0 mistakes the last 7 days in this language. Keep it up!"} />
                                        </>
                                    }
                                </>
                            }
                        </div>
                    </>
                :
                    <>
                        <GeneralErrorPage errorObject={errorObject} goBackLink={backToPlayPageLink} goBackText={"Back to games"} />
                    </>
                }
            </div>
        </>
    )
}

export default DeckGame;
