import {projectFirestore, timeStamp} from '../firebase/config.js';
import { useAuth } from "../contexts/AuthContext";
import {useUserInfoContext} from '../contexts/UserInfoContext';
import useLogs from './useLogs';
import {  httpsCallable } from "firebase/functions";

export default function useComments() {
    
    let {currentUser} = useAuth();
    const {saveErrorLog} = useLogs();
    const {userInfo} = useUserInfoContext();

    const fetchCommentsFromSourceId = async ({source_id, sortByValue}) => {
        let ref = projectFirestore.collection("comments");
        let query = ref.where("source_id", "==", source_id).where("comment_type", "==", "comment");
        if (sortByValue === "highest_rating"){
            query = query.orderBy("rating", "desc");
        } else if (sortByValue === "most_recent"){
            query = query.orderBy("created_timestamp", "desc")
        }
        let results = await query.get().catch(err=>{
            saveErrorLog({errorObject: err, functionName: "fetchCommentsFromSourceId"});
        });
        if (results.empty){return []}
        let com = [];
        results.forEach((comment, commentIndex)=>{
            let o = {...comment.data()};
            o['comment_doc_id'] = comment.ref.id;
            com.push(o);
        });
        return com;
    }

    const fetchCommentsByUid= async ({uid, sortByValue}) => {
        let ref = projectFirestore.collection("comments");
        let query = ref.where("uid", "==", uid);
        if (sortByValue === "highest_rating"){
            query = query.orderBy("rating", "desc");
        } else if (sortByValue === "most_recent"){
            query = query.orderBy("created_timestamp", "desc")
        }
        let results = await query.get().catch(err=>{
            saveErrorLog({errorObject: err, functionName: "fetchCommentsByUid"});
        });
        if (results.empty){return []}
        let com = [];
        results.forEach((comment, commentIndex)=>{
            let o = {...comment.data()};
            o['comment_doc_id'] = comment.ref.id;
            com.push(o);
        });
        return com;
    }

    const fetchAllComments = async ({limit, sortByValue}) => {
        let query = projectFirestore.collection("comments").where("comment_type", "==", "post");
        if (sortByValue === "highest_rating"){
            query = query.orderBy("rating", "desc");
        } else if (sortByValue === "most_recent"){
            query = query.orderBy("created_timestamp", "desc")
        }
        query = query.limit(limit);
        let results = await query.get().catch(err=>{
            saveErrorLog({errorObject: err, functionName: "fetchAllComments"});
        });
        if (results.empty){return []}
        let com = [];
        results.forEach((comment, commentIndex)=>{
            let o = {...comment.data()};
            o['comment_doc_id'] = comment.ref.id;
            com.push(o);
        });
        return com;
    }

    const fetchAllCommentsAndReplies = async ({limit, sortByValue}) => {
        let query = projectFirestore.collection("comments");
        if (sortByValue === "highest_rating"){
            query = query.orderBy("rating", "desc");
        } else if (sortByValue === "most_recent"){
            query = query.orderBy("created_timestamp", "desc")
        }
        query = query.limit(limit);
        let results = await query.get().catch(err=>{
            saveErrorLog({errorObject: err, functionName: "fetchAllCommentsAndReplies"});
        });
        if (results === undefined || results.empty){return []}
        let com = [];
        results.forEach((comment, commentIndex)=>{
            let o = {...comment.data()};
            o['comment_doc_id'] = comment.ref.id;
            com.push(o);
        });
        return com;
    }

    const fetchAllCommentsInRealtime = async ({limit, filterLanguage, sortByValue, setComments, setLoading}) => {
        let query = projectFirestore.collection("comments"); //where("comment_type", "==", "post")
        if (filterLanguage !== "all"){
            query = query.where("comment_language_feed", "==", filterLanguage)
        }
        if (sortByValue === "highest_rating"){
            query = query.orderBy("rating", "desc");
        } else if (sortByValue === "most_recent"){
            query = query.orderBy("created_timestamp", "desc")
        }
        query = query.limit(limit);
        setLoading(true);
        return query.onSnapshot(snapshot=>{
            let com = [];
            if (snapshot.empty){
                com = [];
            }
            for (const comment of snapshot.docs){
                let o = {...comment.data()};
                o['comment_doc_id'] = comment.ref.id;
                com.push(o);
            };
/*             if (sortByValue === "highest_rating"){
                com.sort((a, b) => b.rating - a.rating);
            } */
            console.log("Setting comments: ", com);
            setComments(com);
            setLoading(false);
        });
    }

    const fetchCommentReplies = async ({repliesDocIds}) => {
        const list = [];
        for (const docId of repliesDocIds){
            let snapshot = await projectFirestore.collection("comments").doc(docId).get().catch(err=>{
                saveErrorLog({errorObject: err, functionName: "fetchCommentReplies"});
            });
            if (snapshot.exists){
                list.push({...snapshot.data(), 'comment_doc_id': snapshot.id});
            }
        }
        list.sort((a, b) => b.rating - a.rating);
        return list;
    }

    const fetchCommentRepliesInRealtime = async ({parentCommentDocId, sortByValue, setComments, setLoading}) => {
        console.log(parentCommentDocId, sortByValue);
        let query = projectFirestore.collection("comments").where("comment_parent_doc_id", "==", parentCommentDocId);
        //
/*         if (sortByValue === "highest_rating"){
            query = query.orderBy("rating", "desc");
        } else if (sortByValue === "most_recent"){
            query = query.orderBy("created_timestamp", "desc");
        }  */
        query = query.limit(25);
        setLoading(true);
        return query.onSnapshot(snapshot=>{
            const list = [];
            snapshot.docs.forEach((comment, commentIndex)=>{
                let o = {...comment.data()};
                o['comment_doc_id'] = comment.ref.id;
                list.push(o);
            });
            list.sort((a, b) => b.rating - a.rating);
            console.log("List: ", list);
            setComments(list);
            setLoading(false);
        });
    }
    
    const postComment = async ({commentSource, newPostTextToBeCorrected, newPostAdditionalTextInCorrectionRequest, chosenTopic, chosenLanguage, commentSourceMetadata, commentBody, sourceId, postType, commentParentMetadata, commentCorrectionOriginal, yourCorrection, yourCorrectionExplanation, inputDesign}) => {
        if (userInfo === undefined || userInfo === null){return false}
        if (commentBody === "" && (commentCorrectionOriginal === "" || yourCorrection === "") && (newPostTextToBeCorrected === "")){return null}
        if (!(userInfo.hasOwnProperty("username") && userInfo.hasOwnProperty("displayname"))){return false}

        console.log(commentSource, commentSourceMetadata, commentBody, sourceId, postType, commentParentMetadata);
        let ref = projectFirestore.collection("comments");
        let comment = {
            'comment_source': commentSource, 
            'comment': commentBody,
            'uid': currentUser.uid,
            'rating': 0,
            'downvotes': 0,
            'upvotes': 0,
            'created_timestamp': timeStamp, 
            'last_updated_timestamp': timeStamp, 
            'author_username': userInfo.username,
            'author_name': userInfo.displayname, 
            'comment_type': postType
        }; 
        if (chosenTopic !== null && chosenTopic !== undefined){
            comment['comment_topic'] = chosenTopic.value;
        }
        if (chosenLanguage !== null && chosenLanguage !== undefined){
            comment['comment_language_feed'] = chosenLanguage.value;
        }
        if (newPostTextToBeCorrected !== undefined && newPostTextToBeCorrected !== ""){
            comment['comment_text_to_be_corrected_request'] = newPostTextToBeCorrected;
            comment['design'] = "corrections_request";
        }
        if (newPostAdditionalTextInCorrectionRequest !== undefined && newPostAdditionalTextInCorrectionRequest !== ""){
            comment['comment_additional_message_in_corrections_request'] = newPostAdditionalTextInCorrectionRequest;
            comment['design'] = "corrections_request";
        }
        if (inputDesign === "correction"){
            comment['design'] = "correction";
            comment['comment_correction_original'] = commentCorrectionOriginal;
            comment['comment_correction_submitted'] = yourCorrection;
            comment['comment_correction_explanation'] = yourCorrectionExplanation;
        } else if (!comment.hasOwnProperty("design")) { // not already added above
            comment["design"] = "standard";
        }
        if (commentParentMetadata !== undefined && commentParentMetadata !== null){
            comment["comment_parent_doc_id"] = commentParentMetadata.comment_doc_id;
            comment["comment_parent_uid"] = commentParentMetadata.uid;
            comment["comment_parent_author_name"] = commentParentMetadata.author_name;
            comment["comment_parent_author_username"] = commentParentMetadata.author_username;
        }
        if (commentSourceMetadata !== undefined && commentSourceMetadata !== null && sourceId !== null){
            comment['source_doc_id'] = commentSourceMetadata.hasOwnProperty("doc_id") ? commentSourceMetadata['doc_id'] : commentSourceMetadata['source_doc_id'];
            comment['source_id'] = sourceId; 
            if (commentSource === "deck"){
                comment['comment_source_name'] = commentSourceMetadata.hasOwnProperty("name") ? commentSourceMetadata.name : commentSourceMetadata.comment_source_name;
            }
            if (commentSource === "text"){
                comment['comment_source_name'] = commentSourceMetadata.hasOwnProperty("title") ? commentSourceMetadata.title : commentSourceMetadata.comment_source_name;
                comment['comment_target_ISO_639-1'] = commentSourceMetadata.hasOwnProperty("target_ISO_639-1") ? commentSourceMetadata['target_ISO_639-1'] : commentSourceMetadata['comment_target_ISO_639-1'];
            }
            if (commentSourceMetadata.hasOwnProperty("author") && commentSourceMetadata.author.hasOwnProperty("username") && commentSourceMetadata.author.hasOwnProperty("displayname")){
                comment['comment_source_author_username'] = commentSourceMetadata.author.username;
                comment['comment_source_author_displayname'] = commentSourceMetadata.author.displayname;
            } else if (commentSourceMetadata.hasOwnProperty("comment_source_author_username") && commentSourceMetadata['comment_source_author_displayname']){
                comment['comment_source_author_username'] = commentSourceMetadata.comment_source_author_username;
                comment['comment_source_author_displayname'] = commentSourceMetadata.comment_source_author_displayname;
            }
        }
        await ref.add(comment).catch((err)=>{
            saveErrorLog({errorObject: err, functionName: "postComment"});
        });
        return true;
    }

    const deleteComment = async ({commentDocId}) => {
        if (currentUser === null){return false}
        let ref = projectFirestore.collection("comments").doc(commentDocId);
        return ref.delete().catch(err=>{
            saveErrorLog({errorObject: err, functionName: "deleteComment"});
        });
    }

    const fetchCommentInRealtimeFromDocId = async (docId, setComment) => {
        let query = projectFirestore.collection("comments").doc(docId);
        return query.onSnapshot((snapshot)=>{
            if (snapshot.exists){
                let obj = snapshot.data();
                obj['comment_doc_id'] = snapshot.id;
                setComment(obj);
            } else {
                setComment(false);
            }
        });
    }


    return {
        fetchCommentsFromSourceId, 
        postComment, 
        deleteComment, 
        fetchAllComments,
        fetchCommentReplies, 
        fetchAllCommentsInRealtime, 
        fetchCommentRepliesInRealtime, 
        fetchCommentInRealtimeFromDocId, 
        fetchCommentsByUid, 
        fetchAllCommentsAndReplies
    }

}