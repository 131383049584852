import React, { useEffect, useState } from 'react';
import {DebounceInput} from 'react-debounce-input';
import useQuickNotes from '../../hooks/useQuickNotes';
import Skeleton from 'react-loading-skeleton';
import {useLanguagesContext} from '../../contexts/LanguagesContext';

const QuickNote = () => {
    const [note, setNote] = useState(null);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(false);
    let {allLanguages, activeUserLanguages, globalSelectedTargetLanguage, setGlobalSelectedTargetLanguage, globalSelectedSourceLanguage, setGlobalSelectedSourceLanguage, activeTargetLanguageOptions} = useLanguagesContext();

    const {fetchQuickNote, saveQuickNote} = useQuickNotes();

    useEffect(() => {
        const fetchNote = async () => {
            const note = await fetchQuickNote(globalSelectedTargetLanguage);
            console.log(note)
            if (note !== false && note.hasOwnProperty('text')){
                setNote(note.text);
            } else {
                setNote('');
            }
        }
        if (globalSelectedTargetLanguage !== null){
            fetchNote();
        }
    }, [globalSelectedTargetLanguage]);

    const handleNoteChange = (event) => {
        setNote(event.target.value);
    };

    const handleSaveNote = async (e) => {
        setSaving(true);
        const text = e.target.value;
        setNote(text)
        let success = await saveQuickNote(text, globalSelectedTargetLanguage);
        console.log('Note saved:', text);
        setSaving(false);
        if (!success){
            setError(true);
        }   
    };

    return (
<div className="flex flex-col gap-4">
  {/* Header */}
  <div className="font-semibold text-lg lg:text-lg text-center text-gray-700 dark:text-gray-200">
    Sticky note for{' '}
    {allLanguages !== null &&
      globalSelectedTargetLanguage !== null &&
      allLanguages[0][globalSelectedTargetLanguage]['name']['en']}
  </div>

  {/* Subtext */}
  <div className="text-sm text-center text-gray-600 dark:text-gray-400">
    Save words you want to add later or anything else you want to remember about{' '}
    {allLanguages !== null &&
      globalSelectedTargetLanguage !== null &&
      allLanguages[0][globalSelectedTargetLanguage]['name']['en']}{' '}
    here.
  </div>

  {/* Skeleton Loader or Note Input */}
  {note === null ? (
    <>
      <Skeleton
        count={1}
        height={200}
        baseColor="#e0e0e0"
        highlightColor="#f5f5f5"
        className="dark:baseColor:bg-gray-700 dark:highlightColor:bg-gray-600"
      />
    </>
  ) : (
    <>
      <DebounceInput
        className="w-full h-44 p-4 border outline-none border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 text-gray-700 dark:text-gray-200 rounded-md resize-none"
        minLength={1}
        element="textarea"
        value={note}
        placeholder="Save quick notes, tasks, or words you want to add later here..."
        debounceTimeout={700}
        onChange={(event) => handleSaveNote(event)}
      />
    </>
  )}

  {/* Status Message */}
  <div className="text-left">
    {saving ? (
      <div className="text-sm text-gray-400 dark:text-gray-500">Saving...</div>
    ) : (
      <div className="text-sm text-gray-400 dark:text-gray-500">Saved</div>
    )}
    {error && <div className="text-sm text-red-400">Error saving note.</div>}
  </div>
</div>

    );
};

export default QuickNote;