import React from 'react';
import PublicDeckCard from '../vocabulary/decks/PublicDeckCard';
import DecksCard from '../../pages/decks/DecksCard';
import {useLanguagesContext} from '../../contexts/LanguagesContext';
import Skeleton from 'react-loading-skeleton';
import CustomSkeleton from './CustomSkeleton';

const RowOfCards = ({items, languages}) => {
    const {allLanguages} = useLanguagesContext();
    return (
        <>
            <div className="">
                <div className="flex flex-row gap-8 p-8 overflow-visible scrollbar-thin scrollbar-thumb-charcoal scrollbar-track-gray-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
                    {items === null && 
                        <>
                            {[...new Array(4)].map((item, itemIndex)=>(
                                <>
                                    <div key={"skel_"+itemIndex} className="w-full" >
                                        <CustomSkeleton height={300} />
                                    </div>
                                </>
                            ))}
                        </>
                    }
                    {items !== null && items.map((item, index)=>(
                        <div key={"deck_"+index} className="min-w-64 w-64 ">
                            <DecksCard languages={languages !== undefined ? languages : allLanguages} key={'item_'+index} deck={item} />
                        </div>
                    ))
                    }
                </div> 
            </div> 
        </>
    )
}

export default RowOfCards;
