import React, {useState, useEffect, useCallback} from 'react';
import Button from '../general/Button';
import Modal from '../general/Modal';
import {useNavigate} from 'react-router-dom';
import {motion} from 'framer-motion';
import SimpleErrorBar from '../general/SimpleErrorBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faNote} from '@fortawesome/pro-solid-svg-icons';
import {faFolderPlus} from '@fortawesome/pro-solid-svg-icons';
import {faBackwardStep} from '@fortawesome/pro-solid-svg-icons';
import {faCardsBlank} from '@fortawesome/pro-solid-svg-icons';
import {faTableColumns} from '@fortawesome/pro-solid-svg-icons';
import { faLoader, faFontCase } from '@fortawesome/pro-solid-svg-icons';
import {  faSchool, faText, faThumbsDown } from '@fortawesome/pro-duotone-svg-icons';
import { faRotateRight } from '@fortawesome/pro-duotone-svg-icons';
import {AddDeckModalContent} from '../vocabulary/modals/AddDeckModal';
import Select from 'react-select';
import useTables from '../../hooks/useTables';
import useCourses from '../../hooks/useCourses';
import useNotes from '../../hooks/useNotes';
import useTexts from '../../hooks/useTexts';
import {useLanguagesContext} from '../../contexts/LanguagesContext';
import { useAuth } from '../../contexts/AuthContext';

const ModalContent = ({closeFunction, filterContentList, newItemParentFolderDocId, createItemMode, setNewItemType, setNewItemLoading, newItemLoading, newItemType }) => {
    const [page, setPage] = useState("content-type");
    const [loading, setLoading] = useState(true);
    const [filtered, setFiltered] = useState(false);
    const contentClick = useCallback((type) => {
        setNewItemType(type);
        setPage("metadata");
    },[setNewItemType]);

    const {currentUser} = useAuth();

    useEffect(()=>{
        if (filterContentList !== undefined && filterContentList !== null && filterContentList.length === 1){
            let filter = filterContentList[0];
            if (filter === "decks"){
                contentClick("deck");
            }
            if (filter === "tables"){
                contentClick("table");
            }
            if (filter === "notes"){
                contentClick("note");
            }
            setFiltered(true);
            setLoading(false);
        } else {
            setLoading(false);
        }
    },[filterContentList, contentClick]);

    /*
<motion.div onClick={()=>contentClick("note")} whileHover={{scale:1.02}} className="bg-white sm:h-48 sm:w-48 cursor-pointer rounded-xl shadow-lg py-5 px-10 flex flex-col justify-start place-items-center gap-4">
                                    <div className="font-bold text-gray-600 text-lg">
                                        Note
                                    </div>
                                    <div className="text-sandy-brown text-6xl sm:text-8xl">
                                        <FontAwesomeIcon icon={faNote} />
                                    </div>
                                </motion.div>
    */

    return (
        <>
<div className="h-full flex flex-col gap-5 py-4 dark:dark-mode-background-color">
  {!newItemLoading && !loading ? (
    <>
      {page === "content-type" && (
        <>
          <div className="flex flex-col gap-5">
            <h1 className="text-2xl font-bold text-gray-700 dark:text-white">
              Create a new {createItemMode === "main" ? "item" : "subitem"}
            </h1>
            <div className="text-center dark:text-white">
              More options coming soon here.
            </div>
            <div className="flex flex-col lg:flex-row gap-4 justify-evenly w-full">
              {/* Deck Card */}
              <motion.div
                onClick={() => contentClick("deck")}
                whileHover={{ scale: 1.02 }}
                className="bg-white dark:bg-gray-900 cursor-pointer rounded-xl shadow-lg py-5 px-10 flex flex-col justify-start place-items-center gap-4 transition-all"
              >
                <div className="font-bold text-gray-600 dark:text-gray-300 text-lg">
                  Deck
                </div>
                <div className="text-burnt-sienna dark:text-persian-green text-6xl sm:text-8xl">
                  <FontAwesomeIcon icon={faCardsBlank} />
                </div>
                <div className="text-sm text-gray-500 dark:text-gray-400 text-center">
                  A collection of words and phrases you want to remember.
                </div>
              </motion.div>

            
            </div>

            {/* Beta Features for Beta Testers */}
            {currentUser?.uid === "d1AsNHNfQUWYYoplP3UNdKcATss1" && (
              <>
                <h2 className="text-xl font-semibold text-gray-700 dark:text-white">
                  Beta features for beta testers
                </h2>
                <div className="grid grid-cols-auto gap-4 justify-evenly w-full">
                    {/* Table Card */}
              <motion.div
                onClick={() => contentClick("table")}
                whileHover={{ scale: 1.02 }}
                className="bg-white dark:bg-gray-800 cursor-pointer rounded-xl shadow-lg py-5 px-10 flex flex-col justify-start place-items-center gap-4 transition-all"
              >
                <div className="font-bold text-gray-600 dark:text-gray-300 text-lg">
                  Table
                </div>
                <div className="text-beautiful-blue dark:text-persian-green text-6xl sm:text-8xl">
                  <FontAwesomeIcon icon={faTableColumns} />
                </div>
                <div className="text-sm text-gray-500 dark:text-gray-400 dark:dark-mode-muted-text text-center">
                  Study conjugations, declensions, or any other type of table.
                </div>
              </motion.div>

              {/* Text Card */}
              <motion.div
                onClick={() => contentClick("text")}
                whileHover={{ scale: 1.02 }}
                className="bg-white dark:bg-gray-800 cursor-pointer rounded-xl shadow-lg py-5 px-10 flex flex-col justify-start place-items-center gap-4 transition-all"
              >
                <div className="font-bold text-gray-600 dark:text-gray-300 text-lg">
                  Text
                </div>
                <div className="text-charcoal dark:text-persian-green text-6xl sm:text-8xl">
                  <FontAwesomeIcon icon={faFontCase} />
                </div>
                <div className="text-sm text-gray-500 dark:dark-mode-muted-text text-center">
                  Paste a text in your target language that you want to study.
                </div>
              </motion.div>
                  {/* Note Card */}
                  <motion.div
                    onClick={() => contentClick("note")}
                    whileHover={{ scale: 1.02 }}
                    className="bg-white dark:bg-gray-800 cursor-pointer rounded-xl shadow-lg py-5 px-10 flex flex-col justify-start place-items-center gap-4 transition-all"
                  >
                    <div className="font-bold text-gray-600 dark:text-gray-300 text-lg">
                      Note
                    </div>
                    <div className="text-sandy-brown text-6xl sm:text-8xl">
                      <FontAwesomeIcon icon={faNote} />
                    </div>
                  </motion.div>

                  {/* Course Card */}
                  <motion.div
                    onClick={() => contentClick("course")}
                    whileHover={{ scale: 1.02 }}
                    className="bg-white dark:bg-gray-800 cursor-pointer rounded-xl shadow-lg py-5 px-10 flex flex-col justify-start place-items-center gap-4 transition-all"
                  >
                    <div className="font-bold text-gray-600 dark:text-gray-300 text-lg">
                      Course
                    </div>
                    <div className="text-charcoal text-6xl sm:text-8xl">
                      <FontAwesomeIcon icon={faSchool} />
                    </div>
                  </motion.div>
                </div>
              </>
            )}
          </div>
        </>
      )}
      {page === "metadata" && (
        <>
          {!filtered && (
            <div
              onClick={() => setPage("content-type")}
              className="flex flex-row gap-2 text-charcoal dark:text-gray-300 text-sm cursor-pointer underline"
            >
              <FontAwesomeIcon icon={faBackwardStep} />
              <span>Change item type</span>
            </div>
          )}
          {/* Dynamic Modal Content */}
          {newItemType === "deck" && (
            <AddDeckModalContent
              createItemMode={createItemMode}
              newItemParentFolderDocId={newItemParentFolderDocId}
              setAddNewDeckLoading={setNewItemLoading}
              closeFunction={closeFunction}
            />
          )}
          {newItemType === "table" && (
            <AddTableModalContent
              createItemMode={createItemMode}
              newItemParentFolderDocId={newItemParentFolderDocId}
              setAddNewTableLoading={setNewItemLoading}
              closeFunction={closeFunction}
            />
          )}
          {newItemType === "note" && (
            <AddNoteModalContent
              setAddNewNoteLoading={setNewItemLoading}
              newItemParentFolderDocId={newItemParentFolderDocId}
              createItemMode={createItemMode}
              closeFunction={closeFunction}
            />
          )}
          {newItemType === "text" && (
            <AddTextModalContent
              setAddNewTextLoading={setNewItemLoading}
              closeFunction={closeFunction}
              newItemParentFolderDocId={newItemParentFolderDocId}
              createItemMode={createItemMode}
            />
          )}
          {newItemType === "course" && (
            <AddCourseModalContent
              setNewItemLoading={setNewItemLoading}
              closeFunction={closeFunction}
              newItemParentFolderDocId={newItemParentFolderDocId}
              createItemMode={createItemMode}
            />
          )}
        </>
      )}
    </>
  ) : (
    <div className="text-center text-4xl text-gray-700 dark:text-white">
      <FontAwesomeIcon icon={faLoader} className="fa-spin" />
    </div>
  )}
</div>

        </>   
    )
}

const AddNoteModalContent = ({setAddNewNoteLoading, closeFunction, newItemParentFolderDocId, createItemMode}) => {
    let {allLanguages, activeUserLanguages, globalSelectedTargetLanguage, setGlobalSelectedTargetLanguage, globalSelectedSourceLanguage, setGlobalSelectedSourceLanguage, activeTargetLanguageOptions, sourceLanguageOptions} = useLanguagesContext();
    const [newNoteName, setNewNoteName] = useState("");
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [navigateAfterCreation, setNavigateAfterCreation] = useState(true);

    const changeSourceLanguage = (option) => {
        setGlobalSelectedSourceLanguage(option.value);
    }

    const {getNotesFromCurrentUserInRealtime, 
        saveNewNote} = useNotes();

    const navigate = useNavigate();

    const saveNewNoteClick = async () => {
        setAddNewNoteLoading(true);
        let r = await saveNewNote(globalSelectedTargetLanguage, globalSelectedSourceLanguage, newNoteName, createItemMode, newItemParentFolderDocId);
        if (r !== false){
            let id = r['data']['note_id'];
            if (typeof id === 'number' && navigateAfterCreation) {
                navigate("/my-notes/"+id);
            }
        }
        setAddNewNoteLoading(false);
        closeFunction();
        
    }

    const newNoteNameChange = (e) => {
        setNewNoteName(e.target.value);
    }

    const tableNameKeyDown = (e) => {
        if (e.keyCode === 13) {
            saveNewNoteClick();
        } else {
            if (error === true){
                setError(false);
                setErrorMessage("");
            }
        }
    }

    return (
        <>
            <h1>Create a new {allLanguages[0][globalSelectedTargetLanguage]['name']['en']} note</h1>  
            <div className="flex flex-col gap-4">
                <div>
                    <div className="font-bold text-gray-600">
                        Name of the note
                    </div>
                    <div className="text-center">
                        <input value={newNoteName} onKeyDown={tableNameKeyDown} onChange={newNoteNameChange} type="text" className={(error === true ? 'border-red-500 ' : '' ) + "bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white"} />
                    </div>
                    {error === true &&
                        <>
                            <SimpleErrorBar message={errorMessage} />
                        </>
                    }
                </div>
                <div>
                    <div className="font-bold text-gray-600">
                        Source language
                    </div>
                    <div>    
                        <Select onChange={changeSourceLanguage} isSearchable={true} options={sourceLanguageOptions[0]} value={sourceLanguageOptions[1][globalSelectedSourceLanguage]}  />
                    </div>
                </div>
                <div>
                    {error && 
                        <SimpleErrorBar message={"Could not save your note. Please try again."} />
                    }
                </div>
                <div className="flex flex-row gap-2 text-charcoal">
                            <input type="checkbox" checked={navigateAfterCreation} onChange={()=>setNavigateAfterCreation(!navigateAfterCreation)} />
                            <div>
                                Navigate to new note after creation
                            </div>
                        </div>
                <div className="flex flex-row justify-center gap-3">
                    <div>
                        <Button onClick={closeFunction} color="charcoal" text="Cancel" />
                    </div>
                    <div>
                        <Button onClick={saveNewNoteClick} color="green" text="Create note" />
                    </div>
                </div>
            </div>
        </>
    )
}

const AddTableModalContent = ({setAddNewTableLoading, closeFunction, newItemParentFolderDocId, createItemMode}) => {
    let {allLanguages, activeUserLanguages, globalSelectedTargetLanguage, setGlobalSelectedTargetLanguage, globalSelectedSourceLanguage, setGlobalSelectedSourceLanguage, activeTargetLanguageOptions, sourceLanguageOptions} = useLanguagesContext();
    const [newTableName, setNewTableName] = useState("");
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const defaultTemplateOptions = [{label: 'No template', value: 'default'}, {label: 'Standard verb tenses', value: 'verb-tenses'}];
    const [selectedTemplateOption, setSelectedTemplateOption] = useState(defaultTemplateOptions[0]);
    const [navigateAfterCreation, setNavigateAfterCreation] = useState(true);
    const {getTablesFromCurrentUserInRealtime, 
        saveNewTable, 
        deleteTable} = useTables();

    const changeSourceLanguage = (option) => {
        setGlobalSelectedSourceLanguage(option.value);
    }

    const navigate = useNavigate();

    const saveNewTableClick = async () => {
        console.log(globalSelectedTargetLanguage, globalSelectedSourceLanguage, newTableName, selectedTemplateOption.value);
        if (newTableName === ""){
            setError(true);
            setErrorMessage("Table name can't be blank.");
        } else {
            setAddNewTableLoading(true);
            let data = await saveNewTable(globalSelectedTargetLanguage, globalSelectedSourceLanguage, newTableName, selectedTemplateOption.value,  createItemMode, newItemParentFolderDocId);
            if (data !== false){
                let id = data['data']['tableId'];
                if (typeof id === 'number' && navigateAfterCreation) {
                    navigate("/my-tables/"+id);
                }
            }
            setAddNewTableLoading(false);
            closeFunction();
        }
    }

    const tableNameChange = (e) => {
        setNewTableName(e.target.value);
    }

    const tableNameKeyDown = (e) => {
        if (e.keyCode === 13) {
            saveNewTableClick();
        } else {
            if (error === true){
                setError(false);
                setErrorMessage("");
            }
        }
    }

    const changeTemplateOption = (option) => {
        setSelectedTemplateOption(option);
    }
    

    return (
        <>
  <div className="p-6 ">
    <h1 className="text-2xl font-bold text-gray-700 dark:text-white">
      Create a new {allLanguages[0][globalSelectedTargetLanguage]['name']['en']} table
    </h1>
    <div className="flex flex-col gap-4 mt-4">
      {/* Table Name Input */}
      <div>
        <div className="font-bold text-gray-600 dark:text-gray-300">
          Name of the table
        </div>
        <div className="text-center mt-2">
          <input
            value={newTableName}
            onKeyDown={tableNameKeyDown}
            onChange={tableNameChange}
            type="text"
            className={`${error ? 'border-red-500 ' : ''} bg-gray-200 dark:bg-gray-700 dark:text-white border-2 border-gray-200 dark:border-gray-600 rounded w-full py-2 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-gray-800`}
          />
        </div>
        {error && (
          <div className="italic text-red-400 mt-1">
            {errorMessage}
          </div>
        )}
      </div>

      {/* Column Language Select */}
      <div>
        <div className="font-bold text-gray-600 dark:text-gray-300">
          Column language
        </div>
        <div className="mt-2">
          <Select
            onChange={changeSourceLanguage}
            isSearchable={true}
            options={sourceLanguageOptions[0]}
            value={sourceLanguageOptions[1][globalSelectedSourceLanguage]}
            className=" dark:bg-gray-700"
          />
        </div>
      </div>

      {/* Navigate After Creation Checkbox */}
      <div className="flex flex-row gap-2 text-gray-700 dark:text-gray-300">
        <input
          type="checkbox"
          checked={navigateAfterCreation}
          onChange={() => setNavigateAfterCreation(!navigateAfterCreation)}
          className="accent-green-500 dark:accent-green-300"
        />
        <div>Navigate to new table after creation</div>
      </div>

      {/* Buttons */}
      <div className="flex flex-row justify-center gap-3 mt-4">
        <div>
          <Button
            onClick={closeFunction}
            color="charcoal"
            text="Cancel"
          />
        </div>
        <div>
          <Button
            onClick={saveNewTableClick}
            color="green"
            text="Create table"
          />
        </div>
      </div>
    </div>
  </div>
</>

    )
}

const AddTextModalContent = ({setAddNewTextLoading, closeFunction, newItemParentFolderDocId, createItemMode}) => {
    let {allLanguages, activeUserLanguages, globalSelectedTargetLanguage, setGlobalSelectedTargetLanguage, globalSelectedSourceLanguage, setGlobalSelectedSourceLanguage, activeTargetLanguageOptions, sourceLanguageOptions} = useLanguagesContext();
    const [newTextName, setNewTextName] = useState("");
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const defaultTemplateOptions = [{label: 'No template', value: 'default'}, {label: 'Standard verb tenses', value: 'verb-tenses'}];
    const [selectedTemplateOption, setSelectedTemplateOption] = useState(defaultTemplateOptions[0]);
    const [navigateAfterCreation, setNavigateAfterCreation] = useState(true);
    const {saveNewText
        } = useTexts();

    const changeSourceLanguage = (option) => {
        setGlobalSelectedSourceLanguage(option.value);
    }

    const navigate = useNavigate();

    const saveNewTextClick = async () => {
        console.log(globalSelectedTargetLanguage, globalSelectedSourceLanguage, newTextName, selectedTemplateOption.value);
        if (newTextName === ""){
            setError(true);
            setErrorMessage("Text name can't be blank.");
        } else {
            setAddNewTextLoading(true);
            let data = await saveNewText({targetLanguage:globalSelectedTargetLanguage, title:newTextName, parentFolderDocId:newItemParentFolderDocId});
            if (data !== false){
                let id = data['data']['textId'];
                if (typeof id === 'number' && navigateAfterCreation) {
                    navigate("/my-texts/"+id);
                }
            }
            setAddNewTextLoading(false);
            closeFunction();
        }
    }

    const textNameChange = (e) => {
        setNewTextName(e.target.value);
    }

    const textNameKeyDown = (e) => {
        if (e.keyCode === 13) {
            saveNewTextClick();
        } else {
            if (error === true){
                setError(false);
                setErrorMessage("");
            }
        }
    }

    const changeTemplateOption = (option) => {
        setSelectedTemplateOption(option);
    }
    

    return (
        <>
        <div className="p-6 ">
          <h1 className="text-2xl font-bold text-gray-700 dark:text-white">
            Create a new {allLanguages[0][globalSelectedTargetLanguage]['name']['en']} text
          </h1>
          <div className="flex flex-col gap-4 mt-4">
            {/* Name of the Text */}
            <div>
              <div className="font-bold text-gray-600 dark:text-gray-300">
                Name of the text
              </div>
              <div className="text-center mt-2">
                <input
                  value={newTextName}
                  onKeyDown={textNameKeyDown}
                  onChange={textNameChange}
                  type="text"
                  className={`${error ? 'border-red-500 ' : ''} bg-gray-200 dark:bg-gray-700 dark:text-white border-2 border-gray-200 dark:border-gray-600 rounded w-full py-2 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-gray-800`}
                />
              </div>
              {error && (
                <div className="italic text-red-400 mt-1">
                  {errorMessage}
                </div>
              )}
            </div>
      
            {/* Source Language */}
            <div>
              <div className="font-bold text-gray-600 dark:text-gray-300">
                Source language
              </div>
              <div className="mt-2">
                <Select
                  onChange={changeSourceLanguage}
                  isSearchable={true}
                  options={sourceLanguageOptions[0]}
                  value={sourceLanguageOptions[1][globalSelectedSourceLanguage]}
                  className="0"
                />
              </div>
            </div>
      
            {/* Navigate After Creation Checkbox */}
            <div className="flex flex-row gap-2 text-gray-700 dark:text-gray-300">
              <input
                type="checkbox"
                checked={navigateAfterCreation}
                onChange={() => setNavigateAfterCreation(!navigateAfterCreation)}
                className="accent-green-500 dark:accent-green-300"
              />
              <div>Navigate to new text after creation</div>
            </div>
      
            {/* Buttons */}
            <div className="flex flex-row justify-center gap-3 mt-4">
              <div>
                <Button
                  onClick={closeFunction}
                  color="charcoal"
                  text="Cancel"

                />
              </div>
              <div>
                <Button
                  onClick={saveNewTextClick}
                  color="green"
                  text="Create text"

                />
              </div>
            </div>
          </div>
        </div>
      </>
      
    )
}

const AddCourseModalContent = ({setNewItemLoading, closeFunction, newItemParentFolderDocId, createItemMode}) => {
    let {allLanguages, activeUserLanguages, globalSelectedTargetLanguage, setGlobalSelectedTargetLanguage, globalSelectedSourceLanguage, setGlobalSelectedSourceLanguage, activeTargetLanguageOptions, sourceLanguageOptions} = useLanguagesContext();
    const [newItemName, setNewItemName] = useState("");
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [navigateAfterCreation, setNavigateAfterCreation] = useState(true);

    const {createNewCourse} = useCourses();
    const navigate = useNavigate();

    const itemNameKeyDown = (e) => {
        if (e.keyCode === 13) {
            saveNewItemClick();
        } else {
            if (error === true){
                setError(false);
                setErrorMessage("");
            }
        }
    }

    const itemNameChange = (e) => {
        setNewItemName(e.target.value);
    }

    const saveNewItemClick = async () => {
        console.log("New course: ", globalSelectedTargetLanguage, globalSelectedSourceLanguage, newItemName, newItemParentFolderDocId);
        if (newItemName === ""){
            setError(true);
            setErrorMessage("Course name can't be blank.");
        } else {
            setNewItemLoading(true);
            let data = await createNewCourse({targetLanguage:globalSelectedTargetLanguage, sourceLanguage: globalSelectedSourceLanguage, name:newItemName, parentFolderDocId:newItemParentFolderDocId});
            if (data !== false){
                let id = data['data']['itemId'];
                if (typeof id === 'number' && navigateAfterCreation) {
                    navigate("/my-courses/"+id);
                }
            }
            setNewItemLoading(false);
            closeFunction();
        }
    }

    const changeSourceLanguage = (option) => {
        setGlobalSelectedSourceLanguage(option.value);
    }

    return (
        <>
            <div className="p-6 rounded-lg shadow-md bg-white dark:dark-mode-background-color">
    <h1 className="text-xl font-bold text-gray-700 dark:text-white">
        Create a new {allLanguages[0][globalSelectedTargetLanguage]['name']['en']} course
    </h1>
    <div className="flex flex-col gap-4 mt-4">
        {/* Name of the Course */}
        <div>
            <div className="font-bold text-gray-600 dark:dark-mode-muted-text">
                Name of the course
            </div>
            <div className="text-center mt-2">
                <input
                    value={newItemName}
                    onKeyDown={itemNameKeyDown}
                    onChange={itemNameChange}
                    type="text"
                    className={`${error ? 'border-red-500 ' : ''} bg-gray-200 dark:bg-gray-700 dark:text-white border-2 border-gray-200 dark:border-gray-600 rounded w-full py-2 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-gray-800`}
                />
            </div>
            {error && (
                <div className="italic text-red-400 mt-1">
                    {errorMessage}
                </div>
            )}
        </div>

        {/* Source Language */}
        <div>
            <div className="font-bold text-gray-600 dark:dark-mode-muted-text">
                Source language
            </div>
            <div className="mt-2">
                <Select
                    onChange={changeSourceLanguage}
                    isSearchable={true}
                    options={sourceLanguageOptions[0]}
                    value={sourceLanguageOptions[1][globalSelectedSourceLanguage]}
                    className="dark:text-white dark:bg-gray-700"
                />
            </div>
        </div>

        {/* Navigate After Creation */}
        <div className="flex flex-row gap-2 text-gray-700 dark:text-gray-300">
            <input
                type="checkbox"
                checked={navigateAfterCreation}
                onChange={() => setNavigateAfterCreation(!navigateAfterCreation)}
                className="accent-green-500 dark:accent-green-300"
            />
            <div>Navigate to new course after creation</div>
        </div>

        {/* Buttons */}
        <div className="flex flex-row justify-center gap-3 mt-4">
            <div>
                <Button
                    onClick={closeFunction}
                    color="charcoal"
                    text="Cancel"
                    className="bg-gray-300 dark:bg-gray-600 dark:text-white hover:bg-gray-400 dark:hover:bg-gray-500"
                />
            </div>
            <div>
                <Button
                    onClick={saveNewItemClick}
                    color="green"
                    text="Create course"
                    className="bg-green-500 dark:bg-green-600 dark:text-white hover:bg-green-600 dark:hover:bg-green-500"
                />
            </div>
        </div>
    </div>
</div>

        </>
    )
}

const CreateItemModal = ({closeFunction, filterContentList, newItemParentFolderDocId, createItemMode, newItemLoading, setNewItemLoading, setAddNewTextLoading, setNewItemType, newItemType }) => {
  return (
    <>
        <Modal  overflow={"overflow-auto"} closeFunction={closeFunction} content={<ModalContent filterContentList={filterContentList} newItemParentFolderDocId={newItemParentFolderDocId} newItemLoading={newItemLoading} createItemMode={createItemMode} setNewItemLoading={setNewItemLoading} setAddNewTextLoading={setAddNewTextLoading} setNewItemType={setNewItemType} newItemType={newItemType}  closeFunction={closeFunction} />} size="3xl"/>
    </>
  )
};

export default CreateItemModal;
