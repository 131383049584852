import {projectFirestore, projectFunctions, timeStamp} from '../firebase/config.js';
import { useAuth } from "../contexts/AuthContext";
import { useCallback } from 'react';
import {
    getFirestore,
    collection,
    query,
    where,
    setDoc,
    getDoc,
    getDocs,
    doc,
    deleteDoc,
    addDoc,
    updateDoc,
    onSnapshot,
    serverTimestamp,
    orderBy,
    limit,
    startAfter,
    endBefore,
    limitToLast, 
    Timestamp
} from "firebase/firestore";
import {  httpsCallable } from "firebase/functions";

export default function useCourses() {
    
    let {currentUser} = useAuth();

    const fetchYalangoCoursesForLanguage = useCallback(async ({ targetLanguage }) => {
        const courses = [];
        const courseRef = collection(projectFirestore, "courses");
        const q = query(
            courseRef,
            where("target_ISO_639-1", "==", targetLanguage),
            where("uid", "==", "d1AsNHNfQUWYYoplP3UNdKcATss1")
        );
        const snapshot = await getDocs(q);
        snapshot.forEach((doc) => {
            courses.push({ ...doc.data(), doc_id: doc.id });
        });
        return courses;
    }, []);
    
    const fetchCourseFromId = useCallback(async ({ courseId }) => {
        courseId = parseInt(courseId);
        const courseRef = collection(projectFirestore, "courses");
        const q = query(courseRef, where("course_id", "==", courseId));
        const snapshot = await getDocs(q);
        let course = null;
        snapshot.forEach((doc) => {
            course = { ...doc.data(), doc_id: doc.id };
        });
        return course;
    }, []);

    const fetchCourseLessons = useCallback(async ({ lessonIds, course }) => {
        let lessons = [];
        if (!lessonIds || lessonIds.length === 0) return lessons;
    
        const lessonRef = collection(projectFirestore, "course_lessons");
    
        if (lessonIds.length <= 30) {
            const q = query(lessonRef, where("lesson_id", "in", lessonIds));
            const snapshot = await getDocs(q);
            snapshot.forEach((doc) => {
                lessons.push({ ...doc.data(), doc_id: doc.id });
            });
        } else {
            const batchedLessonIds = [];
            const chunk = 30;
            for (let i = 0; i < lessonIds.length; i += chunk) {
                batchedLessonIds.push(lessonIds.slice(i, i + chunk));
            }
    
            for (const batch of batchedLessonIds) {
                const q = query(lessonRef, where("lesson_id", "in", batch));
                const snapshot = await getDocs(q);
                snapshot.forEach((doc) => {
                    lessons.push({ ...doc.data(), doc_id: doc.id });
                });
            }
        }
    
        lessons = lessons.sort(
            (a, b) => course.lesson_ids.indexOf(a.lesson_id) - course.lesson_ids.indexOf(b.lesson_id)
        );
    
        lessons = lessons.map((lesson) => {
            if (lesson.thumbnail_200x200) {
                lesson["full_thumbnail_path_200x200"] = getFullThumbnailPath({
                    dbPath: lesson.thumbnail_200x200,
                });
            } else {
                lesson["thumbnail_200x200"] = null;
                lesson["full_thumbnail_path_200x200"] = null;
            }
            return lesson;
        });
    
        return lessons;
    }, []);
    
    const fetchCourseLesson = useCallback(async ({ lessonId }) => {
        if (!lessonId) return null;
        const lessonRef = collection(projectFirestore, "course_lessons");
        const q = query(lessonRef, where("lesson_id", "==", parseInt(lessonId)));
        const snapshot = await getDocs(q);
        let lesson = null;
        snapshot.forEach((doc) => {
            lesson = { ...doc.data(), doc_id: doc.id };
        });
        return lesson;
    }, []);

    const createNewCourse = useCallback(async ({targetLanguage, sourceLanguage, name, parentFolderDocId}) => {
        console.log("Creating new course: ", targetLanguage, sourceLanguage, name, parentFolderDocId, currentUser)
        if (currentUser === null){return false};
        if (name === "" || name === null || name === undefined){return false};
        
        const createNewCourseFunction =  httpsCallable(projectFunctions, 'createNewCourse');
        let data = await createNewCourseFunction({
            'targetLanguage': targetLanguage,
            'sourceLanguage': sourceLanguage,
            'name': name, 
            'parentFolderDocId': "top_level", 
            'destinationFolder': parentFolderDocId
        }).catch(err=>{return false});
        if (data === false){return false};

        return data;
    },[]);

    const saveChangesToCourse = useCallback(async ({ courseDocId = null, name = null }) => {
        if (!courseDocId) return false;
    
        const courseRef = doc(projectFirestore, "courses", courseDocId);
    
        let obj = {
            last_updated_timestamp: timeStamp,
        };
    
        if (name) {
            obj.name = name;
        }
    
        let success = true;
    
        try {
            await setDoc(courseRef, obj, { merge: true });
        } catch (err) {
            console.error("Error:", err);
            success = false;
        }
    
        return success;
    }, []);

    const saveChangesToLesson = useCallback(async ({ lessonDocId = null, contentId = undefined, contentType = undefined, lessonName = undefined, contentGame = undefined }) => {
        if (!lessonDocId) return false;
    
        const lessonRef = doc(projectFirestore, "course_lessons", lessonDocId);
    
        let obj = {
            last_updated_timestamp: timeStamp,
        };
    
        if (contentId !== undefined) {
            obj.content_id = contentId !== null ? parseInt(contentId) : null; // Null to remove the content_id
        }
        if (lessonName !== undefined) {
            obj.lesson_name = lessonName;
        }
        if (contentType !== undefined) {
            obj.content_type = contentType;
        }
        if (contentGame !== undefined) {
            obj.content_game = contentGame;
        }
    
        let success = true;
    
        try {
            await setDoc(lessonRef, obj, { merge: true });
        } catch (err) {
            console.error("Error:", err);
            success = false;
        }
    
        return success;
    }, []);

    const fetchAllCoursesFromCurrentUserInRealtime = async (setStateFunc) => {
        if (!currentUser) return false;
    
        const coursesRef = collection(projectFirestore, "courses");
        const q = query(coursesRef, where("uid", "==", currentUser.uid));
    
        return onSnapshot(q, (querySnapshot) => {
            const courses = [];
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                data["doc_id"] = doc.id;
                courses.push(data);
            });
            setStateFunc(courses);
        });
    };

    const renameCourse = useCallback(async (courseDocId, newName) => {
        if (!courseDocId || !newName) return false;
    
        const courseRef = doc(projectFirestore, "courses", courseDocId);
        return setDoc(courseRef, {
            name: newName,
            last_updated_timestamp: timeStamp,
        }, { merge: true });
    }, []);

    const deleteCourseFromDocId = async (docId) => {
        const courseRef = doc(projectFirestore, "courses", docId);
        const r = await deleteDoc(courseRef);
        return r;
    };
    
    const getFullThumbnailPath = useCallback(({dbPath}) => {
        let path = "https://firebasestorage.googleapis.com/v0/b/soothing-languages.appspot.com/o/"+dbPath.replaceAll("/", "%2F") + "?alt=media";
        console.log("Path: ", path);
        return path;
    },[]);

    const getDefaultThumbnailImage = useCallback(() => {
        //shutterstock_2282856415_200x200.webp
        let dbPath = "images/courses/thumbnails/thumbnails_200x200/shutterstock_2282856415_200x200.webp";
        return getFullThumbnailPath({dbPath});
    },[]);

    const uploadLessonThumbnail = useCallback(async ({ base64Image, file, contentType, lesson }) => {
        const uploadImage = httpsCallable(projectFunctions, 'uploadCourseThumbnail');
    
        try {
            const result = await uploadImage({
                base64Image,
                fileName: file.name,
                contentType: contentType,
            });
            console.log('Upload successful');
    
            const lessonDocId = lesson.doc_id;
            const lessonRef = doc(projectFirestore, "course_lessons", lessonDocId);
            const dbPath = `images/courses/thumbnails/thumbnails_200x200/${file.name.split(".")[0]}_200x200.webp`; // All are converted to webp
    
            await setDoc(lessonRef, {
                thumbnail_200x200: dbPath,
                last_updated_timestamp: timeStamp,
            }, { merge: true });
    
            return dbPath;
    
        } catch (error) {
            console.error('Error uploading file: ', error);
            return null;
        }
    }, []);

    const uploadCourseThumbnail = useCallback(async ({ base64Image, file, contentType, course }) => {
        const uploadImage = httpsCallable(projectFunctions, 'uploadCourseThumbnail');
    
        try {
            const result = await uploadImage({
                base64Image,
                fileName: file.name,
                contentType: contentType,
            });
            console.log('Upload successful');
    
            const courseDocId = course.doc_id;
            const courseRef = doc(projectFirestore, "courses", courseDocId);
    
            const dbPath200x200 = `images/courses/thumbnails/thumbnails_200x200/${file.name.split(".")[0]}_200x200.webp`; // Converted to webp
            const dbPath2000x2000 = `images/courses/thumbnails/thumbnails_2000x2000/${file.name.split(".")[0]}_2000x2000.webp`; // Converted to webp
    
            await setDoc(courseRef, {
                thumbnail_200x200: dbPath200x200,
                thumbnail_2000x2000: dbPath2000x2000,
                last_updated_timestamp: timeStamp,
            }, { merge: true });
    
            return dbPath200x200;
    
        } catch (error) {
            console.error('Error uploading file: ', error);
            return null;
        }
    }, []);

    const addNewLessonToCourse = useCallback(async ({course, lessonName, contentType, contentId}) => {
        //add new backend side lesson to course_lessons with course_id = course.course_id, content_type = contentType, content_id = contentId, lesson_name = lessonName
        const addLesson = httpsCallable(projectFunctions, 'createNewCourseLesson');
        let data = await addLesson({
            'course': course,
            'lesson_name': lessonName,
            'content_type': contentType,
            'content_id': contentId
        }).catch(err=>{return false});
    },[]);

    return {
        fetchYalangoCoursesForLanguage, 
        fetchCourseFromId, 
        fetchCourseLessons,
        fetchCourseLesson, 
        createNewCourse, 
        saveChangesToCourse, 
        fetchAllCoursesFromCurrentUserInRealtime, 
        renameCourse, 
        saveChangesToLesson,
        deleteCourseFromDocId, 
        getFullThumbnailPath, 
        uploadLessonThumbnail, 
        uploadCourseThumbnail, 
        addNewLessonToCourse, 
        getDefaultThumbnailImage
    }

}