import React, { useCallback } from 'react';
import {projectFirestore, projectFunctions, timeStamp, firebase} from '../firebase/config.js';
import { useAuth } from "../contexts/AuthContext";
import {
    getFirestore,
    collection,
    query,
    where,
    setDoc,
    getDoc,
    getDocs,
    doc,
    deleteDoc,
    addDoc,
    updateDoc,
    onSnapshot,
    serverTimestamp,
    orderBy,
    limit,
    startAfter,
    endBefore,
    limitToLast, 
    Timestamp
} from "firebase/firestore";
import {  httpsCallable } from "firebase/functions";



export default function useNotes() {

    let {currentUser} = useAuth();

    const getNotesFromCurrentUserInRealtime = async (targetLanguage, setStateFunc) => {
        const notesQuery = query(
            collection(projectFirestore, "notes"),
            where("uid", "==", currentUser.uid),
            where("target_ISO_639-1", "==", targetLanguage)
        );
    
        onSnapshot(notesQuery, (querySnapshot) => {
            const notes = querySnapshot.docs.map((doc) => doc.data());
            setStateFunc(notes);
        });
    };
    
    const getAllNotesFromCurrentUserInRealtime = async (setStateFunc) => {
        const notesQuery = query(collection(projectFirestore, "notes"), where("uid", "==", currentUser.uid));
    
        return onSnapshot(notesQuery, (querySnapshot) => {
            const notes = querySnapshot.docs.map((doc) => ({
                ...doc.data(),
                doc_id: doc.id,
            }));
            setStateFunc(notes);
        });
    };
    
    const saveChangesToNote = useCallback(async ({ noteDocId, blocks = null, name = null }) => {
        if (!noteDocId) return;
    
        const noteUpdates = {};
        if (blocks !== null) noteUpdates["blocks"] = blocks;
        if (name !== null) noteUpdates["name"] = name;
    
        if (Object.keys(noteUpdates).length === 0) return;
    
        const noteRef = doc(projectFirestore, "notes", noteDocId);
        await setDoc(noteRef, { ...noteUpdates, last_updated: timeStamp }, { merge: true });
    }, []);
    
    const saveNewNote = async (targetLanguage, sourceLanguage, noteName, type, parentFolderDocId) => {
        const addNewNote = httpsCallable(projectFunctions, "addNewNote");
        return addNewNote({
            name: noteName,
            "target_ISO_639-1": targetLanguage,
            "source_ISO_639-1": sourceLanguage,
            type,
            parentFolderDocId: "top_level",
            destinationFolder: parentFolderDocId,
        });
    };
    
    const renameNote = async (noteDocId, newName) => {
        const noteRef = doc(projectFirestore, "notes", noteDocId);
        return setDoc(noteRef, { name: newName, last_updated: timeStamp }, { merge: true });
    };
    
    const deleteNoteFromDocId = async (noteDocId) => {
        const noteRef = doc(projectFirestore, "notes", noteDocId);
        return deleteDoc(noteRef);
    };
    
    const fetchNote = async (noteId) => {
        const notesQuery = query(
            collection(projectFirestore, "notes"),
            where("note_id", "==", noteId),
            where("uid", "==", currentUser.uid)
        );
    
        const snapshots = await getDocs(notesQuery);
        if (!snapshots.empty && snapshots.docs.length === 1) {
            const snap = snapshots.docs[0];
            if (snap) {
                const data = snap.data();
                data["note_doc_id"] = snap.id;
                return data;
            }
        }
        return null;
    };
    
    const fetchNoteApi = async (noteId) => {
        const fetchNote = httpsCallable(projectFunctions, "fetchPublicNoteOrUserNote");
        return fetchNote({ noteId });
    };
    
    const fetchNoteContent = async (contentDocId) => {
        const contentRef = doc(projectFirestore, "notes-content", contentDocId);
        const snapshot = await getDocs(contentRef);
        if (snapshot.exists()) {
            const data = snapshot.data();
            data["note_content_doc_id"] = snapshot.id;
            return data;
        }
        return null;
    };
    
    const saveNoteContent = async (contentDocId, content) => {
        const contentRef = doc(projectFirestore, "notes-content", contentDocId);
        return setDoc(contentRef, { content: JSON.stringify(content), last_updated: timeStamp }, { merge: true });
    };

    return {
        getNotesFromCurrentUserInRealtime, 
        saveNewNote, 
        fetchNote, 
        fetchNoteContent, 
        saveNoteContent, 
        fetchNoteApi,
        getAllNotesFromCurrentUserInRealtime, 
        renameNote, 
        deleteNoteFromDocId, 
        saveChangesToNote
    }
}