import React, { useContext, useState, useEffect } from "react";
import useStatistics from '../hooks/useStatistics';
import useVocabulary from '../hooks/useVocabulary';
import useGoals from '../hooks/useGoals';
import useStars from '../hooks/useStars';
import useDecks from '../hooks/useDecks';
import {useAuth} from '../contexts/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-duotone-svg-icons';
import {useLanguagesContext} from '../contexts/LanguagesContext';

const UserStatisticsContext = React.createContext("default");

export function useUserStatisticsContext() {
    return useContext(UserStatisticsContext);
};

export function UserStatisticsProvider({ children }) {
    let {currentUser} = useAuth();
    const {getDailyStatsInActiveTargetLanguages, 
        getDailyStatsInTargetLanguage, 
        getXLastProcessedDataInTargetLanguage,
        getProcessedDataInTargetLanguageInMonth, 
        getProcessedStatisticsDataLastXDays,
        getProcessedVocabularyDataInTargetLanguageToday} = useStatistics();
    const {activeUserLanguages, globalSelectedTargetLanguage} = useLanguagesContext();
    const {fetchKnownVocabulary, 
        fetchTargetWordsDoc, 
        fetchMostUrgentNextReviewItemsFromSpacedRepetitionVocabulary,
        fetchMostUrgentNextReviewItemsFromSpacedRepetitionVocabularyApi
    } = useVocabulary();

    const {fetchVocabularyMetadataInTargetLanguage} = useDecks();
    const [dailyStats, setDailyStats] = useState(null);
    const [dailyGoals, setDailyGoals] = useState(null);

    const [dailyGoalsNeedRefresh, setDailyGoalsNeedRefresh] = useState(false);
    const [dailyStatsNeedRefresh, setDailyStatsNeedRefresh] = useState(false);
    const [dailyStreakNeedsRefresh, setDailyStreakNeedsRefresh] = useState(false);
    const [userStarsNeedRefresh, setUserStarsNeedRefresh] = useState(false);

    const activateDailyGoalsNeedRefresh = () => {
        setDailyGoalsNeedRefresh(true);
    }

    const deactivateDailyGoalsNeedRefresh = () => {
        setDailyGoalsNeedRefresh(false);
    }

    const activateDailyStatsNeedRefresh = () => {
        setDailyStatsNeedRefresh(true);
    }

    const deactivateDailyStatsNeedRefresh = () => {
        setDailyStatsNeedRefresh(false);
    }

    const activateDailyStreakNeedsRefresh = () => {
        setDailyStreakNeedsRefresh(true);
    }

    const deactivateDailyStreakNeedsRefresh = () => {
        setDailyStreakNeedsRefresh(false);
    }

    const activateUserStarsNeedRefresh = () => {
        setUserStarsNeedRefresh(true);
    }
  
    const [userStars, setUserStars] = useState(null);

    const [knownVocabulary, setKnownVocabulary] = useState(null);
    const [vocabularyMetadata, setVocabularyMetadata] = useState(null);
    const [activeVocabularyTargetWords, setActiveVocabularyTargetWords] = useState(null);
    const [vocabularyTargetWordsInTargetLanguage, setVocabularyTargetWordsInTargetLanguage] = useState(null);
    const [processedVocabularyData, setProcessedVocabularyData] = useState(null);
    const [mostUrgentNextReviewItems, setMostUrgentNextReviewItems] = useState(null);

    const [userStatisticsFetched, setUserStatisticsFetched] = useState(false);
    const [currentDailyStreak, setCurrentDailyStreak] = useState(null);
    const {getCurrentUserGoals, 
        getCurrentUserDailyStreak, 
        getCurrentUserGoalsInTargetLanguage} = useGoals();

    const {getCurrentUserStars} = useStars();

    const refreshUserStars = async () => {
        let stars = await getCurrentUserStars();
        setUserStars(stars);
    }

    useEffect(()=> {
         const fetchData = async () => {
            await refreshVocabularyMetadataInAllActiveLanguages();
            let d = await getDailyStatsInActiveTargetLanguages(activeUserLanguages);
            setDailyStats(d);
            deactivateDailyStatsNeedRefresh();
            let r = await getCurrentUserGoals();
            setDailyGoals(r);
            deactivateDailyGoalsNeedRefresh();
            let r2 = await getCurrentUserDailyStreak(activeUserLanguages);
            setCurrentDailyStreak(r2);
            deactivateDailyStreakNeedsRefresh();
            setUserStatisticsFetched(true);
        }
        if (activeUserLanguages !== null && currentUser !== null){
            fetchData();
        } 
    },[activeUserLanguages, currentUser]);

    useEffect(()=> {
        if (currentUser === null){
            setVocabularyTargetWordsInTargetLanguage(null);
            setActiveVocabularyTargetWords(null);
            setKnownVocabulary(null);
            setDailyGoals(null);
            setDailyStats(null);
            setUserStars(null);
            setMostUrgentNextReviewItems(null);
            setUserStatisticsFetched(false);
            setCurrentDailyStreak(null);
            setVocabularyMetadata(null);
            setProcessedVocabularyData(null);
            // reset all variables (log out and into new account should not persist state)
        }
    },[currentUser])

    useEffect(()=>{
        console.log("Effect: ", knownVocabulary, globalSelectedTargetLanguage);
        if (knownVocabulary !== null && activeVocabularyTargetWords !== null && globalSelectedTargetLanguage !== null){
            let copy = {};
            if (vocabularyTargetWordsInTargetLanguage !== null){
                copy = JSON.parse(JSON.stringify(vocabularyTargetWordsInTargetLanguage));
            }
            for (const lang of Object.keys(knownVocabulary)) {
                if (activeVocabularyTargetWords.hasOwnProperty(lang)){
                    copy[lang] = [...knownVocabulary[lang], ...activeVocabularyTargetWords[lang]];
                }
            }
            console.log("Target words in target language: ", copy);
            setVocabularyTargetWordsInTargetLanguage(copy);
        }
    },[knownVocabulary, activeVocabularyTargetWords, globalSelectedTargetLanguage]);

    const refreshUserGoalsFunction = async () => {
        setDailyGoals(null);
        let r = await getCurrentUserGoals();
        setDailyGoals(r);
        setDailyGoalsNeedRefresh(false);
        return r;
    }

    const refreshUserGoalsIfNeeded = async () => {
        console.log("Refresh: ", dailyGoalsNeedRefresh);
        if (dailyGoalsNeedRefresh){
            console.log("Goals need to be refreshed");
            let g = await refreshUserGoalsFunction();
            return g;
        }
        return false;
    }

    const refreshDailyStreakIfNeeded = async () => {
        console.log("Refresh: ", dailyStreakNeedsRefresh);
        if (dailyStreakNeedsRefresh){
            console.log("Streak needs to be refreshed");
            let s = await refreshDailyStreaks();
            return s;
        }
        return false;
    }

    const refreshDailyStatsIfNeeded = async () => {
        console.log("Refresh: ", dailyStatsNeedRefresh);
        if (dailyStatsNeedRefresh){
            console.log("Daily stats need to be refreshed");
            let ds = await refreshDailyStatsFunction();
            return ds;
        }
        return false;
    }

    const refreshDailyStreaks = async () => {
        if (activeUserLanguages === null){return}
        setCurrentDailyStreak(null);
        let r2 = await getCurrentUserDailyStreak(activeUserLanguages);
        setCurrentDailyStreak(r2);
        setDailyStreakNeedsRefresh(false);
        return r2;
    }

    const refreshProcessedVocabularyDataInTargetLanguagesLastSevenDays = async ({activeLangs}) => {
        console.log("refreshProcessedVocabularyDataInTargetLanguagesLastSevenDays, ", activeLangs);
        let obj = {};
        let today = new Date();
        let month = today.getMonth();
        let year = today.getFullYear();
        obj[year] = {};
        obj[year][month] = {};
        obj['last_7_days'] = {};
        if (activeLangs === null){setTimeout(()=>console.log("error: languages are null, timeout"),[500])}
        if (activeLangs === null){return false}
        for (const lang of activeLangs){
            let data = await getProcessedStatisticsDataLastXDays({days: 7, targetLanguage: lang.target_language});
            console.log("Data: ", data, lang);
            for (const dataRow of data){
                let thisYear = parseInt(dataRow.doc_id.split(".")[2]);
                let thisMonth = parseInt(dataRow.doc_id.split(".")[1])-1;
                let today = parseInt(dataRow.doc_id.split(".")[0]);
                
                if (obj[thisYear][thisMonth] !== undefined && !obj[thisYear][thisMonth].hasOwnProperty(lang.target_language)){
                    obj[thisYear][thisMonth][lang.target_language] = {};
                }
                if (obj[thisYear][thisMonth] !== undefined){
                    obj[thisYear][thisMonth][lang.target_language][today] = dataRow;
                }
            }
            obj['last_7_days'][lang.target_language] = data;
        }
        console.log("OSetting processed vocabulary: ", obj);
        setProcessedVocabularyData(obj);
    }

    const refreshProcessedVocabularyDataInTargetLanguagesInMonth = async ({month, year}) => {
        if (activeUserLanguages === null){return}
        let obj = {};
        obj[year] = {};
        obj[year][month] = {};
        for (const lang of activeUserLanguages){
            let data = await getProcessedDataInTargetLanguageInMonth(month, year, lang.target_language);
            if (!obj[year][month].hasOwnProperty(lang.target_language)){
                obj[year][month][lang.target_language] = {};
            }
            for (const dataRow of data){
                let todayDay = parseInt(dataRow.doc_id.split(".")[0]);
                obj[year][month][lang.target_language][todayDay] = dataRow;
            }
        }
        setProcessedVocabularyData(obj);
    }

    const refreshProcessedVocabularyDataInTargetLanguagesToday = async () => {
        if (activeUserLanguages === null){return}
        if (processedVocabularyData === null){return}
        let obj = JSON.parse(JSON.stringify(processedVocabularyData));
        let today = new Date();
        let month = today.getMonth();
        let year = today.getFullYear();
        let dateNumber = today.getDate();

        for (const lang of activeUserLanguages){
            let todayData = await getProcessedVocabularyDataInTargetLanguageToday(lang.target_language);
            obj[year][month][lang.target_language][dateNumber] = todayData;
        }
        
        setProcessedVocabularyData(obj);
        return obj;
    }


    const manuallySetVocabularyTargetWordsInTargetLanguage = (known, active, targetLang) => {
        let copy = {};
        if (vocabularyTargetWordsInTargetLanguage !== null){
            copy = JSON.parse(JSON.stringify(vocabularyTargetWordsInTargetLanguage));
        }
        copy[targetLang] = [...knownVocabulary, ...activeVocabularyTargetWords];
        setVocabularyTargetWordsInTargetLanguage(copy);
    }

    const refreshKnownVocabulary = async (targetLang) => {
        let copy = {};
        if (knownVocabulary !== null){
            copy = JSON.parse(JSON.stringify(knownVocabulary));
        }
        if (targetLang === "all"){
            for (const lang of activeUserLanguages){
                let r = await fetchKnownVocabulary(lang.target_language);
                console.log(lang.target_language, " known all: ", r)
                copy[lang.target_language] = r;
            }
        } else {
            let r = await fetchKnownVocabulary(targetLang);
            copy[targetLang] = r;
        }
        setKnownVocabulary(copy);
    }

    const refreshActiveVocabularyTargetWords = async (targetLang) => {
        console.log("Refresh active vocabulary target words roiegjerg: ", targetLang);
        let copy = {};
        if (activeVocabularyTargetWords !== null){
            copy = JSON.parse(JSON.stringify(activeVocabularyTargetWords));
        }
        if (targetLang === "all"){
            for (const lang of activeUserLanguages){
                let r = await fetchTargetWordsDoc(lang.target_language);
                console.log(lang.target_language, " active all: ", r)
                copy[lang.target_language] = r;
            }
        } else {
            let r = await fetchTargetWordsDoc(targetLang);
            copy[targetLang] = r;
        }
        setActiveVocabularyTargetWords(copy);
    }

    const refreshDailyStatsFunction = async () => {
        setDailyStats(null);
        setUserStatisticsFetched(false);
        let d = await getDailyStatsInActiveTargetLanguages(activeUserLanguages);
        console.log("Daily stats: ", d);
        setDailyStats(d);
        setUserStatisticsFetched(true);
        setDailyStatsNeedRefresh(false);
        return d;
    }

    const refreshSpacedRepetitionQueue = async (limit) => {
        let copy = {};
        if (mostUrgentNextReviewItems !== null){
            copy = JSON.parse(JSON.stringify(mostUrgentNextReviewItems));
        }
        let docs = await fetchMostUrgentNextReviewItemsFromSpacedRepetitionVocabularyApi({targetLang: globalSelectedTargetLanguage, howManyItems: limit});
        copy[globalSelectedTargetLanguage] = docs;
        copy[globalSelectedTargetLanguage] = [...new Set(docs)]
        setMostUrgentNextReviewItems(copy);
    }

    const getDailyStatsInGlobalSelectedTargetLanguage = async () => {
        let data = await getDailyStatsInTargetLanguage(globalSelectedTargetLanguage);
        return data;
    }

    const getDailyStatsInLanguage = async (language) => {
        console.log("Lang: ", language);
        let data = await getDailyStatsInTargetLanguage(language);
        return data;
    }

    const getDailyGoalsInGlobalSelectedTargetLanguage = async () => {
        let data = await getCurrentUserGoalsInTargetLanguage(globalSelectedTargetLanguage);
        return data;
    }

    const getDailyGoalsInLanguage = async (language) => {
        let data = await getCurrentUserGoalsInTargetLanguage(language);
        return data;
    }

    const refreshVocabularyMetadata = async (l) => {
        console.log("Refresh: ", l);
        let lang = l === undefined ? globalSelectedTargetLanguage : l;
        let r = await fetchVocabularyMetadataInTargetLanguage(lang);
        if (vocabularyMetadata !== null){
            let copy = JSON.parse(JSON.stringify(vocabularyMetadata));
            copy[lang] = r;
            setVocabularyMetadata(copy);
        } else {
            let d = {};
            d[lang] = r;
            setVocabularyMetadata(d);
        }
    }

    const refreshVocabularyMetadataInAllActiveLanguages = async () => {
        console.log("Refresh all active languages metadata");
        if (activeUserLanguages === null){return}
        let copy = {};
        for (const language of activeUserLanguages){
            let lang = language.target_language;
            let r = await fetchVocabularyMetadataInTargetLanguage(lang);
            if (vocabularyMetadata !== null){
                copy = JSON.parse(JSON.stringify(vocabularyMetadata));
                copy[lang] = r;   
            } else {
                copy[lang] = r;
            }
        }
        console.log("Vocab: ", copy);
        setVocabularyMetadata(copy);
    }

    const value = {
        dailyStats, 
        dailyGoals, 
        userStars,
        refreshUserStars,
        userStatisticsFetched, 
        refreshUserGoalsFunction, 
        currentDailyStreak, 
        refreshDailyStreaks,
        refreshDailyStatsFunction,
        getDailyStatsInActiveTargetLanguages, 
        getDailyStatsInGlobalSelectedTargetLanguage, 
        getDailyStatsInLanguage,
        getDailyGoalsInGlobalSelectedTargetLanguage, 
        getDailyGoalsInLanguage,
        activeVocabularyTargetWords, 
        setActiveVocabularyTargetWords,
        knownVocabulary,
        setKnownVocabulary,
        refreshKnownVocabulary, 
        refreshActiveVocabularyTargetWords, 
        vocabularyTargetWordsInTargetLanguage, 
        manuallySetVocabularyTargetWordsInTargetLanguage, 
        refreshSpacedRepetitionQueue, 
        mostUrgentNextReviewItems,
        vocabularyMetadata, 
        refreshVocabularyMetadata, 
        refreshVocabularyMetadataInAllActiveLanguages,
        activateDailyGoalsNeedRefresh,
        activateDailyStatsNeedRefresh,
        dailyStatsNeedRefresh,
        dailyGoalsNeedRefresh,
        dailyStreakNeedsRefresh,
        activateDailyStreakNeedsRefresh,
        activateUserStarsNeedRefresh,
        deactivateDailyGoalsNeedRefresh, 
        refreshUserGoalsIfNeeded, 
        refreshDailyStreakIfNeeded, 
        refreshDailyStatsIfNeeded,
        refreshProcessedVocabularyDataInTargetLanguagesInMonth, 
        processedVocabularyData, 
        refreshProcessedVocabularyDataInTargetLanguagesToday, 
        refreshProcessedVocabularyDataInTargetLanguagesLastSevenDays

    }

    return (
    <UserStatisticsContext.Provider value={value}>
        {children}
    </UserStatisticsContext.Provider>
    )
}