import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useThemeContext } from '../../contexts/ThemeContext';

const CustomSkeleton = ({
  customBaseColor,
  customHighlightColor,
  count, 
  height,
}) => {

    const getThemeColors = (theme) => ({
    baseColor: theme === 'dark' ? '#1f2937' : '#e0e0e0',
    highlightColor: theme === 'dark' ? '#485d7c' : '#f5f5f5',
    });
  
  const { theme } = useThemeContext();
  const { baseColor, highlightColor } = customBaseColor && customHighlightColor
    ? { baseColor: customBaseColor, highlightColor: customHighlightColor }
    : getThemeColors(theme);

  return (
    <>
        <Skeleton
        baseColor={baseColor}
        highlightColor={highlightColor}
        count={count}
        height={height}
        />
    </>
  );
};

export default CustomSkeleton;
