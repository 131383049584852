import React from 'react';

const NormalTextInput = ({value, onChange, type, ...buttonProps}) => {
    return (
        <>
            <input {...buttonProps} type={type === "password" ? "password" : "text"} value={value} onChange={onChange} className="bg-gray-200 dark:bg-transparent dark:bg-white appearance-none border-solid border border-gray-300 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white" />
        </>
    )
}

export default NormalTextInput;
