import React, {useState, useEffect} from 'react';
import { useAuth } from "../../contexts/AuthContext";
import useDecks from '../../hooks/useDecks';
import useSearch from '../../hooks/useSearch';
import useFavorites from '../../hooks/useFavorites';
import {useLanguagesContext} from '../../contexts/LanguagesContext';
import SortByButton from '../../components/general/sorting/SortByButton';
import YalangoSvg from '../../assets/svg/yalango/yalango.svg';
import DecksCard from './DecksCard';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import {motion} from 'framer-motion';
import Skeleton from 'react-loading-skeleton';
import {DebounceInput} from 'react-debounce-input';
import queryString from 'query-string';
import {useUserInfoContext} from '../../contexts/UserInfoContext';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Icon from '../../components/general/Icon';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faText} from '@fortawesome/pro-solid-svg-icons';
import {faXmark} from '@fortawesome/pro-solid-svg-icons';
import {faSliders} from '@fortawesome/pro-solid-svg-icons';
import {Helmet} from 'react-helmet';
import FooterMenu from '../../components/menus/FooterMenu';
import NormalTextInput from '../../components/general/input/NormalTextInput';
import MyLibraryMenu from '../../components/menus/MyLibraryMenu';
import ExploreMenu from '../../components/menus/ExploreMenu';
import CustomSkeleton from '../../components/general/CustomSkeleton';


const DecksDirectory = () => {
    let { currentUser } = useAuth();
    let location = useLocation();
    const {searchPublicDecks} = useSearch();
    let {fetchAllPublicCommunityDecksDataFromOneSourceLanguage, fetchAllPublicCommunityDecksDataFromOneTargetLanguage, fetchAllPublicCommunityDecksData, fetchCommunityDecksDataFromOneLanguage, fetchAllPublicDecksDataFromOneSourceLanguage, fetchAllPublicDecksDataFromOneTargetLanguage, fetchAllPublicYalangoDecksDataFromOneSourceLanguage, fetchAllPublicYalangoDecksDataFromOneTargetLanguage, fetchAllPublicYalangoDecksData, fetchPublicDecksDataFromOneLanguage, fetchYalangoDecksDataFromOneLanguage, fetchAllPublicDecksData} = useDecks();
    let {allLanguages, activeUserLanguages, activeSourceLanguageOptions, activeTargetLanguageOptions, globalSelectedTargetLanguage, setGlobalSelectedTargetLanguage, globalSelectedSourceLanguage, setGlobalSelectedSourceLanguage} = useLanguagesContext();
    let { favorites } = useUserInfoContext();
    let {addOrRemoveDeckFavorite} = useFavorites();
    const [decks, setDecks] = useState(null);
    const [languages, setLanguages] = useState(allLanguages !== null ? allLanguages : null);
    const [loading, setLoading] = useState(true);
    const [langLoading, setLangLoading] = useState(true);
    const [targetLangOptions, setTargetLangOptions] = useState(null);
    const [sourceLangOptions, setSourceLangOptions] = useState(null);
    const [decksChoice, setDecksChoice] = useState('all');
    const [queryStringsRead, setQueryStringsRead] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [deckOptions, setDeckOptions] = useState([
        {value: 'all',
        label: 'All decks'
        }, 
        {value: 'yalango',
        label: 'Official Yalango decks'
        },
        {value: 'community',
        label: 'Community decks'
        }
    ]);
    const [deckOptionsDict, setDeckOptionsDict] = useState(
        {
            'all': {value: 'all',
            label: 'All decks'
            },
            'yalango': {value: 'yalango',
            label: 'Official Yalango decks'
            }, 
            'community': {value: 'community',
            label: 'Community decks'
            }
        }
    );
    const [langOptionsDict, setLangOptionsDict] = useState(null);
    const [sourceLanguage, setSourceLanguage] = useState(globalSelectedSourceLanguage !== null ? globalSelectedSourceLanguage : "all");
    const [targetLanguage, setTargetLanguage] = useState(globalSelectedTargetLanguage !== null ? globalSelectedTargetLanguage : "all" );
    const [currentPage, setCurrentPage] = useState(null);
    const [numberOfResults, setNumberOfResults] = useState(null);
    const [numberOfResultsPerPage, setNumberOfResultsPerPage] = useState(20);
    const [numberOfPages, setNumberOfPages] = useState(null);

    const [showFilterOptions, setShowFilterOptions] = useState(false);
    const sortByOptions = [
        {label: 'Most relevant', value:'most_relevant'},
        {label: 'Number of items', value:'number_of_items'},
        {label: 'Most recently updated', value:'last_updated_timestamp_desc'},
        {label: 'Most recently created', value:'created_timestamp_desc'},     
        {label: 'Updated (ascending)', value:'last_updated_timestamp_asc'},
        {label: 'Created (ascending)', value:'created_timestamp_asc'},       
    ];
    const [sortByValue, setSortByValue] = useState(sortByOptions[0].value);
    const sortByOptionsDict = sortByOptions.reduce(function(result, item) {
        var key = item.value; 
        result[key] = item;
        return result;
      }, {});

    let navigate = useNavigate();
    let currentPath = location.pathname;
    let currentSearch = location.search;

    useEffect(()=>{
        if (allLanguages !== null){
            setLanguages(allLanguages);
        }
        if (globalSelectedSourceLanguage !== null){
            setSourceLanguage(globalSelectedSourceLanguage);
        }
        if (globalSelectedTargetLanguage !== null){
            setTargetLanguage(globalSelectedTargetLanguage);
        }
    },[allLanguages, globalSelectedSourceLanguage, globalSelectedTargetLanguage])

    useEffect(()=>{
        
        function sortByKey(array, key) {
            return array.sort(function(a, b) {
                var x = a[key]; var y = b[key];
                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            });
        }

        const fetchAllLanguages = async () => {
            let target_options = [];
            let source_options = [];
            let options_dict = {};
            let userTargetLanguagesGroup = {
                label: 'My target languages',
                options: []
            };
            let userSourceLanguagesGroup = {
                label: 'My source languages',
                options: []
            };
            let allTargetLanguagesGroup = {
                label: 'All languages',
                options: []
            };
            let allSourceLanguagesGroup = {
                label: 'All languages',
                options: []
            };
            for (const lang of allLanguages[1]){
                let addedToTargetUserLanguages = false;
                let addedToSourceUserLanguages = false;
                if (activeUserLanguages !== null && activeUserLanguages.length > 0){
                    for (const u_lang of activeUserLanguages){
                        if (lang === u_lang['target_language']){
                            userTargetLanguagesGroup.options.push({
                                value: lang,
                                label: allLanguages[0][lang]['name']['en']
                            });
                            addedToTargetUserLanguages = true;
                        }
                        if (lang === u_lang['source_language']){
                            let obj = {
                                value: lang,
                                label: allLanguages[0][lang]['name']['en']
                            }
                            //console.log(obj);
                            if (!userSourceLanguagesGroup.options.some(e => e.value === lang)){
                                userSourceLanguagesGroup.options.push(obj);
                                addedToSourceUserLanguages = true;
                            } 
                        }
                    }
                } else {
                    addedToTargetUserLanguages = false;
                    addedToSourceUserLanguages = false;
                }
                
                if (!addedToTargetUserLanguages){
                    allTargetLanguagesGroup.options.push({
                        value: lang, 
                        label: allLanguages[0][lang]['name']['en']
                    });
                }
                if (!addedToSourceUserLanguages){
                    allSourceLanguagesGroup.options.push({
                        value: lang, 
                        label: allLanguages[0][lang]['name']['en']
                    });
                }
                options_dict[lang] = {
                    value: lang, 
                    label: allLanguages[0][lang]['name']['en']
                };
            }

            options_dict['all'] = {
                value: "all",
                label: 'All languages'
            };

            allTargetLanguagesGroup.options = sortByKey(allTargetLanguagesGroup.options, 'label');
            userTargetLanguagesGroup.options = sortByKey(userTargetLanguagesGroup.options, 'label');

            allSourceLanguagesGroup.options = sortByKey(allSourceLanguagesGroup.options, 'label');
            userSourceLanguagesGroup.options = sortByKey(userSourceLanguagesGroup.options, 'label');

            allTargetLanguagesGroup.options.unshift({
                value: "all",
                label: 'All languages'
            });
            allSourceLanguagesGroup.options.unshift({
                value: "all",
                label: 'All languages'
            });

            target_options.unshift(allTargetLanguagesGroup);
            target_options.unshift(userTargetLanguagesGroup);

            source_options.unshift(allSourceLanguagesGroup);
            source_options.unshift(userSourceLanguagesGroup);

            setTargetLangOptions(target_options);
            setSourceLangOptions(source_options);
            setLangOptionsDict(options_dict);

            setDefaultValues(userTargetLanguagesGroup.options, userSourceLanguagesGroup.options);
        }

        const getQueryStrings = () => {
            const parsed = queryString.parse(location.search);

            if ('source' in parsed){
                setSourceLanguage(parsed['source']);
            } 
            if ('target' in parsed){
                setTargetLanguage(parsed['target']); 
            }
            if ('decks' in parsed){
                if (parsed['decks'] === 'all' || parsed['decks'] === 'yalango' || parsed['decks'] === 'community'){
                    setDecksChoice(deckOptionsDict[parsed['decks']].value);
                }
            }
            if ('page' in parsed){
                if (Number.isInteger(parsed['page'])){
                    setCurrentPage(parsed['page']);
                }
                else {
                    setCurrentPage(1);
                }
            } else {
                setCurrentPage(1);
            }
            setQueryStringsRead(true);
        }

        const setDefaultValues = (target_list, source_list) => {
            if (target_list.length > 0){
                if (targetLanguage === null){
                    setTargetLanguage(target_list[0]['value']);
                }
            }
            if (source_list.length > 0){
                if (sourceLanguage === null){
                    setSourceLanguage(source_list[0]['value']);
                }
            }
        }

        const fetchEverything = async () => {
            await fetchAllLanguages();
            await getQueryStrings();
           
        }

        if (allLanguages !== null){
            fetchEverything();
        }
        
    },[allLanguages, activeUserLanguages]);


/*     useEffect(()=>{
        const addMissingFields = (results) => {
            results.forEach((value, index) => {
                //console.log(value, index, results[index]);
                if (!('title' in value)){
                    results[index]['title'] = 'wow';
                }
                if (!('description' in value)){
                    results[index]['description'] = '';
                }
                if (!('author' in value)){
                    results[index]['author'] = {
                        'username': '',
                        'displayname': '' 
                    };
                }
            }); 
            //console.log(results);
            for (const result of results){
                //console.log(result);
            }
            return results;
        }

        const fetchData = async () => {
            let results;
            //console.log("Choice: ", decksChoice);
            if (targetLanguage !== "all" && sourceLanguage !== "all"){
                if (decksChoice === "all"){
                    results = await fetchPublicDecksDataFromOneLanguage(targetLanguage, sourceLanguage);
                }
                else if (decksChoice === "yalango"){
                    results = await fetchYalangoDecksDataFromOneLanguage(targetLanguage, sourceLanguage);
                }
                else if (decksChoice === "community"){
                    results = await fetchCommunityDecksDataFromOneLanguage(targetLanguage, sourceLanguage);
                }
            } else {
                if (decksChoice === "all"){
                    if (targetLanguage === "all" && sourceLanguage === "all"){
                        results = await fetchAllPublicDecksData();
                    }
                    else if (targetLanguage === "all" && sourceLanguage !== "all"){
                        results = await fetchAllPublicDecksDataFromOneSourceLanguage(sourceLanguage);
                    }
                    else if (targetLanguage !== "all" && sourceLanguage === "all"){
                        results = await fetchAllPublicDecksDataFromOneTargetLanguage(targetLanguage);
                    }
                } 
                else if (decksChoice === "yalango"){
                    if (targetLanguage === "all" && sourceLanguage === "all"){
                        results = await fetchAllPublicYalangoDecksData();
                    }
                    else if (targetLanguage === "all" && sourceLanguage !== "all"){
                        results = await fetchAllPublicYalangoDecksDataFromOneSourceLanguage(sourceLanguage);
                    }
                    else if (targetLanguage !== "all" && sourceLanguage === "all"){
                        results = await fetchAllPublicYalangoDecksDataFromOneTargetLanguage(targetLanguage);
                    }
                }
                else if (decksChoice === "community"){
                    if (targetLanguage === "all" && sourceLanguage === "all"){
                        results = await fetchAllPublicCommunityDecksData();
                    }
                    else if (targetLanguage === "all" && sourceLanguage !== "all"){
                        results = await fetchAllPublicCommunityDecksDataFromOneSourceLanguage(sourceLanguage);
                    }
                    else if (targetLanguage !== "all" && sourceLanguage === "all"){
                        results = await fetchAllPublicCommunityDecksDataFromOneTargetLanguage(targetLanguage);
                    }
                }
                
            }
            //console.log("Results: ", results);
            if (results.length > 0){
                results = await addMissingFields(results);
            }
            //console.log(results);
            setDecks(results);
            setLoading(false);
        }

        if (sourceLanguage !== null && targetLanguage !== null && decksChoice !== null){
            setLoading(true);
            fetchData();
        };

    },[sourceLanguage, targetLanguage, decksChoice]); */

    useEffect(()=>{
        console.log("decks:", decks);
        console.log("loading: ", loading);
    },[decks, loading])

    useEffect(()=>{
        if (targetLangOptions !== null && queryStringsRead === true && languages !== null && targetLanguage !== null && sourceLanguage !== null && langOptionsDict !== null && deckOptions !== null && decksChoice !== null && currentPage !== null){
            setLoading(false);
        } 
    },[targetLangOptions, queryStringsRead, langOptionsDict, languages, targetLanguage, sourceLanguage, deckOptions, decksChoice, currentPage]);

    useEffect(()=> {
        if (targetLangOptions !== null && langOptionsDict !== null && languages !== null && deckOptions !== null && decksChoice !== null && queryStringsRead === true){
            setLangLoading(false);
            /* if (targetLanguage === null){
                setTargetLanguage('no');
                const url = setParams('no', "target");
                navigate(currentPath+'?'+url);
            } 
            if (sourceLanguage === null){
                setSourceLanguage('en');
            } */
        }
    },[targetLangOptions, queryStringsRead, langOptionsDict, languages, targetLanguage, sourceLanguage, deckOptions, decksChoice]);

    const setParams = (value = "", name) => {
        const searchParams = new URLSearchParams(currentSearch);
        searchParams.set(`${name}`, value);
        return searchParams.toString();
    }

    const handleTargetLanguageChange = async (option) => {
        setTargetLanguage(option.value);
        const url = setParams(option.value, "target");
        navigate(currentPath+'?'+url);
    }
    const handleSourceLanguageChange = async (option) => {
        setSourceLanguage(option.value);
        const url = setParams(option.value, "source");
        navigate(currentPath+'?'+url);
    }
    const handleDecksChoiceChange = async (option) => {
        setDecksChoice(option.value);
        const url = setParams(option.value, "decks");
        navigate(currentPath+'?'+url);
    }

    useEffect(()=>{
        const fetchData = async () => {
            await fetchSearchResults();
        }
        if (targetLanguage !== null && sourceLanguage !== null && decksChoice !== null && sortByValue !== null){
            fetchData();
        }
    },[targetLanguage, sourceLanguage, sortByValue, decksChoice, searchTerm, currentPage]);

    useEffect(()=>{
        if (numberOfResults !== null && numberOfResultsPerPage !== null){
            setNumberOfPages(Math.ceil(numberOfResults/numberOfResultsPerPage));
        }
    },[numberOfResults, numberOfResultsPerPage]);

    const fetchSearchResults = async () => {
        setLoading(true);
        let r = await searchPublicDecks(searchTerm, targetLanguage, sourceLanguage, decksChoice, numberOfResultsPerPage, currentPage, sortByValue);
        let d_list = [];
        r['hits'].forEach((hit)=>{
            d_list.push(hit.document);
        });
        console.log("Results: ", d_list);
        setDecks(d_list);
        setNumberOfResults(r['found']);
        setCurrentPage(r['page']);
        setLoading(false);
    }

    const searchClick = async () => {
        await fetchSearchResults();
    }

    const searchInputChange = (e) => {
        setSearchTerm(e.target.value);
    }

    const changePageNumber = (nb) => {
        setCurrentPage(nb);
        const url = setParams(nb, "page");
        navigate(currentPath+'?'+url);
        window.scrollTo(0, 0);
    }

    
    
    const changeSortByValue = (option) => {
        setSortByValue(option.value);
    }
    //<MyLibraryMenu activeName="explore" />
    //<ExploreMenu activeName={"decks"} />
    return (
        <>
            <Helmet>
                <title>Public decks - Yalango</title>
                <meta name="description" content="Discover and play with the huge amount of public decks available on Yalango." />
            </Helmet>
                <ReactTooltip id="directory-tooltip" effect="solid" />
                <div className=" min-h-screen overflow-x-hidden">
                <h1>Public decks</h1>
                <div className=" ">
                    <div className="flex flex-col gap-8">
                        
                        
                        <div className="px-5 py-4">
                            <div className="flex flex-row justify-center place-items-center gap-8 mb-6">
                                <div className="text-xl cursor-pointer" onClick={()=>setShowFilterOptions(!showFilterOptions)}>
                                    <FontAwesomeIcon icon={faSliders} />
                                </div>
                                <div className="sm:w-1/3 w-2/3">
                                    <DebounceInput 
                                        placeHolder="Search public decks" 
                                        value={searchTerm} 
                                        onChange={searchInputChange} 
                                        minLength={1}
                                        debounceTimeout={300}
                                        type="text" 
                                        className="bg-gray-200 appearance-none h-12 sm:text-lg border-solid border border-gray-300 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white"
                                    />
                                </div>
                                
                            </div>
                            {!showFilterOptions && allLanguages !== null && targetLanguage !== null && !langLoading && (
                            <div className="flex my-4 flex-row sm:text-base text-xs justify-center gap-2">
                                {/* Target Language Filter */}
                                {targetLanguage !== "all" && (
                                <motion.div
                                    onClick={() => setTargetLanguage("all")}
                                    whileHover={{ scale: 1.02 }}
                                    className="group cursor-pointer whitespace-nowrap bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 p-3 rounded-lg shadow-xl flex flex-row place-items-center justify-center gap-4"
                                >
                                    <div className="text-gray-600 dark:text-gray-300">
                                    <span className="font-bold">Target:</span>{" "}
                                    {allLanguages[0][targetLanguage]["name"]["en"]}
                                    </div>
                                    <div className="group-hover:text-red-500 text-lg">
                                    <FontAwesomeIcon icon={faXmark} />
                                    </div>
                                </motion.div>
                                )}

                                {/* Source Language Filter */}
                                {sourceLanguage !== "all" && (
                                <motion.div
                                    onClick={() => setSourceLanguage("all")}
                                    whileHover={{ scale: 1.02 }}
                                    className="group cursor-pointer whitespace-nowrap bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 p-3 rounded-lg shadow-xl flex flex-row place-items-center justify-center gap-4"
                                >
                                    <div className="text-gray-600 dark:text-gray-300">
                                    <span className="font-bold">Source:</span>{" "}
                                    {allLanguages[0][sourceLanguage]["name"]["en"]}
                                    </div>
                                    <div className="group-hover:text-red-500 text-lg">
                                    <FontAwesomeIcon icon={faXmark} />
                                    </div>
                                </motion.div>
                                )}

                                {/* Deck Choice Filter */}
                                {decksChoice !== "all" && (
                                <motion.div
                                    onClick={() => setDecksChoice("all")}
                                    whileHover={{ scale: 1.02 }}
                                    className="group cursor-pointer whitespace-nowrap bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 p-3 rounded-lg shadow-xl flex flex-row place-items-center justify-center gap-4"
                                >
                                    <div className="text-gray-600 dark:text-gray-300">
                                    <span className="font-bold">Decks by:</span> {decksChoice}
                                    </div>
                                    <div className="group-hover:text-red-500 text-lg">
                                    <FontAwesomeIcon icon={faXmark} />
                                    </div>
                                </motion.div>
                                )}
                            </div>
                            )}

                            {!langLoading && showFilterOptions && (
                            <div className="w-5/6 m-auto flex flex-col sm:flex-row gap-3 flex-grow justify-center place-items-center">
                                {/* Target Language Select */}
                                <div className="w-full flex flex-col flex-grow">
                                <div className="font-bold text-gray-600 dark:text-gray-300">
                                    I'm learning
                                </div>
                                <Select
                                    options={targetLangOptions}
                                    value={langOptionsDict[targetLanguage]}
                                    autoFocus={false}
                                    placeholder="I'm learning"
                                    className="text-gray-500 dark:text-gray-400 text-base"
                                    onChange={handleTargetLanguageChange}
                                />
                                </div>

                                {/* Source Language Select */}
                                <div className="w-full flex flex-col flex-grow">
                                <div className="font-bold text-gray-600 dark:text-gray-300">
                                    I speak
                                </div>
                                <Select
                                    options={sourceLangOptions}
                                    value={langOptionsDict[sourceLanguage]}
                                    autoFocus={false}
                                    placeholder="I speak"
                                    className="text-gray-500 dark:text-gray-400 text-base"
                                    onChange={handleSourceLanguageChange}
                                />
                                </div>

                                {/* Deck Type Select */}
                                <div className="w-full flex flex-col flex-grow">
                                <div className="font-bold text-gray-600 dark:text-gray-300">
                                    Deck type
                                </div>
                                <Select
                                    options={deckOptions}
                                    isSearchable={false}
                                    value={deckOptionsDict[decksChoice]}
                                    autoFocus={false}
                                    placeholder="Decks"
                                    className="text-gray-500 dark:text-gray-400 text-base"
                                    onChange={handleDecksChoiceChange}
                                />
                                </div>
                            </div>
                            )}

                            {loading &&
                                <>
                                    <div  className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 text-center">
                                    {[...Array(numberOfResultsPerPage)].map((n, i)=>(
                                        <>
                                            <div className="h-64 p-4 w-full" key={"skeleton_"+i}>
                                                <CustomSkeleton count={1} height={230} />
                                            </div>
                                        </>
                                    ))}
                                    </div>
                                </>
                            }
                            {(!loading && decks !== null) ?
                                <>
                                    <div className="flex flex-row justify-start sm:justify-end px-8">
                                        <SortByButton textSize={"sm"} onChange={changeSortByValue} value={sortByOptionsDict[sortByValue]} options={sortByOptions} />
                                    </div>
                                    <div className="grid gap-16 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 4xl:grid-cols-6 place-items-center justify-center text-center">
                                        {(languages !== null) && decks.map((deck, index)=>(
                                            <div key={"card_"+index} className="size-64">
                                                <DecksCard currentUser={currentUser} addOrRemoveDeckFavorite={addOrRemoveDeckFavorite} favorites={favorites} languages={languages} key={'deck_'+index} deck={deck} />
                                            </div>
                                        ))
                                        }
                                    </div>
                                    {decks.length === 0 &&
                                        <div className="text-center italic text-base mb-5 w-1/2 m-auto">
                                            No decks found. Please try again with other filters.
                                        </div>
                                    }
                                    
                                    {numberOfPages !== null && 
                                        <>
                                            <div className="flex flex-row justify-center gap-2">
                                                {[...Array(numberOfPages)].map((val, index)=>(
                                                    <>
                                                        <motion.div onClick={()=>changePageNumber(index+1)} whileHover={{scale:1.03}} key={"page_"+index} className={(currentPage === (index+1) ? 'bg-charcoal text-white ': 'bg-white ' ) + " cursor-pointer p-2 px-4 rounded-lg shadow-xl"}>
                                                            {index+1}
                                                        </motion.div>
                                                    </> 
                                                ))}
                                            </div>
                                        </>
                                    }
                                    {decks.length !== 0 &&
                                        <div className="text-center italic text-sm mt-4">These decks have been created by Yalangoers. Decks are private by default, but these users have chosen to share their work publicly.</div>
                                    }
                                </>
                            :
                                <>
                                    {queryStringsRead &&
                                        <>
                                            <div className="text-center p-12 italic">
                                            {targetLanguage !== null && sourceLanguage !== null ?
                                                <>
                                                    
                                                </>
                                            :
                                                <>Please choose a target and source language in order to make a search in our directory.</>
                                            }
                                            </div>
                                        </>
                                    }
                                </>
                            }   
                        </div>
                    </div>
                </div>
                
            </div>
        </>
    )
}

export default DecksDirectory;