import React, {useState, useEffect} from 'react';
import {projectFirestore} from '../../../firebase/config.js';
import { collection, query, where, getDocs, doc, deleteDoc } from "firebase/firestore";
import {faArrowDown} from '@fortawesome/pro-solid-svg-icons';
import {faArrowUp} from '@fortawesome/pro-solid-svg-icons';
//import '../../../css/Card.css';
import {motion, AnimatePresence } from 'framer-motion';
import Icon from '../../general/Icon.js';
import {DebounceInput} from 'react-debounce-input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { faPencil } from '@fortawesome/pro-light-svg-icons';

import CountryFlag from '../../languages/CountryFlag';
import useDecks from '../../../hooks/useDecks';
import { useAuth } from "../../../contexts/AuthContext";
import Skeleton from 'react-loading-skeleton';


const Card = (props) => {
    let {currentUser} = useAuth();
    const {saveChangesToDeckCard} = useDecks();


    const handleEditCardClick = (e) => {
        console.log("Click: ", props.doc.id);
        console.log("Fields. ", props.customFields);
        props.setModalMode("edit");
        props.setEditCardId(props.doc.id);
        props.setShowModal(true); 
    }

    const handleDeleteCardClick = async () => {
        try {
            const decksColRef = collection(projectFirestore, 'decks');
    
            // Create a query to find the deck containing the card
            const queryRef = query(
                decksColRef,
                where('uid', '==', currentUser.uid),
                where('id', '==', props.doc.deck_id)
            );
    
            // Execute the query and get the matching document
            const querySnapshot = await getDocs(queryRef);
            const docId = querySnapshot.docs[0]?.id;
    
            if (!docId) {
                console.error("Deck not found");
                return;
            }
    
            // Reference the specific card in "deck-cards" collection
            const cardDocRef = doc(projectFirestore, "deck-cards", props.doc.id);
    
            // Attempt to delete the card
            await deleteDoc(cardDocRef);
    
            // Update the local state after successful deletion
            const newDocsList = props.docs.filter(d => d.id !== props.doc.id);
            props.setDocs(newDocsList);
    
            if (props.hasOwnProperty('addDocIdToDeletedCardsList')) {
                props.addDocIdToDeletedCardsList(props.doc.id);
            }
    
            if (props.hasOwnProperty('decrementNumberOfCards')) {
                props.decrementNumberOfCards(1);
            }
        } catch (err) {
            console.error("Error deleting card:", err);
    
            // Handle errors and update local state
            const newDocsList = props.docs.filter(d => d.id !== props.doc.id);
            props.setDocs(newDocsList);
    
            if (props.hasOwnProperty('addDocIdToDeletedCardsList')) {
                props.addDocIdToDeletedCardsList(props.doc.id);
            }
    
            if (props.hasOwnProperty('decrementNumberOfCards')) {
                props.decrementNumberOfCards(1);
            }
        }
    };

    const [showCustomFields, setShowCustomFields] = useState(false);
    const [hasAnyCustomFields, setHasAnyCustomFields] = useState(false);
    const [numberOfCustomFields, setNumberOfCustomFields] = useState(null);
    const [showPinyin, setShowPinyin] = useState(false);

    useEffect(()=>{
        if (props.apiType === "typesense"){
            if (props.customFields !== undefined && props.customFields !== null && props.customFields.length > 0){
                let counter = 0;
                for (const customField of props.customFields){
                    if (props.doc.hasOwnProperty('custom_fields.'+customField.data.id) && props.doc['custom_fields.'+customField.data.id] !== ""){
                        counter = counter + 1;
                    }
                }
                if (counter > 0){
                    setHasAnyCustomFields(true); 
                }
                setNumberOfCustomFields(counter);
            }
        }
        if (props.allLanguages !== undefined){
            console.log(props.allLanguages)
        }
    },[props]);


    return (
        <>
            {props.allLanguages !== undefined && (
    <>
        <div className="flex w-full h-full relative overflow-auto rounded-lg shadow-md hover:shadow-lg bg-white dark:bg-gray-800">
            <div className="m-auto w-full px-1 py-4 text-center">
                <div className="max-h-xs flex flex-col place-items-center gap-6 w-full">
                    <div className="flex w-full flex-col gap-2">
                        {/* Target Word Input */}
                        <div
                            className={`${
                                props.doc.target.length < 5
                                    ? 'text-2xl xl:text-3xl 3xl:text-5xl'
                                    : props.doc.target.length < 10
                                    ? 'text-xl xl:text-2xl 3xl:text-3xl'
                                    : props.doc.target.length < 15
                                    ? 'text-base xl:text-lg 3xl:text-2xl'
                                    : props.doc.target.length < 20
                                    ? 'text-sm xl:text-base 3xl:text-xl'
                                    : 'text-xs xl:text-sm 3xl:text-base'
                            } gap-3 w-full m-1`}
                        >
                            <DebounceInput
                                className="text-center font-semibold w-full bg-transparent border-none outline-none dark:text-white"
                                minLength={1}
                                value={props.doc.target}
                                debounceTimeout={1000}
                                onChange={(event) =>
                                    props.targetWordOnChange(event, props.doc.id)
                                }
                            />
                        </div>
                        {/* Source Word Input */}
                        <div
                            className={`${
                                props.doc.source.length < 5
                                    ? 'text-2xl'
                                    : props.doc.source.length < 10
                                    ? 'text-lg'
                                    : props.doc.source.length < 15
                                    ? 'text-base'
                                    : props.doc.source.length < 20
                                    ? 'text-sm'
                                    : 'text-xs'
                            } gap-3 w-full m-1`}
                        >
                            <DebounceInput
                                className="text-center w-full bg-transparent border-none outline-none dark:text-gray-300"
                                minLength={1}
                                value={props.doc.source}
                                debounceTimeout={1000}
                                onChange={(event) =>
                                    props.sourceWordOnChange(event, props.doc.id)
                                }
                            />
                        </div>
                    </div>

                    {props.apiType === 'typesense' ? (
                        <>
                            <AnimatePresence>
                                {hasAnyCustomFields && numberOfCustomFields > 0 && (
                                    <div className="flex flex-col gap-2 p-4 max-h-36 overflow-auto scrollbar-thin scrollbar-thumb-charcoal dark:scrollbar-thumb-gray-600 scrollbar-track-gray-100 dark:scrollbar-track-gray-700 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
                                        <div
                                            className="text-sm text-gray-600 dark:text-gray-300 cursor-pointer flex flex-row place-items-center justify-center gap-2"
                                            onClick={() =>
                                                setShowCustomFields(!showCustomFields)
                                            }
                                        >
                                            <div className="opacity-70 text-xs">
                                                <FontAwesomeIcon
                                                    icon={showCustomFields ? faArrowUp : faArrowDown}
                                                />
                                            </div>
                                            <div>
                                                Custom fields ({numberOfCustomFields})
                                            </div>
                                        </div>
                                        {showCustomFields && (
                                            <motion.div
                                                variants={{
                                                    initial: { height: 0 },
                                                    animate: {
                                                        height: 'auto',
                                                        transition: {
                                                            when: 'beforeChildren',
                                                            duration: 0.2,
                                                        },
                                                    },
                                                    exit: {
                                                        height: 0,
                                                        transition: { when: 'afterChildren' },
                                                    },
                                                }}
                                                initial="initial"
                                                animate="animate"
                                                exit="exit"
                                            >
                                                <motion.div
                                                    variants={{
                                                        initial: { opacity: 0 },
                                                        animate: { opacity: 1, duration: 0.3 },
                                                        exit: { opacity: 0 },
                                                    }}
                                                >
                                                    <div className="w-full text-sm flex flex-col gap-2">
                                                        {props.customFields?.length > 0 &&
                                                            props.customFields.map(
                                                                (customField, customFieldIndex) => (
                                                                    <>
                                                                        {props.doc.hasOwnProperty(
                                                                            'custom_fields.' +
                                                                                customField.data.id
                                                                        ) &&
                                                                            props.doc[
                                                                                'custom_fields.' +
                                                                                    customField.data.id
                                                                            ] !== '' && (
                                                                                <div
                                                                                    key={`custom_fields_row_${customFieldIndex}`}
                                                                                    className="flex flex-row justify-start place-items-center gap-4 w-full whitespace-nowrap"
                                                                                >
                                                                                    <div
                                                                                        key={`name_field${customFieldIndex}`}
                                                                                        className="font-bold text-gray-600 dark:text-gray-300"
                                                                                    >
                                                                                        {
                                                                                            customField.data
                                                                                                .name
                                                                                        }
                                                                                    </div>
                                                                                    <div
                                                                                        className="overflow-x-auto"
                                                                                        key={`value_field${customFieldIndex}`}
                                                                                    >
                                                                                        {customField.data
                                                                                            .type ===
                                                                                        'list' ? (
                                                                                            <div className="flex flex-row gap-1 justify-start place-items-center">
                                                                                                {props.doc[
                                                                                                    'custom_fields.' +
                                                                                                        customField
                                                                                                            .data.id
                                                                                                ]
                                                                                                    .split(
                                                                                                        ','
                                                                                                    )
                                                                                                    .map(
                                                                                                        (
                                                                                                            item,
                                                                                                            item_index
                                                                                                        ) => (
                                                                                                            <div
                                                                                                                className="bg-charcoal dark:bg-gray-600 text-white dark:text-gray-200 rounded-lg py-1 px-2"
                                                                                                                key={`list_item_${item_index}`}
                                                                                                            >
                                                                                                                {
                                                                                                                    item
                                                                                                                }
                                                                                                            </div>
                                                                                                        )
                                                                                                    )}
                                                                                            </div>
                                                                                        ) : (
                                                                                            props.doc[
                                                                                                'custom_fields.' +
                                                                                                    customField
                                                                                                        .data.id
                                                                                            ]
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                    </>
                                                                )
                                                            )}
                                                    </div>
                                                </motion.div>
                                            </motion.div>
                                        )}
                                    </div>
                                )}
                            </AnimatePresence>
                        </>
                    ) : (
                        <>
                            {props.doc.custom_fields &&
                                Object.keys(props.doc.custom_fields).length > 0 &&
                                props.customFields &&
                                props.customFields.length > 0 && (
                                    <div className="grid grid-cols-2 text-left gap-4">
                                        {Object.keys(props.doc.custom_fields).map(
                                            (key, index) =>
                                                props.customFields.map((cust, index2) => (
                                                    cust.data.id === parseInt(key) &&
                                                    props.doc.custom_fields[key] !== '' && (
                                                        <React.Fragment
                                                            key={`custom_field_${index}_${index2}`}
                                                        >
                                                            <div className="font-bold text-gray-600 dark:text-gray-300">
                                                                {cust.data.name}
                                                            </div>
                                                            <div className="w-full overflow-x-auto dark:text-gray-200">
                                                                {props.doc.custom_fields[key]}
                                                            </div>
                                                        </React.Fragment>
                                                    )
                                                ))
                                        )}
                                    </div>
                                )}
                        </>
                    )}
                </div>
            </div>
        </div>

        {/* Edit/Delete Buttons */}
        {props.editAuthorization && (
            <div className="absolute bottom-0 left-0 w-full">
                <div className="flex justify-between p-4 m-1">
                    <Icon
                        color="red"
                        icon={<FontAwesomeIcon icon={faTrashAlt} />}
                        onClick={handleDeleteCardClick}
                    />
                    <Icon
                        color="yellow"
                        icon={<FontAwesomeIcon icon={faPencil} />}
                        onClick={handleEditCardClick}
                    />
                </div>
            </div>
        )}
    </>
)}

        </>
    )
}

/* 
OLD CARD DESIGN

<div className="absolute w-full bg-green-300 rounded-lg hover:shadow-lg">
                <div className="text-center p-4 font-bold text-xl"> 
                <FontAwesomeIcon icon={faPencil} onClick={handleEditTargetClick} /> 
                {editTargetMode ? 
                    <input autoFocus type="text" value={editItemTarget} onChange={editItemTargetChange}  onBlur={editItemTargetBlur} onKeyDown={checkIfTargetEnter} />
                :
                    target
                }
                </div>
            </div>
            <div className="flex w-full h-full overflow-hidden rounded-lg shadow-md hover:shadow-lg">
                <div className="text-lg m-auto p-4 text-center">
                    <div className="max-h-xs">
                        {editSourceMode ? 
                        <textarea autoFocus type="text" value={editItemSource} onChange={editItemSourceChange}  onBlur={editItemSourceBlur} />
                        :
                            source
                        }
                    </div>
                </div>
            </div>
            <div className="absolute bottom-0 left-0 w-full">
                <div className="flex justify-evenly p-4">
                    <div className="text-red-500 border-2"><FontAwesomeIcon icon={faTrashAlt} /></div>
                    <div className="text-yellow-500"><FontAwesomeIcon icon={faPencil} onClick={handleEditSourceClick} /></div>
                    <div className="text-blue-500"><FontAwesomeIcon icon={faInfo} /></div>
                </div>   
            </div>


*/

export default Card;