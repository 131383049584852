import {projectFirestore, timeStamp} from '../firebase/config.js';
import { useAuth } from "../contexts/AuthContext";

import {
    getFirestore,
    collection,
    query,
    where,
    setDoc,
    getDoc,
    getDocs,
    doc,
    deleteDoc,
    addDoc,
    updateDoc,
    onSnapshot,
    serverTimestamp,
    orderBy,
    limit,
    startAfter,
    endBefore,
    limitToLast, 
    Timestamp
} from "firebase/firestore";
import {  httpsCallable } from "firebase/functions";

export default function useLanguages() {
    let {currentUser} = useAuth();

    const getAllLanguages = async () => {
        const ref = query(collection(projectFirestore, "languages"), orderBy("name.en"));
        const dict = {};
        const language_codes = [];
    
        const docs = await getDocs(ref);
        docs.forEach((doc) => {
            dict[doc.data()["ISO_639-1"]] = doc.data();
            language_codes.push(doc.data()["ISO_639-1"]);
        });
    
        return [dict, language_codes];
    };

    const getLastActiveTargetLanguageFromCurrentUser = async () => {
        if (!currentUser) return null;
    
        const docRef = doc(projectFirestore, "users", currentUser.uid, "private-data", "languages");
        const snapshot = await getDoc(docRef);
    
        if (snapshot.exists()) {
            const data = snapshot.data();
            return data["last_active_ISO_639-1"] || null;
        }
    
        return null;
    };

    const updateLastActiveTargetLanguageForCurrentUser = async (lang) => {
        if (!currentUser) return null;
    
        const docRef = doc(projectFirestore, "users", currentUser.uid, "private-data", "languages");
        const obj = { "last_active_ISO_639-1": lang };
    
        return setDoc(docRef, obj, { merge: true });
    };

    const getRealtimeUserLanguagesFromUid = async (uid, setStateFunc) => {
        const colRef = collection(projectFirestore, "users", uid, "private-data", "languages", "ISO_639-1");
    
        const unsubscribe = onSnapshot(colRef, (snapshot) => {
            const doc_list = [];
            snapshot.forEach((doc) => {
                doc_list.push(doc.data());
            });
            setStateFunc(doc_list);
        });
    
        return unsubscribe;
    };

    const getRealtimeUserLanguagesFromCurrentUser = async (setStateFunc) => {
        const colRef = collection(projectFirestore, "users", currentUser.uid, "private-data", "languages", "ISO_639-1");
        return onSnapshot(colRef, (snapshot) => {
            const doc_list = [];
            snapshot.forEach((doc) => {
                doc_list.push(doc.data());
            });
            setStateFunc(doc_list);
        });
    };
    
    const getUserLanguagesFromUid = async (uid) => {
        const colRef = collection(projectFirestore, "users", uid, "private-data", "languages", "ISO_639-1");
        const snapshot = await getDocs(colRef);
        const doc_list = [];
        snapshot.forEach((doc) => {
            doc_list.push(doc.data());
        });
        return doc_list;
    };
    
    const getUserLanguages = async () => {
        const colRef = collection(projectFirestore, "users", currentUser.uid, "private-data", "languages", "ISO_639-1");
        const snapshot = await getDocs(colRef);
        const doc_list = [];
        snapshot.forEach((doc) => {
            const metadata = doc.data();
            metadata.level = metadata.level || null;
            metadata.currently_studying = metadata.currently_studying || false;
            doc_list.push(metadata);
        });
        return doc_list;
    };
    
    const updateLanguages = async (new_data, languagesToBeDeleted) => {
        for (const item of new_data) {
            const colRef = collection(projectFirestore, "users", currentUser.uid, "private-data", "languages", "ISO_639-1");
            const q = query(colRef, where("target_language", "==", item.target_language));
            const snapshot = await getDocs(q);
    
            if (!snapshot.empty) {
                const docRef = doc(projectFirestore, "users", currentUser.uid, "private-data", "languages", "ISO_639-1", snapshot.docs[0].id);
                await setDoc(docRef, item);
            } else {
                await addDoc(colRef, item);
            }
        }
    
        for (const delItem of languagesToBeDeleted) {
            const colRef = collection(projectFirestore, "users", currentUser.uid, "private-data", "languages", "ISO_639-1");
            const q = query(colRef, where("target_language", "==", delItem));
            const snapshot = await getDocs(q);
            if (!snapshot.empty) {
                await deleteDoc(doc(projectFirestore, "users", currentUser.uid, "private-data", "languages", "ISO_639-1", snapshot.docs[0].id));
            }
        }
        return true;
    };
    
    const addNewLanguages = async (new_data) => {
        for (const item of new_data) {
            const colRef = collection(projectFirestore, "users", currentUser.uid, "private-data", "languages", "ISO_639-1");
            const q = query(colRef, where("target_language", "==", item.target_language));
            const snapshot = await getDocs(q);
    
            if (snapshot.empty) {
                await addDoc(colRef, {
                    target_language: item.target_language,
                    source_language: item.source_language,
                    created_timestamp: timeStamp,
                    level: "A1",
                    currently_studying: true,
                    name: {
                        en: item.name,
                    },
                });
            }
        }
        return true;
    };

    const addNewLanguagesToWishlist = async (new_data) => {
        for (const item of new_data) {
            const colRef = collection(projectFirestore, "users", currentUser.uid, "private-data", "languages", "ISO_639-1");
            const q = query(colRef, where("target_language", "==", item.target_language));
            const snapshot = await getDocs(q);
    
            if (snapshot.empty) {
                await addDoc(colRef, {
                    target_language: item.target_language,
                    source_language: item.source_language,
                    created_timestamp: timeStamp,
                    level: "zero",
                    currently_studying: false,
                    name: {
                        en: item.name,
                    },
                });
            }
        }
        return true;
    };
    
    const updateLanguageSettings = async ({ targetLanguage, settings }) => {
        const colRef = collection(projectFirestore, "users", currentUser.uid, "private-data", "languages", "ISO_639-1");
        const q = query(colRef, where("target_language", "==", targetLanguage));
        const snapshot = await getDocs(q);
    
        if (!snapshot.empty) {
            const docId = snapshot.docs[0].id;
            const docRef = doc(projectFirestore, "users", currentUser.uid, "private-data", "languages", "ISO_639-1", docId);
            await setDoc(docRef, { last_updated_timestamp: timeStamp, ...settings }, { merge: true });
        }
        return true;
    };

    return {
        getAllLanguages, 
        getRealtimeUserLanguagesFromUid, 
        getUserLanguagesFromUid, 
        getUserLanguages, 
        updateLanguages, 
        updateLanguageSettings,
        addNewLanguages, 
        getRealtimeUserLanguagesFromCurrentUser, 
        addNewLanguagesToWishlist, 
        updateLastActiveTargetLanguageForCurrentUser,
        getLastActiveTargetLanguageFromCurrentUser
    };
}
