import {projectFunctions, projectFirestore} from '../firebase/config.js';
import {
    getFirestore,
    collection,
    query,
    where,
    setDoc,
    getDoc,
    getDocs,
    doc,
    deleteDoc,
    addDoc,
    updateDoc,
    onSnapshot,
    serverTimestamp,
    orderBy,
    limit,
    startAfter,
    endBefore,
    limitToLast
} from "firebase/firestore";
import {  httpsCallable } from "firebase/functions";
import { useAuth } from "../contexts/AuthContext";

export default function useUserSettings() {

    let {currentUser} = useAuth();

    const fetchPublicUserInfoFromUid = async (uid) => {
        let info = await getDoc(doc(projectFirestore, 'users', uid)).then((snapshots) => {
            return snapshots.data();
        });
        return info;
    };

    const fetchPublicUserInfoFromUsername = async (username, current_uid) => {
        let own_profile_bool = false;
        let results;
    
        if (current_uid !== null) {
            let current_query = doc(projectFirestore, 'users', current_uid);
            const snapshot = await getDoc(current_query);
            if (snapshot.exists() && snapshot.data().username === username) {
                // looking at own profile
                return [true, snapshot];
            }
        }
    
        if (!own_profile_bool) {
            let userQuery = query(collection(projectFirestore, 'users'), where('username', '==', username), where('public_profile', '==', true));
            const snapshots = await getDocs(userQuery);
            if (!snapshots.empty) {
                results = snapshots.docs[0];
            } else {
                return [null, false]; // handle case where no matching user is found
            }
        }
    
        let data = results.data();
        let profile_uid = results.id;
        data['uid'] = profile_uid;
    
        // add default values
        if (!('displayname' in data)) {
            data['displayname'] = "";
        }
    
        return [data, own_profile_bool];
    };

    const getAccountInformationFromCurrentUser = async () => {
        const docRef = doc(projectFirestore, 'users', currentUser.uid, 'private-data', 'general');
        const snapshot = await getDoc(docRef);
        if (snapshot.exists()) {
            return snapshot.data();
        } else {
            return false;
        }
    };

    const addUidsToAllDecks = async () => {
        const usersQuery = collection(projectFirestore, 'users');
        const usersSnapshot = await getDocs(usersQuery);
        let user_list = [];
        
        usersSnapshot.forEach((snapshot) => {
            user_list.push(snapshot.id);
        });
    
        for (const userID of user_list) {
            const catCollectionRef = collection(doc(projectFirestore, 'users', userID), 'private-data', 'decks', 'categories');
            const catSnapshot = await getDocs(catCollectionRef);
            let cat_list = [];
    
            catSnapshot.forEach((snapshot) => {
                cat_list.push(snapshot.id);
            });
    
            for (const cat of cat_list) {
                const catDocRef = doc(catCollectionRef, cat);
                const catDocSnapshot = await getDoc(catDocRef);
                let deck_ids = [];
    
                try {
                    deck_ids = catDocSnapshot.data()?.decks?.id || [];
                } catch (error) {
                    console.error("Error fetching deck IDs:", error);
                }
    
                for (const doc_id of deck_ids) {
                    const deckQuery = query(collection(projectFirestore, 'decks'), where('id', '==', doc_id));
                    const deckSnapshot = await getDocs(deckQuery);
    
                    deckSnapshot.forEach((snapshot) => {
                        const deckId = snapshot.id;
                        const deckDocRef = doc(projectFirestore, 'decks', deckId);
                        updateDoc(deckDocRef, {
                            'uid': userID
                        }).catch((err) => console.error("Error updating deck:", err));
                    });
                }
            }
        }
    };

    const fetchPublicUserInfo = async () => {
        const docRef = doc(projectFirestore, 'users', currentUser.uid);
        const snapshot = await getDoc(docRef);
        return snapshot.data();
    }
    
    const fetchCurrentUserInfo = async () => {
        const docRef = doc(projectFirestore, 'users', currentUser.uid);
        const snapshot = await getDoc(docRef);
        if (snapshot.exists()) {
            return snapshot.data();
        } else {
            return null;
        }
    }

    const fetchRealtimePublicUserInfo = async (uid, setStateFunc) => {
        const docRef = doc(projectFirestore, 'users', uid);
        onSnapshot(docRef, (snapshot) => {
            setStateFunc(snapshot.data());
        });
    }
    
    const checkIfUsernameExists = async (username) => {
        const usersQuery = query(
            collection(projectFirestore, 'users'),
            where('username', '==', username),
            where(FieldPath.documentId(), '!=', currentUser.uid)
        );
        
        const snapshot = await getDocs(usersQuery);
        return !snapshot.empty;
    };

    const checkIfUsernameAcceptable = (username) => {
        let result = /^[a-z0-9_]{4,18}$/.test(username);
        return result;
    }

    function generateRandomUnverifiedUsername(length) {
        var result           = '';
        var characters       = 'abcdefghijklmnopqrstuvwxyz0123456789_';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * 
     charactersLength));
       }
       return result;
    }

    const generateRandomVerifiedUsername = async () => {
        let generatedUsername = await generateRandomUnverifiedUsername(15);
        //console.log("Generated: ", generatedUsername);
        let acceptable = await checkIfUsernameAcceptable(generatedUsername);
        //console.log("Acceptable ", acceptable);
        while (!acceptable){
            generatedUsername = await generateRandomUnverifiedUsername(15);
            acceptable = await checkIfUsernameAcceptable(generatedUsername);
        }
        if (acceptable){
            //console.log("Checking...");
            let exists = await checkIfUsernameExists(generatedUsername);
            //console.log("Exists: ", exists);
            if (exists){
                generateRandomVerifiedUsername();
            } else {
                return generatedUsername;
            }
        }
    }

    const updatePublicUserInfo = async (state) => {
        let success = true;
        let errorField = null;
        let errorFields = [];
        let errorMessages = {};
    
        let usernameAcceptable = await checkIfUsernameAcceptable(state.username);
        state.username = state.username.toLowerCase();
        state.displayname = state.displayname.trim();
    
        if (usernameAcceptable) {
            let usernameExists = await checkIfUsernameExists(state.username);
            if (usernameExists) {
                success = false;
                errorField = "username";
                errorFields.push(errorField);
                errorMessages[errorField] = "This username is already in use.";
            }
    
            if (success) {
                const userDocRef = doc(projectFirestore, 'users', currentUser.uid);
                await updateDoc(userDocRef, state);
            }
        } else {
            success = false;
            errorField = "username";
            errorFields.push(errorField);
            errorMessages[errorField] = "The username needs to be between 4 and 18 valid characters (numbers, latin letters and underscore).";
        }
    
        return {
            success: success,
            errorFields: errorFields,
            errorMessages: errorMessages
        };
    };

    const fetchUserActionsFromCurrentUser = async () => {
        let actions = null;
        const userActionsDocRef = doc(projectFirestore, 'users', currentUser.uid, 'private-data', 'actions');
        
        const querySnapshot = await getDoc(userActionsDocRef);
        
        if (!querySnapshot.exists()) {
            actions = {
                'my_decks': {
                    'introduction': false
                }
            };
            await setDoc(userActionsDocRef, actions);
        } else {
            actions = querySnapshot.data();
        }
    
        return actions;
    };

    const setActionToTrueForCurrentUser = async (folder, name) => {
        const userActionsDocRef = doc(projectFirestore, 'users', currentUser.uid, 'private-data', 'actions');
        
        await setDoc(
            userActionsDocRef,
            {
                [`${folder}`]: {
                    [`${name}`]: true
                }
            },
            { merge: true }
        );
    };
    
    const setTopLevelActionToTrueForCurrentUser = async (name) => {
        const userActionsDocRef = doc(projectFirestore, 'users', currentUser.uid, 'private-data', 'actions');
        
        await setDoc(
            userActionsDocRef,
            {
                [`${name}`]: true
            },
            { merge: true }
        );
    };

    const setOnboardingCompletedForUser = async () => {
        if (!currentUser) {
            return false;
        }
    
        const onboardingCompleted = httpsCallable(projectFunctions, 'onboardingCompleted');
        
        await onboardingCompleted();
        
        return true;
    };

    const createUserInDatabase = async (uid, name) => {
        const docRef = doc(projectFirestore, 'users', uid);  // Create a reference to the document
        await setDoc(docRef, {
            'username': null,
            'displayname': '',
            'friends_privacy': 'private',
            'languages_privacy': 'public',
            'points_privacy': 'private',
            'public_profile': false
        });
        
        return true;
    };

    const generateUniqueUsernameApi = async () => {
        const generateUsername = httpsCallable(projectFunctions, 'generateUsername');
        const results = await generateUsername();
        return results.data;
    };
    
    const addUsernameToCurrentUser = async () => {
        const addUsernameToCurrentUser = httpsCallable(projectFunctions, 'addUsernameToCurrentUser');
        return addUsernameToCurrentUser();
    };
    
    const checkUsernameApi = async (username) => {
        const checkUsername = httpsCallable(projectFunctions, 'checkUsername');
        const results = await checkUsername({ username: username });
        return results.data;
    };
    
    const saveProfileSettingsApi = async (state) => {
        const saveProfileSettings = httpsCallable(projectFunctions, 'saveProfileSettings');
        const results = await saveProfileSettings({ state: state });
        return results.data;
    };
    
    const updateAccountNameForCurrentUserApi = async (name) => {
        const updateAccountName = httpsCallable(projectFunctions, 'updateAccountName');
        try {
            await updateAccountName({ name: name });
            return true;
        } catch {
            return false;
        }
    };

const updateAccountNameForCurrentUser = async (name) => {
    if (name.length > 50) {
        return false;
    }
    try {
        const userDocRef = doc(projectFirestore, 'users', currentUser.uid, "private-data", "general");
        await setDoc(userDocRef, { 'name': name }, { merge: true });
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
};

const verifyThatCurrentUserExistsInDatabase = async () => {
    const doCheck = async () => {
        try {
            const userDocRef = doc(projectFirestore, 'users', currentUser.uid);
            const snapshot = await getDoc(userDocRef);
            return snapshot.exists();
        } catch (error) {
            return false;
        }
    }

    let success = await doCheck();
    
    // Multiple tries with increasing timeouts
    if (success) return success;
    await new Promise(resolve => setTimeout(resolve, 2000));

    success = await doCheck();
    if (success) return success;

    await new Promise(resolve => setTimeout(resolve, 2000));

    success = await doCheck();
    if (success) return success;

    await new Promise(resolve => setTimeout(resolve, 3000));

    success = await doCheck();
    if (success) return success;

    await new Promise(resolve => setTimeout(resolve, 5000));

    success = await doCheck();
    if (success) return success;

    await new Promise(resolve => setTimeout(resolve, 10000));

    success = await doCheck();
    if (success) return success;

    await new Promise(resolve => setTimeout(resolve, 5000));

    success = await doCheck();
    if (success) return success;

    return false;
};

    const createUserDatabaseEntries = async () => {
        const createUserDatabaseEntries =  httpsCallable(projectFunctions, 'createUserDatabaseEntries');
        return createUserDatabaseEntries();
    }

    const updateUserDeckSettingsApi = async (settings) => {
        let f = httpsCallable(projectFunctions, 'updateUserDeckSettings');
        return f({'settings': settings});
    }

    const fetchCurrentUserSettings = async () => {
    const docRef = doc(projectFirestore, "users", currentUser.uid, "private-data", "settings");
    const snap = await getDoc(docRef);

    if (snap.exists()) {
        return snap.data();
    } else {
        return null;
    }
}

    const updateLibraryViewSetting = async ({ view }) => {
        if (view === "grid" || view === "tree") {
            const docRef = doc(projectFirestore, "users", currentUser.uid, "private-data", "settings");
            await setDoc(docRef, {
                'library_view': view
            }, { merge: true });
        }
    }

    const updateUserSettings = async ({ settings }) => {
        let newSettings = {};
        if (settings.hasOwnProperty("autoplayGameAudio")) {
            newSettings['autoplayGameAudio'] = settings['autoplayGameAudio'];
        }
    
        if (Object.keys(newSettings).length > 0) {
            const docRef = doc(projectFirestore, "users", currentUser.uid, "private-data", "settings");
            await updateDoc(docRef, newSettings);
        }
    }

    const getUserSettings = async () => {
        const docRef = doc(projectFirestore, "users", currentUser.uid, "private-data", "settings");
        let data = await getDoc(docRef).then((snap) => {
            if (snap.exists()) {
                let d = snap.data();
                d['doc_id'] = snap.id;
                return d;
            } else {
                return false;
            }
        }).catch(err => {
            return false;
        });
        return data;
    }

    const readLibraryViewSetting = async () => {
        const docRef = doc(projectFirestore, "users", currentUser.uid, "private-data", "settings");
        let data = await getDoc(docRef).then((snap) => {
            if (snap.exists()) {
                return snap.data();
            } else {
                return null;
            }
        }).catch(err => {
            return null;
        });
    
        let view = data && data.hasOwnProperty("library_view") ? data['library_view'] : false;
        return view;
    }

    return {
        addUidsToAllDecks, 
        fetchCurrentUserSettings,
        updateUserDeckSettingsApi,
        setActionToTrueForCurrentUser,
        fetchPublicUserInfo, 
        updatePublicUserInfo, 
        generateRandomVerifiedUsername, 
        fetchPublicUserInfoFromUid, 
        fetchRealtimePublicUserInfo, 
        fetchPublicUserInfoFromUsername, 
        fetchCurrentUserInfo,
        fetchUserActionsFromCurrentUser, 
        createUserInDatabase, 
        generateUniqueUsernameApi, 
        checkUsernameApi, 
        checkIfUsernameAcceptable, 
        saveProfileSettingsApi, 
        updateAccountNameForCurrentUserApi, 
        updateAccountNameForCurrentUser, 
        setTopLevelActionToTrueForCurrentUser, 
        setOnboardingCompletedForUser,
        verifyThatCurrentUserExistsInDatabase, 
        createUserDatabaseEntries, 
        addUsernameToCurrentUser, 
        getAccountInformationFromCurrentUser, 
        updateLibraryViewSetting, 
        readLibraryViewSetting,
        updateUserSettings,
        getUserSettings
    };

}