import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import HelmetData from '../../components/helmet/HelmetData';
import SRCard from '../../components/dashboard/SRCard';
import GreetingMessage from '../../components/dashboard/GreetingMessage';
import MyStarsCounter from '../../components/stars/MyStarsCounter';
import QuickNote from '../../components/dashboard/QuickNote';
import {useLanguagesContext} from '../../contexts/LanguagesContext';
import NoActiveTargetLanguage from '../../components/errors/NoActiveTargetLanguage';
import MistakesCard from '../../components/dashboard/MistakesCard';
import MiniGamesCard from '../../components/dashboard/MiniGamesCard';
import PremiumCard from '../../components/dashboard/PremiumCard';
import SpecificLanguageTools from '../../components/language-tools/SpecificLanguageTools';
import YalangoContentForLanguage from '../../components/dashboard/YalangoContentForLanguage';
import { useUserStatisticsContext } from '../../contexts/UserStatisticsContext';
import ReactTooltip from 'react-tooltip';
import AccountMenu from '../../components/menus/AccountMenu';
import GoalsCard from '../../components/goals/GoalsCard';
import VocabularyHighlightCard from '../../components/vocabulary/cards/VocabularyHighlightCard';
import LifetimeAnalyticsCard from '../../components/analytics/LifetimeAnalyticsCard';
import AnalyticsCard from '../../components/analytics/AnalyticsCard';

const CleanDashboard = () => {
  let {allLanguages, activeUserLanguages, globalSelectedTargetLanguage, setGlobalSelectedTargetLanguage, globalSelectedSourceLanguage, setGlobalSelectedSourceLanguage, activeTargetLanguageOptions} = useLanguagesContext();
  const {vocabularyMetadata} = useUserStatisticsContext();
  // <QuickNote />
  /*
            <div className="text-sm text-center">
              Please note that Yalango is being actively developed. If you encounter any issues or have any feature requests, <Link to={"/my-feedback"}>please let us know</Link>. Thank you for your support!
            </div>
  */
  useEffect(()=>{
    ReactTooltip.rebuild();
  },[globalSelectedTargetLanguage, vocabularyMetadata]);
  //<MyStarsCounter />
  const cardClass = "p-8  border-solid border-gray-200 border rounded-lg bg-white shadow-lg dark:dark-mode-background-color";

  /*
              <div className={cardClass}>
                <div className="flex flex-col gap-4">
                  <div className="text-lg font-semibold text-center">Practice my vocabulary</div>
                <div className="flex flex-col place-items-center xl:place-items-start xl:flex-row justify-center gap-3">
                  <div className="w-64 h-64">
                    {vocabularyMetadata !== null && vocabularyMetadata.hasOwnProperty(globalSelectedTargetLanguage) && vocabularyMetadata[globalSelectedTargetLanguage].hasOwnProperty("number_of_items") && vocabularyMetadata[globalSelectedTargetLanguage]["number_of_items"] > 0 ?
                      <Link data-for="tooltip-dashboard" data-tip="Review the next items in your spaced repetition queue" className={"no-underline"} to={`/my-vocabulary/${globalSelectedTargetLanguage}/mix?level=spaced-repetition&items=20&mode=mix&back=dashboard`}>
                        <SRCard />
                      </Link>
                      :
                      <>
                        <div data-for="tooltip-dashboard" data-tip="Spaced repetition will be activated after you start a course or add your own decks to your library" className="grayscale h-full w-full">
                          <SRCard />
                        </div>
                      </>
                    }
                  </div>
                  <div className="w-64 h-64">
                  {vocabularyMetadata !== null && vocabularyMetadata.hasOwnProperty(globalSelectedTargetLanguage) && vocabularyMetadata[globalSelectedTargetLanguage].hasOwnProperty("number_of_items") && vocabularyMetadata[globalSelectedTargetLanguage]["number_of_items"] > 0 ?
                      <Link data-for="tooltip-dashboard" data-tip="Review your most recent mistakes" className="no-underline" to={`/my-vocabulary/${globalSelectedTargetLanguage}/review-mistakes?back=dashboard`}>
                        <MistakesCard />
                      </Link>
                      :
                      <>
                        <div data-for="tooltip-dashboard" data-tip="You can review your mistakes after you have started reviewing on Yalango" className="grayscale h-full w-full">
                          <MistakesCard />
                        </div>
                      </>
                    }
                    
                  </div>
                  <div className="w-64 h-64">
                  {vocabularyMetadata !== null && vocabularyMetadata.hasOwnProperty(globalSelectedTargetLanguage) && vocabularyMetadata[globalSelectedTargetLanguage].hasOwnProperty("number_of_items") && vocabularyMetadata[globalSelectedTargetLanguage]["number_of_items"] > 0 ?
                      <Link data-for="tooltip-dashboard" data-tip="Review your content with minigames here"  className="no-underline" to={`/my-vocabulary/play?back=dashboard`}>
                        <MiniGamesCard />
                      </Link>
                      :
                      <>
                        <div data-for="tooltip-dashboard" data-tip="After you have started studying on Yalango, you can play minigames with your content here" className="grayscale h-full w-full">
                        <MiniGamesCard />
                        </div>
                      </>
                    }
                    
                    
                  </div>
                </div>
                </div>
              </div>
  */
  return (
    <>
        <ReactTooltip place="bottom" id="tooltip-dashboard" effect="solid" />
        <HelmetData title={"My Dashboard"} description={"View your personal dashboard on Yalango."} />
        {activeUserLanguages !== null && activeUserLanguages.length === 0 ?
          <>
              <NoActiveTargetLanguage />
          </>
          :
          <>
          <div className="flex flex-col gap-16">
            <div classsName="">
              <div className="flex flex-row place-items-center  w-full justify-between">
                  <div className="">
                      <PremiumCard />
                  </div>
                  <div className="flex justify-center flex-grow">
                      <GreetingMessage />
                  </div>
                  <div className="absolute right-2 lg:right-12 lg:top-4 top-2">
                      <AccountMenu />
                  </div>
              </div>
              <div className="text-center mt-3 text-base font-semibold text-gray-600 dark:dark-mode-muted-text">
                Welcome to your {allLanguages !== null && globalSelectedTargetLanguage !== null && allLanguages[0][globalSelectedTargetLanguage]["name"]["en"]} dashboard!
              </div>  
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 3xl:grid-cols-3 gap-6 w-full mx-auto">
              {globalSelectedTargetLanguage !== null &&
                <div className={`${cardClass} flex flex-col gap-4 `}>
                  <div className="">
                    <MyStarsCounter />
                  </div>
                  <GoalsCard language={"all"} />
                  </div>
              }

              <div className={`${cardClass} flex flex-col lg:flex-row gap-4 `}>
                <VocabularyHighlightCard filter="best" howManyItems={5} />
                <VocabularyHighlightCard filter="worst" howManyItems={5} />
              </div>
              <div className={cardClass}>
                <LifetimeAnalyticsCard language={globalSelectedTargetLanguage} />
              </div>

              <div className={cardClass}>
                <QuickNote />
              </div>
            </div>
                
          </div>
          </>
        }
    </>
  )
}

export default CleanDashboard;