import React from 'react';
import {projectFirestore, projectFunctions, timeStamp, firebase} from '../firebase/config.js';
import {  httpsCallable } from "firebase/functions";
const usePinyin = () => {

    const fetchPinyin = async (text) => {
        const getPinyin = httpsCallable(projectFunctions, 'getPinyin'); // get your callable function
        try {
            const response = await getPinyin({ text });
            const data = response.data.pinyin;  // Assuming the response structure includes a pinyin array
            return data;
        } catch (err) {
            console.error('Error calling getPinyin:', err);
            return false;
        }
    };

    return { fetchPinyin };
};

export default usePinyin;