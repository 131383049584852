import { projectFirestore, projectFunctions, timeStamp} from '../firebase/config.js';
import { useAuth } from "../contexts/AuthContext";
import {useLanguagesContext} from '../contexts/LanguagesContext';
import useLogs from './useLogs';
import {
    getFirestore,
    collection,
    query,
    where,
    setDoc,
    getDoc,
    getDocs,
    doc,
    deleteDoc,
    addDoc,
    updateDoc,
    onSnapshot,
    serverTimestamp,
    orderBy,
    limit,
    startAfter,
    endBefore,
    limitToLast, 
    Timestamp
} from "firebase/firestore";
import {  httpsCallable } from "firebase/functions";

export default function useGoals() {
    let {currentUser} = useAuth();
    const {saveErrorLog} = useLogs();
    //projectFunctions.useEmulator("localhost", 5001);
    const {activeUserLanguages} = useLanguagesContext();
    //const {activateDailyGoalsNeedRefresh, deactivateDailyGoalsNeedRefresh} = useUserStatisticsContext();

    const saveGoalsApi = async (itemsPerDay) => {
        let f = httpsCallable(projectFunctions, 'saveDailyGoals');
        let clientDate = new Date();
        clientDate = clientDate.getDate() + '.' + (clientDate.getMonth() + 1) + '.' + clientDate.getFullYear();
        let r = await f({'itemsPerDay': itemsPerDay, 'clientDate': clientDate}).catch(err=>{
            console.log("Error: ", err);
        });
        //activateDailyGoalsNeedRefresh();
        return r;
    }

    const saveDailyGoalsInTargetLanguageApi = async (itemsPerDay, targetLanguage) => {
        console.log(itemsPerDay, targetLanguage)
        let f = httpsCallable(projectFunctions, 'saveDailyGoalsInTargetLanguage');
        let clientDate = new Date();
        clientDate = clientDate.getDate() + '.' + (clientDate.getMonth() + 1) + '.' + clientDate.getFullYear();
        let success = await f({'itemsPerDay': itemsPerDay, 'target_language': targetLanguage, 'clientDate': clientDate})
        .then(()=>{
            return true;
        })
        .catch(err=>{
            saveErrorLog({errorObject: err, functionName: "saveDailyGoalsInTargetLanguageApi"});
            return false;
        });
        //activateDailyGoalsNeedRefresh();
        return success;
    }

    const refreshCurrentStreakAPI = async (lang) => {
        let f = httpsCallable(projectFunctions, 'refreshCurrentStreak');
        let r = await f({'lang':lang}).catch((err)=>{
            saveErrorLog({errorObject: err, functionName: "refreshCurrentStreakAPI"});
        });
        return r;
    }

    const getCurrentUserGoals = async () => {
        const goals = {};
        if (activeUserLanguages !== null) {
            for (const lang of activeUserLanguages) {
                try {
                    const docRef = doc(
                        projectFirestore,
                        "users",
                        currentUser.uid,
                        "private-data",
                        "goals",
                        "daily",
                        lang.target_language
                    );
                    const docSnapshot = await getDoc(docRef);
                    if (docSnapshot.exists()) {
                        goals[lang.target_language] = docSnapshot.data();
                    }
                } catch (err) {
                    saveErrorLog({ errorObject: err, functionName: "getCurrentUserGoals" });
                }
            }
        }
        return goals;
    };
    
    const getCurrentUserGoalsInTargetLanguage = async (lang) => {
        let goals = false;
        try {
            const docRef = doc(
                projectFirestore,
                "users",
                currentUser.uid,
                "private-data",
                "goals",
                "daily",
                lang
            );
            const docSnapshot = await getDoc(docRef);
            if (docSnapshot.exists()) {
                goals = docSnapshot.data();
            }
        } catch (err) {
            saveErrorLog({ errorObject: err, functionName: "getCurrentUserGoalsInTargetLanguage" });
        }
        return goals;
    };

    const getCurrentUserGoalsInRealtime = async (setGoals) => {
        if (!currentUser) return null;
    
        const unsubscribers = [];
        const goals = {};
    
        if (activeUserLanguages !== null) {
            for (const lang of activeUserLanguages) {
                const docRef = doc(
                    projectFirestore,
                    "users",
                    currentUser.uid,
                    "private-data",
                    "goals",
                    "daily",
                    lang.target_language
                );
                const unsub = onSnapshot(docRef, (docSnapshot) => {
                    if (docSnapshot.exists()) {
                        goals[lang.target_language] = docSnapshot.data();
                    }
                });
                unsubscribers.push(unsub);
            }
        }
        setGoals(goals);
        return unsubscribers;
    };
    
    const getCurrentUserDailyStreak = async () => {
        if (!currentUser || !activeUserLanguages) return null;
    
        const streaks = {};
    
        for (const lang of activeUserLanguages) {
            try {
                const docRef = doc(
                    projectFirestore,
                    "users",
                    currentUser.uid,
                    "private-data",
                    "statistics",
                    "processed_data",
                    "languages",
                    lang.target_language,
                    "daily"
                );
                const docSnapshot = await getDoc(docRef);
                if (docSnapshot.exists()) {
                    streaks[lang.target_language] = docSnapshot.data();
                } else {
                    streaks[lang.target_language] = {};
                }
            } catch (err) {
                saveErrorLog({ errorObject: err, functionName: "getCurrentUserDailyStreak" });
            }
        }
    
        return streaks;
    };

    const getCurrentUserDailyStreakInTargetLanguage = async (lang) => {
        if (!currentUser || !activeUserLanguages) return null;
    
        try {
            const docRef = doc(
                projectFirestore,
                "users",
                currentUser.uid,
                "private-data",
                "statistics",
                "processed_data",
                "languages",
                lang,
                "daily"
            );
            const docSnapshot = await getDoc(docRef);
            if (docSnapshot.exists()) {
                return docSnapshot.data();
            }
        } catch (err) {
            saveErrorLog({
                errorObject: err,
                functionName: "getCurrentUserDailyStreakInTargetLanguage",
            });
        }
    
        return null;
    };

    const validateStreak = async (dailyStreak) => {
        let streakOK = {};
        let streak = {};
        if (dailyStreak === undefined){return null}
        for (const lang of Object.keys(dailyStreak)){
            streakOK[lang] = true;
            streak[lang] = {}
            if (dailyStreak[lang] !== null && 'current_streak_dates' in dailyStreak[lang]){
                let dates = dailyStreak[lang]['current_streak_dates']; 
                if (dates !== null && dates.length > 0){
                    let index = 0;
                    let lastDay = null;
                    let thisDay = null;
                    let foundFail = false;
                    for (const date of dates){
                        let comps = date.split(".");
                        let date_string = comps[1] + '/' + comps[0] + '/' + comps[2];
                        thisDay = new Date(date_string);
                        if (index > 0){
                            let lastcomps = dates[index-1].split(".");
                            let last_date_string = lastcomps[1] + '/' + lastcomps[0] + '/' + lastcomps[2];
                            lastDay = new Date(last_date_string);
                            if ((Date.parse(thisDay) - Date.parse(lastDay)) >= 2*86400000){
                                foundFail = true;
                            }
                        }
                        index = index + 1;
                    }
                    if (foundFail){
                        streakOK[lang] = false;
                        let index = 0; //last element since reverse
                        let newDates = [];
                        let finished = false;
                        let reversedDates = [...dates].reverse();
                        for (const date of reversedDates){
                            let comps = date.split(".");
                            let date_string = comps[1] + '/' + comps[0] + '/' + comps[2];
                            thisDay = new Date(date_string);
                            if (index > 0){
                                if (!finished){
                                    let lastcomps = reversedDates[index-1].split(".");
                                    let last_date_string = lastcomps[1] + '/' + lastcomps[0] + '/' + lastcomps[2];
                                    lastDay = new Date(last_date_string);
                                    if ((Date.parse(lastDay) - Date.parse(thisDay)) >= 2*86400000){
                                        finished = true;
                                        newDates = newDates.reverse();
                                        streak[lang]['current_streak_dates'] = newDates;
                                        streak[lang]['current_streak'] = newDates.length;
                                    } else {
                                        newDates.push(date);
                                    }
                                }
                            } else {
                                let todayDate = new Date();
                                let comps = date.split(".");
                                let date_string = comps[1] + '/' + comps[0] + '/' + comps[2];
                                let lastDate = new Date(date_string);
                                if (Date.parse(todayDate) - Date.parse(lastDate) <= 86400000){
                                    newDates.push(date);
                                }
                            }
                            index = index + 1;
                            }
                    } else {
                        streakOK[lang] = true;
                        streak[lang]['current_streak_dates'] = dailyStreak[lang]['current_streak_dates'];
                        streak[lang]['current_streak'] = dailyStreak[lang]['current_streak'];
                    }

                    // check compared to today's date
                    lastDay = dates[dates.length - 1];
                    let comps = lastDay.split(".");
                    let date_string = comps[1] + '/' + comps[0] + '/' + comps[2];
                    let lastDate = new Date(date_string);
                    let todayDate = new Date();
                    if ((Date.parse(todayDate) - Date.parse(lastDate)) >= 2*86400000){
                        streakOK[lang] = false;
                        streak[lang]['current_streak_dates'] = [];
                        streak[lang]['current_streak'] = 0;
                    }
                } else {
                    streakOK[lang] = false;
                }
            } else {
                streak[lang]['current_streak'] = 0;
            }
        }
        return {streakOK, streak};
    }

    const getCurrentUserDailyStreakInRealtime = async (setStreak) => {
        if (!currentUser || !activeUserLanguages) return null;
    
        let data = {};
        let unsubscribers = [];
    
        for (const lang of activeUserLanguages) {
            const docRef = doc(
                projectFirestore,
                "users",
                currentUser.uid,
                "private-data",
                "statistics",
                "processed_data",
                "languages",
                lang.target_language,
                "daily"
            );
    
            const unsubscribe = onSnapshot(docRef, (snapshot) => {
                if (snapshot.exists()) {
                    data[lang.target_language] = snapshot.data();
                }
            });
    
            unsubscribers.push(unsubscribe);
        }
    
        setStreak(data);
        return unsubscribers;
    };

    return {
        saveGoalsApi, 
        getCurrentUserGoals, 
        getCurrentUserGoalsInRealtime, 
        getCurrentUserDailyStreak, 
        getCurrentUserDailyStreakInRealtime, 
        validateStreak, 
        refreshCurrentStreakAPI, 
        getCurrentUserDailyStreakInTargetLanguage, 
        getCurrentUserGoalsInTargetLanguage,
        saveDailyGoalsInTargetLanguageApi
    }
}