import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { useAuth } from "../../contexts/AuthContext";
import Button from '../../components/general/Button';
import {Helmet} from 'react-helmet';

const MainSettings = () => {
    let {logout} = useAuth();
    const navigate = useNavigate();
    const logOutClick = async () => {
        try {
            await logout();
            //console.log("success");
            navigate("/login");
          } catch {
            //console.log("Failed to log out");
          };
    }
    return (
        <>
            <Helmet>
                <title>Settings - Yalango</title>
                <meta name="description" content={"Change your user settings on Yalango."} />
            </Helmet>
            <div className=" ">
                <h1>Settings</h1>
                <div className="grid">
                    <div>
                        <Link to="/my-languages">My languages</Link> 
                    </div>
                    <div>
                        <Link to="/settings/profile">Profile settings</Link> 
                    </div>
                    <div>
                        <Link to="/premium">Yalango Premium</Link> 
                    </div>
                    <div>
                        <Link to="/settings/account">Account settings</Link> 
                    </div>
                </div>
                <div className="text-center flex flex-col place-items-center">
                    <a href="https://yalango.com/logout"  className=" underline cursor-pointer">
                        <Button text="Log out" color="bg-gray-600" />
                    </a>
                </div>
            </div>
        </>
    )
}

export default MainSettings;
