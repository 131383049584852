import { useState } from 'react'
import { useNavigate } from 'react-router-dom';

export default function ProgressMenu(props) {
  const {tabValue} = props;
  const [activeTab, setActiveTab] = useState(tabValue);
    console.log(activeTab)
  const [tabs, setTabs] = useState([{label:'Spaced repetition', value: 'spaced-repetition', url: '/my-vocabulary/spaced-repetition'}, {label:'My vocabulary', value: 'my-vocabulary', url: '/my-vocabulary'}]);
    const navigate = useNavigate();
  return (
<div className="w-full max-w-3xl mx-auto p-6">
  <div className="mb-4">
    <div className="flex border-b border-gray-200 dark:border-gray-600">
      {tabs.map((tab) => (
        <button
          key={tab.value}
          onClick={() => navigate(tab.url)}
          className={`py-2 px-4 text-center w-full capitalize transition-colors duration-300 ${
            activeTab === tab.value
              ? 'border-b-2 border-charcoal text-charcoal dark:text-white dark:border-white font-medium'
              : 'text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300'
          }`}
        >
          {tab.label}
        </button>
      ))}
    </div>
  </div>
</div>

  )
}
