import { projectFirestore, timeStamp} from '../firebase/config.js';
import { useAuth } from "../contexts/AuthContext";
import {useLanguagesContext} from '../contexts/LanguagesContext';

import {
    getFirestore,
    collection,
    query,
    where,
    setDoc,
    getDoc,
    getDocs,
    doc,
    deleteDoc,
    addDoc,
    updateDoc,
    onSnapshot,
    serverTimestamp,
    orderBy,
    limit,
    startAfter,
    endBefore,
    limitToLast, 
    Timestamp
} from "firebase/firestore";
import {  httpsCallable } from "firebase/functions";

export default function useStatistics() {
    let {currentUser} = useAuth();
    const {activeUserLanguages} = useLanguagesContext();

    const addFlashcardStatForCurrentUser = async ({ deck_uid, doc_id, deck_id, correct, data_type, view_mode, target_word, source_word, target_iso, source_iso }) => {
        if (target_iso === "" || target_iso === null || source_iso === "" || source_iso === null || (correct !== false && correct !== true)) {
            return false;
        }
        if (currentUser === null) { return null; }
    
        const statsRef = collection(doc(projectFirestore, 'users', currentUser.uid, 'private-data', 'statistics', 'historic_data', 'languages', target_iso, 'flashcards', 'items'));
        let today = new Date();
        let obj = {
            'correct': correct,
            'target_word': target_word,
            'source_word': source_word,
            'doc_id': doc_id,
            'deck_id': deck_id,
            'deck_uid': deck_uid,
            'view_mode': view_mode,
            'target_ISO_639-1': target_iso,
            'source_ISO_639-1': source_iso,
            'data_type': data_type,
            'timestamp': timeStamp,
            'UTCTimezoneOffsetInMinutes': today.getTimezoneOffset(),
            'date': today.getDate() + '.' + (today.getMonth() + 1) + '.' + today.getFullYear()
        };
        console.log(obj);
        await addDoc(statsRef, obj).catch(err => console.log(err));
    }
    
    const addReviewStatForCurrentUser = async ({ deck_uid, doc_id, deck_id, game, correct, data_type, view_mode, target_word, source_word, target_iso, source_iso }) => {
        console.log("function: ", target_iso, source_iso, correct);
        if (target_iso === "" || target_iso === null || source_iso === "" || source_iso === null || (correct !== false && correct !== true)) {
            return false;
        }
        if (currentUser === null) { return null; }
    
        const statsRef = collection(doc(projectFirestore, 'users', currentUser.uid, 'private-data', 'vocabulary', 'languages', target_iso, 'mistakes', 'reviews', 'items'));
        let today = new Date();
        let obj = {
            'correct': correct,
            'target_word': target_word,
            'source_word': source_word,
            'source_doc_id': doc_id,
            'deck_id': deck_id,
            'deck_uid': deck_uid,
            'view_mode': view_mode,
            'target_ISO_639-1': target_iso,
            'source_ISO_639-1': source_iso,
            'data_type': data_type,
            'timestamp': timeStamp,
            'UTCTimezoneOffsetInMinutes': today.getTimezoneOffset(),
            'date': today.getDate() + '.' + (today.getMonth() + 1) + '.' + today.getFullYear(),
            'game': game
        };
        console.log("adding ", obj);
        await addDoc(statsRef, obj).catch(err => console.log("Error: ", err));
    }
    
    const getFlashcardStatisticsForCurrentUserInTargetLanguage = async (target_language) => {
        if (currentUser === null) { return null; }
    
        const statsRef = doc(projectFirestore, 'users', currentUser.uid, 'private-data', 'statistics', 'historic_data', 'languages', target_language, 'flashcards');
        try {
            const data = await getDoc(statsRef);
            if (!data.exists()) {
                return null;
            }
            return data.data();
        } catch {
            return null;
        }
    }
    
    const getQuizStatisticsForCurrentUserInTargetLanguage = async (target_language) => {
        if (currentUser === null) { return null; }
    
        const statsRef = doc(projectFirestore, 'users', currentUser.uid, 'private-data', 'statistics', 'historic_data', 'languages', target_language, 'quiz');
        try {
            const data = await getDoc(statsRef);
            if (!data.exists()) {
                return null;
            }
            return data.data();
        } catch {
            return null;
        }
    }
    
    const addQuizStatForCurrentUser = async (obj) => {
        console.log(obj);
        if (currentUser !== null) {
            let today = new Date();
            obj['UTCTimezoneOffsetInMinutes'] = today.getTimezoneOffset();
            obj['date'] = today.getDate() + '.' + (today.getMonth() + 1) + '.' + today.getFullYear();
            const statsRef = collection(doc(projectFirestore, 'users', currentUser.uid, 'private-data', 'statistics', 'historic_data', 'languages', obj['target_ISO_639-1'], 'quiz', 'items'));
            await addDoc(statsRef, obj);
        }
    }
    
    const addInputStatForCurrentUser = async (obj) => {
        console.log(obj);
        if (currentUser !== null) {
            obj['timestamp'] = timeStamp;
            let today = new Date();
            obj['UTCTimezoneOffsetInMinutes'] = today.getTimezoneOffset();
            obj['date'] = today.getDate() + '.' + (today.getMonth() + 1) + '.' + today.getFullYear();
            const statsRef = collection(doc(projectFirestore, 'users', currentUser.uid, 'private-data', 'statistics', 'historic_data', 'languages', obj['target_ISO_639-1'], 'input', 'items'));
            await addDoc(statsRef, obj);
        }
    }
    
    const getGlobalDeckGameStatsInRealtime = async (setStats) => {
        const statsRef = doc(projectFirestore, 'statistics', 'public');
        return onSnapshot(statsRef, (snap) => {
            if (!snap.exists()) {
                return null;
            }
            setStats(snap.data());
        });
    }

    const getInputStatisticsForCurrentUserInTargetLanguage = async (target_language) => {
        if (currentUser === null) { return null; }
    
        const statsRef = doc(projectFirestore, 'users', currentUser.uid, 'private-data', 'statistics', 'historic_data', 'languages', target_language, 'input');
        try {
            const data = await getDoc(statsRef);
            if (!data.exists()) {
                return null;
            }
            return data.data();
        } catch {
            return null;
        }
    }
    
    const getRealtimeDailyStatsInTargetLanguage = (dailyStats, setDailyStats, isoCode) => {
        if (currentUser === null) { return null; }
        
        let today = new Date();
        let docName = `${today.getDate()}.${today.getMonth() + 1}.${today.getFullYear()}`;
        const pathRef = doc(projectFirestore, "users", currentUser.uid, "private-data", "statistics", "processed_data", "languages", isoCode, "daily", "items", docName);
        
        const unsub = onSnapshot(pathRef, (querySnapshot) => {
            if (!querySnapshot.empty) {
                let updatedStats = JSON.parse(JSON.stringify(dailyStats)) || {};
                updatedStats[isoCode] = querySnapshot.data();
                setDailyStats(updatedStats);
            }
        });
        
        return unsub;
    }
    
    const getRealtimeDailyStatsInActiveTargetLanguages = async (dailyStats, setDailyStats) => {
        if (currentUser === null || activeUserLanguages === null) { return null; }
    
        let today = new Date();
        let docName = `${today.getDate()}.${today.getMonth() + 1}.${today.getFullYear()}`;
        let data = {};
        let unsubscribers = [];
    
        for (const language of activeUserLanguages) {
            const pathRef = doc(projectFirestore, "users", currentUser.uid, "private-data", "statistics", "processed_data", "languages", language.target_language, "daily", "items", docName);
            const unsubscribe = onSnapshot(pathRef, (querySnapshot) => {
                if (querySnapshot.exists()) {
                    data[language.target_language] = querySnapshot.data();
                }
            });
            unsubscribers.push(unsubscribe);
        }
    
        setDailyStats(data);
        return unsubscribers;
    }
    
    const getDailyStatsInActiveTargetLanguages = async () => {
        if (currentUser === null || activeUserLanguages === null) { return null; }
    
        let today = new Date();
        let docName = `${today.getDate()}.${today.getMonth() + 1}.${today.getFullYear()}`;
        let data = {};
    
        for (const language of activeUserLanguages) {
            const pathRef = doc(projectFirestore, "users", currentUser.uid, "private-data", "statistics", "processed_data", "languages", language.target_language, "daily", "items", docName);
            const querySnapshot = await getDoc(pathRef);
            if (querySnapshot.exists()) {
                data[language.target_language] = querySnapshot.data();
            }
        }
    
        return data;
    }
    
    const getDailyStatsInTargetLanguage = async (targetLanguage) => {
        if (currentUser === null || targetLanguage === null) { return null; }
    
        let today = new Date();
        let docName = `${today.getDate()}.${today.getMonth() + 1}.${today.getFullYear()}`;
        let data = false;
    
        const pathRef = doc(projectFirestore, "users", currentUser.uid, "private-data", "statistics", "processed_data", "languages", targetLanguage, "daily", "items", docName);
        const querySnapshot = await getDoc(pathRef);
    
        if (querySnapshot.exists()) {
            data = querySnapshot.data();
        }
    
        return data;
    }

    const getXLastProcessedDataInTargetLanguage = async ({ numberOfItems, targetLanguage, dailyGoals, backfillMissingDays }) => {
        // Define collection reference with necessary order and limit
        const pathRef = collection(projectFirestore, "users", currentUser.uid, "private-data", "statistics", "processed_data", "languages", targetLanguage, "daily", "items");
        const q = query(pathRef, orderBy("last_updated", "desc"), limit(numberOfItems));
        
        // Fetch the documents
        const snapshot = await getDocs(q);
        
        // Process the documents into an array
        let days = [];
        snapshot.forEach(doc => {
            let d = doc.data();
            d['doc_id'] = doc.id;
            if (!('total_wrong_items' in d)) {
                d['total_wrong_items'] = 0;
            }
            days.push(d);
        });
        
        days = days.reverse();
        console.log("Days: ", days);
    
        let filteredDays = [];
        days.forEach((day, index) => {
            let comps = day.doc_id.split(".");
            let date_string = `${comps[1]}/${comps[0]}/${comps[2]}`;
            
            if (index > 0) {
                let dateObject = new Date(date_string);
                let previousDocId = days[index - 1].doc_id;
                let prevComps = previousDocId.split(".");
                let date_prev_string = `${prevComps[1]}/${prevComps[0]}/${prevComps[2]}`;
                let prevDateObject = new Date(date_prev_string);
    
                if (Date.parse(dateObject) - Date.parse(prevDateObject) > 86400000) {
                    let nbOfDays = (Date.parse(dateObject) - Date.parse(prevDateObject)) / 86400000;
                    
                    for (let i = 0; i < nbOfDays - 1; i++) {
                        let date = new Date();
                        date.setDate(prevDateObject.getDate() + (i + 1));
                        let date_s = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
                        let o = {
                            'doc_id': date_s,
                            'total_correct_items': 0,
                            'total_wrong_items': 0,
                            'total_correct_items_per_day_goal_today': filteredDays[index - 1]['total_correct_items_per_day_goal_today']
                        };
                        filteredDays.push(o);
                    }
                }
                filteredDays.push(day);
            } else {
                filteredDays.push(day);
            }
        });
    
        // Backfill missing days up to the current day
        if (backfillMissingDays === true) {
            let today = new Date();
            
            if (filteredDays.length > 0) {
                let lastDocId = filteredDays[filteredDays.length - 1].doc_id;
                let comps = lastDocId.split(".");
                let date_last_string = `${comps[1]}/${comps[0]}/${comps[2]}`;
                let lastDateObject = new Date(date_last_string);
                let nbOfDays = (Date.parse(today) - Date.parse(lastDateObject)) / 86400000;
    
                for (let i = 0; i < nbOfDays - 1; i++) {
                    let date = new Date();
                    date.setDate(lastDateObject.getDate() + (i + 1));
                    let date_s = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
                    let o = {
                        'doc_id': date_s,
                        'total_correct_items': 0,
                        'total_wrong_items': 0,
                        'total_correct_items_per_day_goal_today': filteredDays[filteredDays.length - 1]['total_correct_items_per_day_goal_today']
                    };
                    filteredDays.push(o);
                }
            } else {
                for (let i = 0; i < numberOfItems - 1; i++) {
                    let date = new Date();
                    date.setDate(date.getDate() - i);
                    let goalToday = 0;
                    if (dailyGoals !== null && dailyGoals !== false) {
                        if (dailyGoals.hasOwnProperty('total_correct_items_per_day')) {
                            const todayNumber = date.getDay() === 0 ? 6 : date.getDay() - 1;
                            goalToday = dailyGoals['total_correct_items_per_day'][todayNumber];
                        }
                    }
                    let date_s = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
                    let o = {
                        'doc_id': date_s,
                        'total_correct_items': 0,
                        'total_wrong_items': 0,
                        'total_correct_items_per_day_goal_today': goalToday
                    };
                    filteredDays.push(o);
                }
                filteredDays = filteredDays.reverse();
            }
            
            // Slice the array to return the required number of items
            filteredDays = filteredDays.slice(-numberOfItems);
        }
    
        return filteredDays;
    }
    const getDatesInMonth = (month, year) => {
        var date = new Date(year, month, 1);
        var days = [];
        while (date.getMonth() === month) {
          days.push(new Date(date));
          date.setDate(date.getDate() + 1);
        }
        return days;
    }

    function getDatesLastXDays (x) {
        var result = [];
        for (var i=0; i<x; i++) {
            var d = new Date();
            d.setDate(d.getDate() - i);
            result.push(d);
        }
    
        return result.reverse();
    }

    const getProcessedStatisticsDataLastXDays = async ({ days, targetLanguage }) => {
        // Get dates for the last X days
        let dates = getDatesLastXDays(days);
        console.log("Dates: ", dates);
        let docNames = dates.map(date => {
            let monthString = (date.getMonth() + 1).toString();
            return `${date.getDate()}.${monthString}.${date.getFullYear()}`;
        });
        console.log("Doc names: ", docNames);
        
        let stats = [];
        let dataRows = await getXLastProcessedDataInTargetLanguage({ numberOfItems: days, targetLanguage: targetLanguage });
        console.log(dataRows);
    
        // Iterate over the doc names and match them with the fetched data
        for (const docName of docNames) {
            let found = false;
            for (const dataRow of dataRows) {
                if (dataRow.doc_id === docName) {
                    console.log("Found matching doc:", dataRow);
                    let d = { ...dataRow, exists_in_db: true };
                    stats.push(d);
                    found = true;
                    break;
                }
            }
            // If no match found, return a default object for that day
            if (!found) {
                stats.push({
                    doc_id: docName,
                    exists_in_db: false,
                    total_correct_items: 0,
                    total_wrong_items: 0,
                });
            }
        }
        return stats;
    };
    
    // Function to get processed data for a specific month and year
    const getProcessedDataInTargetLanguageInMonth = async (month, year, targetLanguage) => {
        let dates = getDatesInMonth(month, year);
        console.log("Dates: ", dates);
        let today = new Date();
        let docNames = dates.filter(date => date <= today).map(date => {
            let monthString = (date.getMonth() + 1).toString();
            return `${date.getDate()}.${monthString}.${date.getFullYear()}`;
        });
        console.log("Doc names: ", docNames);
    
        let stats = [];
        let pathRef = collection(projectFirestore, "users", currentUser.uid, "private-data", "statistics", "processed_data", "languages", targetLanguage, "daily", "items");
    
        // Fetch the documents for all available dates
        for (const docName of docNames) {
            const docRef = doc(pathRef, docName);
            const snapshot = await getDoc(docRef);
    
            let s = snapshot.exists()
                ? { ...snapshot.data(), doc_id: snapshot.id, exists_in_db: true, total_wrong_items: snapshot.data().total_wrong_items || 0 }
                : { doc_id: docName, exists_in_db: false, total_correct_items: 0, total_wrong_items: 0 };
            
            stats.push(s);
        }
        console.log(targetLanguage, stats);
        return stats;
    };
    

    const getProcessedVocabularyDataInTargetLanguageToday = async (targetLanguage) => {
        if (!currentUser) return null; // Early return if no current user
    
        let today = new Date();
        let monthString = String(today.getMonth() + 1).padStart(2, '0'); // Ensure 2-digit month
        let docName = `${today.getDate()}.${monthString}.${today.getFullYear()}`;
    
        // Reference to the document in Firestore
        const docRef = doc(
            projectFirestore,
            "users",
            currentUser.uid,
            "private-data",
            "statistics",
            "processed_data",
            "languages",
            targetLanguage,
            "daily",
            "items",
            docName
        );
    
        try {
            const snapshot = await getDoc(docRef); // Get the document snapshot
    
            if (snapshot.exists()) {
                let data = snapshot.data();
                return {
                    ...data,
                    doc_id: snapshot.id,
                    exists_in_db: true,
                    total_wrong_items: data.total_wrong_items || 0, // Default to 0 if missing
                };
            } else {
                return {
                    doc_id: docName,
                    exists_in_db: false,
                    total_correct_items: 0,
                    total_wrong_items: 0,
                };
            }
        } catch (error) {
            console.error("Error fetching vocabulary data for today:", error);
            return null; // Return null on error
        }
    };
    
    // Function to add flashcard statistics to Firestore
    const addTableFlashcardsStatistic = async (obj) => {
        if (!currentUser) return null; // Early return if no current user
    
        let today = new Date();
        obj['UTCTimezoneOffsetInMinutes'] = today.getTimezoneOffset();
        obj['timestamp'] = Date.now(); // Use current timestamp
        obj['date'] = `${today.getDate()}.${today.getMonth() + 1}.${today.getFullYear()}`;
    
        // Reference to the collection in Firestore
        const query = collection(
            projectFirestore,
            'users',
            currentUser.uid,
            'private-data',
            'statistics',
            'historic_data',
            'languages',
            obj['target_ISO_639-1'],
            'table-flashcards',
            'items'
        );
    
        try {
            await addDoc(query, obj); // Add new document to the collection
            console.log("Flashcard statistic added successfully.");
        } catch (error) {
            console.error("Error adding flashcard statistic:", error);
        }
    };

    const collectTableFlashcardsStatistics = async ({correct, row, tableColumns, table, howManyItems, highlightColumnId, gamePlayedFrom}) => {
        if (currentUser === null){return}
        console.log(correct, row, tableColumns, table, howManyItems, highlightColumnId, gamePlayedFrom);
        let tableColObject = {};
        tableColumns.forEach((col)=> {
            tableColObject[col.id] = {
                'name': col.name,
                'doc_id': col.doc_id
            }
        });
        let obj = {
            'row_doc_id': row.doc_id,
            'row_id': row.id,
            'table_id': row.table_id,
            'row_values': row.values, 
            'table_doc_id': table.doc_id,
            'table_name': table.name,
            'table_columns': tableColObject,
            'table_column_order': table.column_order,
            'target_ISO_639-1': table['target_ISO_639-1'],
            'source_ISO_639-1': table['source_ISO_639-1'],
            'uid': table['uid'], 
            'how_many_items_in_game': howManyItems,
            'highlight_column_id': highlightColumnId, 
            'correct': correct, 
            'game_played_from': gamePlayedFrom, 
            'table': table

        };
        console.log("Stat: ", obj);
        await addTableFlashcardsStatistic(obj).catch(err=>console.log("Error: ", err));
        return true;
    }

    return {
        addFlashcardStatForCurrentUser, 
        getFlashcardStatisticsForCurrentUserInTargetLanguage,
        addQuizStatForCurrentUser, 
        getQuizStatisticsForCurrentUserInTargetLanguage, 
        getInputStatisticsForCurrentUserInTargetLanguage,
        addInputStatForCurrentUser, 
        getGlobalDeckGameStatsInRealtime, 
        getRealtimeDailyStatsInTargetLanguage, 
        getRealtimeDailyStatsInActiveTargetLanguages, 
        getDailyStatsInActiveTargetLanguages, 
        getXLastProcessedDataInTargetLanguage, 
        getDailyStatsInTargetLanguage, 
        addTableFlashcardsStatistic, 
        addReviewStatForCurrentUser, 
        getProcessedDataInTargetLanguageInMonth, 
        collectTableFlashcardsStatistics, 
        getProcessedVocabularyDataInTargetLanguageToday, 
        getProcessedStatisticsDataLastXDays
    }
}