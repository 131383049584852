import React, {useEffect, useState} from 'react';
import useTranslate from '../../hooks/useTranslate';
import {useLanguagesContext} from '../../contexts/LanguagesContext';
import Button from '../../components/general/Button';
import Select from 'react-select';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLoader} from '@fortawesome/pro-solid-svg-icons';
import CountryFlag from '../../components/languages/CountryFlag';

const Translator = () => {
    const {translateText} = useTranslate();
    const [inputText, setInputText] = useState("");
    let {allLanguages, targetLanguageOptions, sourceLanguageOptions, activeSourceLanguageOptions, activeTargetLanguageOptions, globalSelectedTargetLanguage, setGlobalSelectedTargetLanguage, globalSelectedSourceLanguage, setGlobalSelectedSourceLanguage} = useLanguagesContext();

    const [targetLanguage, setTargetLanguage] = useState(globalSelectedTargetLanguage);
    const [sourceLanguage, setSourceLanguage] = useState(globalSelectedSourceLanguage);
    const [translationWorking, setTranslationWorking] = useState(false);
    const [translatedText, setTranslatedText] = useState(null);
    const [translatedTextHistory, setTranslatedTextHistory] = useState([]);

    const inputOnChange = (e) => {
        setInputText(e.target.value);
    }

    const handleSourceLanguageChange = (option) => {
        setSourceLanguage(option.value);
    }

    const handleTargetLanguageChange = (option) => {
        setTargetLanguage(option.value);
    }

    const translateButtonClick = async () => {
        setTranslationWorking(true);
        let r = await translateText(inputText, targetLanguage);
        if (r.success){
            setTranslatedText(r.data);
            setTranslationWorking(false);
            setTranslatedTextHistory([...translatedTextHistory, {'translations': r, 'input': inputText, 'targetLanguage': targetLanguage, 'sourceLanguage': sourceLanguage}]);
        }
    }
    
    return (
    <>
        <div className="  overflow-x-hidden">
            <h1>Translator</h1>
            <div className="flex flex-col sm:flex-row gap-16 justify-start place-items-start sm:w-5/6 sm:m-auto">
                <div className="sm:w-3/4 flex flex-col place-items-start gap-6 m-auto">
                    <div className="flex flex-row gap-4 sm:gap-16 justify-center">
                        <div className="flex flex-col gap-2 ">
                            <div className="font-bold text-gray-600">
                                Source language
                            </div>
                            <div className="w-44">
                                {(sourceLanguageOptions !== null) &&
                                    <Select options={sourceLanguageOptions[0]} value={sourceLanguageOptions[1][sourceLanguage]} autoFocus={false} placeholder="Source language" className=" text-gray-500 text-base" onChange={handleSourceLanguageChange} />
                                }
                            </div>
                        </div>
                        <div className="flex flex-col gap-2 sm:w-96">
                            <div className="font-bold text-gray-600">
                                Text to translate
                            </div>
                            <div>
                                <input value={inputText} onChange={inputOnChange} type="text" className="bg-gray-200 appearance-none border-2  rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white" />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row gap-16 justify-center">
                        <div className="flex flex-col gap-2 ">
                            <div className="font-bold text-gray-700">
                                Target language
                            </div>
                            <div className="w-44">
                                {(sourceLanguageOptions !== null) &&
                                    <Select options={sourceLanguageOptions[0]} value={sourceLanguageOptions[1][targetLanguage]} autoFocus={false} placeholder="Target language" className=" text-gray-500 text-base" onChange={handleTargetLanguageChange} />
                                }
                            </div>
                        </div>
                        <div className="flex flex-col gap-2 w-96">
                            <div className="font-bold text-gray-700">
                                Translated text
                            </div>
                            <div className="relative">
                                {translationWorking &&
                                    <>
                                        <div className="text-center z-10 text-xl absolute left-0 text-black">
                                            <FontAwesomeIcon icon={faLoader} className="fa-spin" />
                                        </div>
                                    </>
                                }
                                {!translationWorking && translatedText !== null &&
                                    <>
                                        {translatedText.map((t, index)=>(
                                            <>
                                                <div key={"translated_"+index}>
                                                    {t}
                                                </div>
                                            </>
                                        ))}
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div>
                        <Button onClick={translateButtonClick} text="Translate" />
                    </div>
                </div>
                <div>
                    {translatedTextHistory.length > 0 &&
                    <div className="font-bold text-gray-700 text-xl">Recent translations</div>
                    }
                    {translatedTextHistory.map((elem, index)=>(
                        <>
                            <div className="my-2 flex flex-row gap-16 justify-start">
                                <div className="flex w-44 flex-row place-items-center gap-2">
                                    <div className="flex flex-row place-items-center gap-3">
                                        <div className="font-bold text-gray-700">
                                            {index+1}
                                        </div>
                                        <CountryFlag countryCode={allLanguages[0][elem.sourceLanguage]['country_flag']} size="2em" flagName={allLanguages[0][elem.sourceLanguage]['name']['en']} />
                                    </div>
                                    <div>
                                        {elem.input}
                                    </div>
                                </div>
                                <div className="flex w-44 flex-row place-items-center gap-2">
                                    <div>
                                        <CountryFlag countryCode={allLanguages[0][elem.targetLanguage]['country_flag']} size="2em" flagName={allLanguages[0][elem.targetLanguage]['name']['en']} />
                                    </div>
                                    <div>
                                    {elem.translations.map((t, i_2)=>(
                                        <>
                                            <div key={"tr_"+i_2}>
                                                {t}
                                            </div>
                                        </>
                                    ))}
                                    </div>
                                </div>
                            </div>
                        </>
                    ))}
                </div>
            </div>
        </div>
    </>
  )
}

export default Translator