import React, {useEffect, useState, useCallback, useRef} from 'react';
import Button from '../../components/general/Button';

import { Link } from 'react-router-dom';
import useUserSettings from "../../hooks/useUserSettings";
import NotificationMessage from '../../components/general/NotificationMessage';
import Select from 'react-select';
import {Helmet} from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import NotReadyYet from '../../components/errors/NotReadyYet';

const Settings = () => {

    let {saveProfileSettingsApi, fetchCurrentUserInfo, updatePublicUserInfo, generateUniqueUsernameApi, checkUsernameApi, checkIfUsernameAcceptable} = useUserSettings();

    let usernameInputRef = useRef();
    let displaynameInputRef = useRef();
    let introductionInputRef = useRef();
    let websiteInputRef = useRef();

    const [oldUserSettings, setOldUserSettings] = useState(null);
    const [userSettings, setUserSettings] = useState(null);
    const [loadingSettings, setLoadingSettings] = useState(true);

    const [saveButtonText, setSaveButtonText] = useState("Save changes");
    const [saveButtonEnabled, setSaveButtonEnabled] = useState(false);
    const [showChangesSavedConfirmation, setShowChangesSavedConfirmation] = useState(false);

    // Error handling
    const [usernameErrorBool, setUsernameErrorBool] = useState(false);
    const [usernameErrorMsg, setUsernameErrorMsg] = useState("");

    const [displaynameErrorBool, setDisplaynameErrorBool] = useState(false);
    const [displaynameErrorMsg, setDisplaynameErrorMsg] = useState("");

    // Changes
    const [usernameChanged, setUsernameChanged] = useState(false);

    // Accepted values tests
    const [usernameAllowedBool, setUsernameAllowedBool] = useState(false);
    const [usernameAllowedMsg, setUsernameAllowedMsg] = useState("");

    const [websiteErrorBool, setWebsiteErrorBool] = useState("");
    const [websiteErrorMsg, setWebsiteErrorMsg] = useState("");

    // Loading handling
    const [checkUsernameLoading, setCheckUsernameLoading] = useState(false);
    const [generateUsernameLoading, setGenerateUsernameLoading] = useState(false);
    const [saveSettingsLoading, setSaveSettingsLoading] = useState(false);

    // Cloud functions
    const [readyToAccess, setReadyToAccess] = useState(true);

    let privacyDropdownOptions = [{value: 'private', label:'Only me'}, {value:'public', label:'Anyone'}];
    let privacyLabels = {private: 'Only me', public: 'Anyone'};

    const usernameInputChange =  (e) => {
        let newState = {...userSettings}; 
        newState['username'] = e.target.value.toLowerCase().trim();
        setUserSettings(newState);
        let basicTestPassed = checkIfUsernameAcceptable(newState['username']);
        //console.log("Test: ", basicTestPassed);
        if (!basicTestPassed){
            setUsernameErrorBool(true);
            setUsernameErrorMsg("The username needs to be between 4 and 18 valid characters (numbers, latin letters and underscore).");
            setSaveButtonEnabled(false);
        } else {
            setUsernameErrorBool(false);
            setUsernameErrorMsg("");
        }
        
        setUsernameAllowedBool(false);
        setUsernameAllowedMsg("");
        setCheckUsernameLoading(false);
    }

    const displaynameInputChange = (e) => {
        let newState = {...userSettings}; 
        newState['displayname'] = e.target.value.replace(/\s+/g, ' '); //remove extra spaces
        setUserSettings(newState);
    }

    const introductionInputChange = (e) => {
        let newState = {...userSettings}; 
        newState['introduction'] = e.target.value.replace(/\s+/g, ' '); //remove extra spaces
        setUserSettings(newState);
    }

    
    const websiteInputChange = (e) => {
        let newState = {...userSettings}; 
        newState['website'] = e.target.value.replace(/\s+/g, ' '); //remove extra spaces
        setUserSettings(newState);
    }

    const publicProfileInputChange = (e) => {
        let newState = {...userSettings}; 
        newState['public_profile'] = (e.target.checked);
        setUserSettings(newState);
    }

    const handleLanguagesPrivacyChange = (option) => {
        let newState = {...userSettings}; 
        newState['languages_privacy'] = (option.value);
        setUserSettings(newState);
    }

    const handleFriendsPrivacyChange = (option) => {
        let newState = {...userSettings}; 
        newState['friends_privacy'] = (option.value);
        setUserSettings(newState);
    }

    const handlePointsPrivacyChange = (option) => {
        let newState = {...userSettings}; 
        newState['points_privacy'] = (option.value);
        setUserSettings(newState);
    }

    useEffect(()=>{
        const fetchData = async () => {
            let data = await fetchCurrentUserInfo();
            if (data !== null){
                if (!('displayname' in data) || data['displayname'] === null){
                    data['displayname'] = "";
                }
                if (!('username' in data) || data['username'] === null){
                    data['username'] = "";
                }
                if (!('public_profile' in data) || data['public_profile'] === null){
                    data['public_profile'] = false;
                }
                if (!('languages_privacy' in data || data['languages_privacy'] === null || data['languages_privacy'] === "friends")){
                    data['languages_privacy'] = "private";
                }
                if (!('friends_privacy' in data) || data['friends_privacy'] === null || data['friends_privacy'] === "friends"){
                    data['friends_privacy'] = "private";
                }
                if (!("introduction" in data)){
                    data['introduction'] = "Welcome to my profile on Yalango!";
                }
                if (!("website" in data)){
                    data['website'] = "";
                }
                setUserSettings(data);
                setOldUserSettings(data);
            } else {
                let data = {};
                data['displayname'] = "";
                data['username'] = "";
                data['public_profile'] = false;
                data['languages_privacy'] = "private";
                data['friends_privacy'] = "private";
                data['introduction'] = "Welcome to my profile on Yalango!";
                data['website'] = "";
                setUserSettings(data);
                setOldUserSettings(data);
            }
        };
        fetchData();

    },[]);

    const equalObjects = useCallback((x, y) => {
        // checks if state has updated
        const ok = Object.keys, tx = typeof x, ty = typeof y;
        return x && y && tx === 'object' && tx === ty ? (
          ok(x).length === ok(y).length &&
            ok(x).every(key => equalObjects(x[key], y[key]))
        ) : (x === y);
      },[]);

    useEffect(()=>{
        if (userSettings!== null && oldUserSettings !== null){
            setLoadingSettings(false);
            if (userSettings.username === oldUserSettings.username){
                setUsernameChanged(false);
            } else {
                setUsernameChanged(true);
            }
            if (!usernameErrorBool && !displaynameErrorBool){
                if (!equalObjects(oldUserSettings, userSettings)){
                    // a change has been made
                    setSaveButtonEnabled(true);
                    //setSaveButtonText("Save changes");
                }
                else {
                    setSaveButtonEnabled(false);
                    //setSaveButtonText("No changes made")
                }
            } 
        }
    },[userSettings, oldUserSettings, equalObjects]);

    const resetFieldErrors = () => {
        setUsernameErrorBool(false);
        setUsernameErrorMsg(null);
        setDisplaynameErrorBool(false);
        setDisplaynameErrorMsg(null);
        setUsernameAllowedBool(false);
        setUsernameAllowedMsg("");
    }

    const saveSettingsClick = async () => {
        setSaveSettingsLoading(true);
        let {success, errorFields, errorMessages} = await saveProfileSettingsApi(userSettings);
        //console.log(success, errorFields, errorMessages);
        if (success){
            setOldUserSettings(userSettings);
            setShowChangesSavedConfirmation(true);
            setSaveSettingsLoading(false);
            resetFieldErrors();
        }
        else {
            if (errorFields.includes("username")){
                //console.log("Error in username");
                setUsernameErrorBool(true);
                setUsernameErrorMsg(errorMessages['username']);
                usernameInputRef.current.focus();
            }
            if (errorFields.includes("displayname")){
                setDisplaynameErrorBool(true);
                setDisplaynameErrorMsg(errorMessages['displayname']);
            }
            setSaveSettingsLoading(false);
        }
    }

    const generateUsernameClick = async () => {
        setGenerateUsernameLoading(true);
        let results = await generateUniqueUsernameApi();
        let newState = {...userSettings}; 
        newState['username'] = (results);
        setUserSettings(newState);
        setGenerateUsernameLoading(false);
        setUsernameErrorBool(false);
        setUsernameErrorMsg("");
        setUsernameAllowedBool(true);
        setUsernameAllowedMsg("This username is available.");
    }

    const checkUsernameClick = async () => {
        setCheckUsernameLoading(true);
        let allowed = await checkUsernameApi(userSettings.username);
        if (!allowed){
            setCheckUsernameLoading(false);
            setUsernameErrorBool(true);
            setUsernameErrorMsg("This username is already taken.");
            setUsernameAllowedBool(false);
            setUsernameAllowedMsg("");
            setSaveButtonEnabled(false);
        } else {
            setCheckUsernameLoading(false);
            setUsernameErrorBool(false);
            setUsernameErrorMsg("");
            setUsernameAllowedBool(true);
            setUsernameAllowedMsg("This username is available.");
        }
    }

    /* 
<div className="flex my-2">
                                                    <div className="w-1/3">
                                                        <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" for="inline-full-name">
                                                            Friends
                                                        </label>
                                                    </div>
                                                    <div className="w-2/3">
                                                        <Select isSearchable={ false } options={privacyDropdownOptions} value={{value: userSettings['friends_privacy'], label:privacyLabels[userSettings['friends_privacy']]}} onChange={handleFriendsPrivacyChange} />
                                                    </div>
                                                </div>
    */
    console.log(userSettings);
    return (
        <>
            <Helmet>
                <title>Profile settings - Yalango</title>
                <meta name="description" content={"Change your profile settings on Yalango."} />
            </Helmet>
                {!loadingSettings && 
                    <>
                        <div className=" ">
                            <h1>Public profile settings</h1>
                            <div className="w-5/6 md:w-3/6 m-auto">
                                <div className="flex flex-col mb-3">
                                    <div className="my-4">
                                            {(oldUserSettings.username !== null && oldUserSettings.username !== undefined && oldUserSettings.username !== "") &&
                                            <>
                                            <div className="text-center">
                                                You can visit your public profile <Link to={"/u/"+oldUserSettings.username}>here</Link>.
                                            </div>
                                            </>
                                            }
                                            <div className="text-center text-xl my-4 p-3 bg-persian-green text-white rounded-xl shadow-lg">Profile information</div> 
                                                <div className="my-2 md:flex md:flex-rows md:items-center">
                                                    <div className="md:w-1/3">
                                                        <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" htmlFor="inline-full-name">
                                                            Username
                                                        </label>
                                                    </div>
                                                    <div className="md:w-2/3">
                                                        <input ref={usernameInputRef} className={"bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white " + (usernameAllowedBool && ' border-green-500 ') + (usernameErrorBool && ' border-red-500 ')} id="inline-full-name" type="text" value={userSettings.username} onChange={usernameInputChange} />
                                                    </div>
                                                </div>
                                                {(!usernameChanged && !usernameErrorBool) && 
                                                    <>
                                                        <div className="md:flex md:items-center">
                                                            <div className="md:w-1/3"></div>
                                                            <div className="md:w-2/3 text-gray-500 italic text-sm">This is your current username.</div>
                                                        </div>
                                                    </>
                                                }
                                                {usernameErrorBool && 
                                                    <>
                                                        <div className="md:flex md:items-center">
                                                            <div className="md:w-1/3"></div>
                                                            <div className="md:w-2/3 text-red-500 italic text-sm">{usernameErrorMsg}</div>
                                                        </div>
                                                    </>
                                                }
                                                {usernameAllowedBool && 
                                                    <>
                                                    <div className="md:flex md:items-center">
                                                        <div className="md:w-1/3"></div>
                                                        <div className="md:w-2/3 text-green-500 italic text-sm">{usernameAllowedMsg}</div>
                                                    </div>
                                                    </>
                                                }
                                                <div className="my-1 md:flex md:items-center">
                                                    <div className="md:w-1/3">
                                                        
                                                    </div>
                                                    <div className="md:w-2/3 flex flex-col md:flex-row md:justify-between gap-2">
                                                        {!generateUsernameLoading ?
                                                            <>
                                                                <div className="text-gray-500 underline cursor-pointer" onClick={generateUsernameClick} >
                                                                    Random username
                                                                </div>
                                                            </>
                                                        :
                                                            <>
                                                                <div className="text-gray-500" >
                                                                    <FontAwesomeIcon icon={faSpinner} className="fa-pulse" />
                                                                </div>
                                                            </>
                                                        }
                                                        {(usernameChanged && !usernameAllowedBool) && 
                                                            <>
                                                            {!checkUsernameLoading ?
                                                                <>
                                                                    <div className="text-gray-500 underline cursor-pointer" onClick={checkUsernameClick} >
                                                                        Available?
                                                                    </div>
                                                                </>
                                                            :
                                                                <>
                                                                    <div className="text-gray-500" >
                                                                        <FontAwesomeIcon icon={faSpinner} className="fa-pulse" />
                                                                    </div>
                                                                </>
                                                            }
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                                
                                                
                                            
                                            <div>
                                                <div className="my-6 md:flex md:items-center">
                                                    <div className="md:w-1/3">
                                                        <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" htmlFor="inline-full-name">
                                                            Display name
                                                        </label>
                                                    </div>
                                                    <div className="md:w-2/3">
                                                        <input ref={displaynameInputRef} className={"bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white " + (displaynameErrorBool ? 'border-red-500' : "focus:border-blue-500")} id="inline-full-name" type="text" value={userSettings.displayname} onChange={displaynameInputChange} />
                                                    </div>
                                                </div>
                                                <div className="md:flex md:items-center">
                                                    <div className="md:w-1/3"></div>
                                                    <div className="md:w-2/3 text-red-500">{displaynameErrorBool && displaynameErrorMsg}</div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="my-6 md:flex md:items-center">
                                                    <div className="md:w-1/3">
                                                        <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" htmlFor="inline-full-name">
                                                            Introduction
                                                        </label>
                                                    </div>
                                                    <div className="md:w-2/3">
                                                        <textarea ref={introductionInputRef} className={"bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white " + (displaynameErrorBool ? 'border-red-500' : "focus:border-blue-500")} id="inline-full-name" type="text" value={userSettings.introduction} onChange={introductionInputChange} />
                                                    </div>
                                                </div>
                                                <div className="my-6 md:flex md:items-center">
                                                    <div className="md:w-1/3">
                                                        <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" htmlFor="inline-full-name">
                                                            Website
                                                        </label>
                                                    </div>
                                                    <div className="md:w-2/3">
                                                        <input ref={websiteInputRef} className={"bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white " + (websiteErrorBool ? 'border-red-500' : "focus:border-blue-500")} id="inline-full-name" type="text" value={userSettings.website} onChange={websiteInputChange} />
                                                    </div>
                                                </div>
                                                <div className="md:flex md:items-center">
                                                    <div className="md:w-1/3"></div>
                                                    <div className="md:w-2/3 text-red-500">{displaynameErrorBool && displaynameErrorMsg}</div>
                                                </div>
                                            </div>
                                            <div className="my-2">
                                                <div className="text-center text-xl my-4 p-3 bg-persian-green text-white rounded-xl shadow-lg">Privacy options</div>    
                                            </div>
                                            
                                            <div className="">
                                                <div className="text-center text-base my-4">Who can see my...</div>
                                                <div className="flex my-2">
                                                    <div className="w-1/3">
                                                        <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4" htmlFor="inline-full-name">
                                                            Languages
                                                        </label>
                                                    </div>
                                                    <div className="w-2/3">
                                                        <Select isSearchable={ false } options={privacyDropdownOptions} value={{value: userSettings['languages_privacy'], label:privacyLabels[userSettings['languages_privacy']]}} onChange={handleLanguagesPrivacyChange} />
                                                    </div>
                                                </div> 
                                                
                                            </div>

                                </div>
                                
                                <div className="text-center flex flex-row justify-center">
                                    {saveSettingsLoading ? 
                                        <>
                                            <FontAwesomeIcon icon={faSpinner} className="fa-pulse" />
                                        </>
                                        :
                                        <>
                                            <Button text={saveButtonText} disabledDesign={!saveButtonEnabled ? true : false} color={saveButtonEnabled ? 'green' : 'bg-gray-500'} onClick={saveSettingsClick} disabled={!saveButtonEnabled && true} />
                                        </>
                                    }
                                </div>
                            </div>
                            </div>
                            
                            <NotificationMessage type="save-success" showMessageBool={showChangesSavedConfirmation} setShowMessageBool={setShowChangesSavedConfirmation} />
                        </div>   
                    </>
                }
        </>
    )
}

export default Settings;
