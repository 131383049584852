import React, {useState, useEffect} from 'react';
import YalangoStars from '../../assets/svg/yalango/YalangoStars.svg';
import {useUserStatisticsContext} from '../../contexts/UserStatisticsContext'; 
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/pro-duotone-svg-icons';
import {faRotateRight} from '@fortawesome/pro-solid-svg-icons';
import Skeleton from 'react-loading-skeleton';
import {Link} from 'react-router-dom';
import {faAngleDown} from '@fortawesome/pro-solid-svg-icons';
import {faAngleUp} from '@fortawesome/pro-solid-svg-icons';

const MyStarsCounter = () => {
    const { 
        userStars,
        refreshUserStars,
    } = useUserStatisticsContext();
    useEffect(()=>{
        refreshUserStars();
    },[]);
  return (
    <>
      <div className="w-full flex flex-col gap-0 p-0 relative">
    <Link className="no-underline" to={"/analytics"}>
        <div className="flex flex-row w-full justify-start gap-2 text-gray-600 dark:text-gray-300">
            <div className="text-yellow-500 dark:text-white">
                <FontAwesomeIcon icon={faStar} />
            </div>
            <div className="font-bold">
                {(userStars === undefined || userStars === null || userStars === false || !userStars.hasOwnProperty("total_stars"))
                    ? 0
                    : userStars.total_stars}
            </div>
        </div>
    </Link>
</div>

    </>
  )
}

export default MyStarsCounter;