import React, {useState, useMemo, useEffect} from 'react';
import { createEditor, Descendant } from 'slate';
import {useParams} from "react-router-dom";
import {Helmet} from 'react-helmet';
import Icon from '../../components/general/Icon';
import ReactTooltip from 'react-tooltip';
import { faLock } from '@fortawesome/pro-duotone-svg-icons';
import { faGlobe, faPlay } from '@fortawesome/pro-duotone-svg-icons';
import Button from '../../components/general/Button';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import useTexts from '../../hooks/useTexts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {DebounceInput} from 'react-debounce-input';
import { Slate, Editable, withReact } from 'slate-react';

const EditMyText = () => {

    let { id } = useParams();
    console.log("ID: ", id)
    let textId = parseInt(id);

    const {fetchPersonalText, 
        saveChangesToText} = useTexts();

    const editor = useMemo(() => withReact(createEditor()), []);
    const [showPrivacyBox, setShowPrivacyBox] = useState(false);
    const [text, setText] = useState(null);
    const [textTitle, setTextTitle] = useState(null);
    const [textBody, setTextBody] = useState(null);
    const [jsonTextBody, setJsonTextBody] = useState(null);
    const [textPrivacy, setTextPrivacy] = useState(null);
    const [textSlug, setTextSlug] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        const fetchData = async () => {
            let r = await fetchPersonalText(textId);
            if (r === false || r === null || r === undefined){return false}
            console.log("text: ", r)
            let parsedBody = JSON.parse(r?.body ? r.body : '[{"type":"paragraph","children":[{"text":""}]}]');
            setText(r);
            setTextTitle(r?.title ? r.title : "Untitled");
            setTextSlug(r?.slug ? r.slug : "untitled");
            setJsonTextBody(parsedBody);
            setTextBody(parsedBody[0]["children"][0]["text"]);
            setTextPrivacy(r?.privacy ? r.privacy : "private");
            setLoading(false);
        }

        if (textId !== null){
            fetchData();
        }
    },[textId]);

    const privacyOptionChanged = async (e) => {
        console.log(e.target.value);
        let newPrivacyOption = e.target.value;
        setTextPrivacy(newPrivacyOption);
        await saveChangesToText({textDocId: text.doc_id, privacy: newPrivacyOption})
    }

    const togglePrivacyBox = () => {
        setShowPrivacyBox(!showPrivacyBox);
    }

    const renameNameOnInput = async (e) => {
        let newTitle = e.target.value;
        setTextTitle(newTitle);
        setTextSlug(newTitle.toLowerCase().replaceAll(" ", "-"))
        await saveChangesToText({textDocId: text.doc_id, title: newTitle});
    }


    const saveChangesToBodyToDatabase = async (jsonBody) => {
        console.log('Saving data');
        await saveChangesToText({textDocId: text.doc_id, body: JSON.stringify(jsonBody)});
    }


    const textBodyOnChange = async (e) => {
        let newBody = e.target.value;
        setTextBody(newBody);
        let newJsonBody = [{"type":"paragraph","children":[{"text":newBody}]}];
        setJsonTextBody(newJsonBody);
        saveChangesToBodyToDatabase(newJsonBody);
    }

    return (
        <>
            <ReactTooltip place="bottom" id="card-menu-tooltip" effect="solid" />
            <div className="  min-h-screen h-full">
                <div className="flex flex-col gap-8">
                    <div className="pl-4 py-2 flex w-full flex-row justify-start place-items-center">
                        <div className="flex flex-row gap-4 justify-start place-items-start">
                            <div className="text-xl relative">
                                {!loading &&
                                    <>
                                        <div onClick={togglePrivacyBox}>
                                            {(textPrivacy === "private") && 
                                                <Icon data-place="left" data-for="card-menu-tooltip"  icon={<FontAwesomeIcon icon={faLock} />} color="charcoal" hover="none" cursor="pointer" /> 
                                            }
                                            {(textPrivacy === "public") && 
                                                <Icon data-place="left" data-for="card-menu-tooltip"  icon={<FontAwesomeIcon icon={faGlobe} />} color="charcoal" hover="none" cursor="pointer" /> 
                                            }
                                        </div>
                                        {showPrivacyBox &&
                                            <>
                                            <div className="absolute left-0 top-8 bg-white rounded-xl w-48 shadow-xl p-5 z-20">
                                                <fieldset>
                                                    <div className="flex flex-col gap-3">
                                                        <div>
                                                            <div className="flex flex-row gap-2 font-bold text-base">
                                                                <input onChange={privacyOptionChanged} type="radio" id="private" value="private" checked={textPrivacy === "private" ? true : false} />
                                                                <label htmlFor="private">Private</label>
                                                            </div>
                                                            <div className="text-xs muted-text">
                                                                Only you can see this text.
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="flex flex-row gap-2 font-bold text-base">
                                                                <input onChange={privacyOptionChanged} type="radio" id="public" value="public" checked={textPrivacy === "public" ? true : false} />
                                                                <label htmlFor="public">Public</label>
                                                            </div>
                                                            <div className="text-xs muted-text">
                                                                    Due to copyright laws, please only publish texts that are in the public domain or that you've written yourself.
                                                                </div>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </div>
                                            <div onClick={togglePrivacyBox} className="z-10 w-screen h-screen fixed left-0 top-0 ">

                                            </div>
                                            </>
                                        }
                                    </>
                                }
                                {loading &&
                                    <>
                                        <Skeleton count={1} height={30} />
                                    </>
                                }
                            </div>
                            <div className="">
                                <div className="font-bold muted-text text-lg">
                                {textTitle !== null ? 
                                <DebounceInput
                                    className={" text-left hover:underline bg-transparent font-semibold border-none outline-none"}
                                    minLength={1}
                                    value={textTitle}
                                    debounceTimeout={1000}
                                    onChange={event => renameNameOnInput(event)} 
                                /> : 
                                <Skeleton count={1} height={30} />}
                                </div>
                                
                            </div>
                            
                        </div>
                    
                    </div>
                    <div>
                        {!loading &&
                        <div className="flex flex-row gap-3 md:gap-6 justify-center">
                            <div className="flex flex-row justify-center md:order-last">
                                <Link to={"/my-texts/" + textId+"/study"} className="no-underline">
                                    <Button text="Study text" size="normal" icon={faPlay} color="green" />
                                </Link>
                            </div>
                        </div>
                        }
                    </div>
                    <div className="w-full  h-screen flex flex-row justify-center">
                                {!loading &&
                                    <>
                                        <div className="relative w-5/6 xl:w-4/6 mx-auto">
                                            <DebounceInput
                                                className={" bg-transparent xl:text-lg h-full border-none outline-none min-h-64  p-4 w-full"}
                                                minLength={1}
                                                element="textarea"
                                                placeholder="Write or paste your text in this field."
                                                value={textBody}
                                                debounceTimeout={1000}
                                                onChange={event => textBodyOnChange(event)} 
                                            /> 
                                         <div className=" hidden absolute left-0 top-0 inset-0 -z-20 h-full w-full bg-white bg-[linear-gradient(to_right,#f0f0f0_1px,transparent_1px),linear-gradient(to_bottom,#f0f0f0_1px,transparent_1px)] bg-[size:6rem_4rem]">

</div>
                                        </div>
                                    </>
                                }
                               
                </div>
            </div>
            </div>
        </>
    )
}

export default EditMyText;