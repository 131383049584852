import React, { createContext, useContext, useState, useEffect } from "react";
import { getAuth, signInWithCustomToken, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-duotone-svg-icons";
import YalangoSvg from "../assets/svg/yalango/yalango.svg";
import {projectAuth} from '../firebase/config';

// Create the AuthContext
const AuthContext = createContext();

// Hook to access the AuthContext
export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}

// AuthProvider component
export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(undefined); // default to undefined to distinguish between loading and logged out states
  const [isPremium, setIsPremium] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showSplash, setShowSplash] = useState(true);

  const signInInDevelopment = async (email, password) => {
    await signInWithEmailAndPassword(projectAuth, email, password);
  };

  // Function to automatically log in using the session cookie
  const autoLogin = async () => {
    try {
      const response = await fetch("https://yalango.com/api/token", {
        credentials: "include", // Include session cookie in the request
      });
      console.log("Response from /api/token:", response);
  
      if (!response.ok) {
        console.error("Failed to fetch custom token");
        setCurrentUser(null);
        return;
      }
  
      const { token } = await response.json();
      console.log("Custom token fetched:", token);
  
      const userCredential = await signInWithCustomToken(projectAuth, token);
      console.log("User signed in:", userCredential.user);
      setCurrentUser(userCredential.user);
    } catch (error) {
      console.error("Error during auto-login:", error);
      setCurrentUser(null);
    } finally {
      setLoading(false);
      setShowSplash(false);
    }
  };
  

  // Check if the user is a premium user
  const checkIfPremiumUser = async () => {
    if (!currentUser) return false;
    try {
      const idTokenResult = await currentUser.getIdTokenResult();
      const premium = idTokenResult.claims?.stripeRole === "premium";
      setIsPremium(premium);
      return premium;
    } catch (error) {
      console.error("Error checking premium status:", error);
      return false;
    }
  };

  // Logout function
  const logout = async () => {
    try {
      await fetch("https://yalango.com/api/logout", { method: "POST" }); // Clear the session cookie
      await signOut(projectAuth);
      setCurrentUser(null);
      setIsPremium(null);
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  // Determine the login behavior based on environment
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      autoLogin();
    } else {
      setCurrentUser(null);
      setLoading(false); // Skip auto-login in development
    }
  }, []);


  // Re-check premium status when the current user changes
  useEffect(() => {
    if (currentUser) {
      checkIfPremiumUser();
    }
    const isDevelopment = process.env.NODE_ENV === "development";
    let unsubscribe = null;
    if (isDevelopment){
      unsubscribe = projectAuth.onAuthStateChanged(async (user) => {
        setCurrentUser(user);
        checkIfPremiumUser(user);
        setLoading(false);
      })
    }
    return ()=>{
      isDevelopment && unsubscribe();
    }
  }, [currentUser]);

  const value = {
    currentUser,
    isPremium,
    logout,
    checkIfPremiumUser,
    autoLogin, 
    signInInDevelopment
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
