import {projectFirestore, projectFunctions, timeStamp, firebase} from '../firebase/config.js';
import { useAuth } from "../contexts/AuthContext";

import {
    getFirestore,
    collection,
    query,
    where,
    setDoc,
    getDoc,
    getDocs,
    doc,
    deleteDoc,
    addDoc,
    updateDoc,
    onSnapshot,
    serverTimestamp,
    orderBy,
    limit,
    startAfter,
    endBefore,
    limitToLast, 
    Timestamp
} from "firebase/firestore";
import {  httpsCallable } from "firebase/functions";

export default function useTexts() {

    let {currentUser} = useAuth();

    const fetchText = async (id) => {
        const textQuery = query(
            collection(projectFirestore, "texts", "items", "items"),
            where("privacy", "==", "public"),
            where("premium", "==", false),
            where("text_id", "==", parseInt(id))
        );
        const snap = await getDocs(textQuery);
        if (!snap.empty && snap.docs.length === 1) {
            const data = snap.docs[0].data();
            data['doc_id'] = snap.docs[0].id;
            return data;
        } else {
            return fetchPersonalText(id);
        }
    };

    const fetchPersonalText = async (id) => {
        if (currentUser === null) { return false; }
        
        const personalTextQuery = query(
            collection(projectFirestore, "texts", "items", "items"),
            where("uid", "==", currentUser.uid),
            where("text_id", "==", parseInt(id))
        );
        const snap = await getDocs(personalTextQuery);
        if (!snap.empty && snap.docs.length === 1) {
            const data = snap.docs[0].data();
            data['doc_id'] = snap.docs[0].id;
            return data;
        } else {
            return false;
        }
    };

    const fetchAllTextsInTargetLanguage = async (targetLang) => {
        const textsQuery = query(
            collection(projectFirestore, "texts", "items", "items"),
            where("target_ISO_639-1", "==", targetLang),
            where("privacy", "==", "public"),
            where("premium", "==", false)
        );
        const snap = await getDocs(textsQuery);
        let docs = [];
        if (!snap.empty) {
            snap.docs.forEach((doc) => {
                let d = doc.data();
                d['doc_id'] = doc.id;
                docs.push(d);
            });
        }
        return docs;
    };

    const fetchMostRecentTextsInTargetLanguage = async ({ targetLang, limit }) => {
        let textsQuery = query(
            collection(projectFirestore, "texts", "items", "items"),
            where("target_ISO_639-1", "==", targetLang),
            where("privacy", "==", "public"),
            where("premium", "==", false),
            orderBy("created_timestamp", "desc"),
            limit(limit)
        );
        const snap = await getDocs(textsQuery);
        let docs = [];
        if (!snap.empty) {
            snap.docs.forEach((doc) => {
                let d = doc.data();
                d['doc_id'] = doc.id;
                docs.push(d);
            });
        }
        return docs;
    };

    const fetchAllTexts = async () => {
        const textsQuery = query(
            collection(projectFirestore, "texts", "items", "items"),
            where("privacy", "==", "public"),
            where("premium", "==", false)
        );
        const snap = await getDocs(textsQuery);
        let docs = [];
        if (!snap.empty) {
            snap.docs.forEach((doc) => {
                let d = doc.data();
                d['doc_id'] = doc.id;
                docs.push(d);
            });
        }
        return docs;
    };

    const fetchAllTextsFromCurrentUserInRealtime = async (setStateFunc) => {
        const textsQuery = query(
            collection(projectFirestore, 'texts', 'items', 'items'),
            where('uid', '==', currentUser.uid)
        );
        return onSnapshot(textsQuery, (querySnapshot) => {
            let texts = [];
            querySnapshot.docs.forEach((doc) => {
                let d = doc.data();
                d['doc_id'] = doc.id;
                texts.push(d);
            });
            setStateFunc(texts);
        });
    };

    const renameText = async (textDocId, newName) => {
        console.log("Renaming");
        const textRef = doc(projectFirestore, "texts", "items", "items", textDocId);
        await setDoc(textRef, {
            'title': newName,
            'last_updated_timestamp': timeStamp
        }, { merge: true });
    };
    
    const saveChangesToText = async ({ textDocId = null, title = null, privacy = null, body = null }) => {
        if (textDocId === null || textDocId === undefined) { return false; }
    
        let obj = { 'last_updated': timeStamp };
    
        if (title !== null) {
            obj['title'] = title;
            obj['slug'] = title.toLowerCase().replaceAll(" ", "-");
        }
        if (privacy !== null) {
            obj['privacy'] = privacy;
        }
        if (body !== null) {
            obj['body'] = body;
        }
    
        console.log("New text: ", obj);
    
        const textRef = doc(projectFirestore, "texts", "items", "items", textDocId);
        await setDoc(textRef, obj, { merge: true }).catch((err) => {
            console.log("Error: ", err);
        });
    
        return true;
    };

    const saveNewText = async ({ targetLanguage, title, parentFolderDocId }) => {
        if (currentUser === null) { return false; }
        if (title === "" || title === null || title === undefined) { return false; }

        const saveNewTextFunction = httpsCallable(projectFunctions, 'saveNewText');
        let data = await saveNewTextFunction({
            'targetLanguage': targetLanguage,
            'title': title,
            'parentFolderDocId': "top_level",
            'destinationFolder': parentFolderDocId
        }).catch((err) => { return false; });

        if (data === false) { return false; }

        return data;
    };

    const deleteTextFromDocId = async (docId) => {
        const textRef = doc(projectFirestore, "texts", "items", "items", docId);
        await deleteDoc(textRef);
    };


    return {
        fetchText,
        fetchAllTextsInTargetLanguage, 
        fetchAllTexts, 
        fetchMostRecentTextsInTargetLanguage, 
        fetchAllTextsFromCurrentUserInRealtime, 
        renameText, 
        fetchPersonalText, 
        saveChangesToText, 
        saveNewText, 
        deleteTextFromDocId
    }
}