import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-solid-svg-icons';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { faCaretDown, faUpload, faFileImport } from '@fortawesome/pro-solid-svg-icons';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import { faHome } from '@fortawesome/pro-solid-svg-icons';
import { faFolder } from '@fortawesome/pro-solid-svg-icons';
import {faPen} from '@fortawesome/pro-solid-svg-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import Button from '../../../components/general/Button';
import { faLoader } from '@fortawesome/pro-solid-svg-icons';
import { faFolderPlus } from '@fortawesome/pro-solid-svg-icons';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import Icon from '../../../components/general/Icon';
import YalangoHead from '../../../assets/svg/yalango/YalangoHead.svg';
import {faEllipsisV} from '@fortawesome/pro-solid-svg-icons'; 
import { faCog } from '@fortawesome/pro-solid-svg-icons';
import { faPlay } from '@fortawesome/pro-solid-svg-icons';
import { faUpDownLeftRight } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';
import { faCardsBlank } from '@fortawesome/pro-solid-svg-icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {motion, AnimatePresence } from 'framer-motion';
import {useNavigate} from 'react-router-dom';
import LibraryItemMenu from '../../../pages/mylibrary/LibraryItemMenu';

const MenuOptions = ({closeMenu, renameItemClick, deleteItemClick, closeCreateItemModal, openCreateSubItemModal, openCreateSubFolderModal, openCreateFolderModal, parentFolder, draggableId, setMoveItemCurrentParent, changeItemFolderClick, showOptionMenuDraggableId, setShowOptionMenuDraggableId, item}) => {
    
    const navigate = useNavigate();

    const menuClick = (e) => {
        e.stopPropagation();
        if (draggableId === showOptionMenuDraggableId){
            setShowOptionMenuDraggableId(null);
        } else {
            setShowOptionMenuDraggableId(draggableId);
        }
    }

    const backgroundClick = (e) => {
        setShowOptionMenuDraggableId(null);
    }

    const changeFolderOptionMenuClick = (e) => {
        e.stopPropagation();
        setShowOptionMenuDraggableId(null);
        setMoveItemCurrentParent(parentFolder);
        changeItemFolderClick(e, item);
    }

    const renameItemClickHelper = (e) => {
        e.stopPropagation();
        setShowOptionMenuDraggableId(null);
        renameItemClick(e, item);
    }

    const deleteItemClickHelper = (e) => {
        e.stopPropagation();
        setShowOptionMenuDraggableId(null);
        deleteItemClick(e, item);
    }

    const openCreateSubFolderModalClick = (e) => {
        e.stopPropagation();
        setShowOptionMenuDraggableId(null);
        setMoveItemCurrentParent(item); //
        openCreateSubFolderModal(e, item);
    }

    const openCreateSubItemModalClick = (e) => {
        e.stopPropagation();
        setShowOptionMenuDraggableId(null);
        openCreateSubItemModal(e, item);
    }

    const navigateToPlayAllDecks = (e) => {
        e.stopPropagation();
        backgroundClick();
        closeMenu();
        navigate('/my-folders/'+item.doc_id+"/decks/play");
    }

    const navigateToStudyNote = (e) => {
        e.stopPropagation();
        backgroundClick();
        closeMenu();
        navigate("/my-notes/note/"+item.id+"/study");
    }

    const navigateToPlayDeck = (e) => {
        e.stopPropagation();
        backgroundClick();
        closeMenu();
        navigate("/my-decks/"+item.id+"/play");
    }

    const navigateToPlayTable = (e) => {
        e.stopPropagation();
        backgroundClick();
        closeMenu();
        navigate("/my-tables/"+item.id+"/play");
    }

    const navigateToEditStudyNote = (e) => {
        e.stopPropagation();
        backgroundClick();
        closeMenu();
        navigate("/my-notes/note/"+item.id);
    }

    const navigateToEditDeck = (e) => {
        e.stopPropagation();
        backgroundClick();
        closeMenu();
        navigate("/my-decks/"+item.id);
    }

    const navigateToEditTable = (e) => {
        e.stopPropagation();
        backgroundClick();
        closeMenu();
        navigate("/my-tables/"+item.id);
    }
    

    return (
        <>  
            <AnimatePresence>
                {showOptionMenuDraggableId === draggableId ?
                    <>
                        <motion.div initial={{opacity: 0}} animate={{opacity:1}} exit={{opacity:0}} onClick={backgroundClick} className="fixed w-screen bg-transparent cursor-default">
                        </motion.div>
                        <motion.div 
                            variants={{
                                initial: {
                                    height: 0,
                                },
                                animate: {
                                    height: 'auto',
                                    transition: {
                                        when: 'beforeChildren',
                                        duration: 0.10
                                    },
                                },
                                exit: {
                                    height: 0,
                                    transition: {
                                        when: 'afterChildren',
                                    },
                                },
                            }}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                        className="absolute overflow-visible text-sm  z-30 rounded-lg shadow-xl bg-white right-0 top-0">
                            <motion.div 
                            variants={{
                                    initial: {
                                        opacity: 0,
                                    },
                                    animate: {
                                        opacity: 1,
                                        duration: 0.10
                                    },
                                    exit: {
                                        opacity: 0,
                                    }
                                }}
                            className="flex font-bold text-gray-700 flex-col justify-start place-items-start">
                                {item.content_type === "folder" &&
                                    <>
                                        <div onClick={(e)=>navigateToPlayAllDecks(e)}  className="flex flex-row gap-3 hover:bg-gray-100 w-full  p-3">
                                            <div>
                                                <Icon color="persian-green" icon={<FontAwesomeIcon icon={faPlay} />} />
                                            </div>
                                            <div>
                                                Play all decks
                                            </div>
                                        </div>
                                        <div onClick={(e)=>openCreateSubItemModalClick(e)}  className="flex flex-row gap-3 hover:bg-gray-100 w-full  p-3">
                                            <div>
                                                <Icon color="charcoal" icon={<FontAwesomeIcon icon={faPlus} />} />
                                            </div>
                                            <div>
                                                Create subitem
                                            </div>
                                        </div>
                                        <div onClick={(e)=>openCreateSubFolderModalClick(e)}  className="flex flex-row gap-3 hover:bg-gray-100 w-full  p-3">
                                            <div>
                                                <Icon color="charcoal" icon={<FontAwesomeIcon icon={faFolderPlus} />} />
                                            </div>
                                            <div>
                                                Create subfolder
                                            </div>
                                        </div>
                                        
                                    </>
                                }
                                {item.content_type === "note" &&
                                    <>
                     
                                            <div onClick={(e)=>navigateToStudyNote(e)} const className="flex flex-row gap-3 hover:bg-gray-100 w-full  p-3">
                                                <div>
                                                    <Icon color="persian-green" icon={<FontAwesomeIcon icon={faPlay} />} />
                                                </div>
                                                <div>
                                                    Study note
                                                </div>
                                            </div>
                                            <div onClick={(e)=>navigateToEditStudyNote(e)} const className="flex flex-row gap-3 hover:bg-gray-100 w-full  p-3">
                                                <div>
                                                    <Icon color="charcoal" icon={<FontAwesomeIcon icon={faPen} />} />
                                                </div>
                                                <div>
                                                    Edit note
                                                </div>
                                            </div>
                                        
                                    </>
                                }
                                {item.content_type === "deck" &&
                                    <>
                                        <div onClick={(e)=>navigateToPlayDeck(e)} const className="flex flex-row gap-3 hover:bg-gray-100 w-full  p-3">
                                            <div>
                                                <Icon color="persian-green" icon={<FontAwesomeIcon icon={faPlay} />} />
                                            </div>
                                            <div>
                                                Play deck
                                            </div>
                                        </div>
                                        <div onClick={(e)=>navigateToEditDeck(e)} const className="flex flex-row gap-3 hover:bg-gray-100 w-full  p-3">
                                            <div>
                                                <Icon color="charcoal" icon={<FontAwesomeIcon icon={faPen} />} />
                                            </div>
                                            <div>
                                                Edit deck
                                            </div>
                                        </div>
                                    
                                    </>
                                }
                                {item.content_type === "table" &&
                                    <>
                     
                                            <div onClick={(e)=>navigateToPlayTable(e)} const className="flex flex-row gap-3 hover:bg-gray-100 w-full  p-3">
                                                <div>
                                                    <Icon color="persian-green" icon={<FontAwesomeIcon icon={faPlay} />} />
                                                </div>
                                                <div>
                                                    Play table
                                                </div>
                                            </div>
                                            <div onClick={(e)=>navigateToEditTable(e)} const className="flex flex-row gap-3 hover:bg-gray-100 w-full  p-3">
                                                <div>
                                                    <Icon color="charcoal" icon={<FontAwesomeIcon icon={faPen} />} />
                                                </div>
                                                <div>
                                                    Edit table
                                                </div>
                                            </div>
                                    </>
                                }
                                
                                <div onClick={renameItemClickHelper}  className="flex flex-row gap-3 hover:bg-gray-100 w-full   p-3">
                                    <div>
                                        <Icon color="charcoal" icon={<FontAwesomeIcon icon={faPen} />} />
                                    </div>
                                    <div>
                                        Rename
                                    </div>
                                </div>
                                <div onClick={changeFolderOptionMenuClick}  className="flex flex-row gap-3 hover:bg-gray-100 w-full   p-3">
                                    <div>
                                        <Icon color="charcoal" icon={<FontAwesomeIcon icon={faUpDownLeftRight} />} />
                                    </div>
                                    <div>
                                        Move {item.content_type}
                                    </div>
                                </div>
                                <div onClick={deleteItemClickHelper}  className="flex flex-row gap-3 hover:bg-gray-100 w-full   p-3">
                                    <div>
                                        <Icon color="red" icon={<FontAwesomeIcon icon={faTrash} />} />
                                    </div>
                                    <div>
                                        Delete
                                    </div>
                                </div>
                                
                            </motion.div>
                        </motion.div>
                    </>
                    :
                    <>
                        <div onClick={menuClick} className="absolute px-3 right-0 text-gray-600">
                            <FontAwesomeIcon icon={faEllipsisV} />
                        </div>
                    </>
                }
            </AnimatePresence>
        </>
    )
}

const MenuItem = ({ parentFolder,  renameItemClick, deleteItemClick, openCreateSubItemModal, closeCreateItemModal, openCreateSubFolderModal, openCreateFolderModal, setMoveItemCurrentParent, draggableSnapshot,  changeItemFolderClick, setShowOptionMenuDraggableId, showOptionMenuDraggableId, draggingId, showItem, item, provided, isDraggable, closeMenu, index, toggleItem, toggleItems, folderChild, level, lastChildDroppableId }) => {
    //openCreateSubFolderModal={openCreateSubFolderModal} openCreateFolderModal={openCreateFolderModal}
    let draggablePropsObject = {};
    if (provided !== undefined) {
        draggablePropsObject = {
            ref: provided.innerRef,
            ...provided.dragHandleProps,
            ...provided.draggableProps
        };
    }
    //<MenuItem lastChildDroppableId={lastChildDroppableId === undefined ? `folder_${item.doc_id}`: lastChildDroppableId + '-'+item.doc_id} level={level + 1} key={"menu_item_" + index} draggableSnapshot={snapshot_drag} showItem={showItem} isDraggable={isDraggable} provided={provided_drag} closeMenu={closeMenu} folderChild={true} item={child} index={child_index} toggleItem={toggleItem} toggleItems={toggleItems} />
    const newDroppableId = lastChildDroppableId === undefined ? `folder_${item.doc_id}`: lastChildDroppableId + '-'+item.doc_id;
    const navigate = useNavigate(); 
    
    const linkClick = (link) => {
        closeMenu();
        navigate(link);
    }

    

    return (
        <>
            <div ref={provided !== undefined && provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={(draggableSnapshot.isDragging ? 'rounded-lg shadow-xl' : '') + (draggingId === provided.draggableProps['data-rbd-draggable-id'] ? 'h-18 overflow-auto ' : '') + " whitespace-nowrap   p-3 rounded-xl w-full text-base muted-text"} key={"menu_item_" + index}>
                {item.type === "function" &&
                    <div onClick={(e) => item.function(e, item)} className={('size' in item ? (item['size'] === "sm" ? ' ' : "") : ' ') + " hover:bg-gray-50 p-3  w-full shadow-xl rounded-lg cursor-pointer flex flex-row justify-start gap-6 place-items-center"}>
                        {item.showIcon !== false &&
                            <>
                                <div className={item.color}>
                                    <FontAwesomeIcon icon={item.icon} />
                                </div>
                            </>
                        }
                        <div className=" overflow-x-auto whitespace-normal">
                            {item.text}
                        </div>
                    </div>
                }
                {item.type === "link" &&
                    <div onClick={() => linkClick(item.link)}>
                            <div className="group p-3  w-full  relative  cursor-pointer ">
                                <div className="flex flex-row flex-between">
                                    <div>
                                        <Link className="no-underline" to={item.link}>
                                            <div className="flex flex-row  justify-start gap-2 place-items-center">
                                                {item.showIcon !== false &&
                                                    <>
                                                        <div className={item.color}>
                                                            <FontAwesomeIcon icon={item.icon} />
                                                        </div>
                                                    </>
                                                }
                                                <div className=" group-hover:underline overflow-x-auto">
                                                    {item.text}
                                                </div>
                                                {(item.hasOwnProperty('temp_adding_items') && item['temp_adding_items'] === true) ?
                                                    <>
                                                        <div className="flex ml-0 sm:ml-4 opacity-80 text-gray-600 flex-row gap-1 text-xs justify-start place-items-center">
                                                            <div>
                                                                <FontAwesomeIcon icon={faLoader} className="fa-spin" />
                                                            </div>
                                                            {item.hasOwnProperty('temp_number_of_items_to_add') &&
                                                            <div className="invisible sm:visible italic">
                                                                {item['number_of_items']} (adding {item['temp_number_of_items_to_add']} items)
                                                            </div>
                                                            }
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        {item.hasOwnProperty('number_of_items') &&
                                                            <div className="flex ml-2  text-gray-600 flex-row gap-1 text-xs opacity-80 justify-start place-items-center">
                                                                <div className="">
                                                                    ({item['number_of_items']}) 
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </Link>
                                    </div>
                                    <div>
                                        <LibraryItemMenu renameItemClick={renameItemClick} deleteItemClick={deleteItemClick} closeMenu={closeMenu} closeCreateItemModal={closeCreateItemModal} openCreateSubItemModal={openCreateSubItemModal} openCreateSubFolderModal={openCreateSubFolderModal} openCreateFolderModal={openCreateFolderModal} parentFolder={parentFolder} setMoveItemCurrentParent={setMoveItemCurrentParent} changeItemFolderClick={changeItemFolderClick} item={item} showingLibraryItemMenuID={showOptionMenuDraggableId} setShowingLibraryItemMenuID={setShowOptionMenuDraggableId} libraryItemMenuID={provided.draggableProps['data-rbd-draggable-id']} className="" />
                                    </div>
                                </div>
                            </div>
                        
                    </div>
                }
                {item.type === "folder" &&
                    <div className="flex flex-col h-full gap-2">
                        <div onClick={() => toggleItem(item.doc_id)} className="p-3  group  relative cursor-pointer flex flex-row justify-start gap-2 place-items-center">
                            <LibraryItemMenu renameItemClick={renameItemClick} deleteItemClick={deleteItemClick} closeMenu={closeMenu} closeCreateItemModal={closeCreateItemModal} openCreateSubItemModal={openCreateSubItemModal} openCreateSubFolderModal={openCreateSubFolderModal} openCreateFolderModal={openCreateFolderModal} parentFolder={parentFolder} setMoveItemCurrentParent={setMoveItemCurrentParent} changeItemFolderClick={changeItemFolderClick} item={item} showingLibraryItemMenuID={showOptionMenuDraggableId} setShowingLibraryItemMenuID={setShowOptionMenuDraggableId} libraryItemMenuID={provided.draggableProps['data-rbd-draggable-id']} className="" />
                            <div className="flex flex-row gap-3">

                                <div className="text-sandy-brown">
                                    <FontAwesomeIcon icon={faFolder} />
                                </div>
                                <div className="text-gray-400 ">
                                    <FontAwesomeIcon icon={toggleItems[item.doc_id] ? faCaretDown : faCaretRight} />
                                </div>
                            </div>

                            {item.showIcon === true &&
                                <>
                                    <div className={item.color}>
                                        <FontAwesomeIcon icon={item.icon} />
                                    </div>
                                </>
                            }
                            <div className="group-hover:underline overflow-x-auto whitespace-normal">
                                {item.text} 
                            </div>
                        </div>
                        <AnimatePresence>
                        {(toggleItems[item.doc_id]) &&
                            <>
                                <motion.div
                                     variants={{
                                        initial: {
                                            height: 0,
                                        },
                                        animate: {
                                            height: 'auto',
                                            transition: {
                                                when: 'beforeChildren',
                                                duration: 0.10
                                            },
                                        },
                                        exit: {
                                            height: 0,
                                            transition: {
                                                when: 'afterChildren',
                                            },
                                        },
                                    }}
                                    initial="initial"
                                    animate="animate"
                                    exit="exit"
                                    
                                >
                                    <motion.div
                                    variants={{
                                        initial: {
                                            opacity: 0,
                                        },
                                        animate: {
                                            opacity: 1,
                                            duration: 0.10
                                        },
                                        exit: {
                                            opacity: 0,
                                        }
                                    }}>
                                    <div className={"ml-4 px-2"}>
                                        {item.children.map((child, child_index) => (
                                            <>
                                                {child.save_to_db === false &&
                                                    <>
                                                        {child.type === "function" &&
                                                            <div key={"button_"+child_index+"_"+newDroppableId} onClick={(e) => child.function(e, child)} className={('size' in child ? (child['size'] === "sm" ? 'text-sm ' : "") : 'text-base ') + " hover:bg-gray-50 p-3 rounded-lg cursor-pointer flex flex-row justify-start gap-6 place-items-center"}>
                                                                {child.showIcon !== false &&
                                                                    <>
                                                                        <div className={child.color + ' opacity-80'}>
                                                                            <FontAwesomeIcon icon={child.icon} />
                                                                        </div>
                                                                    </>
                                                                }
                                                                <div className="text-xs text-gray-600 ">
                                                                    {child.text}
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                }
                                            </>
                                        ))}
                                    </div>
                                    <Droppable droppableId={newDroppableId} type={newDroppableId}>
                                        {(provided_drop, snapshot_drop) => (
                                            <div className={(snapshot_drop.isDraggingOver ? 'bg-gray-50 shadow-lg ' : '') + ' rounded-lg flex flex-col gap-2  pl-2 ml-4'} ref={provided_drop.innerRef} {...provided_drop.droppableProps}>
                                                {item.children.map((child, child_index) => (
                                                    <>
                                                        {child.save_to_db !== false &&
                                                            <Draggable isDragDisabled={isDraggable ? false : true} key={child.doc_id} draggableId={child.doc_id} index={child_index}>
                                                                {(provided_drag, snapshot_drag) => (
                                                                    <MenuItem renameItemClick={renameItemClick} deleteItemClick={deleteItemClick} closeCreateItemModal={closeCreateItemModal} openCreateSubItemModal={openCreateSubItemModal} openCreateSubFolderModal={openCreateSubFolderModal} openCreateFolderModal={openCreateFolderModal} parentFolder={item} setMoveItemCurrentParent={setMoveItemCurrentParent} changeItemFolderClick={changeItemFolderClick} setShowOptionMenuDraggableId={setShowOptionMenuDraggableId} showOptionMenuDraggableId={showOptionMenuDraggableId} draggingId={draggingId} className={"ml-0"}  lastChildDroppableId={lastChildDroppableId === undefined ? `folder_${item.doc_id}`: lastChildDroppableId + '-'+item.doc_id} level={level + 1} key={"menu_item_" + index} draggableSnapshot={snapshot_drag} showItem={showItem} isDraggable={isDraggable} provided={provided_drag} closeMenu={closeMenu} folderChild={true} item={child} index={child_index} toggleItem={toggleItem} toggleItems={toggleItems} />
                                                                )}
                                                            </Draggable>
                                                        }
                                                    </>
                                                ))}
                                                {provided_drop.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                    </motion.div>
                                </motion.div>
                            </>
                        }
                        </AnimatePresence>
                    </div>
                }
            </div>
        </>
    )
}

const GeneralSidebarMenu = ({ openCreateSubItemModal, parentFolder, closeMenu, renameItemClick, deleteItemClick, filterContentList, outputDesign, closeCreateItemModal, openCreateSubFolderModal, openCreateFolderModal, setMoveItemCurrentParent, changeItemFolderClick, moveItemModalItem, setMoveItemModalItem, showMoveItemModal, setShowMoveItemModal, title, onDragEndFunction, menuItems, setMenuItems, showMenu, setShowMenu }) => {
    let startToggle = {};
    menuItems.forEach((item, index) => {
        startToggle[item.doc_id] = false;
    });

    const [toggleItems, setToggleItems] = useState(startToggle);
    const [folderLevel, setFolderLevel] = useState(2);

    const [showCreateItemAssistantMenu, setShowCreateItemAssistantMenu] = useState(false);
    const [showOptionMenuDraggableId, setShowOptionMenuDraggableId] = useState(null);

    const toggleItem = (doc_id) => {
        if (doc_id === undefined) { return null }
        let copy = JSON.parse(JSON.stringify(toggleItems));
        let current = copy[doc_id];
        let n = null;
        if (current) { n = false }
        else { n = true }
        copy[doc_id] = n;
        setToggleItems(copy);
    }
    



    const createItemAssistantButtonClick =  () => {
        setShowCreateItemAssistantMenu(!showCreateItemAssistantMenu);
    }

    const closeToggleItem = (doc_id) => {
        if (doc_id === undefined) { return null }
        let copy = JSON.parse(JSON.stringify(toggleItems));
        copy[doc_id] = false;
        setToggleItems(copy);
    }

    const showItem = (index) => {
        let copy = JSON.parse(JSON.stringify(toggleItems));
        let current = copy[index];
        copy[index] = true;
        setToggleItems(copy);
    }

    const menuClick = () => {
        setShowMenu(!showMenu);
    }

    const backdropClick = (e) => {
        e.stopPropagation();
        //setShowMenu(!showMenu);
        setShowOptionMenuDraggableId(null);
    }



    /* 
                    if (item.doc_id === folder_doc_id && item.type === "folder") {
                        let copy = item.children;
                        console.log("Copy: ", copy, item)
                        let start_index = source.index;
                        let element = copy[start_index];
                        let end_index = destination.index;
                        copy.splice(start_index, 1);
                        copy.splice(end_index, 0, element);
                        copyMenuItems[index]['children'] = copy;
                        setMenuItems(copyMenuItems);
                        onDragEndFunction(copyMenuItems.filter((item) => item.save_to_db !== false));
                    }
                    else {
    */

    const onDragEnd = (result) => {
        setDraggingId(null);
        if (!result.destination) return;
        if (!result.source) return;
        const { destination, source } = result;
        if (source.droppableId !== destination.droppableId) {
            console.log("New droppable")
        } else {
            /* const copy = menuItems.map(a => ({...a}));
            let element = copy[source.index];
            let start_index = source.index;
            let end_index = destination.index;
            copy.splice(start_index, 1);
            copy.splice(end_index, 0, element);
            setMenuItems(copy);
            onDragEndFunction(copy.filter((item)=>item.save_to_db !== false)); */
            if (destination.droppableId.split("folder_").length === 2) {
                let folder_doc_ids = destination.droppableId.split("_")[1].split("-");
                //let folder_doc_id = destination.droppableId.split("-")[1];
                //let level = destination.droppableId.split("_")[1].split("-")[0];
                //console.log("level: ", level);
                const copyMenuItems = menuItems.map(a => ({ ...a }));
                //console.log(level, folder_doc_id);
                let found_folder_ids = [];
                const location_indexes = [];
                let folder_index = 0;
                menuItems.forEach((top_item, top_index) => {
                    if (folder_doc_ids.length > 0){
                        const doCheck = (child_item, folder_index) => {
                            if (child_item.doc_id === folder_doc_ids[folder_index]){
                                return true;
                            } else {
                                return false;
                            }
                        }

                        const goDeeper = (item, item_index) => {
                            let item_found = doCheck(item, folder_index);
                            
                            if (item_found){
                                location_indexes.push(item_index);
                                found_folder_ids.push(item.doc_id);
                                folder_index = folder_index + 1;
                            
                                if (location_indexes.length < folder_doc_ids.length){
                                    'children' in item && item.children.forEach((f_child, f_child_index)=>{
                                        console.log("Child: ", f_child);
                                        //let child_found = doCheck(f_child, folder_index);
                                        //console.log(child_found, item, folder_doc_ids[folder_index], folder_index);
/*                                          if (child_found){
                                            location_indexes.push(f_child_index);
                                            found_folder_ids.push(f_child.doc_id);
                                            folder_index = folder_index + 1;
                                            if (location_indexes.length < folder_doc_ids.length){ */
                                        goDeeper(f_child, f_child_index);    
                                    });
                                }
                            }
                        }

                        if (location_indexes.length < folder_doc_ids.length){
                            goDeeper(top_item, top_index);
                        }
                    }
                    
                })
                console.log(copyMenuItems);
                console.log("Location indexes and folder ids and total: ", location_indexes, found_folder_ids, folder_doc_ids);

                
                let list_change = menuItems.map(a => ({...a}));
                location_indexes.forEach(location_index=>{
                    list_change = list_change[location_index]['children'];
                });
                console.log("List: ", list_change);
                let element = list_change[source.index];
                let start_index = source.index;
                let end_index = destination.index;
                list_change.splice(start_index, 1);
                list_change.splice(end_index, 0, element);
                console.log("New list: ", list_change);
                let copy = menuItems.map(a => ({...a}));
                let all_indices = [];
                location_indexes.forEach(location_index=>{
                    all_indices.push(location_index);
                    all_indices.push("children");
                });
                let curObj = copy;
                let parentObj = copy;
                for (let i of all_indices) {
                    parentObj = curObj;
                    curObj = curObj && curObj[i];
                }
                parentObj[all_indices[all_indices.length - 1]]  = list_change;
                console.log("New object: ", copy);
                setMenuItems(copy);
                onDragEndFunction(list_change.filter((list_change)=>list_change.save_to_db !== false), folder_doc_ids[folder_doc_ids.length -1]);
            } else {
                // top level, 1
                const copy = menuItems.map(a => ({ ...a }));
                let element = copy[source.index];
                let start_index = source.index;
                let end_index = destination.index;
                copy.splice(start_index, 1);
                copy.splice(end_index, 0, element);
                setMenuItems(copy);
                onDragEndFunction(copy.filter((item) => item.save_to_db !== false));
            }
        }
    }

    const onDragStart = (event) => {
        closeToggleItem(event.draggableId);
    }

    const [draggingId, setDraggingId] = useState(null);
    const [isDraggable, setIsDraggable] = useState(false);

    const onBeforeCapture  = (beforeCapture) => {
        setDraggingId(beforeCapture.draggableId);
    }   

    

    //absolute left-6 top-4 sm:left-8 sm:top-8
    /* @
    <div className="sm:hidden absolute w-full mx-auto top-6 text-base flex flex-row justify-center place-items-center gap-2">
                                <img src={YalangoHead} alt="Yalango's mascot Tim's head" className="w-6 -mt-1" />
                                <img src={YalangoTitle} alt="Yalango logo title" className="w-24" />
                            </div>
    */
   useEffect(()=>{  
        if (filterContentList !== undefined && filterContentList !== null){
            setIsDraggable(false);
        } else {
            setIsDraggable(true);
        }
    },[filterContentList]);

    console.log(menuItems);
    /* 
    <div onClick={backdropClick} className={"z-0  w-screen h-screen fixed left-0 top-0 opacity-0"}>
            </div>

            <div className={" text-sm flex flex-col gap-2"}>
                                                            {menuItems.map((child, child_index) => (
                                                                <>
                                                                    {child.save_to_db === false &&
                                                                        <>
                                                                            {child.type === "function" &&
                                                                                <div key={"button_"+child_index} onClick={(e) => child.function(e, child)} className={(child.hasOwnProperty("row_margin") ? child.row_margin + " " : "") + ('size' in child ? (child['size']) : 'text-base ') + " " +  (('background_color' in child ? (child['background_color'] + " " +  ('color' in child ? (child['color'] + " ") : " text-white ")) : " text-muted ") + ("text_decoration" in child ? " " + child['text_decoration'] + " " : "") +  "  p-2 muted-text  cursor-pointer group flex flex-row justify-start gap-6 place-items-center")}>
                                                                                    {child.showIcon !== false &&
                                                                                        <>
                                                                                            <div className={child.color + " opacity-80"}>
                                                                                                <FontAwesomeIcon icon={child.icon} />
                                                                                            </div>
                                                                                        </>
                                                                                    }
                                                                                    <div className="group-hover:underline ">
                                                                                        {child.text}
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </>
                                                                    }
                                                                </>
                                                            ))}
                                                        </div>

                                                        <div className="flex flex-row justify-center">
                                                            <div className="relative">
                                                                <Button onClick={createItemAssistantButtonClick}  text="Upload" icon={faPlus} color="charcoal" />
                                                                <AnimatePresence>
                                                                {showCreateItemAssistantMenu &&
                                                                    <>
                                                                        <motion.div 
                                                                            variants={{
                                                                                initial: {
                                                                                    height: 0,
                                                                                },
                                                                                animate: {
                                                                                    height: 'auto',
                                                                                    transition: {
                                                                                        when: 'beforeChildren',
                                                                                        duration: 0.10
                                                                                    },
                                                                                },
                                                                                exit: {
                                                                                    height: 0,
                                                                                    transition: {
                                                                                        when: 'afterChildren',
                                                                                    },
                                                                                },
                                                                            }}
                                                                            initial="initial"
                                                                            animate="animate"
                                                                            exit="exit"
                                                                        className="absolute overflow-visible text-sm  z-20 rounded-lg shadow-xl bg-white w-full left-0 top-13"
                                                                        >
                                                                            <div>
                                                                                    {menuItems.map((child, child_index) => (
                                                                                        <>
                                                                                            {child.save_to_db === false &&
                                                                                                <>
                                                                                                    {child.type === "function" && child.text === "Deck" &&
                                                                                                        <>
                                                                                                        <motion.div 
                                                                                                            key={"button_"+child_index}
                                                                                                            onClick={(e) => runCreateItemButtonFunction(e, child, "create")}
                                                                                                            variants={{
                                                                                                                    initial: {
                                                                                                                        opacity: 0,
                                                                                                                    },
                                                                                                                    animate: {
                                                                                                                        opacity: 1,
                                                                                                                        duration: 0.10
                                                                                                                    },
                                                                                                                    exit: {
                                                                                                                        opacity: 0,
                                                                                                                    }
                                                                                                                }}
                                                                                                            className="flex font-bold text-gray-700 flex-col justify-start place-items-start">
                                                                                                                    <div className="flex cursor-pointer uppercase flex-row rounded-lg  gap-4 hover:bg-gray-100 w-full p-4">
                                                                                                                        <div className={child.color}>
                                                                                                                            <Icon icon={<FontAwesomeIcon icon={child.icon} />} />
                                                                                                                        </div>
                                                                                                                        <div>
                                                                                                                            {child.text}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                        </motion.div>
                                                                                                        </>
                                                                                                    }
                                                                                                </>
                                                                                            }
                                                                                        </>
                                                                                    ))}
                                                                            
                                                                        </div>
                                                                        </motion.div>
                                                                        <div onClick={()=>setShowCreateItemAssistantMenu(false)} className="fixed  left-0 top-0 w-screen h-screen z-10">

                                                                        </div>
                                                                    </>
                                                                }
                                                                </AnimatePresence>
                                                            </div>
                                                        </div>
    */
   console.log( menuItems.length)
    return (
        <>
            {(outputDesign === "page" && showOptionMenuDraggableId !== null) &&
                <>
                    <div onClick={backdropClick} className={"z-10  w-screen h-screen fixed left-0 top-0 opacity-60 bg-gray-200"}>
                    </div>
                </>
            }
            
            <div className="">
                <div className={outputDesign !== 'page' ? "z-20 bg-white rounded-xl sm:bg-transparent shadow-md sm:shadow-none lg:w-24 flex flex-col gap-6  left-0 top-0 fixed h-16" : ''}>
                    {outputDesign !== "page" &&
                        <>
                            {!showMenu &&
                                <div onClick={menuClick} className="relative  cursor-pointer">
                                    <div className=" text-xl text-charcoal p-4 sm:p-8">
                                        <FontAwesomeIcon icon={faBars} />
                                    </div>
                                    
                                </div>
                            }
                        </>
                    }


                    <div className={(outputDesign === "page" ? 'w-full ' : 'fixed z-10 top-0  left-0 bg-white   shadow-2xl ') + ((showMenu && outputDesign !== "page") ? ' lg:min-w-96 lg:w-1/3 w-screen ' : 'w-0 ') + " overflow-visible  transition-width ease-in-out text-black"}>
                        {(showMenu && title !== undefined) && <><div className="text-gray-600 text-lg p-4 font-bold">{title}</div></>}
                        {(showMenu && outputDesign !== "page") &&
                            <>
                                <div className="absolute  right-10 top-1 cursor-pointer  sm:px-2 py-3" onClick={closeMenu}>
                                    <FontAwesomeIcon icon={faXmark} />
                                </div>
                            </>
                        }
                        <div className="">
                            {(menuItems !== undefined && menuItems !== null) &&
                                <>
                                    <div className={(outputDesign !== "page" ? ' ' : '')+ " text-lg   w-full"}>
                                                <div className=" flex flex-col gap-6  w-full">
                                                    
                                                    <DragDropContext onBeforeCapture={onBeforeCapture} onDragEnd={onDragEnd} onDragStart={onDragStart}>
                                                        <Droppable className="" type="droppable" droppableId="droppable">
                                                            {(provided, snapshot) => (
                                                                <div className={(snapshot.isDraggingOver ? 'bg-gray-50 shadow-lg ' : '') + ' rounded-lg w-full flex flex-col gap-2 '} ref={provided.innerRef} {...provided.droppableProps}>
                                                                    {menuItems.map((item, index) => (
                                                                        <>
                                                                            {item.save_to_db !== false &&
                                                                                <>
                                                                                    <Draggable isDragDisabled={isDraggable ? false : true} key={item.doc_id} draggableId={'doc_id' in item ? item.doc_id : "id"} index={index}>
                                                                                        {(draggableProvided, draggableSnapshot) => (
                                                                                            <>
                                                                                                <MenuItem renameItemClick={renameItemClick} deleteItemClick={deleteItemClick} openCreateSubItemModal={openCreateSubItemModal} openCreateSubFolderModal={openCreateSubFolderModal} openCreateFolderModal={openCreateFolderModal} parentFolder={parentFolder} setMoveItemCurrentParent={setMoveItemCurrentParent} changeItemFolderClick={changeItemFolderClick} showOptionMenuDraggableId={showOptionMenuDraggableId} setShowOptionMenuDraggableId={setShowOptionMenuDraggableId} draggingId={draggingId} level={folderLevel} showItem={showItem} draggableSnapshot={draggableSnapshot} isDraggable={isDraggable} provided={draggableProvided} closeMenu={closeMenu} item={item} index={index} toggleItem={toggleItem} toggleItems={toggleItems} />
                                                                                            </>
                                                                                        )}
                                                                                    </Draggable>
                                                                                </>
                                                                            }

                                                                        </>
                                                                    ))}
                                                                    {provided.placeholder}
                                                                </div>
                                                            )}
                                                        </Droppable>
                                                    </DragDropContext>
                                                </div>
                                                {showMenu &&
                                                    <>
                                                <div className="h-2/6 overflow-x-hidden px-4 pb-4 flex flex-col justify-center gap-2 w-full">
                                                    <div onClick={() => closeMenu()}>
                                                        <Link className="no-underline" to={"/dashboard"}>
                                                            <div className="hover:bg-gray-50 p-2 w-full rounded-lg cursor-pointer flex flex-row justify-start gap-6 place-items-center">

                                                                <div className={"text-persian-green"}>
                                                                    <FontAwesomeIcon icon={faHome} />
                                                                </div>

                                                                <div>
                                                                    Dashboard
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div onClick={() => closeMenu()}>
                                                        <Link className="no-underline" to={"/decks"}>
                                                            <div className="hover:bg-gray-50 p-2 w-full rounded-lg cursor-pointer flex flex-row justify-start gap-6 place-items-center">

                                                                <div className={"text-burnt-sienna"}>
                                                                    <FontAwesomeIcon icon={faCardsBlank} />
                                                                </div>

                                                                <div>
                                                                    Public decks
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div onClick={() => closeMenu()}>
                                                        <Link className="no-underline" to={"/settings"}>
                                                            <div className="hover:bg-gray-50 p-2 w-full rounded-lg cursor-pointer flex flex-row justify-start gap-6 place-items-center">

                                                                <div className={"text-charcoal"}>
                                                                    <FontAwesomeIcon icon={faCog} />
                                                                </div>

                                                                <div>
                                                                    Settings
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    
                                                </div>
                                                </>
                                                    }
                                    </div>
                                </>
                            }
                            {menuItems !== undefined && menuItems !== null && menuItems.length === 3 &&
                                <>
                                    <div className="text-center my-8 w-full  muted-text flex flex-col gap-4">
                                        <div className="font-bold">
                                            Your library is currently empty
                                        </div>
                                        Get started by adding an item from the menu above.
                                    </div>
                                </>
                            }       
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GeneralSidebarMenu;