import {React} from 'react';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTableRows} from '@fortawesome/pro-solid-svg-icons';



const NoteCard = (props) => {
    const {item} = props;
    console.log(item)
    const cardClass = "size-64 p-3 bg-white shadow-lg rounded-xl cursor-pointer";
    return (
        <div
            className={`${cardClass} relative text-center flex flex-row justify-between bg-white dark:bg-gray-800 shadow-md hover:shadow-lg rounded-lg transition-all duration-300`}
        >
            <Link className="flex-grow no-underline h-full" to={item.link}>
                <div className="flex flex-col relative justify-center h-full">
                    <div className="flex-grow flex flex-col gap-3 justify-center place-items-center">
                        {/* Item Text */}
                        <div className="text-lg font-semibold text-gray-600 dark:text-gray-300">
                            {item.text}
                        </div>

                        {/* Note Label */}
                        <div className="absolute font-semibold italic left-0 top-0 p-2 text-xs text-gray-600 dark:text-gray-400">
                            Note
                        </div>

                        {/* Item Description */}
                        <div className="text-sm italic text-gray-600 dark:text-gray-400">
                            {item.original_object?.description}
                        </div>
                    </div>
                </div>
            </Link>
        </div>

    )
}

export default NoteCard;