import React, {useState, useEffect} from 'react';
import Modal from '../../general/Modal';
import Button from '../../general/Button';
import useFeedback from '../../../hooks/useFeedback';
import {Link} from 'react-router-dom';
import {useAuth} from "../../../contexts/AuthContext";

const ModalContent = ({closeFunction}) => {

    let {addFeedbackToDatabase} = useFeedback();
    const {currentUser} = useAuth();
    const saveFeedback = async () => {
        if (text.trim() === ""){
            setError(true);
            setErrorMsg("Your message cannot be blank. Please write a message before submitting your feedback.");
            return;
        }
        if (text.length <= maxAllowed){
            let success = await addFeedbackToDatabase(text.trim());
            if (success){
                setShowThankYou(true);
            }
        } 
    }

    const [text, setText] = useState("");
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const maxAllowed = 2000;
    const [showThankYou, setShowThankYou] = useState(false);

    const onUserInput = (e) => {
        setText(e.target.value);
    }

    useEffect(()=>{
        if (text.length > maxAllowed) {
            setError(true);
            setErrorMsg("Your message is over 2000 characters long. Please try to shorten it before sending it.");
        } 
        else if (text.length < maxAllowed && text.trim() !== ""){
            if (error === true){
                setError(false);
                setErrorMsg(null);
            }
        }
    }, [text, error]);

    return(
        <>
            
            <div className="text-center">
                {!showThankYou ?
                    <>
                        <h1>Send feedback to us</h1>
                        <div className="text-sm text-center bg-persian-green text-white p-4 rounded-3xl shadow-xl">
                            We greatly appreciate all bug reports, product ideas, feature requests, or general feedback messages.
                        </div>
                        <div className="pt-4">
                            <textarea autoFocus={true} value={text} onChange={onUserInput} className={"w-full focus:outline-none min-h-48 rounded-lg shadow-xl p-3 border-solid  border-2 "+(error ? 'border-red-500' : 'focus:border-persian-green')} />
                            <span className={(error && text.trim() !== "") && "text-red-500"}>{text.length}/{maxAllowed}</span>
                        </div>
                        {error && errorMsg !== null &&
                            <>
                                <div className="pt-2 pb-3 font-bold text-gray-700">
                                    {errorMsg}
                                </div>
                            </>
                        }
                        <div className="pt-3 flex flex-row justify-center">
                            <Button disabledDesign={error ? true : false} disabled={error ? true : false} color={error ? 'bg-gray-500' : 'green'} onClick={saveFeedback} text="Send feedback" />
                        </div>
                    </>
                :
                    <>
                        <div className="m-auto w-5/6 p-4 text-center">
                            <div>
                                Thank you very much for making Yalango better.  
                                {currentUser !== null ?
                                    <>
                                        <div>
                                         We will carefully assess your feedback, and update you about our progress in the <Link onClick={closeFunction} to="/my-feedback">My Feedback</Link> center.
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div>
                                         We will carefully assess your feedback.
                                        </div>
                                    </>
                                }
                            </div>
                            <div className="pt-4 flex flex-row justify-center">
                                <Button onClick={closeFunction} text="Close window" color="green" />
                            </div>
                        </div>
                    </> 
                }
            </div>
        </>
    )
}

const GeneralFeedback = ({closeFunction}) => {
    return (
        <>
            <Modal closeFunction={closeFunction} content={<ModalContent closeFunction={closeFunction} />} size="3xl" />
        </>
    )
}



export default GeneralFeedback;
