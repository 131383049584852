import React, {useState, useEffect} from 'react';
import Button from '../../general/Button';
import {useNavigate} from 'react-router-dom';
import Modal from '../../general/Modal';
import Select from 'react-select';
import {useLanguagesContext} from '../../../contexts/LanguagesContext';
import useDecks from '../../../hooks/useDecks';
import useLanguageSpecificInfo from '../../../hooks/useLanguageSpecificInfo';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLoader} from '@fortawesome/pro-solid-svg-icons';

export const AddDeckModalContent = ({closeFunction, setTempSelectedDeck, setAddNewDeckLoading, createItemMode, newItemParentFolderDocId, hideNavigation}) => {
    console.log(hideNavigation);
    let {allLanguages, userLanguages, activeUserLanguages, globalSelectedTargetLanguage, setGlobalSelectedTargetLanguage, globalSelectedSourceLanguage, setGlobalSelectedSourceLanguage, targetLanguageOptions, sourceLanguageOptions} = useLanguagesContext();
    let {handleAddDeckForCurrentUserWithoutCategory} = useDecks();
    const {chineseScriptsOptions} = useLanguageSpecificInfo();

    const navigate = useNavigate();
    const [targetLanguage, setTargetLanguage] = useState(globalSelectedTargetLanguage);
    const [sourceLanguage, setSourceLanguage] = useState(globalSelectedSourceLanguage);
    const [targetScript, setTargetScript] = useState(null);
    const [sourceScript, setSourceScript] = useState(null);

    useEffect(() => {
       activeUserLanguages.forEach(language => {
        console.log(language);
        if (language.target_language === "zh" && language.hasOwnProperty("target_script")){
            console.log(language.target_script)
            setTargetScript(language.target_script);
        } 
        if (language.source_language === "zh" && language.hasOwnProperty("source_script")){
            setSourceScript(language.source_script);
        } 
    });

    }, [activeUserLanguages]);

    const [deckName, setDeckName] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [navigateAfterCreation, setNavigateAfterCreation] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [privacy, setPrivacy] = useState("private");
    const [privacyOptions, setPrivacyOptions] = useState([[{'label': 'Public', 'value': 'public'}, {'label': 'Private', 'value': 'private'}], 
    {'public': {'label': 'Public', 'value': 'public'}, 'private': {'label': 'Private', 'value': 'private'}}]);

    const deckNameChange = (e) => {
        setDeckName(e.target.value);
    }

    const deckNameKeyDown = (e) => {
        if (e.keyCode === 13) {
            saveNewDeck();
        } else {
            if (error === true){
                setError(false);
                setErrorMessage("");
            }
        }
    }

    const changeSourceLanguage = (e) => {
        setSourceLanguage(e.value);
    }

    const changeTargetScript = (option) => {
        setTargetScript(option.value)
    }

    const changeSourceScript = (option) => {
        setSourceScript(option.value)
    }

    const changePrivacy = (e) => {
        setPrivacy(e.value);
    }

    const saveNewDeck = async () => {
        if (deckName.trim() === ""){
            setErrorMessage("The name of the deck cannot be empty.");
            return setError(true);
        } 
        setAddNewDeckLoading(true);
        setLoading(true);
        let id = await handleAddDeckForCurrentUserWithoutCategory(deckName.trim(), sourceLanguage, targetLanguage, privacy, createItemMode, newItemParentFolderDocId, targetScript, sourceScript);
        //setTempSelectedDeck !== undefined && setTempSelectedDeck(id); need to set equal to deck, but then need to return  deck instead of id
        setGlobalSelectedTargetLanguage(targetLanguage);
        setGlobalSelectedSourceLanguage(sourceLanguage);
        if (typeof id === 'number' && navigateAfterCreation && !hideNavigation) {
            navigate("/my-decks/"+id);
        }
        setLoading(false);
        setAddNewDeckLoading(false);
        closeFunction();
    }

    console.log(targetScript, chineseScriptsOptions)

    return (
        <>
  <div className="p-6 ">
    <h1 className="text-2xl font-bold text-gray-700 dark:text-white">
      Create a new {allLanguages[0][targetLanguage]['name']['en']} deck
    </h1>
    {!loading ? (
      <div className="flex flex-col gap-4 mt-4">
        {/* Name of the Deck */}
        <div>
          <div className="font-bold text-gray-600 dark:text-gray-300">
            Name of the deck
          </div>
          <div className="text-center mt-2">
            <input
              value={deckName}
              onKeyDown={deckNameKeyDown}
              onChange={deckNameChange}
              type="text"
              className={`${error ? 'border-red-500 ' : ''} bg-gray-200 dark:bg-gray-700 dark:text-white border-2 border-gray-200 dark:border-gray-600 rounded w-full py-2 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-gray-800`}
            />
          </div>
          {error && (
            <div className="italic text-red-400 mt-1">{errorMessage}</div>
          )}
        </div>

        {/* Target Script (for Chinese) */}
        {targetLanguage === "zh" && (
          <div>
            <div className="font-bold text-gray-600 dark:text-gray-300">
              Target script
            </div>
            <div className="mt-2">
              <Select
                onChange={changeTargetScript}
                isSearchable={false}
                options={chineseScriptsOptions[0]}
                value={chineseScriptsOptions[1][targetScript]}
                className=""
              />
            </div>
          </div>
        )}

        {/* Source Language */}
        <div>
          <div className="font-bold text-gray-600 dark:text-gray-300">
            Source language
          </div>
          <div className="mt-2">
            <Select
              onChange={changeSourceLanguage}
              isSearchable={true}
              options={sourceLanguageOptions[0]}
              value={sourceLanguageOptions[1][sourceLanguage]}
              className=""
            />
          </div>
        </div>

        {/* Source Script (for Chinese) */}
        {sourceLanguage === "zh" && (
          <div>
            <div className="font-bold text-gray-600 dark:text-gray-300">
              Source script
            </div>
            <div className="mt-2">
              <Select
                onChange={changeSourceScript}
                isSearchable={false}
                options={chineseScriptsOptions[0]}
                value={chineseScriptsOptions[1][sourceScript]}
                className=""
              />
            </div>
          </div>
        )}

        {/* Privacy */}
        <div>
          <div className="font-bold text-gray-600 dark:text-gray-300">
            Privacy
          </div>
          <div className="mt-2">
            <Select
              onChange={changePrivacy}
              isSearchable={false}
              options={privacyOptions[0]}
              value={privacyOptions[1][privacy]}
              className=""
            />
          </div>
        </div>

        {/* Navigate After Creation */}
        {hideNavigation !== true && (
          <div className="flex flex-row gap-2 text-gray-700 dark:text-gray-300">
            <input
              type="checkbox"
              checked={navigateAfterCreation}
              onChange={() => setNavigateAfterCreation(!navigateAfterCreation)}
              className="accent-green-500 dark:accent-green-300"
            />
            <div>Navigate to new deck after creation</div>
          </div>
        )}

        {/* Buttons */}
        <div className="flex flex-row justify-center gap-3 mt-4">
          <Button
            onClick={closeFunction}
            color="charcoal"
            text="Cancel"
          />
          <Button
            onClick={saveNewDeck}
            color="green"
            text="Create deck"
          />
        </div>
      </div>
    ) : (
      <div className="text-center text-4xl text-gray-700 dark:text-white">
        <FontAwesomeIcon icon={faLoader} className="fa-spin" />
      </div>
    )}
  </div>
</>

    )
}
const AddDeckModal = ({closeFunction, setAddNewDeckLoading}) => {
  return (
    <>
        <Modal overflow="overflow-visible" closeFunction={closeFunction} content={<AddDeckModalContent setAddNewDeckLoading={setAddNewDeckLoading} closeFunction={closeFunction} />} size="3xl" />
    </>
    )
};

export default AddDeckModal;
