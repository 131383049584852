import React, {useEffect, useState, useRef} from 'react';
import Button from '../../general/Button';
import CancelButton from '../../buttons/CancelButton';
import Modal from '../../general/Modal';
import CountryFlag from '../../languages/CountryFlag';
import useDecks from '../../../hooks/useDecks';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/pro-duotone-svg-icons';
import {faLoader} from '@fortawesome/pro-solid-svg-icons'; 
import {faWandMagicSparkles} from '@fortawesome/pro-solid-svg-icons'; 
import {Link} from 'react-router-dom';
import {DebounceInput} from 'react-debounce-input';
import {faArrowDown} from '@fortawesome/pro-solid-svg-icons';
import {faArrowUp} from '@fortawesome/pro-solid-svg-icons';
import useTranslate from '../../../hooks/useTranslate';
import {motion} from 'framer-motion';
import usePinyin from '../../../hooks/usePinyin';

const ModalContent = ({targetSuggestions, sourceSuggestions, setSourceSuggestions, customFieldsInput, sourceInputRef, targetInputRef, focusTargetInputField, focusSourceInputField, setCustomFieldsInput, customFieldsOpen, setCustomFieldsOpen, customFields, translationWorking, autoTranslateClick, loading, checkIfEnter, sourceLanguage, error, errorMsg, allLanguages, deck, targetInput, sourceInput, setTargetInputFunction , setSourceInputFunction, closeModal, saveWord, saveChanges, modalMode}) => {
    const customFieldsOnChange = (e, field) => {
        let o;
        if (customFieldsInput === null){
            o = {};
        } else {
            o = JSON.parse(JSON.stringify(customFieldsInput));
        }
        o[field.data.id] = e.target.value;
        setCustomFieldsInput(o);
        setSourceSuggestions([]);
    }

    const [newCustomFieldName, setNewCustomFieldName] = useState("");
    const [newCustomFieldType, setNewCustomFieldType] = useState("text");

    const handleNewCustomFieldTypeChange = (e) => {
        setNewCustomFieldType(e.target.value);
    }

    const handleNewCustomFieldNameChange = (e) => {
        setNewCustomFieldName(e.target.value);
    }

    console.log(deck)

/*     const [tempSourceInput, setTempSourceInput] = useState(sourceInput);
    const [tempTargetInput, setTempTargetInput] = useState(targetInput); */

    return (
        <div className="p-2">
        {!loading ? (
            <>
                <div className="flex flex-col justify-center gap-3">
                    <div className="flex flex-col gap-4">
                        {/* Target Input Section */}
                        <div>
                            <div className="mb-3 text-gray-700 dark:text-gray-300 font-bold flex flex-row place-items-center gap-3">
                                <div>
                                    <CountryFlag
                                        countryCode={allLanguages[0][deck['target_ISO_639-1']]['country_flag']}
                                        size="2em"
                                        flagName={allLanguages[0][deck['target_ISO_639-1']]['name']['en']}
                                    />
                                </div>
                                <div>
                                    <span className="capitalize">
                                        {allLanguages[0][deck['target_ISO_639-1']]
                                            ? allLanguages[0][deck['target_ISO_639-1']]['name']['en']
                                            : 'Target'}
                                    </span>{' '}
                                    {deck.target_script ? `(${deck.target_script})` : ''}
                                </div>
                            </div>
                            <div>
                                <DebounceInput
                                    placeHolder="Target word"
                                    inputRef={targetInputRef}
                                    value={targetInput}
                                    onChange={setTargetInputFunction}
                                    minLength={1}
                                    autoFocus={true}
                                    debounceTimeout={200}
                                    onKeyDown={(e) => checkIfEnter(e, sourceInput, e.target.value)}
                                    type="text"
                                    className={`${
                                        error && targetInput.trim() === ''
                                            ? 'border-red-500'
                                            : 'border-gray-200 dark:border-gray-600'
                                    } bg-gray-200 dark:bg-gray-700 dark:text-white appearance-none border-2 rounded w-full py-2 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-gray-800`}
                                />
                            </div>
                        </div>
    
                        {/* Source Input Section */}
                        <div className="relative">
                            {((targetInput && !sourceInput) || (!targetInput && sourceInput)) && (
                                <>
                                    {!translationWorking ? (
                                        <div
                                            onClick={autoTranslateClick}
                                            className="absolute right-0 text-xs font-bold flex flex-row place-items-center gap-2 cursor-pointer text-sandy-brown"
                                        >
                                            <FontAwesomeIcon icon={faWandMagicSparkles} />
                                            <span className="dark:text-gray-300">Translate</span>
                                        </div>
                                    ) : (
                                        <div className="text-center text-sm absolute right-0 font-bold text-gray-700 dark:text-gray-300">
                                            <FontAwesomeIcon icon={faLoader} className="fa-spin" />
                                        </div>
                                    )}
                                </>
                            )}
                            <div className="mb-3 text-gray-700 dark:text-gray-300 font-bold flex flex-row place-items-center gap-3">
                                <div>
                                    <CountryFlag
                                        countryCode={allLanguages[0][deck['source_ISO_639-1']]['country_flag']}
                                        size="2em"
                                        flagName={allLanguages[0][deck['source_ISO_639-1']]['name']['en']}
                                    />
                                </div>
                                <div>
                                    <span className="capitalize">
                                        {allLanguages[0][sourceLanguage]
                                            ? allLanguages[0][sourceLanguage]['name']['en']
                                            : 'Source'}
                                    </span>{' '}
                                    {deck.source_script ? `(${deck.source_script})` : ''}
                                </div>
                            </div>
                            <div>
                                <DebounceInput
                                    inputRef={sourceInputRef}
                                    placeHolder="Source word"
                                    value={sourceInput}
                                    onChange={setSourceInputFunction}
                                    minLength={1}
                                    debounceTimeout={200}
                                    onKeyDown={(e) => checkIfEnter(e, e.target.value, targetInput)}
                                    type="text"
                                    className={`${
                                        error && sourceInput.trim() === ''
                                            ? 'border-red-500'
                                            : 'border-gray-200 dark:border-gray-600'
                                    } bg-gray-200 dark:bg-gray-700 dark:text-white appearance-none border-2 rounded w-full py-2 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-gray-800`}
                                />
                            </div>
                            {/* Suggestions */}
                            <div>
                                {sourceSuggestions.length > 0 && (
                                    <div className="flex flex-row gap-3 my-2">
                                        {sourceSuggestions.map((suggestion, suggestionIndex) => (
                                            <motion.div
                                                key={`suggestion_${suggestionIndex}`}
                                                onClick={() =>
                                                    setSourceInputFunction({ target: { value: suggestion } })
                                                }
                                                whileHover={{ scale: 1.02 }}
                                                className="bg-white dark:bg-gray-700 dark:text-white rounded-xl shadow-xl px-3 py-1 border-solid border-gray-300 dark:border-gray-600 border cursor-pointer"
                                            >
                                                {suggestion}
                                            </motion.div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
    
                        {/* Error Message */}
                        {error && (
                            <div className="text-red-500 text-center">{errorMsg}</div>
                        )}
                    </div>
    
                    {/* Custom Fields */}
                    <div
                        onClick={() => setCustomFieldsOpen(!customFieldsOpen)}
                        className="flex cursor-pointer flex-row gap-2 justify-center my-4 text-center font-bold text-gray-600 dark:text-gray-300"
                    >
                        <span>Custom fields</span>
                        <FontAwesomeIcon icon={customFieldsOpen ? faArrowUp : faArrowDown} />
                    </div>
                    {customFieldsOpen && (
                        <>
                            {(!customFields || customFields.length === 0) ? (
                                <div className="m-auto flex flex-col dark:text-white place-items-center">
                                    <p>
                                        You need to create a custom field in the settings to get started with
                                        this feature.
                                    </p>
                                    <Link
                                        to={`/my-decks/${deck.id}/settings`}
                                        className="no-underline"
                                    >
                                        <Button text="Create custom field" color="charcoal" />
                                    </Link>
                                </div>
                            ) : (
                                customFields.map((field, index) => (
                                    <div className="my-2" key={`c_field_${index}`}>
                                        <div className="mb-3 text-gray-700 dark:text-gray-300 font-bold">
                                            {field.data.name}{' '}
                                            {field.data.type === 'text' ? '(text)' : '(list)'}
                                        </div>
                                        <input
                                            onKeyDown={checkIfEnter}
                                            onChange={(e) =>
                                                customFieldsOnChange(e, field)
                                            }
                                            value={
                                                customFieldsInput?.[field.data.id] || ''
                                            }
                                            className="bg-gray-200 dark:bg-gray-700 dark:text-white appearance-none border-2 rounded w-full py-2 px-4 leading-tight focus:outline-none focus:bg-white dark:focus:bg-gray-800"
                                        />
                                    </div>
                                ))
                            )}
                        </>
                    )}
    
                    {/* Action Buttons */}
                    <div className="flex flex-col-reverse sm:flex-row justify-around gap-1 sm:gap-3 mt-6">
                        <CancelButton text="Cancel" onClick={closeModal} />
                        {modalMode === 'edit' ? (
                            <Button
                                key="save_changes_button"
                                text={
                                    loading ? (
                                        <FontAwesomeIcon
                                            icon={faSpinner}
                                            className="fa-pulse"
                                        />
                                    ) : (
                                        'Save changes'
                                    )
                                }
                                color="green"
                                onClick={(e) =>
                                    saveChanges(e, sourceInput, targetInput)
                                }
                            />
                        ) : (
                            <Button
                                key="save_button"
                                text={
                                    loading ? (
                                        <FontAwesomeIcon
                                            icon={faSpinner}
                                            className="fa-pulse"
                                        />
                                    ) : (
                                        'Add card'
                                    )
                                }
                                color="green"
                                onClick={(e) => saveWord(e, sourceInput, targetInput)}
                            />
                        )}
                    </div>
                </div>
            </>
        ) : (
            <div className="text-center text-4xl">
                <FontAwesomeIcon icon={faLoader} className="fa-spin" />
            </div>
        )}
    </div>
    
        
    )
}

const AddVocabularyModal = ({editCardCustomFields, changeWasFromAddingCardRef, addNewCardToList, incrementNumberOfCards, setEditCardCustomFields, setDocs, customFieldsOpen, setCustomFieldsOpen, customFields, editCardId, setEditCardId, docs, sourceLanguage, allLanguages, sourceInput, setSourceInput, targetInput, setTargetInput, deck, showModal, setShowModal, modalMode, setModalMode, deck_id}) => {
    const {addItemToDeck,
        editItemInDeck
    } = useDecks();

    const [errorMsg, setErrorMsg] = useState(null);
    const {fetchPinyin} = usePinyin();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [customFieldsInput, setCustomFieldsInput] = useState(editCardCustomFields);

    const [sourceSuggestions, setSourceSuggestions] = useState([]);
    const [targetSuggestions, setTargetSuggestions] = useState([]);
    
    useEffect(()=>{
        if (customFields !== null && editCardCustomFields !== null){
            let o = {};
            customFields.forEach(field=>{
                if (field.data.id in editCardCustomFields){
                    o[field.data.id] = editCardCustomFields[field.data.id]
                } else {
                    o[field.data.id] = "";
                }
            });
            setCustomFieldsInput(o);
        }
    },[customFields, editCardCustomFields]);

    const addItemToDeckClick = async (e, source, target) => {
        let emptyFields = checkIfEmptyFields(source, target);
        if (emptyFields) {
            return null;
        }
        else {
            if (e !== undefined){
                e.preventDefault();
            }
            setLoading(true);
            changeWasFromAddingCardRef.current = true;
            let outputFormat = "typesense";
            console.log(source, target, customFieldsInput);
            let newCard = await addItemToDeck({deck_id, sourceInput: source, targetInput: target, customFieldsInput, outputFormat});
            if (newCard !== null && newCard !== undefined){
                console.log(newCard);
                setLoading(false);
                // adding
                let l = [newCard, ...docs];
                setDocs(l);
                incrementNumberOfCards(1);
                addNewCardToList(newCard);
            } else {
                //more?
                setLoading(false);
            }
        }

        closeModal();
    };

    const checkIfEmptyFields = (source, target) => {
        if ((source === undefined || target === undefined) || (source.trim() === "" || target.trim() === "")){
            console.log(source, target);
            setError(true);
            setErrorMsg("None of the input fields can be blank.");
            return true;
        } else {
            return false;
        }
    }

    const saveChangesClick = async (e, source, target) => {
        let emptyFields = checkIfEmptyFields(source, target);
        if (emptyFields) {
            return null;
        }
        else {
            if (e !== undefined){
                e.preventDefault();
            }
            setLoading(true);
            let success = await editItemInDeck(deck_id, editCardId, source, target, customFieldsInput);
            if (success === true){
                // success
                let l = [...docs];
                for (let i=0; i < l.length; i++){
                    if (l[i].id === editCardId){
                        l[i].source = source;
                        l[i].target = target;
                        Object.entries(customFieldsInput).forEach((entry)=>{
                            l[i]['custom_fields.'+entry[0]] = entry[1];
                        });
                    }
                }
                setDocs(l);
            }
            setLoading(false);
            closeModal(); 
        }
        
    }

    const resetInputData = () => {
        setSourceInput("");
        setTargetInput("");
        setEditCardId(0);
        setCustomFieldsInput(null);
        setEditCardCustomFields({});
        setSourceSuggestions([]);
        setTargetSuggestions([]);
    }

    const closeModal = () => {
        resetInputData();
        setShowModal(false);
        setModalMode("add");
        setError(false);
        setErrorMsg("");
    }

    const setSourceInputFunction = async (e) => {
        setError(false);
        setSourceInput(e.target.value);
        setSourceSuggestions([]);
    }

    let {translateText} = useTranslate();

    const sourceInputRef = useRef(null);
    const targetInputRef = useRef(null);

    const [translationWorking, setTranslationWorking] = useState(false);

    const autoTranslateClick = async () => {
        setTranslationWorking(true);
        let r = null;
        let translationMode = null;
        if (targetInput !== ""){
            r = await translateText(targetInput, deck['source_ISO_639-1']);
            translationMode = "to_source";
        } else if (sourceInput !== ""){
            r = await translateText(sourceInput, deck['target_ISO_639-1']); 
            translationMode = "to_target";
        }
        if (r !== null && translationMode !== null && r.success){
            if (r.data !== null && r.data.length > 0){
                if (translationMode === "to_source"){
                    setSourceInput(r.data[0]);
                    focusSourceInputField(); 
                } else if (translationMode === "to_target"){
                    setTargetInput(r.data[0]);
                    focusTargetInputField(); 
                }
            }
        } else {
            setErrorMsg(r.error.message);
            setError(true);
        }
        setTranslationWorking(false);
    }

    const focusSourceInputField = () => {
        sourceInputRef.current.focus();
    }
   
    const focusTargetInputField = () => {
        targetInputRef.current.focus();
    }

    const setTargetInputFunction = async (e) => {
        setError(false);
        setTargetInput(e.target.value);
        if (deck['target_ISO_639-1'] === "zh" && deck['source_ISO_639-1'] === "zh" && e.target.value !== ""){
            let pinyinList = await fetchPinyin(e.target.value);
            if (pinyinList){
                let pinyinValue = pinyinList.join(" ");
                if (pinyinValue.replaceAll(" ", "") !== e.target.value.replaceAll(" ", "")){
                    setSourceSuggestions([pinyinValue]);
                }
                else {
                    setSourceSuggestions([]);
                }
            }
        }
    }

    const checkIfEnter = async (e, source, target) => {
        if (e.key === 'Enter') {
            if (modalMode === "edit"){
                saveChangesClick(e, source, target);
            } else {
                addItemToDeckClick(e, source, target);
            }  
        }; 
    } 

    return (
        <>
            {showModal &&
                <>
                    {(allLanguages !== null && customFieldsInput !== null) && 
                        <>
                            <Modal closeFunction={closeModal} key="modal" content={<ModalContent setSourceSuggestions={setSourceSuggestions} sourceSuggestions={sourceSuggestions} targetSuggestions={targetSuggestions} focusTargetInputField={focusTargetInputField} focusSourceInputField={focusSourceInputField} targetInputRef={targetInputRef} sourceInputRef={sourceInputRef} customFieldsInput={customFieldsInput} setCustomFieldsInput={setCustomFieldsInput} customFieldsOpen={customFieldsOpen} setCustomFieldsOpen={setCustomFieldsOpen} customFields={customFields} translationWorking={translationWorking} autoTranslateClick={autoTranslateClick} loading={loading} checkIfEnter={checkIfEnter} sourceLanguage={sourceLanguage} error={error} errorMsg={errorMsg} allLanguages={allLanguages} deck={deck} key="modal_content" targetInput={targetInput} sourceInput={sourceInput} setTargetInputFunction={setTargetInputFunction} setSourceInputFunction={setSourceInputFunction} closeModal={closeModal} saveWord={addItemToDeckClick} modalMode={modalMode} setModalMode={setModalMode} saveChanges={saveChangesClick} />} size="normal" />
                        </>
                    }
                </>
            }
        </>
    )
}

export default AddVocabularyModal;
