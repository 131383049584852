import {projectFirestore, projectFunctions, timeStamp, firebase} from '../firebase/config.js';
import { useAuth } from "../contexts/AuthContext";
import {
    getFirestore,
    collection,
    query,
    where,
    setDoc,
    getDoc,
    getDocs,
    doc,
    deleteDoc,
    addDoc,
    updateDoc,
    onSnapshot,
    serverTimestamp,
    orderBy,
    limit,
    startAfter,
    endBefore,
    limitToLast, 
    Timestamp
} from "firebase/firestore";
import {  httpsCallable } from "firebase/functions";

export default function useQuickNotes() {

    const {currentUser} = useAuth();

    const getQuickNoteQuery = (lang) => {
        const quickNotesRef = collection(projectFirestore, "quick-notes");
        return query(
            quickNotesRef,
            where("uid", "==", currentUser.uid),
            where("target_ISO_639-1", "==", lang)
        );
    };
    
    // Fetch a quick note for a specific language
    const fetchQuickNote = async (lang) => {
        if (currentUser === null) { 
            console.log("No current user.");
            return false; 
        }
    
        const q = getQuickNoteQuery(lang);
    
        try {
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty && querySnapshot.docs.length === 1) {
                return querySnapshot.docs[0].data();
            } else {
                console.log("No quick note found.");
                return false;
            }
        } catch (err) {
            console.error("Error fetching quick note:", err);
            return false;
        }
    };
    
    // Save or update a quick note for a specific language
    const saveQuickNote = async (text, lang) => {
        if (currentUser === null) { 
            console.log("No current user.");
            return false; 
        }
    
        const q = getQuickNoteQuery(lang);
    
        try {
            const querySnapshot = await getDocs(q);
            let success = true;
    
            if (!querySnapshot.empty && querySnapshot.docs.length === 1) {
                const snap = querySnapshot.docs[0];
                const noteRef = snap.ref;
                await updateDoc(noteRef, {
                    text: text,
                    last_updated_timestamp: timeStamp
                });
            } else {
                const newNoteRef = collection(projectFirestore, "quick-notes");
                await addDoc(newNoteRef, {
                    text: text,
                    last_updated_timestamp: timeStamp,
                    uid: currentUser.uid,
                    'target_ISO_639-1': lang
                });
            }
    
            return success;
        } catch (err) {
            console.error("Error saving quick note:", err);
            return false;
        }
    };

    return {
        fetchQuickNote, 
        saveQuickNote
    }
}