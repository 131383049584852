import React, {useEffect, useState} from 'react';
import Button from '../../components/general/Button';
import { CSVLink } from "react-csv";
import {useParams} from "react-router-dom";
import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTableColumns} from '@fortawesome/pro-solid-svg-icons';
import {faKeyboard} from '@fortawesome/pro-solid-svg-icons';
import useDecks from '../../hooks/useDecks';
import {Link} from 'react-router-dom';
import Quizlet from '../../assets/svg/logos/quizlet.svg';
import Excel from '../../assets/svg/logos/excel.svg';
import {motion} from 'framer-motion';

const Card = (props) => {
    const {title, id, description, urlSlug, image, card} = props;
    return (
        <>
<Link
    className="no-underline"
    to={
        id !== null
            ? `/my-decks/${id}/import/${urlSlug}`
            : `/import/decks/${urlSlug}`
    }
>
    <motion.div
        whileHover={{ scale: 1.01, y: -2 }}
        className="relative p-6 w-64 h-64 rounded-xl shadow-2xl bg-white dark:bg-gray-800 flex flex-col m-auto justify-center place-items-center"
    >
        {/* Title Badge */}
        <div className="absolute bg-persian-green text-white rounded-xl shadow-xl font-bold text-base left-0 top-0 transform -rotate-8 p-3">
            <div>{title}</div>
        </div>

        {/* Card Content */}
        <div className="flex flex-col justify-between h-full gap-4 text-center">
            {/* Image Illustration */}
            {card.illustration === 'image' && (
                <div className="w-36 overflow-hidden m-auto">
                    <img src={image} alt="logo" />
                </div>
            )}

            {/* Icon Illustration */}
            {card.illustration === 'icon' && (
                <div
                    className={`${card.icon_color} text-7xl overflow-hidden m-auto`}
                >
                    <FontAwesomeIcon icon={card.icon} />
                </div>
            )}

            {/* Description */}
            <div className="text-sm italic text-gray-600 dark:text-gray-300">
                {description}
            </div>
        </div>
    </motion.div>
</Link>

        </>
    )
}

const ImportDeck = (props) => {
    let { id } = props;
    if (id === undefined || id === null) {
        id = null; //we need to create a new deck
    }
    let cards = [
        {
            'title': "CSV file",
            'description': 'Import a csv file with translations.',
            'url-slug': 'csv',
            'illustration': 'image',
            'image': Excel
        },
        {
            'title': "Table input",
            'description': 'Create multiple cards with a table.',
            'url-slug': 'table',
            'illustration': 'icon',
            'icon_color': "text-pretty-blue",
            'icon': faTableColumns
        },
        {
            'title': "Text input",
            'description': 'Write raw text to create cards.',
            'url-slug': 'input-text',
            'illustration': 'icon',
            'icon_color': "text-charcoal",
            'icon': faKeyboard
        },
       
    {
        'title': "Quizlet",
        'description': 'Import one of your decks from Quizlet.',
        'url-slug': 'quizlet',
        'illustration': 'image',
        'image': Quizlet
    },

    ];
  return (
    <>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-12">
            {cards !== null && cards.map((card, cardIndex)=>(
                <>
                    <Card key={"card_"+cardIndex} card={card} image={card.image} title={card.title} id={id} description={card.description} urlSlug={card['url-slug']} />
                </>
            ))}
        </div>
    </>
  )
}

export default ImportDeck