import React, {useState, useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {motion} from 'framer-motion';
import {faUser} from '@fortawesome/pro-duotone-svg-icons';
import {faPlay} from '@fortawesome/pro-duotone-svg-icons';
import {faThumbsUp} from '@fortawesome/pro-solid-svg-icons';
import {faPencil} from '@fortawesome/pro-duotone-svg-icons';
import {faVideo} from '@fortawesome/pro-solid-svg-icons';
import {faComment} from '@fortawesome/pro-solid-svg-icons';
import {faTrash} from '@fortawesome/pro-duotone-svg-icons';
import {faHeart} from '@fortawesome/pro-regular-svg-icons';
import {faCardsBlank} from '@fortawesome/pro-solid-svg-icons';
import {faArrowRight}  from '@fortawesome/pro-solid-svg-icons';
import {faHeart as faHeartSolid} from '@fortawesome/pro-solid-svg-icons';
import {faCopy} from '@fortawesome/pro-duotone-svg-icons';
import {faEllipsisV} from '@fortawesome/pro-solid-svg-icons';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import CountryFlag from '../../components/languages/CountryFlag';
import Icon from '../../components/general/Icon';
import useFavorites from '../../hooks/useFavorites';
import { useUserInfoContext } from '../../contexts/UserInfoContext';
import { useLanguagesContext } from '../../contexts/LanguagesContext';
import { useAuth } from '../../contexts/AuthContext';


const DecksCard = ({closeDeckMenu, location="public", openDeckMenuId, openDeckMenuClick, duplicateDeckClick, allDecksViewOpenDeleteDeckModal, deleteDeck, showAdminOptions=false, deck, baseLink="decks", showProfileLink=true}) => {

    let {currentUser} = useAuth();
    let { favorites } = useUserInfoContext();
    let {addOrRemoveDeckFavorite} = useFavorites();
    let {allLanguages} = useLanguagesContext();

    if (location === "library"){
        baseLink = "my-decks";
    }

    const [id, setId] = useState(null);
    const [title, setTitle] = useState(null);
    const [description, setDescription] = useState(null);
    const [processedDeck, setProcessedDeck] = useState(deck);

    useEffect(()=>{
        if (deck !== undefined && deck !== null){
            if ('typesense_deck_id' in deck){
                setId(deck['typesense_deck_id']);
            } else if ('id' in deck){
                setId(deck['id']);
            } else if ('deck_id' in deck){
                setId(deck['deck_id']);
            }
        
            if ('title' in deck){
                if ('name' in deck && deck['title'] === "" && deck['name'] !== ""){
                    setTitle(deck['name']);
                } else {
                    setTitle(deck['title']);
                }
            } else if ('name' in deck){
                setTitle(deck['name']);
            } else if ('deck_name' in deck){
                setTitle(deck['deck_name']);
            }
        
            if ('description' in deck){
                setDescription(deck['description']);
            } else if ('deck_description' in deck){
                setDescription(deck['deck_description']);
            }

            let newObject = {...deck}
            if (newObject.hasOwnProperty('author.username') === false){
                newObject['author.username']= null;
            }
            if (!newObject.hasOwnProperty(["author.displayname"])){
                newObject['author.displayname'] = null;
                setShowProfileLinkState(false);
            }
            setProcessedDeck(newObject);
        }
    },[deck]);

    const [currentFavorite, setCurrentFavorite] = useState(null);
    const [myDecks, setMyDecks] = useState(null);

    const addToFavoritesClick = async (e) => {
        e.stopPropagation();
        await addOrRemoveDeckFavorite(processedDeck);
    }

    const [showProfileLinkState, setShowProfileLinkState] = useState(showProfileLink);
    const [showAdminOptionsState, setShowAdminOptionsState] = useState(showAdminOptions);

    function getRndInteger(min, max) {
        return Math.floor(Math.random() * (max - min + 1) ) + min;
      }
    


    const navigate = useNavigate();
    let colors = ['green', 'red', 'yellow', 'blue', 'gray', 'indigo', 'purple', 'pink'];
    let color = colors[getRndInteger(0, colors.length-1)];
    let color2 = colors[getRndInteger(0, colors.length-1)];
    
    const deckClick = () => {
        navigate('/'+baseLink+'/'+id);
    }

    //bg-gradient-to-r from-" + color +"-200 via-"+color+"-200 to-"+color2+"-200 hover:from-"+color2+"-200 hover:to-"+color+"-200

    while (color === color2) {
        color2 = colors[getRndInteger(0, colors.length-1)];
    }

    useEffect(()=>{
        if (favorites !== undefined && deck !== undefined){
            if (favorites !== null && 'decks' in favorites[0] && deck !== null && deck['typesense_deck_id'] in favorites[0]['decks']){
                setCurrentFavorite(true);
            } else {
                setCurrentFavorite(false);
            }
        } 
    },[favorites, processedDeck]);



    return (
        <>
        {processedDeck && (
            <div
                onClick={deckClick}
                className="relative cursor-pointer flex flex-col w-full h-full rounded-lg bg-white dark:bg-gray-800 shadow-lg hover:shadow-xl transition-shadow duration-300 overflow-hidden"
            >
                {/* Header: Language Flags */}
                {allLanguages && processedDeck["source_ISO_639-1"] && processedDeck["target_ISO_639-1"] && (
                    <div className="flex items-center justify-start gap-3 p-3 bg-gray-50 dark:bg-gray-700 text-gray-600 dark:text-gray-300 text-sm font-medium">
                        <div className="flex items-center gap-2">
                            <CountryFlag
                                flagName={allLanguages[0][processedDeck["source_ISO_639-1"]]?.name?.en}
                                countryCode={allLanguages[0][processedDeck["source_ISO_639-1"]]?.country_flag}
                                size="1.5em"
                            />
                            <FontAwesomeIcon icon={faArrowRight} />
                            <CountryFlag
                                flagName={allLanguages[0][processedDeck["target_ISO_639-1"]]?.name?.en}
                                countryCode={allLanguages[0][processedDeck["target_ISO_639-1"]]?.country_flag}
                                size="1.5em"
                            />
                        </div>
                    </div>
                )}
    
                {/* Content: Title & Description */}
                <div className="flex-grow p-4 flex flex-col justify-center gap-3">
                    {/* Title */}
                    <h3 className="text-lg font-semibold text-center text-charcoal dark:text-white">
                        {title || "Untitled Deck"}
                    </h3>
    
                    {/* Description */}
                    {description && (
                        <p className="text-sm italic text-center text-gray-600 dark:text-gray-400 max-h-20 overflow-auto">
                            {description}
                        </p>
                    )}
                </div>
    
                {/* Footer: Stats & Profile Link */}
                <div className="flex items-center justify-between p-3 bg-gray-50 dark:bg-gray-700 border-t border-gray-200 dark:border-gray-600 text-sm">
                    {/* Stats */}
                    <div className="flex items-center gap-4 text-charcoal dark:text-gray-300">
                        <div className="flex items-center gap-1">
                            <FontAwesomeIcon icon={faCardsBlank} />
                            <span>{processedDeck.number_of_items || 0}</span>
                        </div>
                        <div className="flex items-center gap-1">
                            <FontAwesomeIcon icon={faThumbsUp} />
                            <span>{processedDeck.rating || 0}</span>
                        </div>
                        <div className="flex items-center gap-1">
                            <FontAwesomeIcon icon={faComment} />
                            <span>{processedDeck.number_of_comments || 0}</span>
                        </div>
                        {processedDeck.youtube_id && (
                            <FontAwesomeIcon icon={faVideo} className="text-persian-green" />
                        )}
                    </div>
    
                    {/* Profile Link */}
                    {showProfileLinkState && processedDeck["author.username"] && (
                        <Link
                            to={`/u/${processedDeck["author.username"]}`}
                            className="flex items-center gap-2 text-persian-green hover:underline"
                        >
                            <FontAwesomeIcon icon={faUser} />
                            {processedDeck["author.displayname"] || "Anonymous"}
                        </Link>
                    )}
                </div>
            </div>
        )}
    </>
    
    )
}

export default DecksCard;
