import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faPen, faTrash, faFolderPlus, faPlus, faPlay, faUpDownLeftRight } from '@fortawesome/pro-solid-svg-icons';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import Icon from "../../components/general/Icon";


const LibraryItemMenu = (props) => {
    
    const {closeMenu, iconColor="muted-text", renameItemClick, deleteItemClick, closeCreateItemModal, openCreateSubItemModal, openCreateSubFolderModal, openCreateFolderModal, parentFolder, libraryItemMenuID, setMoveItemCurrentParent, changeItemFolderClick, showingLibraryItemMenuID, setShowingLibraryItemMenuID, item} = props;
    console.log(libraryItemMenuID, showingLibraryItemMenuID)
    const navigate = useNavigate();

    const menuClick = (e) => {
        e.stopPropagation();
        if (libraryItemMenuID === showingLibraryItemMenuID){
            setShowingLibraryItemMenuID(null);
        } else {
            setShowingLibraryItemMenuID(libraryItemMenuID);
        }
    }

    const backgroundClick = (e) => {
        setShowingLibraryItemMenuID(null);
    }

    const changeFolderOptionMenuClick = (e) => {
        e.stopPropagation();
        setShowingLibraryItemMenuID(null);
        setMoveItemCurrentParent(parentFolder);
        changeItemFolderClick(e, item);
    }

    const renameItemClickHelper = (e) => {
        e.stopPropagation();
        setShowingLibraryItemMenuID(null);
        renameItemClick(e, item);
    }

    const deleteItemClickHelper = (e) => {
        e.stopPropagation();
        setShowingLibraryItemMenuID(null);
        deleteItemClick(e, item);
    }

    const openCreateSubFolderModalClick = (e) => {
        e.stopPropagation();
        setShowingLibraryItemMenuID(null);
        setMoveItemCurrentParent(item); //
        openCreateSubFolderModal(e, item);
    }

    const openCreateSubItemModalClick = (e) => {
        e.stopPropagation();
        setShowingLibraryItemMenuID(null);
        openCreateSubItemModal(e, item);
    }

    const navigateToPlayAllDecks = (e) => {
        e.stopPropagation();
        backgroundClick();
        closeMenu();
        navigate('/my-folders/'+item.doc_id+"/decks/play");
    }

    const navigateToStudyNote = (e) => {
        e.stopPropagation();
        backgroundClick();
        closeMenu();
        navigate("/my-notes/note/"+item.id+"/study");
    }

    const navigateToPlayDeck = (e) => {
        e.stopPropagation();
        backgroundClick();
        closeMenu();
        navigate("/my-decks/"+item.id+"/play");
    }

    const navigateToPlayTable = (e) => {
        e.stopPropagation();
        backgroundClick();
        closeMenu();
        navigate("/my-tables/"+item.id+"/play");
    }

    const navigateToEditStudyNote = (e) => {
        e.stopPropagation();
        backgroundClick();
        closeMenu();
        navigate("/my-notes/note/"+item.id);
    }

    const navigateToEditDeck = (e) => {
        e.stopPropagation();
        backgroundClick();
        closeMenu();
        navigate("/my-decks/"+item.id);
    }

    const navigateToEditTable = (e) => {
        e.stopPropagation();
        backgroundClick();
        closeMenu();
        navigate("/my-tables/"+item.id);
    }
    

    return (
        <>
        <AnimatePresence>
            {showingLibraryItemMenuID === libraryItemMenuID ? (
                <>
                    {/* Background Overlay */}
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        onClick={backgroundClick}
                        className="fixed left-0 z-10 top-0 w-screen h-screen bg-gray-500 bg-opacity-50 cursor-default"
                    ></motion.div>
    
                    {/* Menu Dropdown */}
                    <motion.div
                        variants={{
                            initial: { height: 0 },
                            animate: { height: "auto", transition: { when: "beforeChildren", duration: 0.1 } },
                            exit: { height: 0, transition: { when: "afterChildren" } },
                        }}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        className="absolute overflow-visible text-sm z-30 rounded-lg shadow-xl bg-white dark:bg-gray-800 right-0 top-0"
                    >
                        <motion.div
                            variants={{
                                initial: { opacity: 0 },
                                animate: { opacity: 1, duration: 0.1 },
                                exit: { opacity: 0 },
                            }}
                            className="flex font-bold text-gray-700 dark:text-gray-300 flex-col justify-start place-items-start"
                        >
                            {/* Folder Options */}
                            {item.content_type === "folder" && (
                                <>
                                    <div
                                        onClick={(e) => navigateToPlayAllDecks(e)}
                                        className="flex flex-row gap-3 hover:bg-gray-100 dark:hover:bg-gray-700 w-full p-3"
                                    >
                                        <Icon color="persian-green" icon={<FontAwesomeIcon icon={faPlay} />} />
                                        <span>Play all decks</span>
                                    </div>
                                    <div
                                        onClick={(e) => openCreateSubItemModalClick(e)}
                                        className="flex flex-row gap-3 hover:bg-gray-100 dark:hover:bg-gray-700 w-full p-3"
                                    >
                                        <Icon color="charcoal" icon={<FontAwesomeIcon icon={faPlus} />} />
                                        <span>Create subitem</span>
                                    </div>
                                    <div
                                        onClick={(e) => openCreateSubFolderModalClick(e)}
                                        className="flex flex-row gap-3 hover:bg-gray-100 dark:hover:bg-gray-700 w-full p-3"
                                    >
                                        <Icon color="charcoal" icon={<FontAwesomeIcon icon={faFolderPlus} />} />
                                        <span>Create subfolder</span>
                                    </div>
                                </>
                            )}
    
                            {/* Note Options */}
                            {item.content_type === "note" && (
                                <>
                                    <div
                                        onClick={(e) => navigateToStudyNote(e)}
                                        className="flex flex-row gap-3 hover:bg-gray-100 dark:hover:bg-gray-700 w-full p-3"
                                    >
                                        <Icon color="persian-green" icon={<FontAwesomeIcon icon={faPlay} />} />
                                        <span>Study note</span>
                                    </div>
                                    <div
                                        onClick={(e) => navigateToEditStudyNote(e)}
                                        className="flex flex-row gap-3 hover:bg-gray-100 dark:hover:bg-gray-700 w-full p-3"
                                    >
                                        <Icon color="charcoal" icon={<FontAwesomeIcon icon={faPen} />} />
                                        <span>Edit note</span>
                                    </div>
                                </>
                            )}
    
                            {/* Deck Options */}
                            {item.content_type === "deck" && (
                                <>
                                    <div
                                        onClick={(e) => navigateToPlayDeck(e)}
                                        className="flex flex-row gap-3 hover:bg-gray-100 dark:hover:bg-gray-700 w-full p-3"
                                    >
                                        <Icon color="persian-green" icon={<FontAwesomeIcon icon={faPlay} />} />
                                        <span>Play deck</span>
                                    </div>
                                    <div
                                        onClick={(e) => navigateToEditDeck(e)}
                                        className="flex flex-row gap-3 hover:bg-gray-100 dark:hover:bg-gray-700 w-full p-3"
                                    >
                                        <Icon color="charcoal" icon={<FontAwesomeIcon icon={faPen} />} />
                                        <span>Edit deck</span>
                                    </div>
                                </>
                            )}
    
                            {/* Table Options */}
                            {item.content_type === "table" && (
                                <>
                                    <div
                                        onClick={(e) => navigateToPlayTable(e)}
                                        className="flex flex-row gap-3 hover:bg-gray-100 dark:hover:bg-gray-700 w-full p-3"
                                    >
                                        <Icon color="persian-green" icon={<FontAwesomeIcon icon={faPlay} />} />
                                        <span>Play table</span>
                                    </div>
                                    <div
                                        onClick={(e) => navigateToEditTable(e)}
                                        className="flex flex-row gap-3 hover:bg-gray-100 dark:hover:bg-gray-700 w-full p-3"
                                    >
                                        <Icon color="charcoal" icon={<FontAwesomeIcon icon={faPen} />} />
                                        <span>Edit table</span>
                                    </div>
                                </>
                            )}
    
                            {/* General Options */}
                            <div
                                onClick={renameItemClickHelper}
                                className="flex flex-row gap-3 hover:bg-gray-100 dark:hover:bg-gray-700 w-full p-3"
                            >
                                <Icon color="charcoal" icon={<FontAwesomeIcon icon={faPen} />} />
                                <span>Rename</span>
                            </div>
                            <div
                                onClick={changeFolderOptionMenuClick}
                                className="flex flex-row gap-3 hover:bg-gray-100 dark:hover:bg-gray-700 w-full p-3"
                            >
                                <Icon color="charcoal" icon={<FontAwesomeIcon icon={faUpDownLeftRight} />} />
                                <span>Move {item.content_type}</span>
                            </div>
                            <div
                                onClick={deleteItemClickHelper}
                                className="flex flex-row gap-3 hover:bg-gray-100 dark:hover:bg-gray-700 w-full p-3"
                            >
                                <Icon color="red" icon={<FontAwesomeIcon icon={faTrash} />} />
                                <span>Delete</span>
                            </div>
                        </motion.div>
                    </motion.div>
                </>
            ) : (
                <>
                    <div
                        onClick={menuClick}
                        className={`${iconColor} absolute px-3 right-0 text-gray-500 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200 transition-colors duration-300`}
                    >
                        <FontAwesomeIcon icon={faEllipsisV} />
                    </div>
                </>
            )}
        </AnimatePresence>
    </>
    
    )
}

export default LibraryItemMenu;