import React, {useState} from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from "../../../contexts/AuthContext";
import {faLoader} from '@fortawesome/pro-solid-svg-icons';
import {faThumbsUp} from '@fortawesome/pro-solid-svg-icons';
import {faComment} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import CountryFlag from '../../../components/languages/CountryFlag';
import DefaultImage from '../../../assets/svg/yalango/YalangoAI.svg';
import {useLanguagesContext} from '../../../contexts/LanguagesContext';
import ReactTooltip from 'react-tooltip';
import YalangoBook from '../../../assets/svg/yalango/yalango_book.svg';

const TextCard = (props) => {
    const {text, location="public", calculcatedPercentages} = {...props};
    const {allLanguages} = useLanguagesContext();
    const baseLink = location === "public" ? "/texts/" : "/my-texts/";
    console.log(text);
    let { currentUser } = useAuth();

    const percentageBubbleClass = (id) => {
        if (currentUser !== null){
            ReactTooltip.rebuild();
            if (calculcatedPercentages !== undefined && calculcatedPercentages.hasOwnProperty(id)){
                console.log("Yes: ", calculcatedPercentages[id]);
                let number = calculcatedPercentages[id];
                if (number < 20){
                    return "bg-burnt-sienna "
                }
                else if (number < 40){
                    return "bg-sandy-brown "
                }
                else if (number < 70){
                    return "bg-green-400 "
                }
                else if (number < 100){
                    return "bg-persian-green "
                }
                else if (number === 100){
                    return "bg-persian-green border-solid border-2 border-white"
                }
            }
            return "bg-charcoal ";
           
        }
    }

    const [contentTypeClasses, setContentTypeClasses] = useState({
        'story': 'bg-white ', 
        'conversation': 'bg-white ',
        'article': 'bg-white ', 
        'text': 'bg-white ',
        'poem': 'bg-white ', 
        'fairytale': 'bg-white ', 
    });

    console.log(text)

    return (
        <>
            <div>
                <Link
                    className="no-underline"
                    to={
                        location === 'public'
                            ? `${baseLink}${text['target_ISO_639-1']}/${text.text_id}/${text.slug}`
                            : `/my-texts/${text.text_id}`
                    }
                >
                    <div className="bg-white dark:bg-gray-800 shadow-lg relative text-black dark:text-white text-lg p-5 w-64 h-64 rounded-xl flex flex-col gap-3">
                        {/* Content Type Badge */}
                        {text.hasOwnProperty('content_type') && text.content_type !== null && (
                            <div
                                className={`${contentTypeClasses[text['content_type']]} flex flex-row gap-2 absolute -left-2 -top-2 p-2 text-xs text-gray-600 dark:text-gray-300 font-bold rounded-lg shadow-lg`}
                            >
                                {text.hasOwnProperty('target_ISO_639-1') &&
                                    allLanguages[0].hasOwnProperty(text['target_ISO_639-1']) && (
                                        <div className="rounded-xl shadow-xl h-full overflow-hidden">
                                            <CountryFlag
                                                flagName={
                                                    allLanguages[0][text['target_ISO_639-1']]['name']['en']
                                                }
                                                countryCode={
                                                    allLanguages[0][text['target_ISO_639-1']]['country_flag']
                                                }
                                                size="1.5em"
                                            />
                                        </div>
                                    )}
                                <div>
                                    {text.content_type.charAt(0).toUpperCase() +
                                        text.content_type.slice(1)}
                                </div>
                            </div>
                        )}

                        {/* Percentage Bubble */}
                        {currentUser !== null && calculcatedPercentages !== undefined && (
                            <div
                                data-for="tooltip-texts-card"
                                data-tip="Percentage of words in your vocabulary"
                                className={`${percentageBubbleClass(
                                    text.text_id
                                )} absolute -right-4 -top-4 font-bold text-xs m-auto flex flex-col justify-center w-12 h-12 shadow-xl text-white rounded-full px-2 text-center py-3`}
                            >
                                <div className="flex flex-row gap-1 justify-center">
                                    {calculcatedPercentages.hasOwnProperty(text.text_id) ? (
                                        calculcatedPercentages[text.text_id]
                                    ) : (
                                        <FontAwesomeIcon
                                            icon={faLoader}
                                            className="fa-spin"
                                        />
                                    )}
                                    %
                                </div>
                            </div>
                        )}

                        {/* Thumbnail */}
                        {text.hasOwnProperty('thumbnail_500x500') &&
                        text['thumbnail_500x500'] !== null &&
                        text['thumbnail_500x500'].includes('shutterstock') ? (
                            <div className="rounded-xl overflow-hidden shadow-xl h-32">
                                <img
                                    alt={`${text.title} thumbnail`}
                                    src={text['thumbnail_500x500']}
                                />
                            </div>
                        ) : (
                            <div className="rounded-xl overflow-hidden h-32 flex flex-col justify-center place-items-center">
                                {text.uid === 'd1AsNHNfQUWYYoplP3UNdKcATss1' ? (
                                    <img
                                        alt={`${text.title} thumbnail`}
                                        src={DefaultImage}
                                        className="w-2/3 m-auto"
                                    />
                                ) : (
                                    <img
                                        alt={`${text.title} thumbnail`}
                                        src={YalangoBook}
                                        className="w-1/3 m-auto"
                                    />
                                )}
                            </div>
                        )}

                        {/* Title */}
                        <div className="whitespace-nowrap overflow-hidden text-center text-sm font-bold text-gray-600 dark:text-gray-300">
                            {text.title.length > 30
                                ? `${text.title.slice(0, 30)}...`
                                : text.title}
                        </div>

                        {/* Author */}
                        <div className="text-gray-600 dark:text-gray-400 text-xs text-center">
                            <div>By {text.author}</div>
                        </div>

                        {/* Likes and Comments */}
                        <div className="flex flex-row gap-3 text-xs text-gray-600 dark:text-gray-400">
                            <div className="flex flex-row gap-1">
                                <FontAwesomeIcon icon={faThumbsUp} />
                                <span className="font-bold">
                                    {text.hasOwnProperty('rating') ? text.rating : 0}
                                </span>
                            </div>
                            <div className="flex flex-row gap-1">
                                <FontAwesomeIcon icon={faComment} />
                                <span className="font-bold">
                                    {text.hasOwnProperty('number_of_comments')
                                        ? text.number_of_comments
                                        : 0}
                                </span>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </>

  )
}

export default TextCard