import {projectFirestore, timeStamp} from '../firebase/config.js';
import { useAuth } from "../contexts/AuthContext";
import {
    getFirestore,
    collection,
    query,
    where,
    setDoc,
    getDoc,
    getDocs,
    doc,
    deleteDoc,
    addDoc,
    updateDoc,
    onSnapshot,
    serverTimestamp,
    orderBy,
    limit,
    startAfter,
    endBefore,
    limitToLast, 
    Timestamp
} from "firebase/firestore";
import {  httpsCallable } from "firebase/functions";
export default function useFeedback() {
    
    let {currentUser} = useAuth();

    const isLoggedIn = () => {
        if (currentUser !== null){
            return true;
        } else {
            return false;
        }
    }

    const addFeedbackToDatabase = async (text) => {
        const uid = currentUser ? currentUser.uid : "anonymous";
        const feedbackRef = collection(projectFirestore, "feedback", "general", "items");
    
        await addDoc(feedbackRef, {
            uid: uid,
            text: text.toString(),
            created_timestamp: timeStamp,
            number_of_messages: 0,
            status: "unread",
        });
    
        return true;
    };

    const getCurrentUserFeedback = async () => {
        if (!isLoggedIn()) return;
    
        const feedbackRef = collection(projectFirestore, "feedback", "general", "items");
        const q = query(feedbackRef, where("uid", "==", currentUser.uid), orderBy("created_timestamp", "desc"));
        const snapshot = await getDocs(q);
    
        const docs = [];
        if (!snapshot.empty) {
            snapshot.forEach((doc) => {
                if (doc.data().text !== "") {
                    const data = doc.data();
                    data["doc_id"] = doc.id;
                    docs.push(data);
                }
            });
        }
    
        return docs;
    };

    const getFeedbackMessagesInRealtime = async (docId, setMessages) => {
        const messagesRef = collection(projectFirestore, "feedback", "general", "items", docId, "messages");
        const q = query(messagesRef, orderBy("created_timestamp"));
    
        return onSnapshot(q, (snapshot) => {
            const docs = [];
            if (!snapshot.empty) {
                snapshot.forEach((doc) => {
                    docs.push(doc.data());
                });
            }
            setMessages(docs);
        });
    };

    const sendMessage = async (docId, body) => {
        try {
            const messagesRef = collection(projectFirestore, "feedback", "general", "items", docId, "messages");
            await addDoc(messagesRef, {
                body: body,
                uid: currentUser.uid,
                created_timestamp: serverTimestamp(),
            });
        } catch (err) {
            console.error(err);
        }
    };
    
    return {
        addFeedbackToDatabase, 
        getCurrentUserFeedback, 
        getFeedbackMessagesInRealtime, 
        sendMessage
    };
}