import {projectFirestore, timeStamp} from '../firebase/config.js';
import { useAuth } from "../contexts/AuthContext";
import {
    getFirestore,
    collection,
    query,
    where,
    setDoc,
    getDoc,
    getDocs,
    doc,
    deleteDoc,
    addDoc,
    updateDoc,
    onSnapshot,
    serverTimestamp,
    orderBy,
    limit,
    startAfter,
    endBefore,
    limitToLast, 
    Timestamp
} from "firebase/firestore";
import {  httpsCallable } from "firebase/functions";

export default function useFavorites() {
    
    let {currentUser} = useAuth();

    const addOrRemoveDeckFavorite = async (deckData) => {
        console.log(deckData);
        const id = deckData['typesense_deck_id'] !== undefined ? deckData['typesense_deck_id'] : deckData['id'];
        const favoritesRef = collection(projectFirestore, "favorites");
        const q = query(favoritesRef, where("deck_id", "==", id), where("uid", "==", currentUser.uid));
        const snapshot = await getDocs(q);
    
        if (!snapshot.empty) {
            const ref = snapshot.docs[0].id;
            return deleteDeckFromFavorites(ref);
        } else {
            return addDeckToFavorites(deckData);
        }
    };
    
    const deleteDeckFromFavorites = async (docRef) => {
        const docRefToDelete = doc(projectFirestore, "favorites", docRef);
        return deleteDoc(docRefToDelete);
    };

    const addDeckToFavorites = async (deckData) => {
        let title = null;
    
        if ("title" in deckData) {
            if ("name" in deckData && deckData["title"] === "" && deckData["name"] !== "") {
                title = deckData["name"];
            } else {
                title = deckData["title"];
            }
        } else if ("name" in deckData) {
            title = deckData["name"];
        } else if ("deck_name" in deckData) {
            title = deckData["deck_name"];
        }
    
        if ("id" in deckData) {
            try {
                const favoritesRef = collection(projectFirestore, "favorites");
                await addDoc(favoritesRef, {
                    deck_id: deckData["typesense_deck_id"] !== undefined ? deckData["typesense_deck_id"] : deckData["id"],
                    deck_name: title,
                    deck_description: deckData["description"],
                    deck_created_timestamp: deckData.hasOwnProperty("created_timestamp") ? deckData["created_timestamp"] : null,
                    added_to_favorites_timestamp: serverTimestamp(),
                    deck_doc_id: deckData["doc_id"] !== undefined ? deckData["doc_id"] : null,
                    uid: currentUser.uid,
                    deck_author: deckData["author"],
                    deck_author_uid: deckData["uid"],
                    type: "deck",
                    "source_ISO_639-1": deckData["source_ISO_639-1"],
                    "target_ISO_639-1": deckData["target_ISO_639-1"],
                });
            } catch (err) {
                console.error("Error adding deck to favorites:", err);
                return false;
            }
        }
    };

    const getUserFavorites = async (data) => {
        let target = null;
    
        if (data !== undefined && "target" in data) {
            target = data["target"];
        }
    
        const favoritesRef = collection(projectFirestore, "favorites");
        let favoritesQuery = query(favoritesRef, where("uid", "==", currentUser.uid));
    
        if (target !== null) {
            favoritesQuery = query(favoritesQuery, where("target_ISO_639-1", "==", target));
        }
    
        favoritesQuery = query(favoritesQuery, orderBy("added_to_favorites_timestamp"));
    
        const snapshot = await getDocs(favoritesQuery);
        const favorites = [];
        const d = { decks: {} };
    
        if (!snapshot.empty) {
            snapshot.forEach((doc) => {
                const o = doc.data();
                o["doc_id"] = doc.id;
                favorites.push(o);
                d["decks"][o.deck_id] = o;
            });
        }
    
        return [d, favorites];
    };

    const getUserFavoritesRealtime = async (setState) => {
        const favoritesRef = collection(projectFirestore, "favorites");
        const favoritesQuery = query(favoritesRef, where("uid", "==", currentUser.uid));
    
        return onSnapshot(favoritesQuery, (snapshot) => {
            const favorites = [];
            const d = { decks: {} };
    
            if (!snapshot.empty) {
                snapshot.forEach((doc) => {
                    const o = doc.data();
                    o["doc_id"] = doc.id;
                    favorites.push(o);
                    d["decks"][o.deck_id] = o;
                });
            }
    
            setState([d, favorites]);
        });
    };
    
    return {
        addDeckToFavorites, 
        getUserFavorites,  
        addOrRemoveDeckFavorite, 
        deleteDeckFromFavorites, 
        getUserFavoritesRealtime
    };
}