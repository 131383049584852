import React from 'react';
import Modal from '../../general/Modal';
import Button from '../../general/Button';

const ModalContent = ({closeFunction}) => {

    return (
        <>
        <div className="text-center flex flex-col gap-4 place-items-center p-6 rounded-lg shadow-md">
          {/* Title */}
          <h1 className="text-gray-700 dark:text-gray-200 text-2xl font-semibold">
            What is a source language?
          </h1>
      
          {/* Description */}
          <div className="text-gray-600 dark:text-gray-400 text-base">
            A source language is a language you already know that you will translate your target
            language(s) into. This is typically your native language, but Yalango lets you choose
            different source languages for each target language you wish to learn.
          </div>
      
          <div className="mt-2 text-gray-600 dark:text-gray-400 text-base">
            On your My Languages page, you can modify the source language individually for each language
            you're studying.
          </div>
      
          {/* Button */}
          <div className="mt-3">
            <Button text="Great, thank you!" color="green" onClick={closeFunction} />
          </div>
        </div>
      </>
      
    )
}

const SourceLanguageExplanation = ({closeFunction}) => {
    
    return (
        <>
            <Modal size="normal" content={<ModalContent closeFunction={closeFunction} />} closeFunction={closeFunction}  />
        </>
    )
}

export default SourceLanguageExplanation;
