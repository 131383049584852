import { projectFirestore, projectFunctions, timeStamp} from '../firebase/config.js';
import { useAuth } from "../contexts/AuthContext";
import {useLanguagesContext} from '../contexts/LanguagesContext';
import { faBowlChopsticksNoodles } from '@fortawesome/pro-thin-svg-icons';
import {
    getFirestore,
    collection,
    query,
    where,
    setDoc,
    getDoc,
    getDocs,
    doc,
    deleteDoc,
    addDoc,
    updateDoc,
    onSnapshot,
    serverTimestamp,
    orderBy,
    limit,
    startAfter,
    endBefore,
    limitToLast, 
    Timestamp
} from "firebase/firestore";
import {  httpsCallable } from "firebase/functions";

export default function useFolders() {
    let {currentUser} = useAuth();

    const fetchAllFoldersFromCurrentUserInRealtime = async (setFolders) => {
        if (!currentUser) return [];
        const foldersRef = collection(projectFirestore, "folders");
        const q = query(foldersRef, where("uid", "==", currentUser.uid));
        const unsub = onSnapshot(q, (snapshot) => {
            const folders = [];
            snapshot.forEach((doc) => {
                const data = { ...doc.data(), doc_id: doc.id };
                folders.push(data);
            });
            setFolders(folders);
        });
        return unsub;
    };
    
    const fetchGlobalFoldersOrder = async () => {
        if (!currentUser) return null;
        const foldersRef = collection(
            projectFirestore,
            "users",
            currentUser.uid,
            "private-data",
            "parent_folder",
            "languages"
        );
        const snapshot = await getDocs(foldersRef);
        const data = {};
        if (!snapshot.empty) {
            snapshot.forEach((doc) => {
                data[doc.id] = doc.data();
            });
        }
        return data;
    };
    
    const fetchGlobalFoldersOrderInRealtime = async (setState) => {
        if (!currentUser) return null;
        const foldersRef = collection(
            projectFirestore,
            "users",
            currentUser.uid,
            "private-data",
            "parent_folder",
            "languages"
        );
        const unsub = onSnapshot(foldersRef, (snapshot) => {
            const data = {};
            if (!snapshot.empty) {
                snapshot.forEach((doc) => {
                    data[doc.id] = doc.data();
                });
            }
            setState(data);
        });
        return unsub;
    };

    const updateGlobalFolderOrderInTargetLanguage = async (doc_ids, language) => {
        if (!currentUser) return null;
        console.log(doc_ids, language);
    
        const folderRef = doc(
            projectFirestore,
            "users",
            currentUser.uid,
            "private-data",
            "parent_folder",
            "languages",
            language
        );
        const data = { doc_ids_order: doc_ids };
        await setDoc(folderRef, data, { merge: true });
        return true;
    };

    const createFolder = async (name, language, parentFolderDocId) => {
        if (!currentUser) return null;
        const createFolderFunction = httpsCallable(projectFunctions, "createFolder");
        return createFolderFunction({ name, language, parent_doc_id: parentFolderDocId });
    };
    
    const updateFolderOrder = async (folder_doc_id, new_order) => {
        if (!currentUser) return null;
        const updateFolderOrderFunction = httpsCallable(projectFunctions, "updateFolderOrder");
        return updateFolderOrderFunction({ folder_doc_id, new_order });
    };

    const moveItemToFolder = async (moveItemModalItem, moveItemCurrentParent, destinationFolder) => {
        if (!currentUser) return null;
    
        const moveItemToFolderFunction = httpsCallable(projectFunctions, "moveItemToFolder");
        try {
            const result = await moveItemToFolderFunction({
                item: moveItemModalItem,
                currentParent: moveItemCurrentParent.doc_id,
                destinationFolder: destinationFolder.doc_id,
            });
            console.log(result);
            return result;
        } catch (error) {
            console.error("Error:", error);
            return null;
        }
    };
    
    const fetchFolder = async (docId) => {
        if (!currentUser) return false;
    
        const folderRef = doc(projectFirestore, "folders", docId);
        try {
            const snapshot = await getDoc(folderRef);
            if (snapshot.exists()) {
                return snapshot.data();
            } else {
                return false;
            }
        } catch (error) {
            console.error("Error fetching folder:", error);
            return false;
        }
    };

    const renameFolder = async (docId, name) => {
        if (!currentUser) return false;
    
        const folderRef = doc(projectFirestore, "folders", docId);
        try {
            await updateDoc(folderRef, {
                name: name,
                last_updated: timeStamp,
            });
            return true;
        } catch (error) {
            console.error("Error renaming folder:", error);
            return false;
        }
    };
    
    const deleteFolderFromDocId = async (docId) => {
        if (!currentUser) return false;
    
        const folderRef = doc(projectFirestore, "folders", docId);
        try {
            await deleteDoc(folderRef);
            return true;
        } catch (error) {
            console.error("Error deleting folder:", error);
            return false;
        }
    };
 
    return {
        fetchAllFoldersFromCurrentUserInRealtime, 
        fetchGlobalFoldersOrder, 
        updateGlobalFolderOrderInTargetLanguage, 
        createFolder, 
        updateFolderOrder, 
        moveItemToFolder, 
        fetchFolder,
        fetchGlobalFoldersOrderInRealtime, 
        renameFolder, 
        deleteFolderFromDocId
    }
  
}