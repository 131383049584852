import './css/App.css';
import './css/Colors.css';

import {BrowserRouter as Router, Routes, Route, Navigate, useLocation, useNavigate, Outlet} from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import AuthHandler from './pages/authentication/AuthHandler';
import Vocabulary from './pages/mydecks/Vocabulary';
import PostPage from './pages/comments/PostPage';

import UserProfile from './pages/UserProfile';
import EmailSettings from './pages/settings/EmailSettings';
import AccountSettings from './pages/settings/AccountSettings';
import ImportContentPage from './pages/import/ImportContentPage'

import Analytics from './pages/analytics/Analytics';
import Dashboard from './pages/Dashboard';
import EmailVerificationPage from './pages/email/EmailVerificationPage';
import Explore from './pages/explore/Explore';
import CleanDashboard from './pages/dashboard/CleanDashboard';
import ResourcesDirectory from './pages/resources/ResourcesDirectory';
import MainSettings from './pages/settings/MainSettings';
import ProfileSettings from './pages/settings/ProfileSettings';
import DeckSettings from './pages/settings/DeckSettings';
import IndividualDeckSettings from './pages/settings/IndividualDeckSettings';
import NewNavbar from './components/general/NewNavbar';
import HomepageNavbar from './components/general/HomepageNavbar';

import MyDecks from './pages/decks/mydecks/MyDecks';
import MyDeckCards from './pages/decks/mydecks/MyDeckCards';
import ExportMyDeck from './pages/decks/mydecks/ExportMyDeck';
import ExportCSV from './pages/decks/mydecks/ExportCSV';
import ExportPDF from './pages/decks/mydecks/ExportPDF';
import ImportDeckPage from './pages/decks/mydecks/ImportDeckPage';
import ImportCSVToDeck from './pages/decks/mydecks/ImportCSVToDeck';
import MyTables from './pages/tables/mytables/MyTables';
import MyNotes from './pages/notes/mynotes/MyNotes';
import EditMyNote from './pages/notes/mynotes/EditMyNote';
import EditMyText from './pages/texts/EditMyText';
import EditMyCourse from './pages/courses/course/EditMyCourse';
import Play from './pages/play/Play';
import MyTable from './pages/tables/mytables/MyTable';
import MyTablePlay from './pages/tables/mytables/MyTablePlay';
import TableGame from './pages/tables/games/TableGame';
import MyProfile from './pages/MyProfile';
import MyVocabulary from './pages/MyVocabulary';
import MyVocabularySpacedRepetition from './pages/myvocabulary/MyVocabularySpacedRepetition';
import MyVocabularyExport from './pages/MyVocabularyExport';
import WatchYouTube from './pages/videos/WatchYouTube';
import MyDailyGoals from './pages/goals/MyDailyGoals';
import CommentFeed from './pages/comments/CommentFeed';
import MyGoals from './pages/goals/MyGoals';
import MyFavorites from './pages/favorites/MyFavorites';
import Login from './pages/authentication/Login';
import ForgottenPassword from './pages/authentication/ForgottenPassword';
import LogOutComponent from './components/authentication/LogOutComponent';
import Register from './pages/authentication/Register';
import MyLanguages from './pages/mylanguages/MyLanguages';

import HanziToPinyinConverterPage from './pages/learn/mandarin-chinese/HanziToPinyinConverterPage';
import Translator from './pages/translation/Translator';
import DecksDirectory from './pages/decks/DecksDirectory';
import Premium from './pages/premium/Premium';
import PremiumSuccess from './pages/premium/PremiumSuccess';
import Tools from './pages/tools/Tools';

import PublicDeck from './pages/decks/PublicDeck';
import MyFeedback from './pages/feedback/MyFeedback';
import Onboarding from './pages/onboarding/Onboarding';
import DailyStats from './pages/userStatistics/DailyStats';
import UserProfileDecks from './pages/profile/UserProfileDecks';
import MyStatistics from './pages/userStatistics/MyStatistics';
import MyFlaschardsStatistics from './pages/userStatistics/MyFlaschardsStatistics';
import DeckPlay from './pages/vocabulary/games/DeckPlay';
import { AuthProvider } from "./contexts/AuthContext";
import { SocialProvider } from "./contexts/SocialContext";
import { useAuth } from "./contexts/AuthContext";
import { LanguagesProvider } from "./contexts/LanguagesContext";
import { MyContentProvider } from "./contexts/MyContentContext";
import { UserInfoProvider } from "./contexts/UserInfoContext";
import { UserStatisticsProvider } from "./contexts/UserStatisticsContext";
import TextsDirectory from  './pages/texts/TextsDirectory';
import StudyText from './pages/texts/StudyText';
import Forums from './pages/forums/Forums';

import LanguagePage from './pages/learn/LanguagePage';
import AllLanguages from './pages/languages/AllLanguages';
import Roadmap from './pages/Roadmap';
import DeckGame from './pages/vocabulary/games/DeckGame';


import ScrollToTop from "./components/scrollToTop";
import VocabularyItemPage from './pages/myvocabulary/VocabularyItemPage'
import MyLibrary from './pages/mylibrary/MyLibrary';



import MyCalendar from './pages/calendar/MyCalendar';
import FooterMenu from './components/menus/FooterMenu';
import CourseHome from './pages/courses/course/CourseHome';
import CourseLesson from './pages/courses/course/CourseLesson';
import ExportPDFQuiz from './pages/decks/mydecks/ExportPDFQuiz';
import { ThemeProvider } from './contexts/ThemeContext';


  const PrivateRouteWrapper = () => {
    const location = useLocation();
    const { currentUser } = useAuth();
    const navigate = useNavigate();
  
    useEffect(() => {
      const checkUserState = async () => {
        console.log("Checking user: ", currentUser);
        if (currentUser === undefined) {
          // Authentication state is still resolving; don't redirect before auth context is ready
          return;
        }

        if (currentUser === null) {
          if (process.env.NODE_ENV === "development") {
            // In development, redirect to /login locally unless url is logout
            if (location.pathname !== "/logout"){
              navigate("/login");
            }
          } else {
            // In production, use the full URL redirection
            const currentPath = location.pathname;
  
            // Validate redirect path and construct the redirect URL
            const isAllowedDomain = (url) =>
              url.startsWith("https://yalango.com") ||
              url.startsWith("https://dashboard.yalango.com");
  
            const redirectUrl = `https://yalango.com/login?redirect=${encodeURIComponent(
              isAllowedDomain(window.location.href)
                ? window.location.href
                : "https://dashboard.yalango.com"
            )}`;
  
            window.location.href = redirectUrl; // Perform the redirect
          }
          return;
        }
  
/*         // Check custom claims for onboardingCompleted
        try {
          const idTokenResult = await currentUser.getIdTokenResult();
          const onboardingCompleted = idTokenResult.claims?.onboardingCompleted;
          console.log("onboardingCompleted:", onboardingCompleted);
          if (!onboardingCompleted) {
            // Redirect to the onboarding page if not completed
            const onboardingUrl = "https://dashboard.yalango.com/onboarding";
            console.log("redirecting to onboarding page", onboardingUrl);
            if (window.location.href !== onboardingUrl) {
              navigate("/onboarding");
            }
          }
        } catch (error) {
          console.error("Error checking custom claims:", error);
        } */
        return;
      };
  
      checkUserState();
    }, [currentUser, location.pathname]);
  
    if (!currentUser) {
      // Render nothing or a loading indicator during redirection
      return null;
    }

    // also check if onboardingCompleted is present in user info and redirect to onboarding if necessary. Check custom fields in user object

    
    return <Outlet />;
  }

// Component for external redirects
const ExternalRedirect = ({ url }) => {
  useEffect(() => {
    window.location.href = url; // Redirect to the external URL
  }, [url]);

  return null; // Render nothing
};

const DashboardLayout = () => {
  const [navbarCollapsed, setNavbarCollapsed] = useState(false);
  //<WidgetMenu />
  return (
    <>
      <NewNavbar navbarCollapsed={navbarCollapsed} setNavbarCollapsed={setNavbarCollapsed} />
      
      <div className={`${navbarCollapsed ? "dashboard-collapsed" : "dashboard"} `}>
        <Outlet />
      </div>
      <div className=" ">
        </div>
    </>
  )
}


const HomepageLayout = () => {
  return (
    <>
      <div className="relative homepage flex flex-col ">
        <HomepageNavbar />
        <div className=" flex-grow px-8 py-8 pt-12 lg:pt-0 lg:mt-7">
          <Outlet />
        </div>
        <FooterMenu />
        <div className="dotted-background ">
        </div>
      </div>
    </>
  )
}

function App() {

  return (
    <>
      <Router>
        <ThemeProvider>
          <div className="gray-background-gradient dark:dark-mode-background-gradient">
            <ScrollToTop />
            <AuthProvider>
            <LanguagesProvider>
              <UserInfoProvider>
                <UserStatisticsProvider>
                
                <MyContentProvider>
                  <SocialProvider>
              <Routes>

                
                
                <Route path="/my-vocabulary/:lang/flashcards" element={<DeckGame game="flashcards" isPublic={false} type="vocabulary" />} />
                <Route path="/my-vocabulary/:lang/quiz" element={<DeckGame game="quiz" isPublic={false} type="vocabulary" />} />
                <Route path="/my-vocabulary/:lang/input" element={<DeckGame game="input" isPublic={false} type="vocabulary" />} />
                <Route path="/my-vocabulary/:lang/mix" element={<DeckGame game="mix" isPublic={false} type="vocabulary" />} />
                <Route path="/my-vocabulary/:lang/memory" element={<DeckGame game="memory" isPublic={false} type="vocabulary" />} />
                <Route path="/my-vocabulary/:lang/browse" element={<DeckGame game="browse" isPublic={false} type="vocabulary" />} />

                <Route path="/my-decks/lang/:lang/flashcards" element={<DeckGame game="flashcards" isPublic={false} type="all-decks" />} />
                <Route path="/my-decks/lang/:lang/quiz" element={<DeckGame game="quiz" isPublic={false} type="all-decks" />} />
                <Route path="/my-decks/lang/:lang/input" element={<DeckGame game="input" isPublic={false} type="all-decks" />} />
                <Route path="/my-decks/lang/:lang/mix" element={<DeckGame game="mix" isPublic={false} type="all-decks" />} />
                <Route path="/my-decks/lang/:lang/memory" element={<DeckGame game="memory" isPublic={false} type="all-decks" />} />
                <Route path="/my-decks/lang/:lang/browse" element={<DeckGame game="browse" isPublic={false} type="all-decks" />} />

                <Route path="/my-decks/:id/flashcards" element={<DeckGame game="flashcards" isPublic={false}  type="deck" />} />
                <Route path="/my-decks/:id/browse" element={<DeckGame game="browse" type="deck" isPublic={false}  />} />
                <Route path="/my-decks/:id/memory" element={<DeckGame game="memory" type="deck" isPublic={false} />} />
                <Route path="/my-decks/:id/input" element={<DeckGame game="input" type="deck" isPublic={false} />} />
                <Route path="/my-decks/:id/mix" element={<DeckGame game="mix" type="deck" isPublic={false} />} />
                <Route path="/my-decks/:id/write-hanzi" element={<DeckGame game="write-hanzi" type="deck" isPublic={false} />} />
                <Route path="/my-decks/:id/animate-hanzi" element={<DeckGame game="animate-hanzi" type="deck" isPublic={false} />} />
                <Route path="/my-decks/:id/stroke-order-hanzi" element={<DeckGame game="stroke-order-hanzi" type="deck" isPublic={false} />} />

                <Route path="/my-vocabulary/:lang/review-mistakes" element={<DeckGame game="flashcards" isPublic={false} type="review-mistakes" />} />
              
                <Route path="/my-vocabulary/review" element={<DeckGame game="quiz" type="vocabulary-review" />} />
                <Route path="/my-decks/category/:id/flashcards" element={<DeckGame game="flashcards" isPublic={false}  type="category" />} />
                <Route path="/my-decks/section/:id/flashcards" element={<DeckGame game="flashcards" isPublic={false}  type="section" />} />
                
                <Route path="/my-folders/:id/decks/flashcards" element={<DeckGame game="flashcards" type="folder-decks" isPublic={true} />} />
                <Route path="/my-folders/:id/decks/quiz" element={<DeckGame game="quiz" type="folder-decks" isPublic={true} />} />
                <Route path="/my-folders/:id/decks/input" element={<DeckGame game="input" type="folder-decks" isPublic={true} />} />
                <Route path="/my-folders/:id/decks/mix" element={<DeckGame game="mix" type="folder-decks" isPublic={true} />} />
                <Route path="/my-folders/:id/decks/memory" element={<DeckGame game="memory" type="folder-decks" isPublic={true} />} />
                <Route path="/my-folders/:id/decks/browse" element={<DeckGame game="browse" type="folder-decks" isPublic={true} />} />

                <Route path="/my-folders/:id/all/flashcards" element={<DeckGame game="flashcards" type="folder-all" isPublic={true} />} />
                <Route path="/my-folders/:id/all/quiz" element={<DeckGame game="quiz" type="folder-all" isPublic={true} />} />
                <Route path="/my-folders/:id/all/input" element={<DeckGame game="input" type="folder-all" isPublic={true} />} />
                <Route path="/my-folders/:id/all/mix" element={<DeckGame game="mix" type="folder-all" isPublic={true} />} />
                <Route path="/my-folders/:id/all/memory" element={<DeckGame game="memory" type="folder-all" isPublic={true} />} />
                <Route path="/my-folders/:id/all/browse" element={<DeckGame game="browse" type="folder-all" isPublic={true} />} />
                
                <Route path="/my-tables/:id/fill-in" element={<TableGame game="fill-in" />} />   
                <Route path="/my-tables/:id/flashcards" element={<TableGame game="flashcards" />} />   

                <Route path="/my-decks/category/:id/quiz" element={<DeckGame game="quiz" type="category" isPublic={false}  />} />
                <Route path="/my-decks/section/:id/quiz" element={<DeckGame game="quiz" type="section" isPublic={false}  />} />
                <Route path="/my-decks/:id/quiz" element={<DeckGame game="quiz" type="deck" isPublic={false}  />} />
                <Route path="/my-decks/:id/context" element={<DeckGame game="context" type="deck" isPublic={false}  />} />
                <Route path="/my-decks/category/:id/browse" element={<DeckGame game="browse" type="category" isPublic={false}  />} />
                <Route path="/my-decks/section/:id/browse" element={<DeckGame game="browse" type="section" isPublic={false}  />} />
                

                <Route path="/my-decks/category/:id/memory" element={<DeckGame game="memory" type="category" isPublic={false}  />} />
                <Route path="/my-decks/section/:id/memory" element={<DeckGame game="memory" type="section" isPublic={false}  />} />
                
                
                <Route path="/decks/:id/flashcards" element={<DeckGame game="flashcards" type="deck" isPublic={true} />} />
                <Route path="/decks/:id/quiz" element={<DeckGame game="quiz" type="deck" isPublic={true} />} />
                <Route path="/decks/:id/memory" element={<DeckGame game="memory" type="deck" isPublic={true} />} />
                <Route path="/decks/:id/browse" element={<DeckGame game="browse" type="deck" isPublic={true} />} />
                <Route path="/decks/:id/input" element={<DeckGame game="input" type="deck" isPublic={true} />} />
                <Route path="/decks/:id/mix" element={<DeckGame game="mix" type="deck" isPublic={true} />} />
                <Route path="/decks/:id/write-hanzi" element={<DeckGame game="write-hanzi" type="deck" isPublic={true} />} />
                <Route path="/decks/:id/animate-hanzi" element={<DeckGame game="animate-hanzi" type="deck" isPublic={true} />} />
                <Route path="/decks/:id/stroke-order-hanzi" element={<DeckGame game="stroke-order-hanzi" type="deck" isPublic={true} />} />

                <Route path="/course/:courseId/lesson/:lessonId" element={<CourseLesson />} /> 

                <Route path="/my-decks/category/:id/input" element={<DeckGame game="input" type="category" isPublic={false}  />} />
                <Route path="/my-decks/category/:id/mix" element={<DeckGame game="mix" type="category" isPublic={false}  />} />
                <Route path="/my-decks/section/:id/input" element={<DeckGame game="input" type="section" isPublic={false}  />} />
                <Route path="/my-decks/section/:id/mix" element={<DeckGame game="mix" type="section" isPublic={false}  />} />
                <Route path="/texts/:targetLang/:text_id/:slug" element={<StudyText location={"public"} />} />
                <Route path="/my-texts/:text_id/study" element={<StudyText location={"library"} />} />

                <Route element={<DashboardLayout />}>        

                  <Route path="/auth/action" element={<AuthHandler />} />
                  
              
                  
                  <Route path="/my-texts/:id" element={<EditMyText />} />
                  <Route path="/my-courses/:courseId" element={<EditMyCourse />} />
                  
                  
                  <Route path="/my-vocabulary/:targetLang/item/:id" element={<VocabularyItemPage />} />
                  
                  <Route path="/analytics" element={<Analytics />} />

                  
                  <Route element={<PrivateRouteWrapper />}>
                    
                    <Route path="/premium" element={<Premium />} />
                    <Route path="/import" element={<ImportContentPage />} />
                    <Route path="/import/decks/csv" element={<ImportCSVToDeck design="page" mode="create-deck" type={"csv"} />} />
                    <Route path="/import/decks/quizlet" element={<ImportCSVToDeck design="page" mode="create-deck"  type={"quizlet"} />} />
                    <Route path="/import/decks/table" element={<ImportCSVToDeck design="page" mode="create-deck" type={"table"} />} />
                    <Route path="/import/decks/input-text" element={<ImportCSVToDeck design="page" mode="create-deck"  type={"inputText"} />} />

                    <Route path="/my-calendar" element={<MyCalendar />} />
                    <Route path="/email-verification" element={<EmailVerificationPage />} />
                    <Route path ="/watch" element={<WatchYouTube />} />
                    <Route path="/premium/success" element={<PremiumSuccess />} />
                    <Route path="/my-vocabulary/play" element={<DeckPlay type="vocabulary" isPublic={false} />} />
                    <Route path="/my-decks/:id/play" element={<DeckPlay type="deck" isPublic={false} />} />
                    <Route path="/my-decks/cards" element={<MyDeckCards />} />
                    <Route path="/my-decks/play" element={<DeckPlay type="all-decks" isPublic={false} />} />
                    <Route path="/my-decks" element={<MyDecks />} />
                    <Route path="/my-decks/:id" element={<Vocabulary />} />

                    <Route path="/my-folders/:id/decks/play" element={<DeckPlay type="folder" folderMode="decks" isPublic={true} />} />
                    <Route path="/my-folders/:id/all/play" element={<DeckPlay type="folder" folderMode="all" isPublic={true} />} />
                    <Route path="/my-decks/:id/export" element={<ExportMyDeck />} />
                    <Route path="/my-decks/:id/export/csv" element={<ExportCSV />} />
                    <Route path="/my-decks/:id/export/pdf" element={<ExportPDF />} />
                    <Route path="/my-decks/:id/export/quiz" element={<ExportPDFQuiz />} />
                    
                    <Route path="/my-decks/:id/import" element={<ImportDeckPage />} />
                    <Route path="/my-decks/:id/settings" element={<IndividualDeckSettings />} />
                    <Route path="/my-decks/:id/import/csv" element={<ImportCSVToDeck design="page" mode="edit-deck" type={"csv"} />} />
                    <Route path="/my-decks/:id/import/quizlet" element={<ImportCSVToDeck design="page" mode="edit-deck"  type={"quizlet"} />} />
                    <Route path="/my-decks/:id/import/table" element={<ImportCSVToDeck design="page" mode="edit-deck" type={"table"} />} />
                    <Route path="/my-decks/:id/import/input-text" element={<ImportCSVToDeck design="page" mode="edit-deck"  type={"inputText"} />} />
                    
                    <Route path="/tools/translator" element={<Translator />} />
                    <Route path="/my-library" element={<MyLibrary libraryPage={"library"}  />} />
                    <Route path="/my-library/folder/:folderDocId" element={<MyLibrary libraryPage={"folder"} />} />
                    <Route path ="/my-favorites" element={<MyFavorites />} />
                    
                    <Route path="/my-tables" element={<MyTables />} />
                    <Route path="/my-goals/daily" element={<MyDailyGoals />} />
                    <Route path ="/my-goals" element={<MyGoals />} />
                    <Route path="/my-tables/:id" element={<MyTable />} />   
                    <Route path="/my-tables/:id/play" element={<MyTablePlay />} />      
                    <Route path="/my-notes" element={<MyNotes />} />
                    <Route path="/my-notes/:id" element={<EditMyNote />} /> 
                    <Route path="/stats/daily" element={<DailyStats />} />

                    
                    <Route path="/my-feedback" element={<MyFeedback />} />
                    <Route path="/onboarding" element={<Onboarding />} />
                    <Route path="/my-statistics" element={<MyStatistics />} />
                    <Route path="/my-statistics/flashcards" element={<MyFlaschardsStatistics />} />
                    <Route path="/roadmap" element={<Roadmap />} />
                    <Route path="/my-profile" element={<MyProfile />} />
                    <Route path="/tools" element={<Tools />} />
                    <Route path="/my-vocabulary" element={<MyVocabulary />} />
                    <Route path="/my-vocabulary/spaced-repetition" element={<MyVocabularySpacedRepetition />} />
                    <Route path="/my-vocabulary/export" element={<MyVocabularyExport />} />
                    
                    <Route path="/dashboard" element={<CleanDashboard />} />
                    <Route path="/play" element={<Play />} />
                    <Route path="/old-dashboard" element={<Dashboard />} />
                    <Route path="/settings" element={<MainSettings />} />
                    <Route path="/settings/profile" element={<ProfileSettings />} />
                    <Route path="/settings/email" element={<EmailSettings />} />
                    <Route path="/settings/decks" element={<DeckSettings />} />
                    <Route path="/settings/account" element={<AccountSettings />} />
                    <Route path="/my-languages" element={<MyLanguages />} />  
                    <Route path="/" element={<CleanDashboard />} /> 
                  </Route>
              
                </Route>
                <Route element={<DashboardLayout />}>
                  <Route path="/languages" element={<AllLanguages />} />
                  <Route path="/learn/:language" element={<LanguagePage />} />
                  <Route path="/course/:courseId" element={<CourseHome />} /> 

                  <Route path="/learn/Mandarin-Chinese/hanzi-to-pinyin" element={<HanziToPinyinConverterPage />} />
                  <Route path="/decks/:id/play" element={<DeckPlay type="deck" isPublic={true} />} />
                  <Route path={"/post/:commentDocId"} element={<PostPage />} />
                  <Route path="/decks/section/:id/play" element={<DeckPlay type="section" isPublic={true} />} />
                  <Route path="/decks/category/:id/play" element={<DeckPlay type="category" isPublic={true} />} />
                  <Route path="/explore" element={<Explore />} />
                  <Route path="/decks" element={<DecksDirectory />} />
                  <Route path="/decks/:id" element={<PublicDeck />} />
                  <Route path="/login" element={process.env.NODE_ENV === 'development' ? <Login /> : <ExternalRedirect url="https://yalango.com/login" />} />
                  <Route path="/register" element={process.env.NODE_ENV === 'development' ? <Register /> : <ExternalRedirect url="https://yalango.com/register" />} />
                  <Route path="/login/forgotten-password" element={<ForgottenPassword />} />
                  <Route path="/logout" element={<LogOutComponent />} />
                  <Route path="/resources" element={<ResourcesDirectory />} />
                  <Route path="/u/:username" element={<UserProfile />} />
                  <Route path="/u/:username/decks" element={<UserProfileDecks />} />
                  <Route path="/forums" element={<Forums />} />
                  <Route path="/texts" element={<TextsDirectory />} />
                  <Route path="/feed" element={<CommentFeed />} />
                  <Route path="/forums/:language" element={<CommentFeed />} />
                </Route>
              </Routes>
              
              </SocialProvider>
              </MyContentProvider>
              </UserStatisticsProvider>
              </UserInfoProvider>
                  </LanguagesProvider>
            </AuthProvider>
          </div>
        </ThemeProvider>
      </Router>
    </>
  );
}

export default App;
