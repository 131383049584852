import {projectFirestore, timeStamp} from '../firebase/config.js';
import { useAuth } from "../contexts/AuthContext";
import {useUserInfoContext} from '../contexts/UserInfoContext';
import { useLocation } from "react-router-dom";
import {
  getFirestore,
  collection,
  query,
  where,
  setDoc,
  getDoc,
  getDocs,
  doc,
  deleteDoc,
  addDoc,
  updateDoc,
  onSnapshot,
  serverTimestamp,
  orderBy,
  limit,
  startAfter,
  endBefore,
  limitToLast, 
  Timestamp
} from "firebase/firestore";
import {  httpsCallable } from "firebase/functions";

export default function useQuotas() {
    let {currentUser} = useAuth();
    const location = useLocation();

    const getGoogleTranslateProcessedDocNameForCurrentMonth = () => {
        let date  = new Date();
        let monthNumber = date.getMonth()+1;
        let year = date.getFullYear();
        let docName = monthNumber+"_"+year;
        return docName;
      }
      
      const getNumberOfTranslationsThisMonthFromCurrentUser = async () => {
        if (currentUser === null) { return false; }
    
        const docName = getGoogleTranslateProcessedDocNameForCurrentMonth();
        
        // Use the modular API to create a document reference
        const docRef = doc(
            projectFirestore,
            "users",
            currentUser.uid,
            "private-data",
            "api-usage",
            "processed",
            "google-translate-items",
            "months",
            docName
        );
        
        // Fetch the document using the modular API
        const snapshot = await getDoc(docRef);
        
        let data = null;
        if (snapshot.exists()) {
            data = snapshot.data();
        }
        return data;
    };

    const getFreeQuotaLimits = () => {
        let googleTranslateTranslations = 50;
      
        return {
          'numberOfGoogleTranslations': googleTranslateTranslations
        }
      }

    return {
        getNumberOfTranslationsThisMonthFromCurrentUser, 
        getFreeQuotaLimits, 
        getGoogleTranslateProcessedDocNameForCurrentMonth
    }
}