import {projectFirestore, projectFunctions, timeStamp} from '../firebase/config.js';
import { useAuth } from "../contexts/AuthContext";
import {
    getFirestore,
    collection,
    query,
    where,
    setDoc,
    getDoc,
    getDocs,
    doc,
    deleteDoc,
    addDoc,
    updateDoc,
    onSnapshot,
    serverTimestamp,
    orderBy,
    limit,
    startAfter,
    endBefore,
    limitToLast, 
    Timestamp
} from "firebase/firestore";
import {  httpsCallable } from "firebase/functions";

export default function useResources() {
    
    const getAllResources = async () => {
        const ref = collection(projectFirestore, "resources");  // Reference to the 'resources' collection
    
        try {
            const snapshots = await getDocs(ref);  // Fetching all documents in the collection
            if (!snapshots.empty) {
                let l = [];
                snapshots.docs.forEach((doc) => {
                    let o = doc.data();  // Get document data
                    o['doc_id'] = doc.id;  // Add document ID to the data
                    l.push(o);  // Add the resource to the list
                });
                return l;
            } else {
                return [];  // Return empty array if no documents exist
            }
        } catch (err) {
            console.log(err);  // Handle any errors that occur during the fetch
            return [];
        }
    }

    return {
        getAllResources
    }
}