import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import useUserSettings from '../hooks/useUserSettings';
import { useAuth } from "../contexts/AuthContext";
import YalangoHead from '../assets/svg/yalango/YalangoHead.svg';
import Icon from '../components/general/Icon';
import CountryFlag from '../components/languages/CountryFlag';
import RowOfCards from '../components/general/RowOfCards';
import UserFeed from '../components/comments/UserFeed';

import useLanguages from '../hooks/useLanguages';
import useDecks from '../hooks/useDecks';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/pro-duotone-svg-icons';
import { faGlobe } from '@fortawesome/pro-duotone-svg-icons';
import { faInfo } from '@fortawesome/pro-duotone-svg-icons';
import { faArrowDown } from '@fortawesome/pro-solid-svg-icons';
import { faArrowUp } from '@fortawesome/pro-solid-svg-icons';
import { faUserFriends } from '@fortawesome/pro-duotone-svg-icons';

import {Link} from 'react-router-dom';

const UserProfile = () => {
    let { username } = useParams();
    let {currentUser} = useAuth();
    let {fetchPublicUserInfoFromUsername} = useUserSettings();
    let {getUserLanguagesFromUid, getAllLanguages} = useLanguages();
    let {fetchAllPublicDecksDataFromOneUser} = useDecks();

    const [publicProfileData, setPublicProfileData] = useState(null);
    const [userLanguages, setUserLanguages] = useState(null);
    const [allLanguages, setAllLanguages] = useState(null);
    const [languages, setLanguages] = useState(null);
    const [decks, setDecks] = useState(null);
    const [selectedTab, setSelectedTab] = useState("feed");

    const [decksLoading, setDecksLoading] = useState(true);
    
    const [ownProfile, setOwnProfile] = useState(null);

    const [publicProfileDataLoading, setPublicProfileDataLoading] = useState(true);
    const [userLanguagesLoading, setUserLanguagesLoading] = useState(true);
    const [allLanguagesLoading, setAllLanguagesLoading] = useState(true);

    const [showLanguageDetails, setShowLanguageDetails] = useState(false);
    const [showFriendsDetails, setShowFriendsDetails] = useState(false);

    const [languagesLoading, setLanguagesLoading] = useState(true);

    const decksLimit = 4;

    let languageLevelOptions = [{value: 'zero', label:'Wishlist'}, {value: 'A1', label:'A1'}, {value:'A2', label:'A2'}, {value:'B1', label:'B1'}, {value:'B2', label:'B2'}, {value:'C1', label:'C1'}, {value:'C2', label:'C2'}, {value: 'native', label:'Native'}];

    useEffect(()=>{

        const addMissingFields = (public_data) => {
            if (!('public_profile' in public_data)){
                public_data['public_profile'] = false;
            }
            return public_data;
        }

        const fetchData = async () => {
            let temp_uid;
            if (currentUser !== null){
                temp_uid = currentUser.uid;
            } else {
                temp_uid = null;
            }
            let [public_data, own_profile_bool] = await fetchPublicUserInfoFromUsername(username,temp_uid);
            //console.log("Own profile: ", own_profile_bool);
            if (own_profile_bool){
                setOwnProfile(true);
            } else {
                setOwnProfile(false);
            }
            //console.log(public_data);
            public_data = addMissingFields(public_data);
            let user_languages = await getUserLanguagesFromUid(public_data.uid);
            //console.log("trying all languages");
            let all_languages = await getAllLanguages();
            setLanguages({'user': user_languages, 'all': all_languages});
            setPublicProfileData(public_data);
        }

        fetchData();
    },[currentUser, username]);

    useEffect(()=>{

    },[getAllLanguages, ownProfile, languagesLoading, fetchPublicUserInfoFromUsername, username, currentUser, userLanguagesLoading, publicProfileDataLoading, getUserLanguagesFromUid]);

    useEffect(()=>{
        if (languages !== null){
            setLanguagesLoading(false);
        }
    },[languages]);

    useEffect(()=>{
        if (ownProfile !== null && publicProfileData !== null){
            setPublicProfileDataLoading(false); 
            //console.log(publicProfileData);
        }
    },[ownProfile, publicProfileData])

    useEffect(()=>{
        const fetchData = async () => {
            let r = await fetchAllPublicDecksDataFromOneUser(publicProfileData.uid, decksLimit);
            //console.log(r);
            setDecks(r);
        }
        if(publicProfileData !== null){
            if ('uid' in publicProfileData){
                fetchData();
            }  
        };

    },[publicProfileData]);

    useEffect(()=>{
        if (decks !== null && !allLanguagesLoading){
            setDecksLoading(false);
        }
    },[decks, allLanguagesLoading]);

    useEffect(()=>{
        if (languages !== null){
            //console.log(languages);
            setAllLanguagesLoading(false);
        }
    },[languages]);

    let iconsDict = {
        private: faLock,
        friends: faUserFriends,
        public: faGlobe
    };

    const toggleLanguageBox = () => {
        setShowLanguageDetails(!showLanguageDetails);
    }

    const toggleFriendsBox = () => {
        setShowFriendsDetails(!showFriendsDetails);
    }  

    /*
 <div onClick={toggleLanguageBox} className="relative cursor-pointer flex bg-persian-green rounded-md text-white p-3 flex-row gap-3 items-center text-xl justify-center">
                                        <div className="text-black">
                                            {(ownProfile && !publicProfileDataLoading) && 
                                            <>
                                                <Link to="/settings/profile">
                                                    {'language_privacy' in publicProfileData ? 
                                                        <>
                                                            <Icon icon={<FontAwesomeIcon icon={iconsDict[publicProfileData['language_privacy']]} />} />
                                                        </> 
                                                    : 
                                                        <>
                                                            <Icon icon={<FontAwesomeIcon icon={iconsDict['private']} />} />
                                                        </>
                                                    }
                                                </Link> 
                                            </>
                                            }
                                        </div>
                                        <div className="font-bold ">
                                            Languages
                                        </div>
                                        <div className="absolute right-0 p-3">
                                            <Icon icon={<FontAwesomeIcon icon={showLanguageDetails ? faArrowUp : faArrowDown} />} />
                                        </div>
                                    </div>
    */
//<CountryFlag countryCode={languages['all'][0][value.target_language]['country_flag']} flagName={languages['all'][0][value.target_language]['name']['en']} /> 
//bg-gradient-to-r rounded-xl shadow-xl from-persian-green to-pretty-blue    

function isValidHttpUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }

return (
    <>
    <div className="py-5 ">
      <div className="flex flex-col gap-4">
        {/* Profile Header */}
        <div className="relative">
          <div className="text-charcoal dark:text-gray-200 inset-0 flex gap-3 items-center justify-center">
            <div className="bg-white dark:bg-gray-700 rounded-full shadow-xl w-32 h-32 overflow-hidden">
              <img
                src={YalangoHead}
                className="w-4/6 m-auto h-full"
                alt="profile"
              />
            </div>
            <div className="flex flex-col gap-2">
              <div className="text-xl font-medium">
                {!publicProfileDataLoading && publicProfileData.displayname}
              </div>
              <div className="text-sm text-gray-600 dark:text-gray-400">
                @{!publicProfileDataLoading && publicProfileData.username}
              </div>
            </div>
          </div>
        </div>
  
        {/* Profile Introduction */}
        <div className="px-4 py-6 flex flex-col gap-6">
          {publicProfileData !== null && (
            <>
              <div className="text-gray-700 dark:text-gray-300 text-center w-full m-auto sm:w-1/2">
                {publicProfileData.hasOwnProperty("introduction")
                  ? publicProfileData.introduction
                  : "Welcome to my profile on Yalango!"}
              </div>
              {publicProfileData.hasOwnProperty("website") &&
                publicProfileData.website !== "" &&
                publicProfileData.website !== null &&
                isValidHttpUrl(publicProfileData.website) && (
                  <div className="text-gray-700 dark:text-gray-300 text-center w-full m-auto sm:w-1/2">
                    Website:{" "}
                    <a
                      href={publicProfileData.website}
                      rel="nofollow noreferrer"
                      target="_blank"
                      className="text-persian-green hover:underline"
                    >
                      {publicProfileData.website}
                    </a>
                  </div>
                )}
            </>
          )}
  
          {/* Tabs */}
          <div className="flex flex-row justify-center gap-4">
            <div
              onClick={() => setSelectedTab("feed")}
              className={`${
                selectedTab === "feed" ? "bg-charcoal text-white" : "text-gray-600 dark:text-gray-300"
              } rounded-xl shadow-xl p-2 border border-solid border-charcoal cursor-pointer`}
            >
              Feed
            </div>
            <div
              onClick={() => setSelectedTab("languages")}
              className={`${
                selectedTab === "languages"
                  ? "bg-charcoal text-white"
                  : "text-gray-600 dark:text-gray-300"
              } rounded-xl shadow-xl p-2 border border-solid border-charcoal cursor-pointer`}
            >
              Languages
            </div>
            <div
              onClick={() => setSelectedTab("decks")}
              className={`${
                selectedTab === "decks"
                  ? "bg-charcoal text-white"
                  : "text-gray-600 dark:text-gray-300"
              } rounded-xl shadow-xl p-2 border border-solid border-charcoal cursor-pointer`}
            >
              Decks
            </div>
          </div>
  
          {/* Languages Tab */}
          {selectedTab === "languages" && (
            <div className="mt-4">
              <div className="font-medium text-lg mb-2 text-gray-700 dark:text-gray-300">
                Languages
              </div>
              <div className="w-full p-3 overflow-auto">
                {!allLanguagesLoading && languageLevelOptions !== null && (
                  <div className="flex flex-col gap-2">
                    {languageLevelOptions.map((item, index) => (
                      <div
                        className="flex flex-row place-items-center gap-8"
                        key={`row_${index}`}
                      >
                        <div className="text-left w-16 font-medium text-gray-700 dark:text-gray-300">
                          {item.label}
                        </div>
                        <div className="flex flex-row place-items-center gap-2">
                          {languages !== null &&
                            languages.user.map((value) => (
                              value.level === item.value && (
                                <div
                                  className="p-2 bg-white dark:bg-gray-700 rounded-lg shadow-lg"
                                  key={`lang_${value.target_language}`}
                                >
                                  <Link
                                    className="no-underline text-persian-green"
                                    to={`https://yalango.com/learn/${languages.all[0][value.target_language].name.en.replace(
                                      " ",
                                      "-"
                                    ).toLowerCase()}`}
                                  >
                                    {languages.all[0][value.target_language].name.en}
                                  </Link>
                                </div>
                              )
                            ))}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}
  
          {/* Decks Tab */}
          {selectedTab === "decks" && !decksLoading && (
            <>
              <div className="text-left text-2xl p-4 text-gray-700 dark:text-gray-200">
                {(publicProfileData.displayname || publicProfileData.username) + "'s public decks"}
              </div>
              <RowOfCards languages={languages.all} items={decks} />
              {decks.length >= decksLimit && (
                <div className="p-4">
                  <Link to={`/u/${publicProfileData.username}/decks`} className="text-persian-green hover:underline">
                    See all decks
                  </Link>
                </div>
              )}
              {decks.length === 0 && (
                <div className="text-left px-4 italic text-gray-600 dark:text-gray-400">
                  {publicProfileData.displayname || publicProfileData.username} currently has no public
                  decks.
                </div>
              )}
            </>
          )}
  
          {/* Feed Tab */}
          {selectedTab === "feed" && publicProfileData?.uid && (
            <div className="p-4">
              <UserFeed uid={publicProfileData.uid} />
            </div>
          )}
        </div>
      </div>
    </div>
  </>
  
    )
}

const LanguageRow = ({languages, level}) => {
    return (
        <>
            <tr>
                <td className="text-left italic"> 
                    {level.label}
                </td>
                <td>
                    <div className="flex flex-row gap-1">
                        {languages.user.map((value)=>(
                            <>
                                {(value.level !== null && value.level === level.value) &&
                                    <>
                                    <div key={"lang_"+value.target_language}>
                                        <Link to={'/learn/'+ languages['all'][0][value.target_language]['name']['en'].replace(' ', '-') }> <CountryFlag countryCode={languages['all'][0][value.target_language]['country_flag']} flagName={languages['all'][0][value.target_language]['name']['en']} /> </Link>
                                    </div>
                                    </>
                                }
                            </>
                        ))}
                    </div>
                </td>
            </tr>
        </>
    )
}

export default UserProfile;
