import {projectFirestore, timeStamp} from '../firebase/config.js';
import { useAuth } from "../contexts/AuthContext";
import {useUserInfoContext} from '../contexts/UserInfoContext';
import { useLocation } from "react-router-dom";
import { useCallback } from 'react';

import {
    getFirestore,
    collection,
    query,
    where,
    setDoc,
    getDoc,
    getDocs,
    doc,
    deleteDoc,
    addDoc,
    updateDoc,
    onSnapshot,
    serverTimestamp,
    orderBy,
    limit,
    startAfter,
    endBefore,
    limitToLast, 
    Timestamp
} from "firebase/firestore";
import {  httpsCallable } from "firebase/functions";


export default function useLogs() {
    let {currentUser} = useAuth();
    const location = useLocation();

    const saveErrorLog = async ({ errorObject, functionName }) => {
        const path = collection(projectFirestore, "logs", "errors", "items");
        const obj = {
            created_timestamp: serverTimestamp(),
            last_updated_timestamp: serverTimestamp(),
            uid: currentUser ? currentUser.uid : null,
            error_location: "client",
            location_path_name: location.pathname,
            location_search: location.search,
        };
    
        if (errorObject) {
            if (errorObject.name) obj.error_name = errorObject.name;
            if (errorObject.message) obj.error_message = errorObject.message;
            if (errorObject.stack) obj.error_stack = errorObject.stack;
        }
        if (functionName) obj.function_name = functionName;
    
        await addDoc(path, obj).catch((err) => console.log(err));
    };
    
    const addExportDeckLog = useCallback(async ({ deckId, exportType }) => {
        const path = collection(projectFirestore, "user-actions", "export-decks", "logs");
        const obj = {
            created_timestamp: serverTimestamp(),
            deckId,
            exportType,
            uid: currentUser ? currentUser.uid : null,
        };
    
        await addDoc(path, obj).catch((err) => console.log(err));
    }, []);

    return {
        saveErrorLog,
        addExportDeckLog
    }
}