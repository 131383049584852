import {projectFirestore, projectFunctions, timeStamp, firebase} from '../firebase/config.js';
import {  httpsCallable } from "firebase/functions";

export default function useTextToSpeech() {

    //projectFunctions.useEmulator("localhost", 5001); // local testing

    const textToSpeechWithGoogle = async (card, type) => {
        const func =  httpsCallable(projectFunctions, 'textToSpeechWithGoogle');
        const results = await func({'card': card, 'type': type});
        console.log("Results: ", results);
        return results['data'];
    }

    const getStandardForvoPronunciation = async (word, language) => {
        const f = httpsCallable(projectFunctions, 'getStandardForvoPronunciation');
        const results = await f({'word': word, 'language': language});
        return results['data'];
    }

    return {
        textToSpeechWithGoogle, 
        getStandardForvoPronunciation
    }
}

