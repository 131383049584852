import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { useLanguagesContext } from '../../contexts/LanguagesContext';
import { useUserStatisticsContext } from '../../contexts/UserStatisticsContext';
import CustomSkeleton from '../general/CustomSkeleton';

// Register ChartJS components once globally
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const AnalyticsCard = ({ language, timePeriod }) => {
  const { activeUserLanguages, allLanguages } = useLanguagesContext();
  const { processedVocabularyData, dailyStatsNeedRefresh, refreshProcessedVocabularyDataInTargetLanguagesInMonth } = useUserStatisticsContext();

  const [lineChartData, setLineChartData] = useState(null);
  const [lineChartOptions, setLineChartOptions] = useState(null);
  const [loading, setLoading] = useState(true);

  const today = new Date();
  const [month] = useState(today.getMonth());
  const [year] = useState(today.getFullYear());

  useEffect(() => {
    const loadData = async () => {
      if (!processedVocabularyData) {
        setLoading(true);
        await refreshProcessedVocabularyDataInTargetLanguagesInMonth({ month, year });
        setLoading(false);
      }
    };

    loadData();
  }, [processedVocabularyData, month, year]);

  useEffect(() => {
    if (!processedVocabularyData?.[year]?.[month]) {
      setLineChartData(null);
      return;
    }

    const finalData = { labels: [], datasets: [] };
    const colors = ['#3deb34', '#34dceb', '#6234eb', '#eb34c9', '#eb3456'];
    const langs = Object.keys(processedVocabularyData[year][month]);

    langs.forEach((lang, index) => {
      const languageData = processedVocabularyData[year][month][lang];
      const color = colors[index % colors.length];

      if (index === 0) {
        finalData.labels = Object.keys(languageData).map(day => `${day}.${month + 1}`);
      }

      finalData.datasets.push({
        label: `Correct ${allLanguages?.[0]?.[lang]?.name?.en} exercises`,
        data: Object.values(languageData).map(value => value?.total_correct_items || 0),
        borderColor: color,
        backgroundColor: color,
      });
    });

    setLineChartData(finalData);

    setLineChartOptions({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: { position: 'top' },
        title: { display: false },
      },
      elements: { line: { tension: 0.4 } },
      scales: {
        x: { grid: { display: false } },
        y: { min: 0, ticks: { stepSize: 1 }, grid: { display: false } },
      },
    });
  }, [processedVocabularyData, year, month, allLanguages]);

  return (
    <div className="w-full">
      {loading ? (
        <CustomSkeleton height={350} count={1} />
      ) : lineChartData ? (
        <div className="min-h-80 h-96 p-2">
          <Line options={lineChartOptions} data={lineChartData} />
        </div>
      ) : (
        <div className="text-center muted-text">No data to show.</div>
      )}
    </div>
  );
};

export default AnalyticsCard;
