import { faCardsBlank, faFolder } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {useState, useEffect} from "react";
import {Link, useParams} from "react-router-dom";
import ViewFolderPath from "./ViewFolderPath";
import LibraryItemMenu from "../../pages/mylibrary/LibraryItemMenu";
import DecksCard from "../../pages/decks/DecksCard";
import TableCard from "../tables/TableCard";
import NoteCard from "../notes/NoteCard";
import TextCard from "../texts/components/TextCard";
import CourseCard from "../courses/CourseCard";

const MyLibraryGrid = (props) => {
    const {openCreateSubItemModal, libraryItemMenuProps, folderDocId, filteredItems, libraryPage, renameItemClick, deleteItemClick, filterContentList, outputDesign, closeCreateItemModal, openCreateSubFolderModal, openCreateFolderModal, setMoveItemCurrentParent, changeItemFolderClick, moveItemModalItem, setMoveItemModalItem, showMoveItemModal, setShowMoveItemModal, title, onDragEndFunction, menuItems, setMenuItems, showMenu, setShowMenu} = props;
    const [showingLibraryItemMenuID, setShowingLibraryItemMenuID] = useState(null);

    const menuClick = () => {
        setShowMenu(!showMenu);
    }

    const backdropClick = (e) => {
        e.stopPropagation();
        setShowingLibraryItemMenuID(null);
    }

    return (
        <>
            <div className="flex flex-col gap-6">
                <div>
                    <ViewFolderPath folderDocId={folderDocId} menuItems={menuItems} />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-3 4xl:grid-cols-4 place-items-center justify-center">
                        {filteredItems.map((item, itemIndex) => (
                            <>
                                {item.save_to_db !== false &&
                                    <>
                                        <div className="size-64">
                                            <GridItem item={item} itemIndex={itemIndex} libraryItemMenuProps={{...libraryItemMenuProps, showingLibraryItemMenuID, setShowingLibraryItemMenuID}} />
                                        </div>
                                    </>
                                }

                            </>
                        ))}
                    
                </div>
            </div>
        </>
    )
};

const GridItem = (props) => {
    const {item, libraryItemMenuProps, itemIndex} = props;
   
    const linkClass = "";
    const folderClass = "cursor-pointer size-64 ";

    const menuProps = {...libraryItemMenuProps, item, libraryItemMenuID:itemIndex};

    /*
    <div className={`${cardClass} relative text-center flex flex-row justify-between`}>
                            <Link className={` ${linkClass} flex-grow no-underline h-full`} to={item.link}>
                                <div className={` flex  flex-col relative justify-center h-full`}>
                                    <div className="flex-grow flex flex-col gap-3 justify-center place-items-center">
                                        <div className="text-lg font-semibold muted-text ">
                                            {item.text}
                                        </div>
                                        <div className="muted-text font-bold text-xs">
                                        {item.hasOwnProperty("number_of_items") && item.hasOwnProperty("content_type") &&
                                            <>
                                                {item.number_of_items} {item.content_type === "deck" ? "cards" : item.content_type === "table" ? "rows" : ""}
                                            </>
                                        }
                                    </div>
                                        <div className="text-sm">
                                            {item.hasOwnProperty("original_object") && item.original_object.hasOwnProperty("description") && item.original_object.description}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            <div>
                                <LibraryItemMenu {...menuProps} /> 
                            </div>
                        </div>
    */
    return (
        <>
            <div className="w-full h-full">
                {item.type === "link" &&
                    <>
                        <div className="relative w-full h-full">
                        {item.hasOwnProperty("content_type") && item.content_type === "deck" &&
                            <>
                                <DecksCard location={"library"} deck={item.original_object} showAdminOptions={true} />
                            </>
                        }
                        {item.hasOwnProperty("content_type") && item.content_type === "table" &&
                            <>
                                <TableCard item={item} />
                            </>
                        }
                       {item.hasOwnProperty("content_type") && item.content_type === "note" &&
                            <>
                                <NoteCard item={item} />
                            </>
                        }
                        {item.hasOwnProperty("content_type") && item.content_type === "text" &&
                            <>
                                <TextCard location={"library"} text={item.original_object} showAdminOptions={true} />
                            </>
                        }
                        {item.hasOwnProperty("content_type") && item.content_type === "course" &&
                            <>
                                <CourseCard location={"library"} course={item.original_object} showAdminOptions={true} />
                            </>
                        }
                            <div className="absolute right-0 w-full text-lg top-2 cursor-pointer text-white">
                                <LibraryItemMenu {...menuProps} /> 
                            </div>
                        </div>
                        
                    </>
                }
                
                {item.type === "folder" &&
                    <>
                        <div className="relative w-full h-full">
                        <Link
                            className={`${linkClass} flex-grow no-underline`}
                            to={`/my-library/folder/${item.doc_id}`}
                        >
                            <div
                                className={`${folderClass} relative flex flex-col items-center text-center justify-between p-6 rounded-lg bg-white dark:bg-gray-800 hover:shadow-lg transition-shadow duration-300`}
                            >
                                {/* Folder Link */}

                                    <div className="flex flex-col items-center justify-center h-full relative text-gray-800 dark:text-gray-200">
                                        {/* Folder Icon */}
                                        <div className="bg-gray-100 dark:bg-gray-700 text-gray-500 dark:text-gray-400 rounded-full p-6 shadow-sm mb-4">
                                            <FontAwesomeIcon icon={faFolder} className="text-5xl" />
                                        </div>

                                        {/* Folder Content */}
                                        <div className="flex flex-col gap-2">
                                            {/* Folder Name */}
                                            <div className="text-lg font-semibold dark:text-white">{item.text}</div>

                                            {/* Items Count */}
                                            {item.children?.length > 0 ? (
                                                <div className="text-sm font-medium text-gray-500 dark:text-gray-400">
                                                    {item.children.length} {item.children.length === 1 ? "item" : "items"}
                                                </div>
                                            ) : (
                                                <div className="text-sm font-medium text-gray-400 dark:text-gray-500">
                                                    Empty
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    </div>
                                </Link>

                                {/* Menu Options */}
                                <div className="absolute top-2  cursor-pointer right-0 w-full">
                                    <LibraryItemMenu
                                        iconColor={
                                            "text-gray-500 cursor-pointer dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200 transition-colors duration-300"
                                        }
                                        {...menuProps}
                                    />
                                </div>
                        
                       </div>
                </>
                }
            </div>
        </>
    )
}

export default MyLibraryGrid;